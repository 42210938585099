import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { change } from 'redux-form';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import Button from '@material-ui/core/Button';
import Fuse from 'fuse.js';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DocuSignForm from './components/DocuSignForm/DocuSignForm';
import GroupForm from './components/GroupForm/GroupForm';

export class CrudDocuSign extends Component {
  state = {
    loading: true,
    open: false,
    error: '',
    document: {
      templateId: '',
      roleName: '',
      templateName: '',
      props: {},
    },
    docuSign: [],
    result: [],
  };

  async componentDidMount() {
    await this.loadDocuSign();
  }

  search = (search) => {
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['templateName'],
    };
    const fuse = new Fuse(this.state.docuSign, options);

    return this.setState((oldState) => {
      const result = search ? fuse.search(search) : oldState.docuSign;
      return { result };
    });
  }

  loadDocuSign = async () => {
    try {
      const queryString = {
        query: `{
          docuSign(id: "all") {
            id
            templateId
            roleName
            templateName
            subQuery
            component
            signerOneCallBack
            signerTwoCallBack
            props
          }
        }`,
      };
      const req = await axios.post('/graphql/', queryString);
      this.setState({
        docuSign: req.data.data.docuSign,
        loading: false,
        error: '',
      });
      return this.search('');
    } catch (error) {
      return this.setState({
        error: error.toString(),
        loading: false,
      });
    }
  }

  deleteDocument = async (id) => {
    try {
      const queryString = {
        query: `mutation {
          docuSignDelete(
            id: ${id}
          ) { id }
        }`,
      };
      const req = await axios.post('/graphql/', queryString);

      return this.setState((oldState) => {
        const docuSign = oldState.docuSign.filter(doc => (
          doc.id !== req.data.data.docuSignDelete.id
        ));
        return { docuSign, result: docuSign };
      });
    } catch (error) {
      return this.setState({
        error: error.toString(),
        loading: false,
      });
    }
  }

  mapValuesToForm = async (props, form, component = {}) => {
    let selectedComponent;
    if (Object.entries(component).length === 0) {
      selectedComponent = this.props.formState[form].values
        ? this.props.formState[form].values.selectedComponent : props;
    } else {
      selectedComponent = component;
    }

    await this.props.dispatch(change(form, 'props', props));
    await this.props.dispatch(change(form, 'selectedComponent', selectedComponent));
    await this.props.dispatch(change(form, 'component', selectedComponent.component));
  }

  changeIndex = async (props = {}, form = 'DocuSignForm') => {
    const formValues = this.props.formState[form].values;
    const setProps = { ...formValues.props, ...props };
    await this.mapValuesToForm(setProps, form);
  }

  toggleDialog = (document = { props: {} }) => {
    const docuSign = [...this.state.docuSign];

    if (document.id && this.state.open) {
      const index = docuSign.findIndex(doc => doc.id === document.id);
      if (docuSign[index]) docuSign[index] = document;
      else docuSign.push(document);
    }

    this.setState(oldState => ({
      document,
      open: !oldState.open,
      docuSign,
    }));
    return this.search('');
  }

  render() {
    return (
      <>
        <DocuSignForm
          changeIndex={this.changeIndex}
          open={this.state.open}
          document={this.state.document}
          toggleDialog={this.toggleDialog}
          formState={this.props.formState}
          mapValuesToForm={this.mapValuesToForm}
        />

        {this.state.error && <h5>{this.state.error}</h5>}
        {this.state.loading
          ? (<AppLoader loading={this.state.loading} />)
          : (
            <Grid container>
              <Grid
                container
                item
                xs={6}
                justify="center"
                alignItems="center"
              >
                <TextField
                  onChange={e => this.search(e.target.value)}
                  label="Search by Template Name"
                  margin="normal"
                  variant="outlined"
                  style={{ width: '50%' }}
                />
              </Grid>
              <Grid container item xs={6}>
                <Button onClick={() => this.toggleDialog({
                  templateId: '', roleName: '', templateName: '', props: {},
                })}
                >
                  New document
                </Button>
              </Grid>
              <GroupForm
                changeIndex={this.changeIndex}
                toggleDialog={this.toggleDialog}
                deleteDocument={this.deleteDocument}
                docuSign={this.state.docuSign}
                result={this.state.result}
                loadDocuSign={this.loadDocuSign}
                formState={this.props.formState}
                dispatch={this.props.dispatch}
                mapValuesToForm={this.mapValuesToForm}
              />
            </Grid>
          )
        }
      </>
    );
  }
}

export default CrudDocuSign;

CrudDocuSign.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    DocuSignForm: PropTypes.shape({
      values: PropTypes.shape({
        selectedComponent: PropTypes.shape({}),
        props: PropTypes.shape({}),
      }),
    }),
  }).isRequired,
};
