import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Avatar from '@material-ui/core/Avatar';
import styles from './Center.module.scss';

export const Center = ({
  toggleView,
  leftComponent,
  rightComponent,
  centerComponent,
  renderThis,
  title,
}) => (
  <Grid container item xs={12} md={renderThis()}>
    <Card className={styles.card}>
      <CardHeader
        title={title}
        avatar={(
          <>
            {leftComponent && (
            <Avatar
              className={styles.onClick}
              onClick={() => toggleView('leftView')}
            >
              <KeyboardArrowLeft />
            </Avatar>
            )}
          </>
        )}
        action={(
          <>
            {rightComponent && (
            <Avatar
              className={styles.onClick}
              onClick={() => toggleView('rightView')}
            >
              <KeyboardArrowRight />
            </Avatar>
            )}
          </>
        )}
      />
      <CardContent>
        {centerComponent}
      </CardContent>
    </Card>
  </Grid>
);

export default Center;

Center.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  toggleView: PropTypes.func.isRequired,
  renderThis: PropTypes.func.isRequired,
  leftComponent: PropTypes.node,
  rightComponent: PropTypes.node,
  centerComponent: PropTypes.node,
};

Center.defaultProps = {
  leftComponent: null,
  rightComponent: null,
  centerComponent: null,
  title: '',
};
