import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocuSignTable from './components/DocuSignTable/DocuSignTable';
import GroupPropsForm from './components/GroupPropsForm/GroupPropsForm';

export class GroupForm extends Component {
  state = {
    open: false,
    documents: [],
  };

  toggleGroupFrom = (values = {}) => {
    const documents = values.documents ? values.documents.map(v => JSON.parse(v)) : [];
    this.setState(oldState => ({
      open: !oldState.open,
      documents,
    }));
  }

  render() {
    return (
      <>
        <GroupPropsForm
          dispatch={this.props.dispatch}
          loadDocuSign={this.props.loadDocuSign}
          changeIndex={this.props.changeIndex}
          formState={this.props.formState}
          open={this.state.open}
          documents={this.state.documents}
          toggleGroupFrom={this.toggleGroupFrom}
          mapValuesToForm={this.props.mapValuesToForm}
        />
        <DocuSignTable
          toggleGroupFrom={this.toggleGroupFrom}
          toggleDialog={this.props.toggleDialog}
          docuSign={this.props.docuSign}
          result={this.props.result}
          deleteDocument={this.props.deleteDocument}
          formState={this.props.formState}
        />
      </>
    );
  }
}

export default GroupForm;

GroupForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loadDocuSign: PropTypes.func.isRequired,
  changeIndex: PropTypes.func.isRequired,
  mapValuesToForm: PropTypes.func.isRequired,
  formState: PropTypes.shape({}).isRequired,
  toggleDialog: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  docuSign: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  result: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
