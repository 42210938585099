/* eslint-disable */

class RDGridHelpers {

  /**
  * indexRows takes and array and sets an id to them if you need to show 
  * grids array for sub view
  */
  static indexRows = (rows, isSubRow = '') => rows.map((r, i) => {
    const id = isSubRow ? `${isSubRow}-${i + 1}` : i + 1;
    return {
      ...r,
      id,
    };
  });

  static formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  };

}


export default RDGridHelpers;
