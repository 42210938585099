/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RDGridHelpers from 'utils/RDGridHelpers';
import DateHelpers from 'utils/DateHelpers';
import DthFieldCell from './components/DthFieldCell/DthFieldCell';
import NormalizeVolume from './components/NormalizeVolume/NormalizeVolume';

export class BodyCells extends Component {
  totalReceiptVolumeDay = (date) => {
    let value = 0;
    const formValue = this.props.formState.PathForm
      ? this.props.formState.PathForm.values : {};

    if (formValue[date]) {
      value = formValue[date].reduce((accumulator, currentValue) => {
        if (!currentValue.archived) {
          return accumulator + parseInt(currentValue.dth, 10);
        }
        return accumulator;
      }, 0);
    }

    return value;
  }

  setColor = (row, total) => {
    if (total === row.volume) return 'green';
    if (total > row.volume) return 'red';
    return 'black';
  }

  render() {
    return (
      <TableBody>
        {this.props.rows.map((row) => {
          const total = this.totalReceiptVolumeDay(row.name);

          return (
            <TableRow key={row.name}>
              <TableCell align="center" colSpan={1}>
                {this.props.showVolumes ? row.name.split('-')[2] : DateHelpers.monthDayYearFormat(row.name, 1)}
              </TableCell>
              <TableCell align="center" colSpan={1}>
                <NormalizeVolume
                  volume={row.volume}
                  locationId={this.props.locationId}
                  pipelineId={row.id}
                  gasDay={DateHelpers.monthDayYearFormat(row.name, 1)}
                  editPathBatch={this.props.editPathBatch}
                />
              </TableCell>
              <DthFieldCell
                disabled={this.props.disabled}
                sumReceiptVolumes={this.props.sumReceiptVolumes}
                sumDeliveryVolume={this.props.sumDeliveryVolume}
                formState={this.props.formState}
                dispatch={this.props.dispatch}
                editPathBatch={this.props.editPathBatch}
                showVolumes={this.props.showVolumes}
                row={row}
              />
              <TableCell
                align="center"
                style={{
                  color: this.setColor(row, total),
                }}
              >
                {RDGridHelpers.formatNumber(total)}
              </TableCell>
              <TableCell align="center" colSpan={1}>
                {RDGridHelpers.formatNumber((row.volume - total))}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
}

export default BodyCells;

BodyCells.propTypes = {
  locationId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  sumReceiptVolumes: PropTypes.func.isRequired,
  sumDeliveryVolume: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    PathForm: PropTypes.shape({
      values: PropTypes.shape({}),
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  editPathBatch: PropTypes.func.isRequired,
  showVolumes: PropTypes.bool.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  }).isRequired).isRequired,
};

BodyCells.defaultProps = {
  formState: {
    PathForm: {
      values: {},
    },
  },
};
