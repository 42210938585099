import React from 'react';
import PropTypes from 'prop-types';
import RFFInput from './components/RFFInput/RFFInput';
import ReduxFormInput from './components/ReduxFormInput/ReduxFormInput';

const ChoicePromoCodeInput = ({
  reactFinalFormInput,
  name,
  label,
  quote,
}) => {
  const isEligible = () => {
    const {
      totalUsages,
      accountList,
      term,
    } = quote.quoteData;

    const wy = quote.quoteData.accountList[0].serviceState.includes('WY');
    const eligibleVolue = accountList.length * 40;
    return !wy && term > 1 && eligibleVolue <= totalUsages;
  };

  return (
    <>
      {isEligible() && (
        <>
          {!reactFinalFormInput && (
          <ReduxFormInput
            name={name}
            label={label}
          />
          )}

          {reactFinalFormInput && (
          <RFFInput
            name={name}
            label={label}
          />
          )}
        </>
      )}
    </>
  );
};

export default ChoicePromoCodeInput;


ChoicePromoCodeInput.propTypes = {
  quote: PropTypes.shape({
    quoteData: PropTypes.shape({
      term: PropTypes.string,
      totalUsages: PropTypes.number,
      volume: PropTypes.number,
      accountList: PropTypes.arrayOf(PropTypes.shape({
        serviceState: PropTypes.string,
      })),
    }),
  }).isRequired,
  reactFinalFormInput: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
};

ChoicePromoCodeInput.defaultProps = {
  name: 'promoCode',
  label: 'Promo Code',
  reactFinalFormInput: false,
};
