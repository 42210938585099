import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DateHelpers from 'utils/DateHelpers';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import Typography from '@material-ui/core/Typography';
import DealTable from './components/DealTable/DealTable';
import PathsTable from './components/PathsTable/PathsTable';

export class PipelineBreakDown extends Component {
  state = {
    deals: [],
    deliveryVolumes: [],
    receiptVolumes: [],
    loading: true,
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      await this.getPipelineBreakDown();
    }
  }

  getPipelineBreakDown = async () => {
    this.setState({ loading: true });
    const query = {
      query: `{
        pipelineSummary (
          endDate: "${DateHelpers.formatDate(this.props.endDate)}"
          startDate: "${DateHelpers.formatDate(this.props.startDate)}"
          companyId: ${this.props.companyId}
          pipelineId: ${this.props.pipelineId}
          ${this.props.locationId ? `locationId: ${this.props.locationId}` : ''}
        ) {
          deals {
            id
            volume
            counterpartyId
            tradeDate
            market
            evergreen
          }
          deliveryVolumes {
            id
            dth
            gasDay
            receiptLocation {
              name
              pipeline {
                name
              }
            }
            deliveryLocation {
              name
            }
          }
          receiptVolumes {
            id
            dth
            gasDay
            receiptLocation {
              name
              pipeline {
                name
              }
            }
            deliveryLocation {
              name
            }
          }
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      return this.assignState(res);
    } catch (error) {
      return error;
    }
  }

  assignState = (res) => {
    const { deals, receiptVolumes, deliveryVolumes } = res.data.data.pipelineSummary;

    const totalDealVolume = deals
      .reduce((accumulator, currentValue) => accumulator + currentValue.volume, 0);

    const totalReceiptVolumes = receiptVolumes
      .reduce((accumulator, currentValue) => accumulator + currentValue.dth, 0);

    const totalDeliveryVolumes = deliveryVolumes
      .reduce((accumulator, currentValue) => accumulator + currentValue.dth, 0);

    return this.setState({
      deals,
      receiptVolumes,
      deliveryVolumes,
      totalDealVolume,
      totalReceiptVolumes,
      totalDeliveryVolumes,
      loading: false,
    });
  }

  render() {
    return (
      <MuiDialog
        open={this.props.open}
        toggleDialog={this.props.toggleDialog}
        titleText={`${this.props.pipelineName} Volume: ${this.props.pipelineVolume}`}
        fullScreen={true}
      >
        {!this.state.loading ? (
          <>
            <DealTable
              deals={this.state.deals}
              totalVolume={this.state.totalDealVolume}
            />
            <PathsTable
              paths={this.state.receiptVolumes}
              title="Downstream"
              totalVolume={this.state.totalReceiptVolumes}
            />
            <PathsTable
              paths={this.state.deliveryVolumes}
              title="Upstream"
              totalVolume={this.state.totalDeliveryVolumes}
            />
            <Typography>
              {`Total ${this.state.totalDealVolume + this.state.totalReceiptVolumes - this.state.totalDeliveryVolumes}`}
            </Typography>
          </>
        ) : (
          <AppLoader loading={this.state.loading} />
        )}
      </MuiDialog>
    );
  }
}

export default PipelineBreakDown;

PipelineBreakDown.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  pipelineName: PropTypes.string,
  pipelineVolume: PropTypes.number,
  companyId: PropTypes.number,
  pipelineId: PropTypes.number,
  locationId: PropTypes.number,
};

PipelineBreakDown.defaultProps = {
  pipelineName: undefined,
  pipelineVolume: undefined,
  companyId: undefined,
  pipelineId: undefined,
  locationId: undefined,
};
