import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const DealTable = ({
  deals,
}) => (
  <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography variant="h6" id="tableTitle">
        {`Deals total volume: ${deals.reduce((accumulator, currentValue) => accumulator + currentValue.volume, 0)}`}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Paper style={{ width: '100%' }}>
        <Table size="small" aria-label="">
          <TableHead>
            <TableRow>
              <TableCell align="center">id</TableCell>
              <TableCell align="center">counterpartyId</TableCell>
              <TableCell align="center">volume</TableCell>
              <TableCell align="center">tradeDate</TableCell>
              <TableCell align="center">market</TableCell>
              <TableCell align="center">evergreen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deals.map(deal => (
              <TableRow key={deal.id}>
                <TableCell align="center">{deal.id}</TableCell>
                <TableCell align="center">{deal.counterpartyId}</TableCell>
                <TableCell align="center">{deal.volume}</TableCell>
                <TableCell align="center">{deal.tradeDate}</TableCell>
                <TableCell align="center">{deal.market ? 'true' : 'false'}</TableCell>
                <TableCell align="center">{deal.evergreen ? 'true' : 'false'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

export default DealTable;

DealTable.propTypes = {
  deals: PropTypes.arrayOf(PropTypes.shape({
    counterpartyId: PropTypes.number,
    volume: PropTypes.number,
    tradeDate: PropTypes.string,
    market: PropTypes.bool,
    evergreen: PropTypes.bool,
  })),
};

DealTable.defaultProps = {
  deals: [],
};
