/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';
import styles from './TreeGrid.module.scss';

/**
 * Tree grid example with react data grid
 */


export const setSubRows = async (group, rowData, rowIndex) => {
  let subRows;
  if (typeof group === 'function') {
    subRows = await group(rowData);
  } else subRows = rowData[rowIndex][group];
  return subRows;
};

export const getSubRowDetails = (expandedRows, field) => (rowItem) => {
  const expanded = expandedRows[rowItem.id] ? expandedRows[rowItem.id] : false;
  const shouldGroup = (!!(rowItem && rowItem.id));
  return {
    group: shouldGroup,
    field,
    expanded,
    treeDepth: rowItem.treeDepth || 0,
    siblingIndex: rowItem.siblingIndex,
    numberSiblings: rowItem.numberSiblings,
  };
};

export const updateSubRowDetails = (nestedRows, parentTreeDepth = 0) => nestedRows.map((sr, i) => ({
  ...sr,
  expanded: sr.expanded || false,
  treeDepth: (parentTreeDepth) + 1,
  siblingIndex: i,
  numberSiblings: nestedRows.length,
}));

export const onCellExpand = async ({ rowData }, group, expandedRows, rows) => {
  const rowIndex = rows.indexOf(rowData);
  const rowKey = rowData.id || rowIndex;
  let subRows = [];
  if (expandedRows && !expandedRows[rowKey]) {
    subRows = await setSubRows(group, rowData, rowIndex);
    expandedRows[rowKey] = subRows.length || 0;
    subRows = updateSubRowDetails(subRows, rowData.treeDepth);
    rows.splice(rowIndex + 1, 0, ...subRows);
  } else if (expandedRows[rowKey]) {
    Object.keys(expandedRows).forEach((item) => {
      if (item.indexOf(rowKey) === 0) {
        rows.splice(rowIndex + 1, expandedRows[item]);
        expandedRows[item] = false;
      }
    });
  }

  return {
    expandedRows,
    rows,
  };
};

// eslint-disable-next-line react/prop-types
export const RowRenderer = ({ renderBaseRow, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const color = props.idx % 2 ? 'green' : 'blue';
  return <div style={{ color }}>{renderBaseRow(props)}</div>;
};

export const handleFilterChange = filter => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

export const setHeight = () => {
  const { body, documentElement } = window.document;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight,
  ) / 1.69 || 600;
};

const TreeGrid = ({
  rows,
  columns,
  field,
  group,
  defaultColumnProperties,
  headers,
  minHeight,
  downloadOnClick,
}) => {
  const [filters, setFilters] = useState({});
  const [state, setState] = useState({ expandedRows: {}, rows });
  const visibleRows = Data.Selectors.getRows({ rows: state.rows, filters });

  return (
    <>
      {rows && rows.length > 0 && (
      <ReactDataGrid
        className={styles.grid}
        columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
        getSubRowDetails={getSubRowDetails(state.expandedRows, field)}
        onCellExpand={async (args) => {
          const data = await onCellExpand(args, group, state.expandedRows, state.rows);
          setState(data);
        }}
        minHeight={minHeight || setHeight()}
        rowRenderer={RowRenderer}
        rowGetter={i => visibleRows[i]}
        rowsCount={visibleRows.length}
        onAddFilter={filter => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        toolbar={(
          <Toolbar enableFilter={true}>
            {headers && headers.length > 0 && (
              <CSVLink
                data={visibleRows}
                headers={headers}
                className={styles.button}
                onClick={() => {
                  downloadOnClick();
                }}
              >
                <Button variant="outlined" className={styles.button}>
                  Download
                </Button>
              </CSVLink>
            )}
          </Toolbar>
        )}
      />
      )}
    </>
  );
};

export default TreeGrid;

TreeGrid.propTypes = {
  /** data to display in grid */
  rows: PropTypes.arrayOf(PropTypes.shape()),
  /** columns of rows to show (key of object) */
  columns: PropTypes.arrayOf(PropTypes.shape()),
  /** headers if provided will show download button ex [{label: 'label', key: 'key'}] */
  headers: PropTypes.arrayOf(PropTypes.shape()),
  /** callback for download button this will not affect the data set */
  downloadOnClick: PropTypes.func,
  /** default column properties to apply to all columns */
  defaultColumnProperties: PropTypes.shape({}),
  /** the key of the column to show drop down action */
  field: PropTypes.string.isRequired,
  /** min heigh of grid */
  minHeight: PropTypes.number,
  //* * callback action to get nested rows on click of drop down action */
  group: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]).isRequired,
};

TreeGrid.defaultProps = {
  minHeight: null,
  rows: [],
  columns: [],
  defaultColumnProperties: {
    resizable: true,
  },
  downloadOnClick: () => {},
  headers: [],
};
