import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';

export const RFFInput = ({
  name,
  label,
}) => (
  <Field
    name={name}
    label={label}
  >
    {field => (
      <Input
        field={field}
      />
    )}
  </Field>
);

export default RFFInput;


RFFInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

RFFInput.defaultProps = {
  name: 'promoCode',
  label: 'Promo Code',
};
