import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import Radio from 'sharedComponents/FinalForm/Radio/Radio';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import { Form, Field } from 'react-final-form';
import styles from './BheEmail.module.scss';

export const validate = (values) => {
  const errors = {};
  if (!values.email) errors.email = 'Required';
  else {
    const emailParts = values.email.split('@');
    if (emailParts[1] !== 'blackhillscorp.com') errors.email = 'email address must go to a black hills employee email ending in @blackhillscorp.com';
  }

  if (!values.billClass) errors.billClass = 'Required';
  if (!values.serviceState) errors.serviceState = 'Required';

  return errors;
};

export class BheEmail extends Component {
  state = {
    sent: '',
    loading: true,
  };

  async componentDidMount() {
    await this.sgCustomersUniqueColumn();
  }

  onSubmit = async (values) => {
    try {
      const params = {
        emailed_to: values.email,
        bill_class: values.billClass,
        service_state: values.serviceState,
      };
      const url = '/bhe_choice_selection_emails/email_black_hills_choice_selections';
      await axios.post(url, params);
      this.setState({
        sent: `Email sent to ${values.email} and choice@woodriverenergy.com`,
        error: '',
      });
    } catch (error) {
      this.setState({
        error: error.toString(),
        sent: '',
      });
    }
  };

  sgCustomersUniqueColumn = async () => {
    try {
      const query = {
        query: `{
          sgCustomersUniqueColumn(columns: [
            "bill_class",
            "service_state",
          ])
        }`,
      };
      const res = await axios.post('/graphql/', query);

      if (res.data.errors) {
        return res.data.errors;
      }
      const { sgCustomersUniqueColumn } = res.data.data;
      return this.setState({ sgCustomersUniqueColumn, loading: false });
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Card className={styles.card}>
          <CardHeader
            avatar={<Avatar>!</Avatar>}
            title="Choice Selections Email"
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              Send an email to Black Hills Energy with all of WoodRivers new choice signups
            </Typography>

            <AppLoader loading={this.state.loading} />
            {!this.state.loading && (
              <Form
                onSubmit={this.onSubmit}
                validate={validate}
                initialValues={{
                  email: '',
                  billClass: 'AGR',
                  serviceState: 'NE',
                }}
              >
                {({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      {this.state.sgCustomersUniqueColumn.serviceState.map((option) => {
                        if (option !== 'ALL') {
                          return (
                            <Field
                              key={option}
                              name="serviceState"
                              type="radio"
                              value={option}
                              label={option}
                            >
                              {field => <Radio field={field} />}
                            </Field>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div>
                      {this.state.sgCustomersUniqueColumn.billClass.map((option) => {
                        if (option !== 'ALL') {
                          return (
                            <Field
                              key={option}
                              name="billClass"
                              type="radio"
                              value={option}
                              label={option}
                            >
                              {field => <Radio field={field} />}
                            </Field>
                          );
                        }
                        return null;
                      })}
                    </div>
                    <Field
                      name="email"
                      label="email"
                    >
                      {field => <Input field={field} width="100%" />}
                    </Field>
                    <Submit submitting={submitting}>
                      Send Email
                    </Submit>
                  </form>
                )}
              </Form>
            )}

            {this.state.sent && <Typography variant="body2" component="p">{this.state.sent}</Typography>}
            {this.state.error && <Typography variant="body2" color="error" component="p">{this.state.error}</Typography>}
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default BheEmail;
