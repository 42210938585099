import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import styles from './DisplayErrorMessage.module.scss';

const DisplayErrorMessage = ({ errorMessage }) => (
  <>
    {errorMessage && (
      <Typography variant="subtitle1" className={styles.errorText}>
        {errorMessage}
      </Typography>
    )}
  </>
);

export default DisplayErrorMessage;

DisplayErrorMessage.propTypes = {
  errorMessage: PropTypes.string,
};

DisplayErrorMessage.defaultProps = {
  errorMessage: '',
};
