import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

/**
 * Input for redux forms input field
 *
 * console shows onSubmit values
 */

export const Input = ({
  classes,
  type,
  disabled,
  input,
  label,
  name,
  margin,
  helperText,
  setValue,
  tempValue,
  meta: { error },
  custom,
  endAdornment,
}) => {
  const [state, setState] = useState({ labelWidth: 0 });
  const inputLabelRef = useRef(null);

  useEffect(() => {
    const findNode = findDOMNode(inputLabelRef.current); // eslint-disable-line
    setState({
      labelWidth: findNode ? findNode.offsetWidth : 0,
    });
  }, []);

  const pickValue = (permanent, inputValue, temp) => {
    if (permanent) return permanent;
    if (inputValue) return inputValue;
    if (temp && !inputValue) return temp;
    return '';
  };

  return (
    <FormControl variant="outlined" className={classes.input} style={custom.style}>
      {type !== 'date' ? (
        <InputLabel
          style={error && { color: 'red' }}
          htmlFor="component-outlined"
          ref={inputLabelRef}
          shrink={true}
        >
          {label}
        </InputLabel>
      ) : <small style={error && { color: 'red' }}>{label}</small>}
      <OutlinedInput
        {...custom}
        {...input}
        value={pickValue(setValue, input.value, tempValue)}
        error={error && error.length > 1}
        name={name}
        margin={margin}
        labelWidth={state.labelWidth || 0}
        disabled={disabled}
        type={type}
        endAdornment={endAdornment}
      />
      {helperText && (<FormHelperText id="component-helper-text">{helperText}</FormHelperText>)}
      {error && (<FormHelperText id="component-error-text">{error}</FormHelperText>)}
    </FormControl>
  );
};


export default Input;

Input.propTypes = {
  /** type of the input ex ( text, number, date) */
  type: PropTypes.string,
  /** label for your input */
  label: PropTypes.string,
  /** margin for your input */
  margin: PropTypes.string,
  /** helper text for your input */
  helperText: PropTypes.string,
  /** input for your input ;) */
  input: PropTypes.shape({
    value: PropTypes.string,
  }),
  /** custom props for your input */
  custom: PropTypes.shape({
    style: PropTypes.shape(),
  }),
  /** add a component to show at the end of the input */
  endAdornment: PropTypes.node,
  /** metadata regarding input state */
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  /** disabled input */
  disabled: PropTypes.bool,
  /** set the value of the input out side of the redux form */
  setValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  tempValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
};

Input.defaultProps = {
  endAdornment: undefined,
  type: 'text',
  input: {},
  custom: {},
  meta: {
    touched: 'false',
    error: '',
    message: '',
  },
  label: '',
  helperText: '',
  margin: 'none',
  classes: {},
  disabled: false,
  setValue: false,
  tempValue: false,
  name: '',
};
