import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Call,
  Copyright,
  Email,
} from '@material-ui/icons';
import {
  Grid,
  Toolbar,
  AppBar,
  Hidden,
  ListItemIcon,
} from '@material-ui/core';

import FacebookSvg from 'sharedComponents/FacebookSvg/FacebookSvg';
import BBBSvg from 'sharedComponents/BBBSvg/BBBSvg';
import LinkedinSvg from 'sharedComponents/LinkedinSvg/LinkedinSvg';
import GoogleSvg from 'sharedComponents/GoogleSvg/GoogleSvg';
import styles from './Footer.module.scss';

const today = new Date();

export class Footer extends Component {
  render() {
    const {
      bbbUrl,
      facebookUrl,
      googleUrl,
      linkedinUrl,
      phoneNumber,
      displayNumber,
      salesEmail,
      companyName,
    } = this.props.appInfo;
    return (
      <Hidden smDown>
        <footer>
          <AppBar position="static">
            <Toolbar>
              <Grid
                container
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid xs={9} item container alignItems="center" justify="flex-start">
                  <Grid className={styles.text}>
                    <Copyright />
                  </Grid>
                  <Grid className={styles.text}>
                    {`${this.props.currentYear} ${companyName}`}
                  </Grid>
                  <Grid className={styles.text}>
                    <Call />
                  </Grid>
                  <Grid className={styles.text}>
                    <a href={`tel:+${phoneNumber}`} className={styles.text}>
                      {displayNumber}
                    </a>
                  </Grid>
                  <Grid className={styles.text}>
                    <Email />
                  </Grid>
                  <Grid className={styles.text}>
                    <a href={`mailto:${salesEmail}`} className={styles.text}>
                      {salesEmail}
                    </a>
                  </Grid>
                </Grid>
                <Grid xs={3} item container alignItems="center" justify="flex-end">
                  {bbbUrl && (
                    <Grid item className={styles.text} xs={2}>
                      <a rel="noreferrer noopener" target="_blank" href={bbbUrl}>
                        <ListItemIcon>
                          <BBBSvg />
                        </ListItemIcon>
                      </a>
                    </Grid>
                  )}
                  {facebookUrl && (
                  <Grid item className={styles.text} xs={2}>
                    <a rel="noreferrer noopener" target="_blank" href={facebookUrl}>
                      <ListItemIcon>
                        <FacebookSvg />
                      </ListItemIcon>
                    </a>
                  </Grid>
                  )}
                  {linkedinUrl && (
                    <Grid item className={styles.text} xs={2}>
                      <a rel="noreferrer noopener" target="_blank" href={linkedinUrl}>
                        <ListItemIcon>
                          <LinkedinSvg />
                        </ListItemIcon>
                      </a>
                    </Grid>
                  )}
                  {googleUrl && (
                    <Grid item className={styles.text} xs={2}>
                      <a rel="noreferrer noopener" target="_blank" href={googleUrl}>
                        <ListItemIcon>
                          <GoogleSvg />
                        </ListItemIcon>
                      </a>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </footer>
      </Hidden>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    appInfo: state.appInfo,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(Footer);

Footer.propTypes = {
  currentYear: PropTypes.number,
  appInfo: PropTypes.shape({
    displayNumber: PropTypes.string.isRequired,
    bbbUrl: PropTypes.string.isRequired,
    facebookUrl: PropTypes.string.isRequired,
    googleUrl: PropTypes.string.isRequired,
    linkedinUrl: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    salesEmail: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
  }).isRequired,
};

Footer.defaultProps = {
  currentYear: today.getFullYear(),
};
