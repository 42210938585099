import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContainer from 'sharedComponents/TabContainer/TabContainer';
import RegionTable from './components/RegionTable/RegionTable';

export class RegionTabs extends Component {
  state = {
    tabIndex: 0,
    regionsList: [],
  };

  async componentDidMount() {
    this.setRegionsList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.choiceParams !== prevProps.choiceParams) {
      this.setRegionsList();
    }
  }

  setRegionsList = () => {
    const regionsList = this.props.regions
      .map(region => this.props.choiceParams.filter(param => param.region === region));
    this.setState({ regionsList });
  }

  handleChangeIndex = (event, tabIndex) => {
    this.setState({ tabIndex });
  };

  render() {
    return (
      <>
        <AppBar position="static">
          <Tabs
            value={this.state.tabIndex}
            onChange={this.handleChangeIndex}
            scrollable="true"
            scrollButtons="auto"
          >
            {this.props.regions.map(region => (
              <Tab key={region} label={region} />
            ))}
          </Tabs>
        </AppBar>

        <SwipeableViews
          index={this.state.tabIndex}
          onChangeIndex={this.handleChangeIndex}
        >
          {this.state.regionsList && this.state.regionsList.map((params, i) => (
            <div key={i}>
              {this.state.tabIndex === i && (
              <TabContainer>
                <RegionTable
                  sortParams={this.props.sortParams}
                  params={params}
                  region={params[0].region}
                  formState={this.props.formState}
                />
              </TabContainer>
              )}
            </div>
          ))}
        </SwipeableViews>
      </>
    );
  }
}

export default RegionTabs;

RegionTabs.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.string).isRequired,
  choiceParams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formState: PropTypes.shape({}).isRequired,
  sortParams: PropTypes.func.isRequired,
};

RegionTabs.defaultProps = {};
