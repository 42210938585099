/* eslint-disable */

class DateHelpers {
  /**
   * getMonth takes month and returns string name of month
   * getMonthName(5) = May
   */
  static getMonthName(monthNumber) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[Math.trunc(monthNumber) - 1];
  }

    /**
   * dateMonthName takes month and returns string name of month
   * getMonthName(2016-01-01) = January
   */
  static dateMonthName(date = new Date()) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return months[date.getMonth()]
  }

  /**
   * Take yyyyMmDd ('2020-09-01') and return Month Accronym with year => Aug 2020
   */
  static yyyyMmDdYearMonthFormat(yyyyMmDd) {
    const options = { year: 'numeric', month: 'short' };
    const date = new Date(`${yyyyMmDd.slice(0, 10)} 00:00:00 GMT-0800`);
    return date.toLocaleDateString('en-US', options);
  }

  /**
  * yyyMmDdGetMonth takes date string and returns number value of month
  * yyyyMmDdGetMonth('2019-05-01') = 05
  */
  static yyyyMmDdGetMonth(yyyyMmDd) {
    return new Date( `${yyyyMmDd} 00:00:00 GMT-0800`).getMonth() + 1
  }

  /**
  * format date string to
  * monthDayYearFormat('2019-05-01') = may 1, 2019
  */
 static monthDayYearFormat(string, addDays = 0) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date  = new Date(`${string.slice(0, 10)} 00:00:00 GMT-0800`);
    return date.toLocaleDateString("en-US", options)
  }

  /**
  * formatDate string to
  */
  static formatDate(dateString) {
    const date = new Date(dateString);
    const dateMonth = date.getMonth() + 1; // getMonth() is zero-based
    const dateDay = date.getDate();

    return [
      date.getFullYear(),
      (dateMonth > 9 ? '' : '0') + dateMonth,
      (dateDay > 9 ? '' : '0') + dateDay
    ].join('-');
  }

  /**
  * formatYearMonth string to
  */
  static formatYearMonth(dateString) {
    return this.formatDate(dateString).slice(0, -3);
  }

  /**
  * daysInMonth gets the days in the month
  * pass month and year as
  */
  static daysInMonth (date = new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1
    return  new Date(year, month, 0).getDate();
  }

  /**
  * pass date in to get beginning Of The Month date
  */
  static beginningOfTheMonth (date = new Date()) {
    const month = date.getMonth() + 1
    return new Date(`${date.getFullYear()}/${month}/1 00:00:00 GMT-0800`);
  }

  /**
  * pass date in to get end Of The Month date
  */
  static endOfTheMonth (date = new Date()) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const daysInMonth = this.daysInMonth(date)
    return new Date(`${year}/${month}/${daysInMonth} 00:00:00 GMT-0800`);
  }

  /**
  * pass date in to get beginning Of The Month date
  */
  static addDaysToDate (date = new Date(), days = 0) {
    const newDate = date.setDate(date.getDate() + days);
    return new Date(newDate);
  }

  /**
  * pass date in to get beginning Of The Month date
  */
  static removeDaysFromDate(days = 0, date = new Date(),) {
    const newDate = date.setDate(date.getDate() - days);
    return new Date(newDate);
  }

  /**
  * pass date in to get beginning Of The Month date
  */
 static addMonthsToDate (date = new Date(), months = 0) {
    const month = date.getMonth() + 1
    return new Date(date.setMonth(month + months));
  }


  /**
  * yearArray(2019) returns array of years
  */
  static yearArray = () => {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 20)).fill('').map((v, idx) => now - idx);
  };

}

export default DateHelpers;
