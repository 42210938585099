import {
  NOTIFY_BANNER_REQUEST,
  NOTIFY_BANNER_SUCCESS,
} from '../constants/ActionTypes';

export const notifyBannerSuccess = data => ({
  type: NOTIFY_BANNER_SUCCESS,
  data,
});

export const notifyBannerRequest = () => ({
  type: NOTIFY_BANNER_REQUEST,
});

export default function notifyBannerAction(bannerData) {
  return async (dispatch) => {
    dispatch(notifyBannerRequest());
    dispatch(notifyBannerSuccess(bannerData));
  };
}
