import React, { Component } from 'react';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';

export class UpdateTabCache extends Component {
  state = {
    docuSignMessage: '',
    error: '',
  }

  updateTabs = async () => {
    const query = {
      query: `{
        docuSignClient(
          query: "set_tabs_to_cache",
        ) {
          message
        }
      }`,
    };
    const { data } = await axios.post('/graphql/', query);
    if (data.data.docuSignClient) {
      this.setState({ error: '', docuSignMessage: data.data.docuSignClient.message });
    } else {
      this.setState({ error: data.errors[0].message })
    }
  }

  render() {
    return (
      <Card>
        <CardHeader
          title="DocuSign Tabs"
          subheader={this.state.docuSignMessage || this.state.error}
        />
        <CardContent>
          <p>
            If you are to add a new “Custom Field” to a DocuSign template press the
            button below to update Woodrivers cache tab data.
          </p>
          <p>This is running on a job to update cache every night.</p>
        </CardContent>
        <CardActions>
          <Button
            onClick={this.updateTabs}
            size="small"
            variant="outlined"
          >
            Update Tabs
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default UpdateTabCache;
