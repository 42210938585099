import React, { Component } from 'react';
import SearchSgCustomer from './components/SearchSgCustomer/SearchSgCustomer';
import SgCustomerGrid from './components/SgCustomerGrid/SgCustomerGrid';

export class ChoiceConfirmationCodesSearch extends Component {
  state = {
    error: '',
    sgCustomers: [],
  };

  render() {
    this.callback = (error = '', sgCustomers = []) => {
      this.setState({ loading: true });
      if (error) return this.setState({ error });
      return this.setState({ sgCustomers, loading: false });
    };

    return (
      <>
        <SearchSgCustomer callback={this.callback} />
        {this.state.error && (<span className={{ color: 'red' }}>{this.state.error}</span>)}
        <SgCustomerGrid
          sgCustomers={this.state.sgCustomers}
          loading={this.state.loading}
        />
      </>
    );
  }
}

export default ChoiceConfirmationCodesSearch;


ChoiceConfirmationCodesSearch.propTypes = {};
