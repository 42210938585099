import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';


const TabContainer = ({ children }) => (
  <Typography
    component="div"
    style={{
      overflowX: 'hidden',
      overflowY: 'hidden',
      width: '100%',
    }}
  >
    {children}
  </Typography>
);

export default TabContainer;

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
