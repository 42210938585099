import { getAppUrl, tryDecomposeHostname } from 'wre_urls';

export const config = {
  decomposedUrl: tryDecomposeHostname(location.hostname),
  apiUrl:
    location.protocol +
    '//' +
    getAppUrl(location.hostname, 'rails-v2', process.env.REACT_APP_RAILS_API),
  angularApiUrl:
    location.protocol +
    '//' +
    getAppUrl(location.hostname, 'app', process.env.REACT_APP_LEGACY_APP_URL),
};

// Useful for editing in devtools.
(window as any).wreConfig = config;

// Override config from URL hash
try {
  let search = window.location.search;

  // Strip leading #
  if (search.startsWith('?')) {
    search = search.substr(1);
  }

  const nonWhitespaceParts = search.split('&').filter((p) => !/^\s*$/.test(p));

  for (let part of nonWhitespaceParts) {
    let split = part.split('=');
    let key = split[0];
    let value = undefined;

    if (split.length === 1) {
      value = true;
    } else if (split.length === 2) {
      value = split[1];
    } else {
      continue;
    }

    // Find the key by lower-case string compare (effectively ignores case for
    // the key in the URL hash).
    let found = false;
    for (let configKey of Object.keys(config)) {
      if (configKey.toLowerCase() === key.toLowerCase()) {
        found = true;

        if (typeof (config as any)[configKey] === typeof value) {
          (config as any)[configKey] = value;
          console.log(`Setting config value ${configKey} to ${value}`);
        }
      }
    }

    if (!found) {
      console.warn(
        `The field ${key} does not exist in the config object, possible values are:`,
        Object.keys(config)
      );
    }
  }
} catch (e) {
  console.warn(`Failed URL hash: ${e}`);
}
