import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import WoodRiverEnergySvg from 'sharedComponents/WoodRiverEnergySvg/WoodRiverEnergySvg';
import LogoSvgConnect from 'sharedComponents/LogoSvgConnect/LogoSvgConnect';
import PropTypes from 'prop-types';

export class MobileTopAppBar extends Component {
  render() {
    return (
      <Toolbar>
        <Grid
          container
          item
          alignItems="center"
          justify="center"
        >
          <a href="/">
            {this.props.appInfo.host.includes('connectenergy') ? (
              <LogoSvgConnect />
            ) : (
              <WoodRiverEnergySvg />
            )}
          </a>
        </Grid>
      </Toolbar>
    );
  }
}

export default MobileTopAppBar;

MobileTopAppBar.propTypes = {
  appInfo: PropTypes.shape({
    host: PropTypes.string.isRequired,
  }).isRequired,
};
