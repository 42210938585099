export const APPLICATION_ERROR =
  "We're sorry something has gone wrong, please contact sales@woodriverenergy.com for more info.";
export const USER_ROLES = {
  ADMIN: 'admin',
  SYSTEM_USER: 'systemuser',
  AGENT: 'agent',
  USER: 'user',
  DEAL_MAKER: 'dealmaker',
};

export const DOCUSIGN_URL = (() => {
  if (process.env.REACT_APP_ENV === 'stage')
    return 'https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature%20impersonation%20extended%20&client_id=9ce19668-a8f8-41b8-b43f-cf8e5d1c7b8e&redirect_uri=https://stage.woodriverenergy.com/docu-sign-portal';
  if (process.env.REACT_APP_ENV === 'production')
    return 'https://account.docusign.com/oauth/auth?response_type=code&scope=signature%20impersonation%20extended%20&client_id=274eb808-bd8c-4986-82bf-92e1948541f7&redirect_uri=https://woodriverenergy.com/docu-sign-portal';
  return 'https://account-d.docusign.com/oauth/auth?response_type=code&scope=signature%20impersonation%20extended%20&client_id=9ce19668-a8f8-41b8-b43f-cf8e5d1c7b8e&redirect_uri=http://localhost:3030/docu-sign-portal';
})();
