/* eslint-disable react/sort-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
// import useFetch from './customHooks/useHook';

export const paths = async (gasDay, locationId, setState) => {
  const query = {
    query: `{
        paths(
            startDate: "${gasDay}",
            endDate:  "${gasDay}",
            deliveryLocationId: ${locationId}
        ) {
          gasDay
          dth
          id
          pipelineId
          receiptLocation {
            name
            pipeline {
              id
              name
            }
          }
        }
      }`,
  };

  let res;
  try {
    res = await axios.post('/graphql/', query);
  } catch (err) {
    return setState({ error: err.toString() });
  }
  if (res.data.errors && res.data.errors.length > 0) {
    return setState({ error: res.data.errors[0].message });
  }
  return setState({
    paths: res.data.data.paths,
    totalVolume: res.data.data.paths.reduce((a, b) => a + (b.dth || 0), 0),
  });
};

const DeliveryPaths = ({
  gasDay,
  locationId,
}) => {
  const [state, setState] = useState({
    paths: [],
    totalVolume: 0,
    error: '',
  });

  useEffect(() => {
    (async () => {
      await paths(gasDay, locationId, setState);
    })();
  }, [gasDay, locationId]);

  return (
    <>
      {state && state.paths && state.paths.length ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Path Id</TableCell>
              <TableCell align="center">Upstream Location</TableCell>
              <TableCell align="center">Upstream Pipe</TableCell>
              <TableCell align="center">Gas Day</TableCell>
              <TableCell align="center">dth</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.paths.map((path, i) => (
              <TableRow key={i}>
                <TableCell align="center">
                  {path.id}
                </TableCell>
                <TableCell align="center">
                  {path.receiptLocation.name}
                </TableCell>
                <TableCell align="center">
                  {path.receiptLocation.pipeline.name}
                </TableCell>
                <TableCell align="center">
                  {new Date(`${path.gasDay} 00:00:00 GMT-0800`).toDateString()}
                </TableCell>
                <TableCell align="center">
                  {path.dth}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell>Total</TableCell>
              <TableCell align="center">{state.totalVolume}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : 'No Upsteam Paths'
    }
      {state.error && (<Typography color="error">{state.error}</Typography>)}
    </>
  );
};


export default DeliveryPaths;

DeliveryPaths.propTypes = {
  gasDay: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
};

DeliveryPaths.defaultProps = {};
