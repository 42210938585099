import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import notifyBannerAction from 'actions/NotifyBannerAction';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SearchSelection from './components/SearchSelection/SearchSelection';
import ResultsTable from './components/ResultsTable/ResultsTable';
import ChoiceSelectionInfo from './components/ChoiceSelectionInfo/ChoiceSelectionInfo';
import NewSelection from './components/NewSelection/NewSelection';
import styles from './ChoiceSelection.module.scss';

export class ChoiceSelection extends Component {
  state = {
    results: [],
    dialog: false,
    newSelectionPopUp: false,
    quote: {},
  };

  setQuote = quote => this.setState({ quote });

  callBack = (results) => {
    this.setState({
      results,
      resultsMessage: results.length > 0 ? '' : 'No Results to display',
    });
  }

  removeDestroyedSelection = (id) => {
    this.setState(oldState => ({
      ...oldState,
      results: oldState.results.filter(result => result.id !== id),
    }));
  }

  updateSelection = (selection) => {
    this.setState(oldState => ({
      ...oldState,
      results: oldState.results.map((result) => {
        if (result.id === selection.id) {
          return selection;
        }
        return result;
      }),
    }));
  }

  toggleDialog = (row = {}) => {
    this.setState(prevState => ({ dialog: !prevState.dialog, row }));
  };

  openNewSelectionPopUp = () => {
    this.setState(prevState => ({ newSelectionPopUp: !prevState.newSelectionPopUp }));
  }

  toggleAndDisplay = (selections) => {
    this.setState({
      quote: {},
      results: selections,
    });
    this.openNewSelectionPopUp();
  }

  render() {
    return (
      <Grid container>
        <Grid item>
          <ChoiceSelectionInfo
            open={this.state.dialog}
            toggleDialog={this.toggleDialog}
            updateSelection={this.updateSelection}
            row={this.state.row}
          />
          <NewSelection
            open={this.state.newSelectionPopUp}
            toggleDialog={this.openNewSelectionPopUp}
            toggleAndDisplay={this.toggleAndDisplay}
            setQuote={this.setQuote}
            quote={this.state.quote}
          />
        </Grid>
        <Grid container justify="center">
          <Grid item style={{ marginTop: '20px' }}>
            <Avatar
              className={styles.onClick}
              onClick={this.openNewSelectionPopUp}
            >
              <AddCircle />
            </Avatar>
          </Grid>
          <Grid item>
            <SearchSelection
              callBack={this.callBack}
              resultsMessage={this.state.resultsMessage}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ResultsTable
            results={this.state.results}
            removeDestroyedSelection={this.removeDestroyedSelection}
            updateSelection={this.updateSelection}
            notifyBannerAction={this.props.notifyBannerAction}
            toggleDialog={this.toggleDialog}
          />
        </Grid>
      </Grid>
    );
  }
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    notifyBannerAction,
  }, dispatch);
}

export default compose(connect(null, mapDispatchToProps))(ChoiceSelection);

ChoiceSelection.propTypes = {
  notifyBannerAction: PropTypes.func.isRequired,
};
