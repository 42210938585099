import createAuth0Client, {
  Auth0Client,
  CacheLocation,
  User,
} from "@auth0/auth0-spa-js";
import axios, { AxiosRequestConfig } from "axios";
import { config } from "config";

export let user: User;
export let token: string;
export let userId: number;
export let allowedRoles: string[];
export let defaultRole: string;

let auth0: any;

export async function bootstrapAuth() {
  auth0 = await createAuth0Client({
    audience: "https://prod.wre.hasura.io",
    cacheLocation: "localstorage" as CacheLocation,
    client_id: "dk7yTkRjEnmCUEIppl3qH12iSYaaUhhs",
    domain: "https://sso-internal.woodriverenergy.com",
    redirect_uri: window.location.origin,
  });

  // Handle login callback.
  if (hasAuthParams()) {
    const results = await (auth0 as any).handleRedirectCallback();
    window.history.replaceState(
      {},
      document.title,
      results.appState?.returnTo || window.location.pathname
    );
  }

  try {
    const res = await auth0.getUser();
    if (!res) {
      throw "User login required";
    }
    user = res;
    token = await auth0.getTokenSilently();
  } catch (e) {
    console.log(e);
    await login(auth0);
    throw "Unreachable";
  }

  // This will throw is the JWT fails to decode. I can live with that, because
  // it's either a critical bug in Auth0 code, or a malformed token.
  const decodedJwt = decodeJwt(token);
  userId = decodedJwt.userId;
  allowedRoles = decodedJwt.allowedRoles;
  defaultRole = decodedJwt.defaultRole;

  // Intercept axios calls and add the auth header
  axios.defaults.baseURL = config.apiUrl;
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config.url?.replaceAll("/", "") === "graphql" || config.url?.replaceAll("/", "") === "sg_customersemail_quote") {
      config.headers.Authorization = token;
    }
    return config;
  });

  console.log("Auth complete");
}

export async function login(auth0: Auth0Client) {
  await auth0.loginWithRedirect({
    appState: {
      returnTo: window.location.href,
    } as AppState,
  });
}

export async function logout() {
  auth0.logout({
    returnTo: window.location.origin,
  });
}

function decodeJwt(token: string): {
  userId: number;
  defaultRole: string;
  allowedRoles: string[];
} {
  const jwtPayload = JSON.parse(window.atob(token.split(".")[1]));
  const claims = jwtPayload["https://hasura.io/jwt/claims"];
  const userId = Number(claims["x-hasura-user-id"]);
  const allowedRoles =
    claims["x-hasura-allowed-roles"]?.map((r: string) => r.toLowerCase()) || [];
  const defaultRole = claims["x-hasura-default-role"]?.toLowerCase();

  return { userId, allowedRoles, defaultRole };
}

interface AppState {
  returnTo: string;
}

const CODE_RE = /[?&]code=[^&]+/;
const STATE_RE = /[?&]state=[^&]+/;
const ERROR_RE = /[?&]error=[^&]+/;

const hasAuthParams = (searchParams = window.location.search): boolean =>
  (CODE_RE.test(searchParams) || ERROR_RE.test(searchParams)) &&
  STATE_RE.test(searchParams);
