import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import DeleteForever from '@material-ui/icons/DeleteForever';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import { IconButton, Button, Switch } from '@material-ui/core';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import jsonToCsv from 'utils/jsonToCsv';
import DateHelpers from 'utils/DateHelpers';
import base64Download from 'utils/base64Download';
import styles from './ResultsTable.module.scss';

export const destroyChoiceSelection = async (id, removeDestroyedSelection, notifyBannerAction) => {
  const queryString = {
    query: `mutation {
          destroyChoiceSelection(id: ${id}) {
            id
           }
        }`,
  };

  let res = null;

  try {
    res = await axios.post('/graphql/', queryString);
  } catch (err) {
    // TODO: airbrake and support email message
    return notifyBannerAction({
      message: err.toString(),
      showBanner: true,
      error: true,
    });
  }
  if (res.data.errors && res.data.errors.length > 0) {
    return notifyBannerAction({
      message: res.data.errors[0].message,
      showBanner: true,
      error: true,
    });
  }
  return removeDestroyedSelection(res.data.data.destroyChoiceSelection.id);
};

export const updateChoiceSelectionCall = async (selection, updateSelection, notifyBannerAction) => {
  const queryString = {
    query: `mutation {
          updateChoiceSelection(choiceSelection: ${graphqlObj(selection)}) {
            id
           }
        }`,
  };

  let res = null;

  try {
    res = await axios.post('/graphql/', queryString);
  } catch (err) {
    // TODO: airbrake and support email message
    return notifyBannerAction({
      message: err.toString(),
      showBanner: true,
      error: true,
    });
  }
  if (res.data.errors && res.data.errors.length > 0) {
    return notifyBannerAction({
      message: res.data.errors[0].message,
      showBanner: true,
      error: true,
    });
  }
  return updateSelection(selection);
};

export const setHeight = () => {
  const { body, documentElement } = window.document;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight,
  ) / 1.69 || 600;
};

// eslint-disable-next-line react/prop-types
export const RowRenderer = ({ renderBaseRow, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const color = props.idx % 2 ? 'green' : 'blue';
  return <div style={{ color }}>{renderBaseRow(props)}</div>;
};

export const handleFilterChange = filter => (filters) => {
  const newFilters = { ...filters };
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter;
  } else {
    delete newFilters[filter.column.key];
  }
  return newFilters;
};

const ResultsTable = ({
  results,
  toggleDialog,
  removeDestroyedSelection,
  notifyBannerAction,
  updateSelection,
}) => {
  const [filters, setFilters] = useState({});
  const defaultColumnProperties = {
    resizable: true,
  };
  const choicePriceTypeFormatter = value => (<span>{value.name}</span>);
  const accountNumFormatter = ({ value, row }) => (
    <Button id="AccountNumBtn" onClick={() => toggleDialog(row)}>
      {value}
    </Button>
  );

  const columns = [{
    key: 'accountNum',
    name: 'Account Number',
    formatter: accountNumFormatter,
    width: 160,
    filterable: true,
  },
  {
    key: 'servicePointId',
    name: 'Service Point Id',
    width: 160,
    filterable: true,
  },
  {
    key: 'name',
    name: 'Full Name',
    width: 160,
    filterable: true,
  },
  {
    key: 'emailAddress', name: 'Email Address', width: 160, filterable: true,
  },
  {
    key: 'serviceAddress', name: 'Service Address', width: 220,
  },
  {
    key: 'priceType',
    name: 'Program',
    width: 150,
  },
  { key: 'term', name: 'Term', width: 50 },
  { key: 'totalPrice', name: 'Total Price', width: 85 },
  { key: 'quotedPrice', name: 'Quoted Price', width: 105 },
  {
    key: 'createdAt',
    name: 'Created At',
    formatter: value => (<span>{DateHelpers.formatDate(value.value)}</span>),
    width: 110,
  },
  {
    key: 'posted',
    name: 'Posted',
    formatter: value => (value.value ? <span>Yes</span> : <span>No</span>),
  },
  {
    key: 'quoteId', name: 'Quote Id', width: 180, filterable: true,
  },
  {
    key: 'controlNumber', name: 'Control Number', width: 180, filterable: true,
  },
  {
    key: 'serviceState', name: 'Service State', width: 180, filterable: true,
  },
  {
    key: 'divisionRegion', name: 'Division Region', width: 180, filterable: true,
  },
  {
    key: 'billClass', name: 'Bill Class', width: 180, filterable: true,
  },
  { key: 'transportCostUnit', name: 'Transport Cost Unit' },
  {
    key: 'delegationAgreementUrl',
    name: 'Delegation Agreement',
    formatter: value => (
      <>
        {value.value ? (
          <a rel="noopener noreferrer" target="_blank" href={value.value} style={{ color: 'inherit' }}>
            Online Document
          </a>
        ) : (
          <span>N/A</span>
        )}
      </>
    ),
    width: 180,
  },
  {
    key: 'id',
    name: 'Delete',
    formatter: value => (
      <IconButton
        aria-label="delete"
        onClick={() => {
          destroyChoiceSelection(value.value, removeDestroyedSelection, notifyBannerAction);
        }}
      >
        <DeleteForever />
      </IconButton>
    ),
  },
  {
    key: 'sentToBhe',
    name: 'Sent to BHE',
    formatter: (value, row) => (
      <Switch
        checked={value.value}
        onChange={() => updateChoiceSelectionCall(
          { ...row, sentToBhe: !row.sentToBhe },
          updateSelection,
          notifyBannerAction,
        )}
        value={true}
      />
    ),
    width: 95,
  }].map(c => ({ ...c, ...defaultColumnProperties }));

  const downloadJson = () => {
    const csv = jsonToCsv(results);
    base64Download(csv, 'choiceSelections.csv');
  };
  if (results.length <= 0) return <AppLoader />;

  let visibleRows;
  if (results.length > 0) visibleRows = Data.Selectors.getRows({ rows: results, filters });

  return (
    <>
      {results.length > 0 && (
        <ReactDataGrid
          className={styles.grid}
          columns={columns}
          rowRenderer={RowRenderer}
          rowGetter={i => visibleRows[i]}
          rowsCount={results.length}
          minHeight={setHeight()}
          onAddFilter={filter => setFilters(handleFilterChange(filter))}
          onClearFilters={() => setFilters({})}
          toolbar={(
            <Toolbar enableFilter={true}>
              <Button style={{ marginLeft: '10px' }} id="DownLoadResults" size="small" onClick={downloadJson}>Download data</Button>
            </Toolbar>
          )}
        />
      )}
    </>
  );
};

export default ResultsTable;


ResultsTable.propTypes = {
  notifyBannerAction: PropTypes.func.isRequired,
  updateSelection: PropTypes.func.isRequired,
  removeDestroyedSelection: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({
    quoteId: PropTypes.number,
    accountNum: PropTypes.string,
    createdAt: PropTypes.string,
    servicePointId: PropTypes.string,
    name: PropTypes.string,
    emailAddress: PropTypes.string,
    serviceState: PropTypes.string,
    divisionRegion: PropTypes.string,
    billClass: PropTypes.string,
    choicePriceType: PropTypes.shape({
      name: PropTypes.string,
    }),
    term: PropTypes.number,
    totalPrice: PropTypes.number,
    transportCostUnit: PropTypes.number,
    controlNumber: PropTypes.string,
    posted: PropTypes.bool,
  })).isRequired,
};

ResultsTable.defaultProps = {};
