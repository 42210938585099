import React, { Component } from 'react';
import ToggleViews from 'sharedComponents/ToggleViews/ToggleViews';
import SearchNacha from './components/SearchNacha/SearchNacha';
import ExtractNacha from './components/ExtractNacha/ExtractNacha';

export class NachaReports extends Component {
  state = {
    nachaReport: [],
  };

  setNachaReport = nachaReport => this.setState({ nachaReport });

  render() {
    return (
      <ToggleViews
        titleCenter="Extract Nacha"
        leftView={true}
        leftComponent={(
          <SearchNacha setNachaReport={this.setNachaReport} />
        )}
        centerComponent={(
          <ExtractNacha
            nachaReport={this.state.nachaReport}
            setNachaReport={this.setNachaReport}
          />
        )}
      />
    );
  }
}

export default NachaReports;
