import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import graphqlCall from 'utils/graphqlCall';
import graphqlObj from 'utils/graphqlObj';
import { FORM_ERROR } from 'final-form';
import {
  Form, MuiInput, MuiSubmit, MuiSelect,
} from 'rff-wrapper';

export class CreateAdder extends Component {
  state = {
    open: false,
  }

  toggleDialog = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  onSubmit = async (values) => {
    const { createCallback } = this.props;

    const newValue = {
      ...values,
      groupId: values.group.id,
    };

    delete newValue.group;

    const queryString = {
      query: `mutation {
          createAndAssignAdderToGroup(adder: ${graphqlObj(newValue)}) {
            id
            nymex
            basis
            index
            season
            groupName
          }
        }`,
    };
    try {
      const { createAndAssignAdderToGroup } = await graphqlCall(queryString);
      createCallback(createAndAssignAdderToGroup);
      return this.setState({ open: false });
    } catch (err) {
      return { [FORM_ERROR]: err.toString() };
    }
  }

  render() {
    const { open } = this.state;
    const { groups, seasons } = this.props;

    return (
      <MuiDialog
        open={open}
        toggleDialog={() => this.toggleDialog()}
        titleText="Create New Adder"
        buttonText="New Adder"
        buttonVariant="outlined"
        buttonSize="small"
      >
        <Form
          onSubmit={this.onSubmit}
          initialValues={{
            nymex: '',
            index: '',
            basis: '',
            season: seasons[0],
            group: groups[0],
          }}
          render={formProps => (
            <form onSubmit={formProps.handleSubmit}>
              <MuiSelect
                name="group"
                label="Group"
                options={groups}
                size="small"
                displayKey="name"
                fullWidth
              />
              <MuiInput name="nymex" label="NYMEX" fullWidth size="small" />
              <MuiInput name="index" label="Index" fullWidth size="small" />
              <MuiInput name="basis" label="Basis" fullWidth size="small" />
              <MuiSelect
                name="season"
                label="Season"
                options={seasons}
                size="small"
                fullWidth
              />
              <MuiSubmit buttonText="Create Adder" />
              {formProps.submitError && <div style={{ color: 'red' }}>{formProps.submitError}</div>}
            </form>
          )}
        />
      </MuiDialog>
    );
  }
}

export default CreateAdder;

CreateAdder.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  seasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  createCallback: PropTypes.func.isRequired,
};
