import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import { Form, Field } from 'react-final-form';

export class SearchForQuote extends Component {
  state = {
    error: '',
  };

  onSubmit = async (values) => {
    const query = {
      query: `{
        choicePriceQuote(id: ${values.id}) {
          id
          pubIndex
          premises
          greenPlus
          updatedAt
          choiceQuoteNotes {
            note
            createdAt
            user {
              firstName
              lastName
            }
          }
          quoteData {
            managedPrice
            totalUsages
            choicePriceTypeId
            expirationDate
            acctNums
            selectedPrice
            baseIndex
            baseTpFuelIndex
            baseTpFuelIndex2
            baseTpFuelIndex3
            baseTpIndex
            currentEmail
            fmb1
            fmb1Total
            fmb2
            fmb2Total
            fmb3
            fmb3Total
            fpPriceYr1NoAdders
            fpPriceYr2NoAdders
            gFixed1
            gFixed2
            gFixed3
            blended1
            blended2
            blended3
            paramType
            price
            program
            term
            transportCostTotal
            transportCostUnit
            volume
            mthlyVolume
            accountList {
              accountNum
              baseIndex
              baseTpFuelIndex
              baseTpIndex
              billClass
              cogPremium
              curDbYear
              divisionRegion
              firstName
              fmb1
              fmb2
              fmb3
              fpPriceYrNoAdders1
              fpPriceYrNoAdders2
              fuelPercent
              gFixed1
              gFixed2
              gFixed3
              blended1
              blended2
              blended3
              lastName
              premiseNumber
              result
              riskFee
              serviceState
              shellFee
              supplierFee
              transportCostTotal
              transportCostUnit
              vFixed1
              vFixed2
              volume
              status
              complete
              verificationId
              controlId
              errorCode
              formattedAddress
            }
          }
        }
      }`,
    };

    let res = null;

    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
      // TODO: airbrake and support email message
      this.setState({ error: 'Sorry something went wrong!' });
      return;
    }
    if (res.data.errors && res.data.errors.length > 0) {
      this.setState({ error: res.data.errors[0].message });
    } else if (res.data.data.choicePriceQuote && res.data.data.choicePriceQuote.id) {
      this.props.setQuote(res.data.data.choicePriceQuote);
    }
  }

  required = value => (value ? undefined : 'Required');

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            {this.state.error && <span style={{ color: 'red' }}>{this.state.error.toString()}</span>}
            <div>
              <Field
                name="id"
                label="quote id"
                type="text"
                validate={this.required}
              >
                {field => <Input field={field} />}
              </Field>
            </div>
            <div>
              <Submit submitting={submitting}>
                Search
              </Submit>
            </div>
          </form>
        )}
      </Form>
    );
  }
}

export default SearchForQuote;

SearchForQuote.propTypes = {
  setQuote: PropTypes.func.isRequired,
};

SearchForQuote.defaultProps = {
};
