import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import styles from './ProgramArea.module.scss';

export const ProgramArea = ({
  greenPlus,
  choicePriceType,
  children,
}) => (
  <Card style={{ width: '100%' }}>
    <CardContent>
      <Grid container>
        <Typography component="h2" gutterBottom>{choicePriceType.name && choicePriceType.name}</Typography>
        <Grow in={greenPlus}>
          <AddCircleOutline className={styles.greenPlusIcon} />
        </Grow>
      </Grid>
      <Typography color="textSecondary" style={{ fontSize: 14 }} gutterBottom>
        {choicePriceType && choicePriceType.specificLegal}
      </Typography>
    </CardContent>
    <CardContent>
      {children}
    </CardContent>
  </Card>
);

export default ProgramArea;

ProgramArea.propTypes = {
  greenPlus: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  choicePriceType: PropTypes.shape({
    name: PropTypes.string,
    specificLegal: PropTypes.string,
  }),
};

ProgramArea.defaultProps = {
  choicePriceType: {},
};
