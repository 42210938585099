import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import appInfoReducer from './AppInfoReducer';
import customersReducer from './CustomersReducer';
import notifyBannerReducer from './NotifyBannerReducer';
import themeReducer from './ThemeReducer';
import userSelectedCustomerReducer from './UserSelectedCustomerReducer';


const rootReducer = (state, action) => {
  const appReducer = combineReducers({
    customers: customersReducer,
    selectedCustomer: userSelectedCustomerReducer,
    form: formReducer,
    appInfo: appInfoReducer,
    notifyBanner: notifyBannerReducer,
    theme: themeReducer,
  });
  return appReducer(state, action);
};

export default rootReducer;
