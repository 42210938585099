import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import SelectField from 'sharedComponents/FinalForm/SelectField/SelectField';
import DateHelpers from 'utils/DateHelpers';

const dateArray = DateHelpers.yearArray();

export class SgCustomerSearch extends Component {
  state = {
    bools: ['true', 'false'],
    volumeSize: ['all', 'small', 'medium', 'large'],
    loading: false,
    message: '',
  }

  onSubmit = async (values) => {
    this.setState({ loading: true });
    const message = await this.search(values);
    return this.setState({ message, loading: false });
  };

  search = async (values) => {
    this.setState({ message: '' });
    const isTrueSet = (values.removeSgCustomersWithoutEmails === 'true');

    try {
      const query = {
        query: `{
          sgCustomerEmailList(
              divisionRegion: "${values.divisionRegion}",
              billClass: "${values.billClass}",
              progYear: ${values.progYear},
              serviceState:"${values.serviceState}",
              balloted: ${false},
              currSupplier: "${values.currSupplier}",
              pricingOpt: "${values.pricingOpt}",
              subject: "${values.subject}",
              removeSgCustomersWithoutEmails: ${isTrueSet},
              volumeSize: "${values.volumeSize}",
            )
          }`,
      };
      const res = await axios.post('/graphql/', query);
      if (res.data.errors) {
        return res.data.errors;
      }
      return res.data.data.sgCustomerEmailList;
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{
          billClass: this.props.billClass[1],
          divisionRegion: this.props.divisionRegion[0],
          serviceState: this.props.serviceState[1],
          pricingOpt: this.props.pricingOpt[0],
          currSupplier: this.props.currSupplier[0],
          progYear: dateArray[0],
          removeSgCustomersWithoutEmails: true,
          volumeSize: this.state.volumeSize[0],
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Field
                name="volumeSize"
                component="select"
                label="Customer Volume Size"
              >
                {field => (
                  <SelectField
                    field={field}
                    options={this.state.volumeSize}
                    width="100%"
                  />
                )}
              </Field>
              <Field
                name="removeSgCustomersWithoutEmails"
                component="select"
                label="Remove SgCustomers Without Emails"
              >
                {field => (
                  <SelectField
                    field={field}
                    options={this.state.bools}
                    width="100%"
                  />
                )}
              </Field>
              <Field name="billClass" component="select" label="bill Class">
                {field => (
                  <SelectField
                    field={field}
                    options={this.props.billClass}
                    width="100%"
                  />
                )}
              </Field>
              <Field name="currSupplier" component="select" label="Curr Supplier">
                {field => (
                  <SelectField
                    field={field}
                    options={this.props.currSupplier}
                    width="100%"
                  />
                )}
              </Field>
              <Field name="pricingOpt" component="select" label="pricing Opt">
                {field => (
                  <SelectField
                    field={field}
                    options={this.props.pricingOpt}
                    width="100%"
                  />
                )}
              </Field>
              <Field name="divisionRegion" component="select" label="division Region">
                {field => (
                  <SelectField
                    field={field}
                    options={this.props.divisionRegion}
                    width="100%"
                  />
                )}
              </Field>
              <Field name="serviceState" component="select" label="service State">
                {field => (
                  <SelectField
                    field={field}
                    options={this.props.serviceState}
                    width="100%"
                  />
                )}
              </Field>
              <Field name="progYear" component="select" label="prog year">
                {field => (
                  <SelectField
                    field={field}
                    options={dateArray}
                    width="100%"
                  />
                )}
              </Field>
              <Field
                name="subject"
                label="subject for email"
                validate={value => (value ? undefined : 'Required')}
              >
                {field => <Input field={field} /> }
              </Field>
            </Grid>
            <div>
              <Submit submitting={this.state.loading || submitting}>
                search
              </Submit>
            </div>
            {this.state.message}
          </form>
        )}
      </Form>
    );
  }
}

export default SgCustomerSearch;

SgCustomerSearch.propTypes = {
  billClass: PropTypes.arrayOf(PropTypes.string).isRequired,
  divisionRegion: PropTypes.arrayOf(PropTypes.string).isRequired,
  serviceState: PropTypes.arrayOf(PropTypes.string).isRequired,
  pricingOpt: PropTypes.arrayOf(PropTypes.string).isRequired,
  currSupplier: PropTypes.arrayOf(PropTypes.string).isRequired,
};

SgCustomerSearch.defaultProps = {};
