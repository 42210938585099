import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FieldArray} from 'react-final-form-arrays';
import Hidden from '@material-ui/core/Hidden';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import CheckBoxArray from 'sharedComponents/FinalForm/CheckBoxArray/CheckBoxArray';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Grid from '@material-ui/core/Grid';
import VolumeTable from './components/VolumeTable/VolumeTable';
import styles from './SearchResultTable.module.scss';

export class SearchResultTable extends Component {
  state = {
    checked: true,
    avgVolumesDialog: false,
    expanded: {},
  };

  toggleCheckboxes = (fields) => {
    this.setState(oldState => ({checked: !oldState.checked}));

    if (!this.state.checked) {
      this.props.searchResults.map(result => fields.push(JSON.stringify(result)));
    } else {
      fields.value.map((value, index) => fields.remove(index));
    }
  };

  expandTable = (index) => {
    this.setState(oldState => ({
      expanded: Object.assign(oldState.expanded, {[index]: !oldState.expanded[index]}),
    }));
  };

  validateLocations = (allValues) => {
    if (allValues.locations.length === 0) return true;
    return false;
  }

  sumVolumes = (key = 'totalUsage', searchResults) => searchResults.reduce(((accumulator, currentValue) => accumulator + currentValue[key]), 0)

  toggleAvgVolumesDialog = (searchResults = []) => {
    if (searchResults.length > 0) {
      const avgVolumes = {
        ...searchResults[0],
        janPremUsage: this.sumVolumes('janPremUsage', searchResults),
        febPremUsage: this.sumVolumes('febPremUsage', searchResults),
        marPremUsage: this.sumVolumes('marPremUsage', searchResults),
        aprPremUsage: this.sumVolumes('aprPremUsage', searchResults),
        mayPremUsage: this.sumVolumes('mayPremUsage', searchResults),
        junPremUsage: this.sumVolumes('junPremUsage', searchResults),
        julPremUsage: this.sumVolumes('julPremUsage', searchResults),
        augPremUsage: this.sumVolumes('augPremUsage', searchResults),
        sepPremUsage: this.sumVolumes('sepPremUsage', searchResults),
        octPremUsage: this.sumVolumes('octPremUsage', searchResults),
        novPremUsage: this.sumVolumes('novPremUsage', searchResults),
        decPremUsage: this.sumVolumes('decPremUsage', searchResults),
        totalUsage: this.sumVolumes('totalUsage', searchResults),
      };
      return this.setState(oldState => ({
        avgVolumesDialog: !oldState.avgVolumesDialog,
        avgVolumes,
      }));
    }

    return this.setState(oldState => ({
      avgVolumesDialog: !oldState.avgVolumesDialog,
    }));
  }

  render() {
    const {searchResults} = this.props;
    return (
      <>
        <div className={`${styles.customRow} ${styles.headerRow}`}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item xs={1}>
              <FieldArray
                name="locations"
              >
                {({fields}) => (
                  <CheckBoxArray
                    fields={fields}
                    callback={this.toggleCheckboxes}
                    checked={this.state.checked}
                    value="selectAll"
                  />
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={2}>Bill Class</Grid>
            <Hidden only={['xs', 'sm']}>
              <Grid item xs={2}>Last Name</Grid>
            </Hidden>
            <Grid item xs={3}>Premise / Account </Grid>
            <Hidden only={['xs', 'sm']}>
              <Grid item xs={2}>Volume</Grid>
              <Grid item xs={2}>Expand</Grid>
            </Hidden>
          </Grid>
        </div>
        {searchResults.map((result, i) => (
          <div className={styles.customRow} key={i}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={1}>
                <FieldArray
                  name="locations"
                  validate={(value, allValues) => this.validateLocations(allValues)}
                >
                  {({fields}) => (
                    <CheckBoxArray
                      fields={fields}
                      value={result}
                    />
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={2}>{result.billClass}</Grid>
              <Hidden only={['xs', 'sm']}>
                <Grid item xs={2}>
                  {`${result.lastName}`}
                </Grid>
              </Hidden>
              <Grid item xs={3}>
                {`${result.premiseNumber}/${result.accountNum}`}

              </Grid>
              <Hidden only={['xs', 'sm']}>
                <Grid item xs={2}>{result.totalUsage}</Grid>
                <Grid
                  item
                  xs={2}
                >
                  {this.state.expanded[i] ? (
                    <KeyboardArrowUp id="ArrowUp" className={styles.expandArrow} onClick={() => this.expandTable(i)} />
                  ) : (
                    <KeyboardArrowDown id="ArrowDown" className={styles.expandArrow} onClick={() => this.expandTable(i)} />
                  )}
                </Grid>
              </Hidden>
              <VolumeTable
                volumes={result}
                expand={this.state.expanded[i]}
              />
            </Grid>
          </div>
        ))}
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={7} />
          <Grid item xs={2}>Total Volume</Grid>
          <Grid item xs={1}>
            <MuiDialog
              open={this.state.avgVolumesDialog}
              toggleDialog={() => this.toggleAvgVolumesDialog(searchResults)}
              titleText="volumes"
              buttonText={this.sumVolumes('totalUsage', searchResults).toString()}
            >
              <VolumeTable
                volumes={this.state.avgVolumes}
                expand={this.state.avgVolumesDialog}
              />
            </MuiDialog>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </>
    );
  }
}

export default SearchResultTable;

SearchResultTable.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape({})),
};

SearchResultTable.defaultProps = {
  searchResults: [],
};
