import { SET_THEME_REQUEST, SET_THEME_SUCCESS } from '../constants/ActionTypes';

export const setThemeRequest = () => ({
  type: SET_THEME_REQUEST,
});

export const setThemeSuccess = theme => ({
  type: SET_THEME_SUCCESS,
  theme,
});

export default function setThemeAction(theme) {
  return async (dispatch) => {
    dispatch(setThemeRequest());
    dispatch(setThemeSuccess(theme));
  };
}
