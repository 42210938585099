import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';
import LocationVolumeHeaders from './components/LocationVolumeHeaders/LocationVolumeHeaders';
import styles from './HeaderCell.module.scss';

const HeaderCell = ({
  startDate,
  sumReceiptVolumes,
  sumDeliveryVolume,
  dispatch,
  editPathBatch,
  summaryViewCallBack,
  showVolumes,
  toggleVolumes,
  toggleArchived,
  formState,
  disabled,
}) => (
  <TableHead>
    <TableRow>
      <TableCell align="center" variant="body" colSpan={1}>
        {showVolumes ? 'Day' : 'Date'}
      </TableCell>
      <TableCell align="center" variant="body" colSpan={1}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid className={styles.toggleVolumes}>UpStream</Grid>
          <Grid className={styles.toggleVolumes}>
            {showVolumes ? (
              <KeyboardArrowLeft
                className={styles.pointer}
                onClick={toggleVolumes}
              />
            ) : (
              <KeyboardArrowRight
                onClick={toggleVolumes}
                className={styles.pointer}
              />
            )}
          </Grid>
        </Grid>
      </TableCell>
      <LocationVolumeHeaders
        disabled={disabled}
        showVolumes={showVolumes}
        sumReceiptVolumes={sumReceiptVolumes}
        toggleArchived={toggleArchived}
        sumDeliveryVolume={sumDeliveryVolume}
        startDate={startDate}
        formState={formState}
        dispatch={dispatch}
        editPathBatch={editPathBatch}
        summaryViewCallBack={summaryViewCallBack}
      />
      <TableCell align="center" variant="body" colSpan={1}>
        DownStream
      </TableCell>
      <TableCell align="center" variant="body" colSpan={1}>
        Balance
      </TableCell>
    </TableRow>
  </TableHead>
);

export default HeaderCell;

HeaderCell.propTypes = {
  disabled: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  sumReceiptVolumes: PropTypes.func.isRequired,
  sumDeliveryVolume: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  editPathBatch: PropTypes.func.isRequired,
  summaryViewCallBack: PropTypes.func.isRequired,
  showVolumes: PropTypes.bool.isRequired,
  toggleVolumes: PropTypes.func.isRequired,
  toggleArchived: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    fields: PropTypes.shape(),
    values: PropTypes.shape(),
  }),
};

HeaderCell.defaultProps = {
  formState: {
    fields: {},
    values: {},
  },
};
