import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Right from './components/Right/Right';
import Left from './components/Left/Left';
import Center from './components/Center/Center';

export class ToggleViews extends Component {
  state = {
    leftView: this.props.leftView,
    rightView: this.props.rightView,
  };

  toggleView = (view) => {
    this.setState(prevState => ({ [view]: !prevState[view] }));
  };

  renderThis = () => {
    let size = 12;
    if (this.state.leftView || this.state.rightView) size = 9;
    if (this.state.leftView && this.state.rightView) size = 6;
    return size;
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Left
          title={this.props.titleLeft}
          leftView={this.state.leftView}
          toggleView={this.toggleView}
          leftComponent={this.props.leftComponent}
        />
        <Center
          title={this.props.titleCenter}
          renderThis={this.renderThis}
          toggleView={this.toggleView}
          leftComponent={this.props.leftComponent}
          rightComponent={this.props.rightComponent}
          centerComponent={this.props.centerComponent}
        />
        <Right
          title={this.props.titleRight}
          rightView={this.state.rightView}
          toggleView={this.toggleView}
          rightComponent={this.props.rightComponent}
        />
      </Grid>
    );
  }
}

export default ToggleViews;

ToggleViews.propTypes = {
  leftComponent: PropTypes.node,
  centerComponent: PropTypes.node.isRequired,
  rightComponent: PropTypes.node,
  leftView: PropTypes.bool,
  rightView: PropTypes.bool,
  titleCenter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  titleRight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  titleLeft: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

ToggleViews.defaultProps = {
  leftComponent: null,
  rightComponent: null,
  leftView: false,
  rightView: false,
  titleCenter: '',
  titleRight: '',
  titleLeft: '',
};
