import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import styles from './Submit.module.scss';

/* istanbul ignore next */
export const Submit = ({
  valid,
  submitting,
  children,
  buttonProps,
  classes,
  variant,
  hide,
  disabled,
}) => (
  <>
    <Button
      type="submit"
      fullWidth
      variant={variant}
      color="primary"
      className={submitting || hide ? styles.hideSubmit : `${classes.submit} ${styles.default}`}
      disabled={!valid || submitting || disabled}
      {...buttonProps}
    >
      {children || 'Submit'}
    </Button>
    {submitting && (
      <AppLoader
        loading={submitting}
      />
    )}
  </>
);

export default Submit;

Submit.propTypes = {
  valid: PropTypes.bool,
  submitting: PropTypes.bool,
  children: PropTypes.node,
  buttonProps: PropTypes.shape({}),
  classes: PropTypes.shape({
    submit: PropTypes.bool,
  }),
  variant: PropTypes.string,
  hide: PropTypes.bool,
  disabled: PropTypes.bool,
};

Submit.defaultProps = {
  children: null,
  classes: {},
  valid: false,
  submitting: false,
  buttonProps: {},
  variant: 'outlined',
  hide: false,
  disabled: false,
};
