import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import PropTypes from 'prop-types';
import styles from './Thumbs.module.scss';

const Thumbs = ({ files }) => (
  <div className={styles.root}>
    <GridList cellHeight={160} className={styles.gridList} cols={3}>
      {files.map(file => (
        <GridListTile key={file.preview} cols={1}>
          {file.type === 'application/pdf' && (
            <embed
              src={file.preview}
              type={file.type}
              alt="Preview"
              height="100%"
              width="100%"
            />
          )}
          {file.type !== 'application/pdf' && (
            <img
              type={file.type}
              src={file.preview}
              alt="Preview"
            />
          )}
        </GridListTile>
      ))}
    </GridList>
  </div>
);

export default Thumbs;

Thumbs.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape()),
};

Thumbs.defaultProps = {
  files: [],
};
