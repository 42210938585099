import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Search from '@material-ui/icons/Search';
import { Avatar, Tooltip } from '@material-ui/core';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import SelectField from 'sharedComponents/FinalForm/SelectField/SelectField';
import { Form, Field } from 'react-final-form';
import DateHelpers from 'utils/DateHelpers';
import styles from './SearchMonth.module.scss';

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['startDate'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export class SearchMonth extends Component {
  state = {
    open: false,
  }

  toggleDialog = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  onSubmit = (values) => {
    const startDate = new Date(`${values.startDate}-1`);
    const daysInMonth = DateHelpers.daysInMonth(startDate);
    const endDate = new Date(`${values.startDate}-${daysInMonth} 00:02:00 GMT-0800`);
    this.props.searchDate(startDate, endDate, values.company, values.interstate);
    this.toggleDialog();
  }

  render() {
    return (
      <>
        <MuiDialog
          open={this.state.open}
          toggleDialog={this.toggleDialog}
          titleText="search for summary"
        >
          <Form
            onSubmit={this.onSubmit}
            validate={validate}
            initialValues={{
              startDate: DateHelpers.formatYearMonth(this.props.startDate),
              company: this.props.company,
              interstate: this.props.interstate,
            }}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field name="company" component="select" label="Company">
                    {field => (
                      <SelectField
                        field={field}
                        options={this.props.companies}
                        displayKey="name"
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <Field name="interstate" component="select" label="ldc">
                    {field => (
                      <SelectField
                        field={field}
                        options={this.props.interstates}
                        displayKey="search"
                      />
                    )}
                  </Field>
                </div>
                <div>
                  <Field
                    name="startDate"
                    label="startDate"
                    type="month"
                  >
                    {field => (<Input field={field} />)}
                  </Field>
                </div>
                <div>
                  <Submit submitting={submitting}>
                    search
                  </Submit>
                </div>
              </form>
            )}
          </Form>
        </MuiDialog>
        <Tooltip title="search paths by month">
          <Avatar
            className={styles.onClick}
            onClick={this.toggleDialog}
          >
            <Search />
          </Avatar>
        </Tooltip>
      </>
    );
  }
}

export default SearchMonth;

SearchMonth.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  searchDate: PropTypes.func.isRequired,
  interstates: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  company: PropTypes.shape().isRequired,
  interstate: PropTypes.shape().isRequired,
};

SearchMonth.defaultProps = {};
