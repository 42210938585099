import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';

export const TermsAndConditions = ({ toggleDialog, open }) => (
  <>
    <Button color="primary" onClick={toggleDialog}>
      Terms & Conditions
    </Button>
    <Dialog
      open={open}
      onClose={toggleDialog}
    >
      <DialogTitle>
        Program Terms and Conditions
      </DialogTitle>
      <DialogContent>
        <Typography component="h2" gutterBottom>All Programs</Typography>
        <DialogContentText>
          Subject only to a material change in the Tallgrass Interstate Gas
          Transmission tariff, the index price is guaranteed for the term of
          the agreement
        </DialogContentText>
        <Typography component="h2" gutterBottom>Secured Fixed</Typography>
        <DialogContentText>
          Subject only to a material change in the Tall grass Interstate Gas
          Transmission tariff, the price is guaranteed not to change for the
          term of the agreement. A $150 termination shall be charged to exit
          program prior to the end of the term. The customer agrees that
          WoodRiver has the right to terminate the deal if the customer
          materially alters the type and amount of usage at the premises
          (e.g. a new addition or an additional furnace).
        </DialogContentText>
        <Typography component="h2" gutterBottom>Residential: Green+</Typography>
        <DialogContentText>
          We’re excited to offer our customers the ability to offset their carbon
          usage with our Green+ program through the use of Renewable Energy Credits
          (RECs). One REC represents 1,000 kilowatt-hours (kWh) of renewable electricity
          generated. These credits are traceable, tradeable, and finite. For each new residential
          Choice customer who participates in the Green+ program, WoodRiver Energy will
          purchase 10 RECs through the North American Renewals Registry.
        </DialogContentText>
        <Typography component="h2" gutterBottom>Commercial: Green+</Typography>
        <DialogContentText>
          We’re excited to offer our commercial customers the ability to offset their
          carbon usage with our Green+ program through the use of Renewable Energy
          Credits (RECs). One renewable energy certificate (REC) represents 1,000
          kilowatt-hours (kWh) of renewable electricity generated. These credits are
          traceable, tradeable, and finite. For each new commercial Choice customer who
          participates in the Green+ program, WoodRiver Energy will purchase 10 RECs
          per every 100 dekatherms of gas that is projected to be used based on … RECs
          through the North American Renewals Registry.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog} color="primary" autoFocus>
          Return to page
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

export default TermsAndConditions;

TermsAndConditions.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

TermsAndConditions.defaultProps = {
  open: false,
};
