import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Clear from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';
import styles from './Right.module.scss';

export const Right = ({
  rightView,
  toggleView,
  rightComponent,
  title,
}) => (
  <Grid
    container
    item
    xs={12}
    md={3}
    style={{
      display: rightView ? 'block' : 'none',
    }}
  >
    <Card className={styles.card}>
      <CardHeader
        title={title}
        action={(
          <Avatar
            className={styles.onClick}
            onClick={() => toggleView('rightView')}
          >
            <Clear />
          </Avatar>
        )}
      />
      <CardContent>
        {rightComponent}
      </CardContent>
    </Card>
  </Grid>
);

export default Right;

Right.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  rightView: PropTypes.bool,
  toggleView: PropTypes.func.isRequired,
  rightComponent: PropTypes.node,
};

Right.defaultProps = {
  title: '',
  rightComponent: null,
  rightView: false,
};
