import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import { change } from 'redux-form';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import { Form, Field } from 'react-final-form';
import CardActionArea from '@material-ui/core/Button';

export class UpdatePathVolumes extends Component {
  state = {
    open: false,
  }

  toggleDialog = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  }

  setFormValues = async (values) => {
    const formValue = this.props.formState.PathForm
      ? this.props.formState.PathForm.values
      : {};

    const pathPromises = Object.keys(formValue).map(async (key) => {
      const keyValue = `['${key}'][${this.props.index}]['dth']`;
      const updatedPath = Object.assign(
        formValue[key][this.props.index],
        { dth: parseInt(values.dth, 10) },
      );

      await this.props.dispatch(change('PathForm', keyValue, values.dth));
      return updatedPath;
    });

    const paths = Promise.all(pathPromises);

    return paths;
  }

  onSubmit = async (values) => {
    const paths = await this.setFormValues(values);
    await this.props.editPathBatch(paths);
    this.toggleDialog();
    return this.setState({ error: '' });
  }

  render() {
    return (
      <>
        <MuiDialog
          open={this.state.open}
          toggleDialog={this.toggleDialog}
          titleText={`Update all of ${this.props.locationName} volumes`}
        >
          <Form
            onSubmit={this.onSubmit}
            initialValues={{ dth: 0 }}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  name="dth"
                  label="dth"
                  validate={value => (value ? undefined : 'Required')}
                >
                  {field => <Input field={field} />}
                </Field>
                <Submit
                  disabled={this.props.disabled}
                  submitting={submitting}
                >
                  update volumes
                </Submit>
              </form>
            )}
          </Form>
          {this.state.error ? this.state.error : ''}
        </MuiDialog>
        <Tooltip title={`Update all of ${this.props.locationName} volumes`} aria-label="add">
          <CardActionArea onClick={this.toggleDialog}>
            {this.props.locationName}
          </CardActionArea>
        </Tooltip>
      </>
    );
  }
}

export default UpdatePathVolumes;

UpdatePathVolumes.propTypes = {
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  editPathBatch: PropTypes.func.isRequired,
  locationName: PropTypes.string.isRequired,
  formState: PropTypes.shape({
    PathForm: PropTypes.shape({
      fields: PropTypes.shape(),
      values: PropTypes.shape(),
    }),
  }),
};

UpdatePathVolumes.defaultProps = {
  formState: {
    PathForm: {
      fields: {},
      values: {},
    },
  },
};
