import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import ControlNumberTable from './components/ControlNumberTable/ControlNumberTable';
import NeedAControlNumber from './components/NeedAControlNumber/NeedAControlNumber';

export const ControlNumbersDialog = ({
  quote,
  formState,
  setQuote,
  complete,
  resetForm,
}) => (
  <>
    <NeedAControlNumber />
    <Grid container>
      <Grid item xs={4}>
        <Button onClick={resetForm}>Reset Form</Button>
      </Grid>
    </Grid>
    <ControlNumberTable
      complete={complete}
      quote={quote}
      formState={formState}
      setQuote={setQuote}
    />
  </>
);

export default ControlNumbersDialog;

ControlNumbersDialog.propTypes = {
  resetForm: PropTypes.func.isRequired,

  complete: PropTypes.bool,
  quote: PropTypes.shape({}),
  formState: PropTypes.shape({}).isRequired,
  setQuote: PropTypes.func.isRequired,
};

ControlNumbersDialog.defaultProps = {
  complete: false,
  quote: {},
};
