import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import TextField from '@material-ui/core/TextField';

export class SearchResults extends Component {
  search = (search) => {
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['name'],
    };
    const fuse = new Fuse(this.props.searchArray, options);
    const result = search ? fuse.search(search) : this.props.searchArray;
    return this.props.setSearchResult(result);
  }

  render() {
    return (
      <TextField
        id="SearchSum"
        onChange={e => this.search(e.target.value)}
        label="Search"
        margin="normal"
        variant="outlined"
      />
    );
  }
}

export default SearchResults;

SearchResults.propTypes = {
  setSearchResult: PropTypes.func.isRequired,
  searchArray: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
};
