import React from 'react';
import PropTypes from 'prop-types';
import ReAuthenticateAdmin from './components/ReAuthenticateAdmin/ReAuthenticateAdmin';
import UpdateTabCache from './components/UpdateTabCache/UpdateTabCache';

const DocuSignApi = ({
  location,
  history,
}) => (
  <>
    <ReAuthenticateAdmin
      location={location}
      history={history}
    />
    <UpdateTabCache />
  </>
);

export default DocuSignApi;

DocuSignApi.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};
