import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

/**
 * Input for redux forms input field
 *
 * console shows onSubmit values
 */

export const InputSmall = ({
  input,
  label,
  type,
  meta: { error },
  custom,
  disabled,
  min,
}) => (
  <>
    <input
      {...{
        ...input,
        ...custom,
        placeholder: label,
        type,
        ...(min && { min }),
        ...(disabled && { disabled }),
      }}
    />
    {error && (
      <FormHelperText
        id="component-error-text"
        margin="dense"
        style={error && { color: 'red' }}
      >
        {error}
      </FormHelperText>
    )}
  </>
);

export default InputSmall;

InputSmall.propTypes = {
  /** type of the input ex ( text, number, date) */
  type: PropTypes.string,
  /** label for your input */
  label: PropTypes.string,
  /** margin for your input */
  margin: PropTypes.string,
  /** helper text for your input */
  helperText: PropTypes.string,
  /** input for your input ;) */
  input: PropTypes.shape({}),
  /** custom props for your input */
  custom: PropTypes.shape({}),
  /** add a component to show at the end of the input */
  endAdornment: PropTypes.node,
  /** metadata regarding input state */
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  /** disabled input */
  disabled: PropTypes.bool,
  /** set the value of the input out side of the redux form */
  setValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  tempValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  min: PropTypes.string,
};

InputSmall.defaultProps = {
  min: null,
  endAdornment: undefined,
  type: 'text',
  input: {},
  custom: {},
  meta: {
    touched: 'false',
    error: '',
    message: '',
  },
  label: '',
  helperText: '',
  margin: 'none',
  classes: {},
  disabled: false,
  setValue: false,
  tempValue: false,
  name: '',
};
