import React, { Component } from 'react';
import PropTypes from 'prop-types';
import notifyBannerAction from 'actions/NotifyBannerAction';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import Radio from 'sharedComponents/FinalForm/Radio/Radio';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import DateHelpers from 'utils/DateHelpers';
import { Grid, TextField } from '@material-ui/core';
import ReviewPaths from './components/ReviewPaths/ReviewPaths';
import styles from './CreatePaths.module.scss';

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['deliveryLocation', 'dth'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export class CreatePaths extends Component {
  // Nasty hack to ensure only the latest results show up.
  queryNumber = 0;
  queryTimeout = null;

  state = {
    deliveryLocations: [],
    open: false,
    searchValue: '',
  };

  handleLocationSearch = (e) => {
    if (e.target.value === '') {
      return this.setState({
        deliveryLocations: [],
        searchValue: '',
      });
    }

    this.setState({ searchValue: e.target.value });

    try {
      const searchQuery = `
        query {
          locationSearch(terms: "${e.target.value}") {
            id
            name
            pipeline {
              name
            }
          }
        }`;

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(async () => {
        this.queryNumber += 1;
        const ourQueryNumber = this.queryNumber;

        const {
          data: {
            data: { locationSearch },
          },
        } = await axios.post('/graphql', { query: searchQuery });

        // Abort if another query superseded this one.
        if (ourQueryNumber !== this.queryNumber) {
          return;
        }

        this.setState({ deliveryLocations: locationSearch });
      }, 500);
    } catch (err) {
      this.props.dispatch(
        notifyBannerAction({
          message: err.toString(),
          showBanner: true,
          error: true,
        })
      );
    }
    return null;
  };

  toggleDialog = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  createPaths = (values) => {
    const date = DateHelpers.beginningOfTheMonth(this.props.startDate);
    const daysInMonth = DateHelpers.daysInMonth(date);
    const { deliveryLocation } = values;
    const value = Object.assign(values, { deliveryLocation });
    const paths = [];

    let i = 0;
    while (i < daysInMonth) {
      const newDate = DateHelpers.addDaysToDate(date, i === 0 ? 0 : 1);
      paths.push(
        Object.assign({ gasDay: DateHelpers.formatDate(newDate) }, value)
      );
      i += 1;
    }

    return paths;
  };

  onSubmit = (values = {}) => {
    const paths = this.createPaths(values);
    this.setState({
      open: true,
      paths,
    });
  };

  render() {
    return (
      <>
        <ReviewPaths
          open={this.state.open}
          paths={this.state.paths}
          toggleDialog={this.toggleDialog}
          pathViewCallBack={this.props.pathViewCallBack}
          dispatch={this.props.dispatch}
        />
        <Form
          onSubmit={this.onSubmit}
          validate={validate}
          initialValues={{
            pipelineId: this.props.pipelineId,
            receiptLocationId: this.props.receiptLocationId,
            deliveryLocation: '',
            receiptLocation: this.props.receiptLocation,
          }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className={styles.searchBar}>
                <TextField
                  id="outlined-name"
                  label="Search for delivery location"
                  value={this.state.searchValue}
                  onChange={this.handleLocationSearch}
                  margin="normal"
                  variant="outlined"
                  helperText="Search for delivery location by location name"
                  fullWidth
                />
              </div>
              <div>
                {this.state.deliveryLocations.map((location, i) => (
                  <Grid container key={i}>
                    <Grid item xs={12} className={styles.icons}>
                      <Field
                        name="deliveryLocation"
                        label={`location: ${location.name}, pipeline: ${location.pipeline.name}`}
                        type="radio"
                        value={location}
                      >
                        {(field) => <Radio field={field} />}
                      </Field>
                    </Grid>
                  </Grid>
                ))}
              </div>
              <div>
                <Field name="dth" label="dth" type="number">
                  {(field) => <Input field={field} />}
                </Field>
              </div>
              <div>
                <Submit submitting={submitting}>review paths</Submit>
              </div>
            </form>
          )}
        </Form>
      </>
    );
  }
}

export default CreatePaths;

CreatePaths.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  dispatch: PropTypes.func.isRequired,
  pipelineId: PropTypes.number.isRequired,
  receiptLocationId: PropTypes.number.isRequired,
  receiptLocation: PropTypes.shape().isRequired,
  pathViewCallBack: PropTypes.func.isRequired,
};
