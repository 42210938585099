import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DateHelpers from 'utils/DateHelpers';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import PathTable from './components/PathTable/PathTable';

export class Paths extends Component {
  state = { loading: true, rows: [], error: '' }

  async componentDidMount() {
    await this.pathByDaySummary();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.row !== prevProps.row || prevProps.toggle !== this.props.toggle) {
      await this.pathByDaySummary();
    }
  }

  pathByDaySummary = async () => {
    this.setState({ loading: true, rows: [] });
    const startDate = DateHelpers.formatDate(this.props.startDate);
    const endDate = DateHelpers.formatDate(this.props.endDate);
    const query = {
      query: `{
        pathingReports(
          id: ${this.props.row.pipelineId}
          locationId: ${this.props.row.id}
          startDate: "${startDate}"
          endDate: "${endDate}"
          companyId: ${this.props.companyId}
        ) {
          id
          name
          volume
          receipts {
            id
            gasDay
            receiptLocationId
            deliveryLocationId
            pipelineId
            groupId
            transportId
            fuelPercent
            companyId
            dth
            archived
            deliveryLocation {
              name
            }
          }
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      this.setState({
        loading: false,
        rows: res.data.data.pathingReports,
      });
    } catch (err) {
      return this.setState({
        error: err.toString(),
      });
    }
    return false;
  };

  render() {
    return (
      <>
        {this.state.error && (
          <span style={{ color: 'red' }}>
            {this.state.error}
          </span>
        )}
        {this.state.loading ? (
          <AppLoader loading={this.state.loading} />
        ) : (
          <PathTable
            locationId={this.props.row.id}
            disabled={this.props.disabled}
            startDate={this.props.startDate}
            rows={this.state.rows}
            formState={this.props.formState}
            dispatch={this.props.dispatch}
            summaryViewCallBack={this.props.summaryViewCallBack}
            lockPaths={this.props.lockPaths}
          />
        )}
      </>
    );
  }
}

export default Paths;

Paths.propTypes = {
  companyId: PropTypes.number.isRequired,
  lockPaths: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  toggle: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.shape({}),
  summaryViewCallBack: PropTypes.func.isRequired,
  row: PropTypes.shape({
    pipelineId: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};

Paths.defaultProps = {
  formState: {},
  toggle: false,
};
