import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Submit from '../Submit/Submit';
import styles from './Form.module.scss';

/**
 * Form wrapper for redux forms need to have at least one child element
 */

export const Form = ({
  children,
  handleSubmit,
  valid,
  submitting,
  classes,
}) => (
  <form
    className={classes.form || styles.form}
    onSubmit={handleSubmit}
  >
    {children.map((child, index) => {
      if (child.type === Submit) {
        return cloneElement(
          child,
          {
            key: index,
            valid,
            submitting,
          },
        );
      }
      return child;
    })}
  </form>
);

export default reduxForm({ enableReinitialize: true })(Form);

Form.propTypes = {
  /** form children */
  children: PropTypes.node.isRequired,
  /** function handling form submission */
  handleSubmit: PropTypes.func.isRequired,
  /** boolean declaring form validity */
  valid: PropTypes.bool,
  /** boolean declaring state of submission */
  submitting: PropTypes.bool,
  /** styles object passed into form */
  classes: PropTypes.shape({
    form: PropTypes.string,
  }),
};

Form.defaultProps = {
  valid: false,
  submitting: false,
  classes: {},
};
