import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DateHelpers from 'utils/DateHelpers';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import { Form } from 'react-final-form';
import notifyBannerAction from 'actions/NotifyBannerAction';
import {
  Tooltip,
  Avatar,
  Typography,
  CardContent,
} from '@material-ui/core';
import {
  LeakRemove,
} from '@material-ui/icons';

import styles from './NormalizeVolumes.module.scss';

export class NormalizeVolumes extends Component {
  state = {
    open: false,
  }

  toggleDialog = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  onSubmit = async () => {
    const query = {
      query: `mutation {
        normalizeDealDetailVolumes (
            begDate: "${DateHelpers.formatDate(this.props.begDate)}",
            endDate:  "${DateHelpers.endOfTheMonth(this.props.begDate)}"
        )
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);

      if (res.data.errors) {
        return this.props.dispatch(notifyBannerAction({
          message: res.data.errors.toString(),
          showBanner: true,
          error: true,
        }));
      }
      return this.toggleDialog();
    } catch (error) {
      return this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
  }

  render() {
    return (
      <>
        <MuiDialog
          open={this.state.open}
          toggleDialog={this.toggleDialog}
          titleText="Normalize Volumes"
        >
          <Form onSubmit={this.onSubmit}>
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <CardContent>
                  <Typography>
                    {`Are you sure you want to normalize volumes for ${DateHelpers.formatDate(this.props.begDate)}`}
                  </Typography>
                </CardContent>
                <Submit submitting={submitting}>
                  YES
                </Submit>
              </form>
            )}
          </Form>
        </MuiDialog>
        <Tooltip title="Normalize Volumes">
          <Avatar
            className={styles.onClick}
            onClick={this.toggleDialog}
          >
            <LeakRemove />
          </Avatar>
        </Tooltip>
      </>
    );
  }
}

export default NormalizeVolumes;

NormalizeVolumes.propTypes = {
  begDate: PropTypes.instanceOf(Date).isRequired,
  dispatch: PropTypes.func.isRequired,
};

NormalizeVolumes.defaultProps = {};
