// takes json array and returns it in a csv in base64 format
const jsonToCsv = (json) => {
  const fields = Object.keys(json[0]);
  const replacer = (key, value) => (value === null ? '' : value);
  const csv = json.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(fields.join(',')); // add header column
  const data = csv.join('\r\n');
  // eslint-disable-next-line no-undef
  return new Blob([data], { type: 'text/csv;charset=utf-8;' });
};

export default jsonToCsv;
