import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import CheckBox from 'sharedComponents/FinalForm/CheckBox/CheckBox';
import { Form, Field } from 'react-final-form';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';

export const ChoicePromoCodeForm = ({
  open,
  toggleDialog,
  selectedRow,
}) => {
  const onSubmit = async (values) => {
    let res;
    try {
      const promo = {
        ...values,
        discount: parseInt(values.discount, 10),
        signUpLimit: parseInt(values.signUpLimit, 10),
      };
      const queryString = {
        query: `mutation {
          upsertChoicePromoCode(choicePromoCode: ${graphqlObj(promo)}) {
            id
            description
            promoCode
            signUpLimit
            discount
            active
          }
        }`,
      };

      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return error;
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return res.data.errors[0].message;
    }
    return toggleDialog(res.data.data.upsertChoicePromoCode);
  };

  return (
    <MuiDialog
      open={open}
      toggleDialog={toggleDialog}
      fullScreen
    >
      <Form
        onSubmit={onSubmit}
        initialValues={selectedRow}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                name="description"
                label="description"
              >
                {field => (
                  <Input
                    width="60%"
                    field={field}
                    multiline
                  />
                )}
              </Field>
              <Field
                name="active"
                label="active"
                type="checkbox"
              >
                {field => <CheckBox field={field} />}
              </Field>
            </div>
            <div>
              <Field
                name="discount"
                label="discount"
                type="number"
              >
                {field => (
                  <Input
                    width="50%"
                    field={field}
                  />
                )}
              </Field>
              <Field
                label="sign up limit"
                name="signUpLimit"
                type="number"
              >
                {field => (
                  <Input
                    width="50%"
                    field={field}
                  />
                )}
              </Field>
            </div>
            <div>
              <Submit
                id="Submit"
                submitting={submitting}
              >
                Submit
              </Submit>
            </div>
          </form>
        )}
      </Form>
    </MuiDialog>
  );
};

export default ChoicePromoCodeForm;

ChoicePromoCodeForm.propTypes = {
  selectedRow: PropTypes.shape(),
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ChoicePromoCodeForm.defaultProps = {
  open: false,
  selectedRow: {},
};
