import React from 'react';
import PropTypes from 'prop-types';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import styles from './ViewNotes.module.scss';

export const ViewNotes = ({ toggleDialog, open, notes }) => (
  <MuiDialog
    open={open}
    toggleDialog={() => toggleDialog('openViewNotes')}
    titleText="Notes"
    buttonText="View Notes"
  >
    <List>
      {notes.reverse().map((note, i) => (
        <ListItem alignItems="flex-start" key={i}>
          <PersonIcon />
          <ListItemText
            primary={`${note ? `${note.user.firstName} ${note.user.lastName}` : 'No notes to show'}`}
            secondary={note && (
              <>
                <Typography component="span" color="textPrimary" className={styles.notes}>
                  {note.note}
                </Typography>
                {`Created At: ${note.createdAt}`}
              </>
            )}
          />
        </ListItem>
      ))}
    </List>
  </MuiDialog>
);

export default ViewNotes;

ViewNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({})),
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ViewNotes.defaultProps = {
  notes: [],
  open: false,
};
