import React, { Component } from 'react';
import graphqlCall from 'utils/graphqlCall';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import CreateConfirmationCodeGroup from './components/CreateConfirmationCodeGroup/CreateConfirmationCodeGroup';
import DisplayChoiceConfirmationCodes from './components/DisplayChoiceConfirmationCodes/DisplayChoiceConfirmationCodes';

export class ChoiceConfirmationCodes extends Component {
  state = {
    groups: [],
    loading: false,
  };

  componentDidMount = async () => {
    await this.getGroups();
  }

  getGroups = async () => {
    this.setState({ loading: true });
    const queryString = {
      query: `query { choiceConfirmationCodeGroups(active: ${true}) {
        id
        name
        choiceConfirmationCodeGroupUrls
        choiceConfirmationCodes {
          id
          code
          divisionRegion
          term
          program
        }
      }
    }`,
    };

    try {
      const { choiceConfirmationCodeGroups } = await graphqlCall(queryString);
      return this.setState({
        groups: choiceConfirmationCodeGroups,
        loading: false,
      });
    } catch (err) {
      return this.setState({ loading: false });
    }
  };

  createCallback = (choiceConfirmCode) => {
    const { groups } = this.state;
    const addToGroups = [choiceConfirmCode, ...groups];
    this.setState({ groups: addToGroups });
  };

  render() {
    if (this.state.loading) return <AppLoader loading={this.state.loading} />;

    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <CreateConfirmationCodeGroup createCallback={this.createCallback} />
        <DisplayChoiceConfirmationCodes groups={this.state.groups} />
      </div>
    );
  }
}

export default ChoiceConfirmationCodes;
