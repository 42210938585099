import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';
import ViewNotes from './components/ViewNotes/ViewNotes';
import CreateNote from './components/CreateNote/CreateNote';
import styles from './ChoiceQuoteNotes.module.scss';

export const ChoiceQuoteNotes = ({ quoteId, setNoteCallBack, notes }) => {
  const [openViewNotes, setOpenViewNotes] = useState(false);
  const [openCreateNote, setOpenCreateNote] = useState(false);

  const toggleDialog = (key) => {
    if (key === 'openViewNotes') {
      setOpenViewNotes(!openViewNotes);
    }
    if (key === 'openCreateNote') {
      setOpenCreateNote(!openCreateNote);
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={8}
        className={styles.container}
        justify="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <List>
            <ListItem alignItems="flex-start">
              <PersonIcon />
              <ListItemText
                primary={`${notes.length ? `${notes[notes.length - 1].user.firstName} ${notes[notes.length - 1].user.lastName}` : 'No notes to show'}`}
                secondary={notes.length && (
                  <>
                    <Typography component="span" color="textPrimary">
                      {notes[notes.length - 1].note}
                    </Typography>
                    {`Created At: ${notes[notes.length - 1].createdAt}`}
                  </>
                )}
              />
            </ListItem>
          </List>
        </Grid>

        <Grid
          container
          direction="column"
          item
          xs={12}
          md={6}
          justify="center"
          alignItems="center"
        >

          <Grid
            item
            xs={12}
            md={6}
          >
            <ViewNotes
              toggleDialog={toggleDialog}
              open={openViewNotes}
              notes={notes}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <CreateNote
              setNoteCallBack={setNoteCallBack}
              toggleDialog={toggleDialog}
              open={openCreateNote}
              quoteId={quoteId}
            />
          </Grid>

        </Grid>

      </Grid>
    </>
  );
};

export default ChoiceQuoteNotes;

ChoiceQuoteNotes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({
    note: PropTypes.string,
    createdAt: PropTypes.string,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  })),
  setNoteCallBack: PropTypes.func.isRequired,
  quoteId: PropTypes.number.isRequired,
};

ChoiceQuoteNotes.defaultProps = {
  notes: [],
};
