import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DateHelpers from 'utils/DateHelpers';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import { Form } from 'react-final-form';
import notifyBannerAction from 'actions/NotifyBannerAction';
import {
  Tooltip,
  Avatar,
  Typography,
  CardContent,
} from '@material-ui/core';
import {
  Lock,
  LockOpen,
} from '@material-ui/icons';
import styles from './IsOpen.module.scss';

export class IsOpen extends Component {
  state = {
    open: false,
  }

  async componentDidMount() {
    await this.checkForOpen();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate) {
      await this.checkForOpen();
    }
  }

  toggleDialog = () => {
    this.setState(prevState => ({
      open: !prevState.open,
    }));
  };

  disablePathsCallBack = (openMonths) => {
    const startDate = DateHelpers.formatDate(this.props.startDate);
    const isOpen = openMonths.find(month => month === startDate);
    this.props.disablePaths(!isOpen);
  }

  checkForOpen = async () => {
    const query = {
      query: '{ openPathMonths }',
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      return this.disablePathsCallBack(res.data.data.openPathMonths);
    } catch (error) {
      this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
    return null;
  };

  onSubmit = async () => {
    const query = {
      query: `mutation {
        editOpenPathsMonth (
          date: "${DateHelpers.formatDate(this.props.startDate)}"
        )
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      this.disablePathsCallBack(res.data.data.editOpenPathsMonth);
      return this.toggleDialog();
    } catch (error) {
      this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
    return null;
  }

  render() {
    const lockText = this.props.disabled ? 'unlock' : 'lock';
    return (
      <>
        <MuiDialog
          open={this.state.open}
          toggleDialog={this.toggleDialog}
          titleText={`${lockText} ${DateHelpers.dateMonthName(this.props.startDate)} paths`}
        >
          <Form onSubmit={this.onSubmit}>
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <CardContent>
                  <Typography>
                    {`Are you sure you want to ${lockText} these paths`}
                  </Typography>
                </CardContent>
                <Submit submitting={submitting}>
                  YES
                </Submit>
              </form>
            )}
          </Form>
        </MuiDialog>
        {this.props.disabled ? (
          <Tooltip title="path month locked">
            <Avatar
              className={styles.onClick}
              onClick={this.toggleDialog}
            >
              <Lock />
            </Avatar>
          </Tooltip>
        ) : (
          <Tooltip title="paths can be edited">
            <Avatar
              className={styles.onClick}
              onClick={this.toggleDialog}
            >
              <LockOpen />
            </Avatar>
          </Tooltip>
        )}
      </>
    );
  }
}

export default IsOpen;

IsOpen.propTypes = {
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disablePaths: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
};

IsOpen.defaultProps = {};
