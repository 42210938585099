import React from 'react';
import {
  Form,
  MuiInput,
  MuiSubmit,
  MuiRadio,
} from 'rff-wrapper';
import Grid from '@material-ui/core/Grid';
import graphqlCall from 'utils/graphqlCall';
import graphqlObj from 'utils/graphqlObj';
import PropTypes from 'prop-types';

export const pickPrice = (term, program, quoteData) => {
  if (term === '1' && program === 'index') return quoteData.baseTpFuelIndex;
  if (term === '2' && program === 'index') return quoteData.baseTpFuelIndex2;
  if (term === '3' && program === 'index') return quoteData.baseTpFuelIndex3;

  if (term === '1' && program === 'fixed') return quoteData.gFixed1;
  if (term === '2' && program === 'fixed') return quoteData.gFixed2;
  if (term === '3' && program === 'fixed') return quoteData.gFixed2;

  if (term === '1' && program === 'fmb') return quoteData.fmb1Total;
  if (term === '2' && program === 'fmb') return quoteData.fmb2Total;
  if (term === '3' && program === 'fmb') return quoteData.fmb2Total;

  return 9999;
};

export const matchCodesToPrograms = sgCustomer => sgCustomer.map(customer => ({
  ...customer,
  codePrices: (
    customer
      ?.confirmationCodeQuote
      ?.choiceConfirmationCodeGroup
      ?.choiceConfirmationCodes
      .map(code => ({
        ...code,
        price: pickPrice(
          code.term,
          code.program,
          customer.confirmationCodeQuote.quoteData,
        ),
      }))
      .filter(code => code.term !== '3') // remove 3 year option from view
  ),
}));

export const searchByNumbers = async (searchValue, searchKey) => {
  const queryString = {
    query: `{
          sgCustomer(
            ${searchKey}: ${(searchKey === 'accountNum') ? searchValue : `"${searchValue}"`}
            billClasses: ${graphqlObj(['COM', 'RES', 'IND', 'AGR'])},
            balloted: ${false}
          ) {
            id
            billClass
            premiseNumber
            accountNum
            lastName
            firstName
            serviceAddress
            serviceCity
            serviceState
            serviceZip
            totalUsage
            confirmationCodeQuote {
              id
              quoteData {
                gFixed1
                gFixed2
                gFixed3
                baseTpFuelIndex
                baseTpFuelIndex2
                baseTpFuelIndex3
                fmb1Total
                fmb2Total
                fmb3Total
              }
              choiceConfirmationCodeGroup {
                choiceConfirmationCodes {
                  code
                  term
                  program
                }
              }
            }
          }
        }`,
  };
  try {
    const { sgCustomer } = await graphqlCall(queryString);
    return ['', matchCodesToPrograms(sgCustomer)];
  } catch (err) {
    return [err.toString(), []];
  }
};

const SearchSgCustomer = ({ callback }) => {
  const onSubmit = async (values) => {
    const { searchKey, searchValue } = values;
    const sgCustomer = await searchByNumbers(
      searchValue,
      searchKey,
    );
    callback(sgCustomer[0], sgCustomer[1]);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ searchKey: 'accountNum' }}
      render={formProps => (
        <form onSubmit={formProps.handleSubmit}>
          <Grid item xs={12} sm={6}>
            <MuiRadio
              name="searchKey"
              value="accountNum"
              label="Account Number"
            />
            <MuiRadio
              name="searchKey"
              value="premiseNumber"
              label="Service Point ID (Premise Number)"
            />
            <MuiRadio
              name="searchKey"
              value="telephone"
              label="Telephone (no spaces)"
            />
            <MuiRadio
              name="searchKey"
              value="serviceAddress"
              label="Service Address"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <MuiInput
              name="searchValue"
              label="search"
            />
          </Grid>
          <MuiSubmit />
        </form>
      )}
    />
  );
};

export default SearchSgCustomer;

SearchSgCustomer.propTypes = {
  callback: PropTypes.func.isRequired,
};
