import {
  NOTIFY_BANNER_REQUEST,
  NOTIFY_BANNER_SUCCESS,
} from '../constants/ActionTypes';

const initialState = {
  loading: true,
  showBanner: false,
  message: '',
  error: false,
};

export default function notifyBannerReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFY_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFY_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    default:
      return state;
  }
}
