import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Input from 'sharedComponents/FinalForm/Input/Input';
import DateHelpers from 'utils/DateHelpers';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import CheckBoxArray from 'sharedComponents/FinalForm/CheckBoxArray/CheckBoxArray';
import MuiTablePagination from 'sharedComponents/MuiTablePagination/MuiTablePagination';
import graphqlObj from 'utils/graphqlObj';
import styles from './ExtractNacha.module.scss';

export class ExtractNacha extends Component {
  state = {
    sort: false,
    checked: true,
    error: null,
    columns: [
      { key: 'invoiceNumber', label: 'Invoice Number', align: 'center' },
      { key: 'customerName', label: 'Name' },
      { key: 'customerId', label: 'Customer Id', align: 'center' },
      { key: 'invoiceDate', label: 'Invoice Date' },
      { key: 'pmtDue', label: 'Pmt Due' },
      {
        key: 'invTotal',
        label: 'Balances',
        headerFormat: (column) => (
          <TableSortLabel
            active={this.state.sort}
            direction={this.state.sort ? 'asc' : 'desc'}
            onClick={this.sortBalance}
          >
            {column.label}
          </TableSortLabel>
        ),
        format: (value, report, columnIndex, rowIndex) => (
          <FieldArray name="radios">
            {() => (
              <Fragment key={columnIndex}>
                <div>
                  <Field
                    id="the-radios-invTotal"
                    name={`radios[${rowIndex}]`}
                    component="input"
                    type="radio"
                    value="invTotal"
                  />
                  <label htmlFor="the-radios-invTotal">
                    {`Current charges ${report.invTotal}`}
                  </label>
                </div>
                <div>
                  <Field
                    id="the-radios-balDue"
                    name={`radios[${rowIndex}]`}
                    component="input"
                    type="radio"
                    value="balDue"
                  />
                  <label htmlFor="the-radios-balDue">
                    {`AR charges ${report.balDue}`}
                  </label>
                </div>
              </Fragment>
            )}
          </FieldArray>
        ),
      },
      {
        key: 'balDue',
        label: 'batch',
        align: 'center',
        headerFormat: (column) => (
          <FormControlLabel
            control={
              <div>
                <Checkbox
                  checked={this.state.checked}
                  onClick={() =>
                    this.setState((oldState) => ({
                      checked: !oldState.checked,
                    }))
                  }
                />
              </div>
            }
            label={column.label}
          />
        ),
        format: (value, report, columnIndex, rowIndex) => (
          <FieldArray name="checkboxes">
            {({ fields }) => (
              <div>
                <CheckBoxArray
                  fields={fields}
                  value={{
                    ...report,
                    index: rowIndex,
                  }}
                />
              </div>
            )}
          </FieldArray>
        ),
      },
    ],
  };

  sortBalance = () => {
    const sortReport = this.props.nachaReport.sort((a, b) => {
      if (this.state.sort) return b.balDue - a.balDue;
      return a.balDue - b.balDue;
    });

    this.props.setNachaReport(sortReport);
    this.setState((oldState) => ({ sort: !oldState.sort }));
  };

  createBatch = async ({ checkboxes, radios, effectiveDate }) => {
    const nachaBatch = checkboxes
      .filter((x) => !!x)
      .map((nacha) => ({
        ...nacha,
        amount: nacha[radios[nacha.index]],
      }));
    await this.createNacha(nachaBatch, effectiveDate);
  };

  downloadLinks = (url, fileName) => {
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
    tempLink.remove();
  };

  createNacha = async (nachaBatch, effectiveDate) => {
    let res;
    try {
      const queryString = {
        query: `mutation {
        createNachaBatch(
          nachaReport: ${graphqlObj(nachaBatch)},
          effectiveDate: "${effectiveDate}",
        ) {
          id
          createdAt
          description
          effectiveDate
          batchStatus
          totalDebit
          nachaFileUrl
          denaliFileUrl
        }
      }`,
      };
      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return this.setState({ error: error.toString() });
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ error: res.data.errors[0].message });
    }

    this.downloadLinks(
      res.data.data.createNachaBatch.nachaFileUrl,
      `NACHA${effectiveDate}.csv`
    );

    const filteredArray = this.props.nachaReport.filter(
      (report) =>
        nachaBatch.filter(
          (batch) => batch.invoiceNumber === report.invoiceNumber
        ).length === 0
    );

    return this.props.setNachaReport(filteredArray);
  };

  validateDate = (dateString) => {
    const day = new Date(dateString).getDay();
    if (day === 5 || day === 6) {
      return 'Unable to process nacha on a weekend day';
    }
    if (!dateString) return 'Required';
    return undefined;
  };

  formValidate = (values) => {
    const errors = {};
    if (!values.checkboxes.length) {
      errors.effectiveDate = 'need to have at least one record in batch';
    }
    return errors;
  };

  formValues = () =>
    this.props.nachaReport.map((v, i) => {
      if (this.state.checked) {
        if (Math.sign(v.balDue) > 0) return { ...v, index: i };
        return null;
      }
      return null;
    });

  render() {
    return (
      <div>
        {this.state.error && (
          <div className={styles.generalErr}>
            <p>{this.state.error}</p>
          </div>
        )}
        {this.props.nachaReport.length > 0 && (
          <Paper className={styles.nachaReportTable}>
            <Form
              onSubmit={this.createBatch}
              mutators={{ ...arrayMutators }}
              validate={this.formValidate}
              initialValues={{
                radios: this.props.nachaReport.map(() => 'balDue'),
                checkboxes: this.formValues(),
                effectiveDate: DateHelpers.formatDate(new Date()),
              }}
            >
              {({ handleSubmit, submitting, form }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field
                      name="effectiveDate"
                      label="Effective Date"
                      validate={this.validateDate}
                      type="date"
                    >
                      {(field) => <Input field={field} />}
                    </Field>
                  </div>
                  <MuiTablePagination
                    columns={this.state.columns}
                    rows={this.props.nachaReport}
                  />
                  <Submit submitting={submitting}>Create Batch</Submit>
                </form>
              )}
            </Form>
          </Paper>
        )}
      </div>
    );
  }
}

export default ExtractNacha;

ExtractNacha.propTypes = {
  nachaReport: PropTypes.arrayOf(PropTypes.shape({})),
  setNachaReport: PropTypes.func.isRequired,
};

ExtractNacha.defaultProps = {
  nachaReport: [],
};
