/* eslint-disable no-tabs */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * WoodRiverEnergySvg takes a color and render woodriver energys logo svg
 */
const WoodRiverEnergySvg = ({
  fillIcon,
  primaryText,
  altText,
  classes,
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 309.9 70.9"
    width={296}
    height={62}
    className={classes.WoodRiverEnergySvg}
  >
    <g>
      <path fill={primaryText} stroke={primaryText} d="M82.5,31.3L79.3,44h-4.2l-5.3-18.5h4.4l3.3,13.4L81,25.5H84l3.5,13.4l3.2-13.4h4.4L89.9,44h-4.2L82.5,31.3z" />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M95,37.3c0-3.8,2.7-7,7.1-7c4.4,0,7.1,3.2,7.1,7c0,3.8-2.7,7.1-7.1,7.1C97.7,44.3,95,41.1,95,37.3z M105.6,37.3
		c0-2.1-1.2-3.9-3.4-3.9s-3.4,1.8-3.4,3.9c0,2.1,1.2,3.9,3.4,3.9S105.6,39.4,105.6,37.3z"
      />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M111,37.3c0-3.8,2.7-7,7.1-7c4.4,0,7.1,3.2,7.1,7c0,3.8-2.7,7.1-7.1,7.1C113.6,44.3,111,41.1,111,37.3z M121.5,37.3
		c0-2.1-1.2-3.9-3.4-3.9s-3.4,1.8-3.4,3.9c0,2.1,1.2,3.9,3.4,3.9S121.5,39.4,121.5,37.3z"
      />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M137,42.3c-1.1,1.3-2.5,2.1-4.1,2.1c-3.4,0-5.9-2.6-5.9-7c0-4.4,2.5-7.1,5.9-7.1c1.6,0,3.1,0.7,4.1,2.1v-6.8h3.6V44H137
		V42.3z M137,34.9c-0.6-0.9-1.9-1.5-3.1-1.5c-2,0-3.4,1.6-3.4,3.9c0,2.3,1.4,3.9,3.4,3.9c1.2,0,2.5-0.6,3.1-1.5V34.9z"
      />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M151,37.3h-2.9V44h-3.9V25.5h8.7c3.9,0,6.2,2.5,6.2,6c0,3.3-2.1,5-4.1,5.5l4.2,7.1h-4.5L151,37.3z M152.2,28.8h-4.1V34h4.1
		c1.6,0,2.8-1,2.8-2.6C155,29.8,153.8,28.8,152.2,28.8z"
      />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M161.6,26.5c0-1.2,1-2.1,2.1-2.1c1.2,0,2.1,0.9,2.1,2.1s-0.9,2.1-2.1,2.1C162.5,28.6,161.6,27.7,161.6,26.5z M161.9,30.6
		h3.5V44h-3.5V30.6z"
      />
      <path fill={primaryText} stroke={primaryText} d="M167,30.6h3.8l3.5,9.3l3.5-9.3h3.8L176.2,44h-3.8L167,30.6z" />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M189.3,30.2c4,0,6.7,3,6.7,7.4v0.8h-10c0.2,1.7,1.6,3.1,3.9,3.1c1.2,0,2.7-0.5,3.6-1.3l1.6,2.3c-1.3,1.2-3.4,1.9-5.5,1.9
		c-4.1,0-7.2-2.8-7.2-7.1C182.4,33.4,185.2,30.2,189.3,30.2z M186,36h6.6c-0.1-1.3-1-3-3.3-3C187.1,33,186.2,34.7,186,36z"
      />
      <path fill={primaryText} stroke={primaryText} d="M198.6,30.6h3.5v1.8c0.9-1.2,2.6-2.1,4.3-2.1v3.4c-0.2-0.1-0.6-0.1-1-0.1c-1.2,0-2.7,0.7-3.3,1.5V44h-3.5V30.6z" />
      <path fill={primaryText} stroke={primaryText} d="M215.8,25.5h13.1v3.4h-9.2v4h9v3.4h-9v4.3h9.2V44h-13.1V25.5z" />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M241,35.9c0-1.9-1-2.5-2.5-2.5c-1.4,0-2.5,0.8-3.1,1.6v9h-3.5V30.6h3.5v1.8c0.9-1,2.5-2.1,4.7-2.1c2.9,0,4.4,1.7,4.4,4.3
		V44H241V35.9z"
      />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M254.1,30.2c4,0,6.7,3,6.7,7.4v0.8h-10c0.2,1.7,1.6,3.1,3.9,3.1c1.2,0,2.7-0.5,3.6-1.3l1.6,2.3c-1.3,1.2-3.4,1.9-5.5,1.9
		c-4.1,0-7.2-2.8-7.2-7.1C247.1,33.4,250,30.2,254.1,30.2z M250.8,36h6.6c-0.1-1.3-1-3-3.3-3C251.9,33,250.9,34.7,250.8,36z"
      />
      <path fill={primaryText} stroke={primaryText} d="M263.4,30.6h3.5v1.8c0.9-1.2,2.6-2.1,4.3-2.1v3.4c-0.2-0.1-0.6-0.1-1-0.1c-1.2,0-2.7,0.7-3.3,1.5V44h-3.5V30.6z" />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M274.7,44.9c1.1,1.2,2.5,1.7,4.2,1.7c1.7,0,3.7-0.8,3.7-3.4v-1.4c-1.1,1.3-2.5,2.1-4.1,2.1c-3.4,0-5.9-2.4-5.9-6.8
		c0-4.4,2.5-6.8,5.9-6.8c1.6,0,3.1,0.7,4.1,2.1v-1.7h3.6v12.5c0,5.1-3.9,6.4-7.3,6.4c-2.3,0-4.1-0.5-5.8-2L274.7,44.9z M282.6,34.9
		c-0.6-0.9-1.9-1.5-3-1.5c-2,0-3.4,1.4-3.4,3.7c0,2.3,1.4,3.7,3.4,3.7c1.2,0,2.4-0.7,3-1.5V34.9z"
      />
      <path
        fill={primaryText}
        stroke={primaryText}
        d="M290.8,46.3c0.9,0,1.5-0.2,1.9-1l0.5-1.1l-5.4-13.6h3.8l3.5,9.3l3.5-9.3h3.8L296,46.2c-1,2.5-2.8,3.2-5.1,3.3
		c-0.4,0-1.3-0.1-1.8-0.2l0.5-3.2C290,46.2,290.4,46.3,290.8,46.3z"
      />
    </g>

    <path
      fill={altText}
      stroke={altText}
      d="M73.9,51.7c-2.5,0-4.2,1.9-4.2,4.2c0,2.6,1.9,4.2,4.3,4.2c1.3,0,2.5-0.4,3.3-1.1l-0.9-1.4c-0.5,0.5-1.4,0.8-2.2,0.8
	c-1.4,0-2.2-0.9-2.3-1.9h6v-0.5C78,53.5,76.3,51.7,73.9,51.7z M71.9,55.1c0.1-0.8,0.7-1.8,2-1.8c1.4,0,1.9,1,2,1.8H71.9z M91.8,54.1
	v5.8h-2.1v-5.1c0-0.8-0.3-1.3-1.2-1.3c-0.8,0-1.4,0.5-1.7,0.9v5.4h-2.1v-5.1c0-0.8-0.3-1.3-1.2-1.3c-0.7,0-1.4,0.5-1.7,1v5.4h-2.1
	v-8.1h2.1v1.1c0.3-0.5,1.4-1.3,2.6-1.3c1.2,0,1.9,0.6,2.2,1.5c0.5-0.7,1.5-1.5,2.8-1.5C90.9,51.7,91.8,52.5,91.8,54.1z M98.7,51.7
	c-1,0-1.9,0.4-2.5,1.2v-1h-2.1V63h2.1v-4.1c0.7,0.8,1.5,1.2,2.5,1.2c2.1,0,3.6-1.6,3.6-4.2C102.2,53.2,100.7,51.7,98.7,51.7z
	 M98,58.3c-0.7,0-1.5-0.4-1.8-0.9v-2.9c0.4-0.5,1.1-0.9,1.8-0.9c1.2,0,2,1,2,2.3C100,57.3,99.2,58.3,98,58.3z M107.7,51.7
	c-2.7,0-4.3,1.9-4.3,4.2c0,2.3,1.6,4.2,4.3,4.2c2.7,0,4.3-1.9,4.3-4.2C112,53.6,110.4,51.7,107.7,51.7z M107.7,58.3
	c-1.3,0-2.1-1.1-2.1-2.4c0-1.3,0.7-2.3,2.1-2.3c1.3,0,2.1,1.1,2.1,2.3C109.8,57.2,109.1,58.3,107.7,58.3z M123.3,51.9h2.2l-2.5,8.1
	h-2.3l-1.7-5.5l-1.7,5.5h-2.3l-2.5-8.1h2.2l1.5,5.4l1.8-5.4h1.9l1.8,5.4L123.3,51.9z M130.4,51.7c-2.5,0-4.2,1.9-4.2,4.2
	c0,2.6,1.9,4.2,4.3,4.2c1.3,0,2.5-0.4,3.3-1.1l-0.9-1.4c-0.5,0.5-1.4,0.8-2.2,0.8c-1.4,0-2.2-0.9-2.3-1.9h6v-0.5
	C134.5,53.5,132.8,51.7,130.4,51.7z M128.4,55.1c0.1-0.8,0.7-1.8,2-1.8c1.4,0,1.9,1,2,1.8H128.4z M140.9,51.7v2.1
	c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.6,0.4-2,0.9v5.3h-2.1v-8.1h2.1V53C138.9,52.3,139.9,51.7,140.9,51.7z M142.4,51.9h2.1v8.1h-2.1
	V51.9z M144.7,49.4c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3C144.1,48.1,144.7,48.7,144.7,49.4z
	 M154.3,54.2v5.7h-2.1v-4.9c0-1.1-0.6-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.8,1v5.4h-2.1v-8.1h2.1v1.1c0.5-0.6,1.5-1.3,2.8-1.3
	C153.5,51.7,154.3,52.7,154.3,54.2z M162.1,52.9c-0.7-0.8-1.5-1.2-2.5-1.2c-2,0-3.6,1.5-3.6,4.1c0,2.7,1.5,4.1,3.6,4.1
	c1,0,1.9-0.5,2.5-1.3v0.8c0,1.6-1.2,2-2.2,2s-1.9-0.3-2.5-1l-1,1.5c1,0.9,2.1,1.2,3.5,1.2c2,0,4.4-0.8,4.4-3.8v-7.5h-2.1V52.9z
	 M162.1,57.1c-0.4,0.5-1.1,0.9-1.8,0.9c-1.2,0-2-0.8-2-2.2s0.8-2.2,2-2.2c0.7,0,1.5,0.4,1.8,0.9V57.1z M176.3,51.9h2.3l-3.8,9.4
	c-0.6,1.5-1.7,1.9-3.1,2c-0.2,0-0.8,0-1.1-0.1l0.3-1.9c0.2,0.1,0.5,0.1,0.7,0.1c0.6,0,0.9-0.2,1.1-0.6l0.3-0.7l-3.3-8.2h2.3l2.1,5.6
	L176.3,51.9z M183.5,51.7c-2.7,0-4.3,1.9-4.3,4.2c0,2.3,1.6,4.2,4.3,4.2c2.7,0,4.3-1.9,4.3-4.2C187.7,53.6,186.1,51.7,183.5,51.7z
	 M183.5,58.3c-1.3,0-2.1-1.1-2.1-2.4c0-1.3,0.7-2.3,2.1-2.3c1.3,0,2.1,1.1,2.1,2.3C185.5,57.2,184.8,58.3,183.5,58.3z M194.9,51.9
	h2.1v8.1h-2.1v-1c-0.6,0.6-1.5,1.2-2.8,1.2c-1.8,0-2.6-1-2.6-2.5v-5.7h2.1v4.9c0,1.1,0.6,1.5,1.5,1.5c0.8,0,1.5-0.5,1.8-0.9V51.9z
	 M204,51.7v2.1c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.6,0.4-2,0.9v5.3h-2.1v-8.1h2.1V53C202,52.3,203,51.7,204,51.7z M214.5,51.7
	c-1,0-1.9,0.4-2.5,1.2v-1h-2.1V63h2.1v-4.1c0.7,0.8,1.5,1.2,2.5,1.2c2.1,0,3.6-1.6,3.6-4.2C218.1,53.2,216.6,51.7,214.5,51.7z
	 M213.9,58.3c-0.7,0-1.5-0.4-1.8-0.9v-2.9c0.4-0.5,1.1-0.9,1.8-0.9c1.2,0,2,1,2,2.3C215.9,57.3,215.1,58.3,213.9,58.3z M223.3,51.7
	c-1.3,0-2.5,0.4-3.5,1.3l0.8,1.4c0.7-0.6,1.5-1,2.4-1c1.1,0,1.8,0.5,1.8,1.4v1.1c-0.5-0.7-1.5-1-2.6-1c-1.3,0-2.8,0.7-2.8,2.6
	c0,1.8,1.5,2.7,2.8,2.7c1.1,0,2-0.4,2.6-1v0.8h2.1v-5.2C226.8,52.4,225.2,51.7,223.3,51.7z M224.7,58c-0.4,0.5-1,0.7-1.7,0.7
	c-0.8,0-1.5-0.4-1.5-1.2c0-0.8,0.7-1.2,1.5-1.2c0.7,0,1.4,0.2,1.7,0.7V58z M235.5,57.6c0,1.5-1.3,2.6-3.5,2.6
	c-1.4,0-2.8-0.5-3.6-1.3l0.9-1.5c0.6,0.6,1.8,1.1,2.8,1.1c0.9,0,1.4-0.3,1.4-0.8c0-0.6-0.7-0.8-1.6-1c-1.4-0.3-3.2-0.6-3.2-2.5
	c0-1.4,1.2-2.5,3.3-2.5c1.4,0,2.5,0.5,3.3,1.1l-0.9,1.5c-0.5-0.5-1.4-0.9-2.4-0.9c-0.8,0-1.3,0.3-1.3,0.8c0,0.5,0.6,0.7,1.5,0.9
	C233.7,55.2,235.5,55.5,235.5,57.6z M243.6,57.6c0,1.5-1.3,2.6-3.5,2.6c-1.4,0-2.8-0.5-3.6-1.3l0.9-1.5c0.6,0.6,1.8,1.1,2.8,1.1
	c0.9,0,1.4-0.3,1.4-0.8c0-0.6-0.7-0.8-1.6-1c-1.4-0.3-3.2-0.6-3.2-2.5c0-1.4,1.2-2.5,3.3-2.5c1.4,0,2.5,0.5,3.3,1.1l-0.9,1.5
	c-0.5-0.5-1.4-0.9-2.4-0.9c-0.8,0-1.3,0.3-1.3,0.8c0,0.5,0.6,0.7,1.5,0.9C241.8,55.2,243.6,55.5,243.6,57.6z M247.7,49.4
	c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3C247.1,48.1,247.7,48.7,247.7,49.4z M245.4,51.9h2.1v8.1h-2.1
	V51.9z M253.5,51.7c-2.7,0-4.3,1.9-4.3,4.2c0,2.3,1.6,4.2,4.3,4.2c2.7,0,4.3-1.9,4.3-4.2C257.8,53.6,256.2,51.7,253.5,51.7z
	 M253.5,58.3c-1.3,0-2.1-1.1-2.1-2.4c0-1.3,0.7-2.3,2.1-2.3c1.3,0,2.1,1.1,2.1,2.3C255.6,57.2,254.8,58.3,253.5,58.3z M267.1,54.2
	v5.7H265v-4.9c0-1.1-0.6-1.5-1.5-1.5c-0.8,0-1.5,0.5-1.8,1v5.4h-2.1v-8.1h2.1v1.1c0.5-0.6,1.5-1.3,2.8-1.3
	C266.3,51.7,267.1,52.7,267.1,54.2z"
    />
    <g>
      <path
        fill={fillIcon}
        stroke={fillIcon}
        d="M44,21.4h-7.4c-2.5,15.2-1.1,29.5,1.4,41.5c-3-5.1-6.7-23.8-6.7-38.5c0-1.1,0-2.1,0-3c0.5-9,4.5-14.3,6.7-16.3
		c-6.7,2.6-9.5,8.8-10.4,16.3c-1.3,11.5,2.2,26,3.7,34.5c0,0-5.7-6.9-7.7-33.6l-0.5-0.9c-5.3,0.1-9.6,4.5-9.6,9.9v23.9
		c0,5.5,4.4,9.9,9.9,9.9h20.4c1.3-2.7,2.2-5.4,2.7-8.2C48.2,48.6,46,32.6,44,21.4z"
      />
      <path fill={fillIcon} stroke={fillIcon} d="M47.9,21.4h-1.5c5.5,12.9,3.9,42.7,3.9,42.7L49.3,65c4.8-0.7,8.5-4.8,8.5-9.8V31.3C57.8,25.8,53.4,21.4,47.9,21.4z" />
    </g>
  </svg>

);

export default WoodRiverEnergySvg;

WoodRiverEnergySvg.propTypes = {
  primaryText: PropTypes.string,
  altText: PropTypes.string,
  fillIcon: PropTypes.string,
  classes: PropTypes.string,
};

WoodRiverEnergySvg.defaultProps = {
  // /** color to fill svg */
  primaryText: '#fff',
  // /** color to fill svg */
  altText: '#fff',
  // /** color to fill svg */
  fillIcon: '#fff',
  // /** class to pass to svg */
  classes: '',
};
