import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const PathsTable = ({
  paths,
  title,
}) => (
  <ExpansionPanel>
    <ExpansionPanelSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography variant="h6" id="tableTitle">
        {`${title} total volume: ${paths.reduce((accumulator, currentValue) => accumulator + currentValue.dth, 0)}`}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Paper style={{ width: '100%' }}>

        <Table size="small" aria-label="">
          <TableHead>
            <TableRow>
              <TableCell align="center">gasDay</TableCell>
              <TableCell align="center">dth</TableCell>
              <TableCell align="center">receiptLocation name</TableCell>
              <TableCell align="center">deliveryLocation name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paths.map(path => (
              <TableRow key={path.id}>
                <TableCell align="center">
                  {path.gasDay}
                </TableCell>
                <TableCell align="center">
                  {path.dth}
                </TableCell>
                <TableCell align="center">
                  {path.receiptLocation.name}
                </TableCell>
                <TableCell align="center">
                  {path.deliveryLocation.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);

export default PathsTable;

PathsTable.propTypes = {
  title: PropTypes.string.isRequired,
  paths: PropTypes.arrayOf(PropTypes.shape({
  })),
};

PathsTable.defaultProps = {
  paths: [],
};
