import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import IconButton from '@material-ui/core/IconButton';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import PropTypes from 'prop-types';
import Clear from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import notifyBannerAction from '../../actions/NotifyBannerAction';
import styles from './NotifyBanner.module.scss';
import Themes from '../../theme';

let timeout;

export class NotifyBanner extends Component {
  theme = process.env.REACT_APP_COMPANY.includes('connectenergy') ? Themes.connectEnergy() : Themes.woodRiver();

  async componentDidUpdate() {
    if (this.props.showBanner) {
      await this.dirtyThirty();
    }
  }

  // setTimeout for 30 seconds to remove banner
  dirtyThirty = async () => {
    if (this.props.showBanner) {
      timeout = await setTimeout(() => {
        this.props.notifyBannerAction({
          message: '',
          showBanner: false,
          error: false,
        });
      }, 30000);
    }
  };

  removeBanner = async () => {
    await clearTimeout(timeout);
    await this.props.notifyBannerAction({
      message: '',
      showBanner: false,
      error: false,
    });
  };

  render() {
    return (
      <>
        { this.props.showBanner && (
        <AppBar
          position="static"
          className={styles.banner}
          style={this.props.error ? this.theme.palette.error : this.theme.palette.secondary}
        >
          <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <IconButton edge="start" color="inherit" aria-label="menu">
                <PriorityHigh />
              </IconButton>
              <Grid item xs={10}>
                <Typography color="inherit" variant="h6">
                  {this.props.message}
                </Typography>
              </Grid>
              <Button
                onClick={this.removeBanner}
                color="inherit"
              >
                <Clear />
              </Button>
            </Grid>
          </Toolbar>
        </AppBar>
        )}
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    showBanner: state.notifyBanner.showBanner,
    message: state.notifyBanner.message,
    error: state.notifyBanner.error,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    notifyBannerAction,
  }, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(NotifyBanner);

NotifyBanner.propTypes = {
  notifyBannerAction: PropTypes.func.isRequired,
  /** showBanner to show banner */
  showBanner: PropTypes.bool,
  /** message to display */
  message: PropTypes.string,
  /** if true banner will show in red */
  error: PropTypes.bool,
};

NotifyBanner.defaultProps = {
  showBanner: false,
  error: false,
  message: '',
};
