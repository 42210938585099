import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
} from '@material-ui/core';
import LockPathsByPipeline from './components/LockPathsByPipeline/LockPathsByPipeline';
import RefreshPathView from './components/RefreshPathView/RefreshPathView';

export const centerTitle = (location = {}) => {
  if (location.pipelineName && location.name) {
    return `Pipeline: ${location.pipelineName} Location: ${location.name}`;
  }
  return 'Pick a Location';
};

export const CenterOptions = ({
  location,
  lockPaths,
  locked,
  disabled,
  dispatch,
  pathViewCallBack,
}) => (
  <Grid
    container
    direction="row"
    justify="center"
    alignItems="center"
    spacing={2}
  >
    <Grid>
      <LockPathsByPipeline
        dispatch={dispatch}
        location={location}
        lockPaths={lockPaths}
        locked={locked}
        disabled={disabled}
      />
    </Grid>
    {location.pipelineName && (
      <Grid>
        <RefreshPathView pathViewCallBack={pathViewCallBack} />
      </Grid>
    )}
    <Grid>
      <Typography variant="subtitle1" gutterBottom>
        {centerTitle(location)}
      </Typography>
    </Grid>
  </Grid>
);

export default CenterOptions;

CenterOptions.propTypes = {
  pathViewCallBack: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pipelineName: PropTypes.string,
  }).isRequired,
  lockPaths: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CenterOptions.defaultProps = {};
