import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaterialSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * Switch for redux forms input field
 *
 * console shows onSubmit values
 */


export class Switch extends Component {
  render() {
    const checked = this.props.input.value || false;
    return (
      <FormControlLabel
        control={(
          <MaterialSwitch
            {...this.props.custom}
            {...this.props.input}
            onClick={this.handleChange}
            checked={checked}
            name={this.props.name}
            color={this.props.color}
          />
        )}
        label={`${this.props.label || this.props.name} ${this.props.showChecked ? checked : ''}`}
      />
    );
  }
}


export default Switch;

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }),
  custom: PropTypes.shape({}),
  color: PropTypes.string,
  showChecked: PropTypes.bool,
};

Switch.defaultProps = {
  input: {},
  custom: {},
  label: '',
  color: 'primary',
  showChecked: true,
};
