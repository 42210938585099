import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchResultsTable from './components/SearchResultsTable/SearchResultsTable';
import SgCustomerVolumeDialog from './components/SgCustomerVolumeDialog/SgCustomerVolumeDialog';
import ChoiceAccountNotes from './components/ChoiceAccountNotes/ChoiceAccountNotes';

export class SgCustomerResults extends Component {
  state = {
    searchResults: [],
    openVolume: false,
    openNotes: false,
    sgCustomer: {},
  };

  componentDidUpdate(prevProps) {
    if (this.props.results !== prevProps.results) {
      this.search();
    }
  }

  setSgCustomer = (sgCustomer = {}) => {
    this.setState(oldState => ({
      sgCustomer,
      openVolume: !oldState.openVolume,
    }));
  };

  toggleDialog = accountNum => this.setState(oldState => ({
    accountNum,
    openNotes: !oldState.openNotes,
  }));

  search = (search) => {
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['lastName', 'mailingAddress', 'mailingCityState', 'accountNum'],
    };
    const fuse = new Fuse(this.props.results, options);
    const searchResults = search ? fuse.search(search) : this.props.results;
    this.setState({searchResults});
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <TextField
          onChange={e => this.search(e.target.value)}
          label="Search by Last name, Mailing Address or mailingCityState"
          margin="normal"
          variant="outlined"
          style={{width: '100%'}}
        />

        <SearchResultsTable
          searchResults={this.state.searchResults}
          setSgCustomer={this.setSgCustomer}
          toggleDialog={this.toggleDialog}
        />

        <SgCustomerVolumeDialog
          sgCustomer={this.state.sgCustomer}
          open={this.state.openVolume}
          toggleDialog={this.setSgCustomer}
        />

        <ChoiceAccountNotes
          open={this.state.openNotes}
          accountNum={this.state.accountNum}
          toggleDialog={this.toggleDialog}
        />
      </Grid>
    );
  }
}

export default SgCustomerResults;

SgCustomerResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})),
};

SgCustomerResults.defaultProps = {
  results: [],
};
