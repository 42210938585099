import {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Radio from 'sharedComponents/FinalForm/Radio/Radio';
import {Form, Field} from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import styles from './ChoiceForm.module.scss';

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['searchKey', 'searchValue'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (values.searchValue) {
    if (values.searchKey === 'accountNums' || values.searchKey === 'premiseNums') {
      if (!values.searchValue.match(/[,\s]+/)) {
        errors.searchValue = 'Needs to be a number separated by commas or spaces';
      }
    }
    if (values.searchKey === 'accountNum' || values.searchKey === 'telephone') {
      if (!values.searchValue.match(/^\d+$/)) {
        errors.searchValue = 'only number no spaces or commas';
      }
    }
  }

  return errors;
};

export class ChoiceForm extends Component {
  state = {error: null};

  searchByQuoteId = async (searchValue, searchKey) => {
    this.props.clearError();
    this.setState({error: null});
    await this.props.loadQuote(searchValue, searchKey);
  }

  onSubmit = async (values) => {
    this.props.clearError();
    this.props.clearQuote();
    const {searchKey, searchValue} = values;
    switch (searchKey) {
      case 'id':
        await this.searchByQuoteId(searchValue, searchKey);
        break;
      default: {
        await this.props.loadLocations(searchValue, searchKey);
        break;
      }
    }
  };

  hideSubmit = () => {
    return false;
  }

  render() {
    return (
      <>
        <Form
          onSubmit={this.onSubmit}
          validate={validate}
          classes={styles.form}
          initialValues={{searchKey: 'accountNum'}}
        >
          {({handleSubmit, submitting}) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="accountNum"
                      label="Account Number"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="premiseNumber"
                      label="Service Point ID (premise number)"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="id"
                      label="Quote Id"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="accountNums"
                      label="Account Numbers (comma or space separated)"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="premiseNums"
                      label="Service Point IDs (comma or space separated)"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="telephone"
                      label="Telephone (no spaces)"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                  <div className={styles.alignLeft}>
                    <Field
                      name="searchKey"
                      type="radio"
                      value="serviceAddress"
                      label="Service Address"
                    >
                      {field => <Radio field={field} />}
                    </Field>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Field
                    name="searchValue"
                    label="search"
                  >
                    {field => (
                      <Input
                        width="95%"
                        field={field}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <div>
                <Submit submitting={submitting} hide={this.hideSubmit()}>
                  'Search Locations'
                </Submit>
              </div>
            </form>
          )}
        </Form>
        {this.state.error && (<span className={styles.error}>{this.state.error}</span>)}
      </>
    );
  }
}

export default ChoiceForm;

ChoiceForm.propTypes = {
  // whereBillClasses: PropTypes.func.isRequired,
  loadQuote: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    ChoiceForm: PropTypes.shape({
      values: PropTypes.shape({
        searchKey: PropTypes.string,
      }),
    }),
    Agr: PropTypes.shape({
      submitSucceeded: PropTypes.bool,
    }),
    ResCom: PropTypes.shape({
      submitSucceeded: PropTypes.bool,
    }),
  }).isRequired,
  // setSearchResults: PropTypes.func.isRequired,
  clearQuote: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

ChoiceForm.defaultProps = {
  quote: {},
};
