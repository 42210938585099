import React, {forwardRef, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DialogContent from '@material-ui/core/DialogContent';
import axios from 'axios';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import ControlNumbersDialog from './components/ControlNumbersDialog/ControlNumbersDialog';
import EnrolledCompleteDialog from './components/EnrolledCompleteDialog/EnrolledCompleteDialog';
import OnlineDelegationAgreement from './components/OnlineDelegationAgreement/OnlineDelegationAgreement';
import SendDelegationAgreement from './components/SendDelegationAgreement/SendDelegationAgreement';

import styles from './Enroll.module.scss';

const Transition = forwardRef((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const pickParamType = (quote) => {
  const wy = quote.quoteData.accountList[0].serviceState.includes('WY');
  const agrBillClass = quote.quoteData.accountList[0].billClass.includes('AGR');

  if (!wy && !agrBillClass) return 'open_enrollment_NE_res_com_ind';
  if (!wy && agrBillClass) return 'open_enrollment_NE_agr';
  return 'open_enrollment_WY';
};

export const Enroll = ({
  toggleDialog,
  open,
  toggleEnrolledCompleteDialog,
  enrolledCompleteDialog,
  quote,
  formState,
  setQuote,
  complete,
  resetForm,
}) => {
  const [state, setState] = useState({loading: true, openEnrollment: undefined});

  const choiceParamOpenEnrollment = async () => {
    if (!state.openEnrollment) {
      const paramType = pickParamType(quote);
      const queryString = {
        query: `query {
          choiceParam(
            region: "ALL"
            billClass: "ALL"
            paramType: "${paramType}"
          ) {
            effectiveDate
          }
        }`,
      };

      const data = await axios.post('/graphql/', queryString);
      return setState({
        openEnrollment: data.data.data.choiceParam.effectiveDate,
        loading: false,
      });
    }
    return null;
  };

  useEffect(() => {
    (async () => {
      await choiceParamOpenEnrollment();
    })();
  });

  const isOpen = () => Date.parse(state.openEnrollment) <= Date.parse(new Date().toISOString().split('T')[0]);

  const canSeeDelegation = () => {
    return true;
  };

  return (
    <>
      <Dialog
        fullScreen={true}
        fullWidth={true}
        open={open}
        onClose={toggleDialog}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <AppBar color="primary">
          <Toolbar>
            {(!complete) && (
              <IconButton color="inherit" onClick={toggleDialog} aria-label="Close">
                <CloseIcon />
              </IconButton>
            )}
            {complete && (
              <IconButton color="inherit" onClick={toggleDialog} aria-label="Close">
                <CloseIcon />
              </IconButton>
            )}
            <Typography variant="h6" color="inherit">
              WoodRiver Energy
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={styles.container}>
          <AppLoader loading={state.loading} />
          <SendDelegationAgreement quote={quote} />
          {canSeeDelegation() && (
            <OnlineDelegationAgreement
              complete={complete}
              quote={quote}
            />
          )}
          {!state.loading && isOpen() && (
            <ControlNumbersDialog
              quote={quote}
              formState={formState}
              setQuote={setQuote}
              complete={complete}
              resetForm={resetForm}
            />
          )}
        </DialogContent>
      </Dialog>
      <EnrolledCompleteDialog
        quote={quote}
        open={enrolledCompleteDialog}
        toggleDialog={toggleEnrolledCompleteDialog}
      />
    </>
  );
};

export default Enroll;

Enroll.propTypes = {
  resetForm: PropTypes.func.isRequired,
  toggleEnrolledCompleteDialog: PropTypes.func.isRequired,
  enrolledCompleteDialog: PropTypes.bool.isRequired,
  complete: PropTypes.bool,
  open: PropTypes.bool,
  toggleDialog: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    quoteData: PropTypes.shape({
      accountList: PropTypes.arrayOf(PropTypes.shape({
        serviceState: PropTypes.string,
        billClass: PropTypes.string,
      })),
    }),
  }),
  formState: PropTypes.shape({}).isRequired,
  setQuote: PropTypes.func.isRequired,
};

Enroll.defaultProps = {
  complete: false,
  open: false,
  quote: {},
};
