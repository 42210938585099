import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateLocation from './components/CreateLocation/CreateLocation';
import CreatePaths from './components/CreatePaths/CreatePaths';

export const PathParts = ({
  pipelineId,
  pathViewCallBack,
  receiptLocationId,
  receiptLocation,
  formState,
  dispatch,
  pipelineName,
  startDate,
}) => (
  <>
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{`Create Location on pipeline: ${pipelineName}`}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <CreateLocation
          pipelineId={pipelineId}
          formState={formState}
          dispatch={dispatch}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{`Create Path with receipt Location: ${receiptLocation.name} on Pipeline: ${pipelineName}`}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <CreatePaths
          formState={formState}
          startDate={startDate}
          dispatch={dispatch}
          pathViewCallBack={pathViewCallBack}
          pipelineId={pipelineId}
          receiptLocationId={receiptLocationId}
          receiptLocation={receiptLocation}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </>
);

export default PathParts;

PathParts.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  pipelineId: PropTypes.number,
  pipelineName: PropTypes.string,
  receiptLocationId: PropTypes.number,
  receiptLocation: PropTypes.shape().isRequired,
  pathViewCallBack: PropTypes.func.isRequired,
  formState: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

PathParts.defaultProps = {
  pipelineId: null,
  receiptLocationId: null,
  pipelineName: '',
};
