import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import { Form, Field } from 'react-final-form';
import validateEmail from 'utils/validateEmail';
import styles from './EmailQuote.module.scss';

export class EmailQuote extends Component {
  state = {
    error: '',
    disableSubmit: true,
    emailResponse: '',
  };

  onSubmit = async (values) => {
    await this.props.updateQuote(this.props.quote);
    const quote = Object.assign({ email: values.email }, this.props.quote);
    const url = '/sg_customers/email_quote';
    const emailResponse = await axios.post(url, {
      sg_customer: {
        id: quote.id,
        email: quote.email,
        quote_pub_index: quote.pubIndex,
        form_type: this.props.formType,
      },
    });
    this.setState({
      emailResponse: emailResponse.data.message,
    });
  };

  validate = (value) => {
    // only update state when email is going from true -> false or false -> true
    if (this.state.disableSubmit && validateEmail(value)) {
      this.setState({ disableSubmit: false });
    } else if (!this.state.disableSubmit && !validateEmail(value)) {
      this.setState({ disableSubmit: true });
    }
  };

  render() {
    return (
      <Card className={styles.card}>
        <p className={styles.emailQuoteDescription}>
          Save your work! Email yourself a link to this quote.
        </p>
        {this.state.emailResponse && (
          <section className={styles.emailSent}>
            {this.state.emailResponse}
          </section>
        )}
        <Form
          onSubmit={this.onSubmit}
          initialValues={{ email: this.props.quote.quoteData.currentEmail }}
        >
          {({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Field
                  name="email"
                  label="email"
                  type="text"
                  validate={this.validate}
                >
                  {field => (
                    <Input
                      field={field}
                      classes={styles}
                    />
                  )}
                </Field>
              </div>
              <div>
                <Submit
                  submitting={submitting}
                  disabled={this.state.disableSubmit}
                >
                  Email Quote
                </Submit>
              </div>
            </form>
          )}
        </Form>
        {this.state.error && (<span className={styles.error}>{this.state.error}</span>)}
      </Card>
    );
  }
}

export default EmailQuote;

EmailQuote.propTypes = {
  formType: PropTypes.string.isRequired,
  updateQuote: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    quoteData: PropTypes.shape({
      currentEmail: PropTypes.string,
    }),
  }).isRequired,
};
