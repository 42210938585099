import React from 'react';
import PropTypes from 'prop-types';
import TreeGrid from 'sharedComponents/TreeGrid/TreeGrid';

const columns = [{
  key: 'expand',
  name: 'expand',
  width: 100,
}, {
  name: 'billClass',
  key: 'billClass',
  filterable: true,
}, {
  name: 'firstName',
  key: 'firstName',
}, {
  name: 'lastName',
  key: 'lastName',
}, {
  name: 'premiseNumber',
  key: 'premiseNumber',
}, {
  name: 'accountNum',
  key: 'accountNum',
  filterable: true,
}, {
  name: 'serviceAddress',
  key: 'serviceAddress',
}, {
  name: 'serviceCity',
  key: 'serviceCity',
}, {
  name: 'serviceState',
  key: 'serviceState',
}, {
  name: 'serviceZip',
  key: 'serviceZip',
}, {
  name: 'totalUsage',
  key: 'totalUsage',
}];

export const groupCallBack = (row) => {
  if (!row.codePrices) {
    return [{
      expand: 'N/A',
    }];
  }

  const subRow = row.codePrices.map(x => ({
    billClass: x.code,
    lastName: x.term,
    premiseNumber: x.price,
    accountNum: x.program,
  }));

  subRow.unshift({
    billClass: 'code',
    lastName: 'term',
    premiseNumber: 'price',
    accountNum: 'program',
  });

  return subRow;
};

const SgCustomerGrid = ({ loading, sgCustomers }) => (
  <>
    {!loading && (
      <TreeGrid
        field="expand"
        group={groupCallBack}
        rows={sgCustomers}
        columns={columns}
      />
    )}
  </>
);

export default SgCustomerGrid;

SgCustomerGrid.propTypes = {
  loading: PropTypes.bool.isRequired,
  sgCustomers: PropTypes.arrayOf(PropTypes.shape()),
};

SgCustomerGrid.defaultProps = {
  sgCustomers: [],
};
