import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import SelectField from 'sharedComponents/ReduxForms/SelectField/SelectField';
import Switch from 'sharedComponents/ReduxForms/Switch/Switch';
import styles from './NewEnrollmentWisconsin.module.scss';

export class NewEnrollmentWisconsin extends Component {
  static validate = (values) => {
    const errors = {};
    const requiredFields = [
      'terms',
      'rateSchedules',
      'utilities',
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  render() {
    const { terms, rateSchedules, utilities } = this.props;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Field
            name="terms"
            component={SelectField}
            options={terms}
            classes={styles}
            displayKey="description"
            selectLabel="terms"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Field
            name="rateSchedules"
            component={SelectField}
            options={rateSchedules}
            classes={styles}
            displayKey="description"
            selectLabel="rateSchedules"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Field
            name="utilities"
            component={SelectField}
            options={utilities}
            classes={styles}
            displayKey="name"
            selectLabel="utilities"
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Field
            component={Switch}
            name="rateScheduleFirstTime"
            label="Is this your first time changing to a new rate?"
          />
        </Grid>
      </Grid>
    );
  }
}


export default NewEnrollmentWisconsin;

NewEnrollmentWisconsin.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.shape({
    term: PropTypes.string,
    price: PropTypes.string,
  })),
  rateSchedules: PropTypes.arrayOf(PropTypes.shape({
    rate: PropTypes.string,
    wre: PropTypes.string,
    description: PropTypes.string,
  })),
  utilities: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
};

NewEnrollmentWisconsin.defaultProps = {
  terms: [{
    term: 'N/A',
    price: 'N/A',
    description: 'unavailable at this time',
  }],
  rateSchedules: [
    { rate: 'FG2', wre: 'TF2', description: 'FG2 4,000 to 40,000 Annual Therms used' },
    { rate: 'FG3', wre: 'TF3', description: 'FG3 40,000 to 100,000 Annual Therms used' },
    { rate: 'FG4', wre: 'TF4', description: 'FG4 100,000 to 500,000 Annual Therms used' },
    { rate: 'FG5', wre: 'TF5', description: 'FG5 500,00 to 1,000,000 Annual Therms used' },
    { rate: 'FG6', wre: 'TF6', description: 'FG6 1,000,000 to 8,000,000 Annual Therms used' },
    { rate: 'FG7', wre: 'TF7', description: 'FG7 8,000,000+ Annual Therms used' },
    { rate: 'TF2', wre: 'TF2', description: 'TF2 4,000 to 40,000 Annual Therms used' },
    { rate: 'TF3', wre: 'TF3', description: 'TF3 40,000 to 100,000 Annual Therms used' },
    { rate: 'TF4', wre: 'TF4', description: 'TF4 100,000 to 500,000 Annual Therms used' },
    { rate: 'TF5', wre: 'TF5', description: 'TF5 500,00 to 1,000,000 Annual Therms used' },
    { rate: 'TF6', wre: 'TF6', description: 'TF6 1,000,000 to 8,000,000 Annual Therms used' },
    { rate: 'TF7', wre: 'TF7', description: 'TF7 8,000,000+ Annual Therms used' },
  ],
  utilities: [
    { name: 'Wisconsin Electric - gas Operations' },
    { name: 'Wisconsin Gas LLC' },
  ],
};
