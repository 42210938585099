import React, { Component } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import SgCustomerSearch from './components/SgCustomerSearch/SgCustomerSearch';

export class ChoiceList extends Component {
  state = {
    currSupplier: [],
    pricingOpt: [],
    billClass: [],
    serviceState: [],
    divisionRegion: [],
    loading: true,
  }

  async componentDidMount() {
    await this.setOptions();
  }

  setOptions = async () => {
    const options = await this.sgCustomersUniqueColumn();
    const {
      currSupplier,
      pricingOpt,
      billClass,
      serviceState,
      divisionRegion,
    } = options;

    this.setState({
      currSupplier,
      pricingOpt,
      billClass,
      serviceState,
      divisionRegion,
      loading: false,
    });
  }

  sgCustomersUniqueColumn = async () => {
    try {
      const query = {
        query: `{
          sgCustomersUniqueColumn(columns: [
            "curr_supplier",
            "pricing_opt",
            "bill_class",
            "service_state",
            "division_region",
          ])
        }`,
      };
      const res = await axios.post('/graphql/', query);
      if (res.data.errors) {
        return res.data.errors;
      }
      return res.data.data.sgCustomersUniqueColumn;
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <>
        <AppLoader loading={this.state.loading} />
        {!this.state.loading && (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <SgCustomerSearch
                setResults={this.setResults}
                currSupplier={this.state.currSupplier}
                pricingOpt={this.state.pricingOpt}
                billClass={this.state.billClass}
                serviceState={this.state.serviceState}
                divisionRegion={this.state.divisionRegion}
              />
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

export default ChoiceList;

ChoiceList.propTypes = {};
