import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import { CreateAdder } from './components/CreateAdder/CreateAdder';
import { DestroyAdder } from './components/DestroyAdder/DestroyAdder';
import { UpdateAdderForm } from './components/UpdateAdderForm/UpdateAdderForm';

// eslint-disable-next-line react/prop-types
export const RowRenderer = ({ renderBaseRow, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const color = props.idx % 2 ? 'black' : 'blue';
  return <div style={{ color }}>{renderBaseRow(props)}</div>;
};

export const setHeight = () => {
  const { body, documentElement } = window.document;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight,
  ) / 1.69 || 600;
};

export class AddersTable extends Component {
  state = {
    filters: {},
    columns: [
      {
        key: 'groupName', name: 'Group', minWidth: 25, filterable: true,
      },
      {
        key: 'nymex', name: 'Nymex', minWidth: 25, filterable: true,
      },
      {
        key: 'index', name: 'Index', minWidth: 25, filterable: true,
      },
      {
        key: 'basis', name: 'Basis', minWidth: 25, filterable: true,
      },
      {
        key: 'season', name: 'Season', minWidth: 25, filterable: true,
      },
      {
        key: 'id',
        name: 'Update',
        minWidth: 25,
        formatter: value => (
          <UpdateAdderForm
            adder={value.row}
            callback={this.props.updateCallback}
            groups={this.props.groups}
            seasons={this.props.seasons}
          />
        ),
      },
      {
        key: 'id',
        name: 'Delete',
        minWidth: 25,
        formatter: value => (
          <DestroyAdder adderId={value.value} callback={this.props.deleteCallback} />
        ),
      },
    ],
  };

    handleFilterChange = (filter) => {
      const { filters } = this.state;
      const newFilters = Object.assign({}, filters);
      if (filter.filterTerm) {
        newFilters[filter.column.key] = filter;
      } else {
        delete newFilters[filter.column.key];
      }
      this.setState({ filters: newFilters });
      return newFilters;
    };

    render() {
      const { columns, filters } = this.state;
      const {
        adders, groups, createCallback, seasons,
      } = this.props;

      const visibleRows = Data.Selectors.getRows({ rows: adders, filters });

      return (
        <div>
          <ReactDataGrid
            columns={columns}
            rowGetter={i => visibleRows[i]}
            rowsCount={visibleRows.length}
            minHeight={setHeight()}
            rowRenderer={RowRenderer}
            toolbar={(
              <Toolbar enableFilter={true} className={{ tools: { display: 'flex' } }}>
                <CreateAdder
                  groups={groups}
                  createCallback={createCallback}
                  seasons={seasons}
                />
              </Toolbar>
            )}
            onAddFilter={filter => this.handleFilterChange(filter)}
            onClearFilters={() => this.setState({ filters: {} })}
          />
        </div>
      );
    }
}

export default AddersTable;

AddersTable.propTypes = {
  adders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  seasons: PropTypes.arrayOf(PropTypes.string).isRequired,
  createCallback: PropTypes.func.isRequired,
  deleteCallback: PropTypes.func.isRequired,
  updateCallback: PropTypes.func.isRequired,
};
