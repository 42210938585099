import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import { loadComponents } from "loadable-components";
import rootReducer from "./reducers";
import App from "./App";
import "./styles/styles.scss";
import "./polyfills";
import { bootstrapAuth } from "auth";

async function main() {
  await bootstrapAuth();

  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk, logger))
  );

  const rootElement = document.getElementById("root");

  await loadComponents();
  render(<App store={store} />, rootElement);
}

void main();
