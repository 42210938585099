import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import compose from 'recompose/compose';
import {bindActionCreators} from 'redux';
import MobileTopAppBar from './components/MobileTopAppBar/MobileTopAppBar';
import MuiToolbar from './components/MuiToolbar/MuiToolbar';

const styles = {
  root: {
    width: '100%',
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  stickToTop: {
    width: '100%',
    position: 'fixed',
    top: 0,
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  bottom: {
    bottom: 0,
    position: 'relative',
  },
};

export class Nav extends Component {
  state = {open: false};

  handleOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div className={this.props.classes.root}>
        <Hidden smDown>
          <AppBar position="static" className={this.props.classes.stickToTop}>
            <MuiToolbar
              classes={this.props.classes}
              handleOpen={this.handleOpen}
              appInfo={this.props.appInfo}
            />
          </AppBar>
        </Hidden>
        <Hidden mdUp>
          <AppBar position="static" className={this.props.classes.stickToTop}>
            <MobileTopAppBar classes={this.props.classes} appInfo={this.props.appInfo} />
          </AppBar>
          <AppBar position="static" className={this.props.classes.stickToBottom}>
            <MuiToolbar
              classes={this.props.classes}
              handleOpen={this.handleOpen}
              appInfo={this.props.appInfo}
            />
          </AppBar>
        </Hidden>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    appInfo: state.appInfo,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(Nav);

Nav.propTypes = {
  appInfo: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({
    button: PropTypes.string,
    root: PropTypes.string,
    menuButton: PropTypes.string,
    grow: PropTypes.string,
    stickToBottom: PropTypes.string,
    alignCenter: PropTypes.string,
    stickToTop: PropTypes.string,
  }).isRequired,
};
