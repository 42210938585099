import React, { Component } from 'react';
import graphqlCall from 'utils/graphqlCall';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import { AddersTable } from './components/AddersTable/AddersTable';
import { CreateGroup } from './components/CreateGroup/CreateGroup';

export class Adder extends Component {
  state = {
    adders: [],
    groups: [],
    seasons: [],
    isLoading: true,
    errors: {
      adderError: '',
      groupError: '',
      seasonError: '',
    },
  }

  componentDidMount = async () => {
    await this.getAdders();
    await this.getGroups();
    await this.getSeasons();
  }

  getAdders = async () => {
    this.setState({ isLoading: true });

    try {
      const queryString = {
        query: `query {
          adders
          {id, index, nymex, basis, groupName, season}
        }`,
      };
      const { adders } = await graphqlCall(queryString);
      return this.setState({
        adders,
        isLoading: false,
        errors: {
          adderError: '',
          groupError: '',
          seasonError: '',
        },
      });
    } catch (err) {
      return this.setState({
        isLoading: false,
        errors: {
          adderError: err.toString(),
          groupError: '',
          seasonError: '',
        },
      });
    }
  }

  getGroups = async () => {
    this.setState({ isLoading: true });

    try {
      const queryString = {
        query: `query {
          groups
          {id, name}
        }`,
      };
      const { groups } = await graphqlCall(queryString);
      return this.setState({
        groups,
        isLoading: false,
        errors: {
          adderError: '',
          groupError: '',
          seasonError: '',
        },
      });
    } catch (err) {
      return this.setState({
        isLoading: false,
        errors: {
          adderError: '',
          groupError: err.toString(),
          seasonError: '',
        },
      });
    }
  }

  getSeasons = async () => {
    this.setState({ isLoading: true });
    try {
      const queryString = {
        query: `query {
          seasons
          {seasons}
        }`,
      };
      const { seasons } = await graphqlCall(queryString);
      const seasonsArr = seasons[0].seasons;
      seasonsArr.unshift('Default');
      return this.setState({
        seasons: seasonsArr,
        isLoading: false,
        errors: {
          adderError: '',
          groupError: '',
          seasonError: '',
        },
      });
    } catch (err) {
      return this.setState({
        isLoading: false,
        errors: {
          adderError: '',
          groupError: '',
          seasonError: err.toString(),
        },
      });
    }
  }

  createCallback = async (newAdder) => {
    const { adders } = this.state;
    const newAdders = adders;
    newAdders.push(newAdder);
    this.setState({ adders: newAdders });
    await this.getAdders;
  }

  filterDeletedAdder = (adderId) => {
    const { adders } = this.state;
    const filteredAdders = adders.filter(adder => adder.id !== adderId);
    this.setState({ adders: filteredAdders });
  }

  updateCallback = (updatedAdder) => {
    const { adders } = this.state;
    const updatedAdders = adders.map((adder) => {
      if (adder.id === updatedAdder.id) {
        return updatedAdder;
      }
      return adder;
    });
    this.setState({ adders: updatedAdders });
  }

  createGroupCallback = async () => {
    await this.getGroups();
  };

  render() {
    const {
      adders, isLoading, errors, groups, seasons,
    } = this.state;

    const { adderError, groupError, seasonError } = errors;

    if (isLoading) return <AppLoader />;

    return (
      <>
        <div
          style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '78px 0 20px 0',
          }}
        >
          <h1 style={{ margin: '0 0 15px 0' }}>Adders</h1>
          <CreateGroup createCallback={this.createGroupCallback} />
        </div>
        {adderError !== '' && (
          <h4 style={{ color: 'red' }}>
            Adder Error:
            {adderError}
          </h4>
        )}
        {groupError !== '' && (
          <h4 style={{ color: 'red' }}>
            Group Error:
            {groupError}
          </h4>
        )}
        {seasonError !== '' && (
          <h4 style={{ color: 'red' }}>
            Season Error:
            {seasonError}
          </h4>
        )}
        <AddersTable
          adders={adders}
          groups={groups}
          seasons={seasons}
          createCallback={this.createCallback}
          deleteCallback={this.filterDeletedAdder}
          updateCallback={this.updateCallback}
        />
      </>
    );
  }
}

export default Adder;
