import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import Radio from 'sharedComponents/FinalForm/Radio/Radio';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import styles from './SearchSelection.module.scss';

export const searchStringValue = (values) => {
  let searchString = `${values.searchKey}: "${values.searchValue}"`;
  if (values.searchKey === 'createdAt') {
    searchString = `startDate: "${values.startDate}", endDate: "${values.endDate}"`;
  }
  if (values.searchKey === 'limit') {
    searchString = `${values.searchKey}: ${values.searchValue}`;
  }
  return searchString;
};

export const search = async (values) => {
  const searchString = searchStringValue(values);

  const queryString = {
    query: `{
      choiceSelection(${searchString}) {
        id,
        promoCodeExported,
        verificationId,
        name,
        createdAt,
        quoteId,
        choicePriceTypeId,
        quotedPrice,
        controlNumber,
        confirmNumber,
        posted,
        sentToBhe,
        emailAddress,
        emailDate,
        serviceState,
        divisionRegion,
        billClass,
        accountNum,
        progYear,
        term,
        transportCostUnit,
        transportCostTotal,
        margin,
        bheConfirmCode,
        totalPrice,
        servicePointId,
        documentUrl,
        delegationAgreementUrl,
        choicePromoCodeId,
        greenPlus,
        serviceAddress,
        priceType,
        choicePromoCodeId,
        choicePriceType { name },
      }
    }`,
  };
  const data = await axios.post('/graphql/', queryString);
  return data.data.data.choiceSelection;
};

export const onSubmit = async (values, callBack) => {
  const data = await search(values);
  callBack(data);
};

export const required = value => (value ? undefined : 'Required');

export const onMount = async (setState, callBack) => {
  setState({ loading: true });
  const data = await search({ searchValue: 50, searchKey: 'limit' });
  callBack(data);
  setState({ loading: false });
};

const SearchSelection = ({ callBack, resultsMessage }) => {
  const [state, setState] = useState({ loading: true });

  useEffect(() => {
    (async () => {
      await onMount(setState, callBack);
    })();
  }, [callBack]);

  return (
    <Card className={styles.card}>
      <CardContent>
        <Form
          onSubmit={values => onSubmit(values, callBack)}
          initialValues={{ searchKey: 'accountNum' }}
        >
          {({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="searchKey"
                type="radio"
                value="accountNum"
                label="Account Number"
              >
                {field => <Radio field={field} />}
              </Field>
              <Field
                name="searchKey"
                type="radio"
                value="servicePointId"
                label="Service Point ID (premise number)"
              >
                {field => <Radio field={field} />}
              </Field>
              <Field
                name="searchKey"
                type="radio"
                value="name"
                label="Full Name"
              >
                {field => <Radio field={field} />}
              </Field>
              <Field
                name="searchKey"
                type="radio"
                value="emailAddress"
                label="Email Address"
              >
                {field => <Radio field={field} />}
              </Field>

              <Field
                name="searchKey"
                type="radio"
                value="createdAt"
                label="createdAt date range"
              >
                {field => <Radio field={field} />}
              </Field>

              {form.getState().values.searchKey === 'createdAt' && (
              <div>
                <Field
                  name="startDate"
                  label="Start Date"
                  validate={required}
                  type="date"
                >
                  {field => <Input field={field} /> }
                </Field>
                <Field
                  name="endDate"
                  label="End Date"
                  validate={required}
                  type="date"
                >
                  {field => <Input field={field} /> }
                </Field>
              </div>
              )}

              {form.getState().values.searchKey !== 'createdAt' && (
              <div>
                <Field
                  name="searchValue"
                  label="Search Value"
                  validate={required}
                >
                  {field => <Input field={field} /> }
                </Field>
              </div>
              )}
              <Submit submitting={state.loading || submitting}>
                search
              </Submit>
            </form>
          )}
        </Form>
        { resultsMessage }
      </CardContent>
    </Card>
  );
};

export default SearchSelection;

SearchSelection.propTypes = {
  callBack: PropTypes.func.isRequired,
  resultsMessage: PropTypes.string,
};

SearchSelection.defaultProps = {
  resultsMessage: '',
};
