import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import MuiTabs from 'sharedComponents/MuiTabs/MuiTabs';
import DocuSignApi from './components/DocuSignApi/DocuSignApi';
import CrudDocuSign from './components/CrudDocuSign/CrudDocuSign';

export const DocuSignPortal = ({
  location,
  history,
  formState,
  dispatch,
}) => (
  <MuiTabs tabLabels={['DocuSign', 'Api']}>
    <CrudDocuSign
      key="0"
      formState={formState}
      dispatch={dispatch}
    />
    <DocuSignApi
      key="1"
      location={location}
      history={history}
    />
  </MuiTabs>
);

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
    dispatch: state.dispatch,
  };
}

export default compose(
  connect(mapStateToProps),
)(DocuSignPortal);

DocuSignPortal.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  formState: PropTypes.shape({}).isRequired,
  dispatch: PropTypes.func.isRequired,
};
