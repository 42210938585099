/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FieldArray } from 'react-final-form-arrays';
import InputFieldArray from 'sharedComponents/FinalForm/InputFieldArray/InputFieldArray';

const NormalizeVolumeTable = ({ fieldName, volume }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell align="center">Customer ID</TableCell>
        <TableCell align="center">Customer Name</TableCell>
        <TableCell align="center">Deal Id</TableCell>
        <TableCell align="center">Pipeline Name</TableCell>
        <TableCell align="center">Gas Day</TableCell>
        <TableCell align="center">ind Type</TableCell>
        <TableCell align="center">Actual Volume</TableCell>
        <TableCell align="center">Contract Volume</TableCell>
        <TableCell align="center">market</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <FieldArray name={fieldName}>
        {({ fields }) => fields.map((name, i) => (
          <TableRow key={i}>
            <TableCell align="center">
              {fields.value[i].customerId}
            </TableCell>
            <TableCell align="center">
              {fields.value[i].customerName}
            </TableCell>
            <TableCell align="center">
              {fields.value[i].dealId}
            </TableCell>
            <TableCell align="center">
              {fields.value[i].pipelineName}
            </TableCell>
            <TableCell align="center">
              {new Date(`${fields.value[i].gasDay} 00:00:00 GMT-0800`).toDateString()}
            </TableCell>
            <TableCell align="center">
              {fields.value[i].indexType}
            </TableCell>
            <TableCell align="center">
              <InputFieldArray
                name={name}
                smallInput
                objKey="actualVolume"
              />
            </TableCell>
            <TableCell align="center">
              {fields.value[i].contractVolume}
            </TableCell>
            <TableCell align="center">
              {fields.value[i].market ? 'true' : 'false'}
            </TableCell>
          </TableRow>
        ))}
      </FieldArray>
      <TableRow>
        <TableCell colSpan={5} />
        <TableCell>Total</TableCell>
        <TableCell align="center">{volume}</TableCell>
      </TableRow>
    </TableBody>
  </Table>
);


export default NormalizeVolumeTable;

NormalizeVolumeTable.propTypes = {
  fieldName: PropTypes.string.isRequired,
  volume: PropTypes.number.isRequired,
};

NormalizeVolumeTable.defaultProps = {};
