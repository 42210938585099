import {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import SgCustomerResults from './components/SgCustomerResults/SgCustomerResults';
import SgCustomerSearch from './components/SgCustomerSearch/SgCustomerSearch';

export class ChoiceCallLog extends Component {
  state = {
    results: [],
  }

  setResults = results => this.setState({results});

  render() {
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <SgCustomerSearch setResults={this.setResults} />
          <SgCustomerResults results={this.state.results} />
        </div>
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
  };
}

export default compose(
  connect(mapStateToProps),
)(ChoiceCallLog);

ChoiceCallLog.propTypes = {
};
