import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './ChoiceSelectionList.module.scss';

const ChoiceSelectionList = ({ row }) => (
  <List className={styles.list}>
    {Object.keys(row).map((key, index) => {
      if (typeof row[key] !== 'object') {
        return (
          <ListItem key={index}>
            {
            // eslint-disable-next-line no-nested-ternary
            key === 'delegationAgreementUrl' && row[key] ? (
              <ListItem button component="a" href={row[key]} rel="noopener noreferrer" target="_blank">
                <ListItemText
                  className={styles.listItem}
                  primary={key}
                  secondary={row[key] ? 'link' : 'N/A'}
                />
              </ListItem>
            ) : typeof row[key] === 'boolean'
              ? (
                <ListItemText
                  className={styles.listItem}
                  primary={key}
                  secondary={row[key] ? 'true' : 'false'}
                />
              )
              : (
                <ListItemText
                  className={styles.listItem}
                  primary={key}
                  secondary={row[key]}
                />
              )
          }
          </ListItem>
        );
      }
      return null;
    })}
  </List>
);

export default ChoiceSelectionList;

ChoiceSelectionList.propTypes = {
  row: PropTypes.shape({}),
};

ChoiceSelectionList.defaultProps = {
  row: {},
};
