import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { Form, MuiSelect, MuiSubmit } from 'rff-wrapper';
import DateHelpers from 'utils/DateHelpers';

export class SgCustomerSearch extends Component {
  state = {
    billClasses: ['AGR', 'COM', 'RES', 'IND'],
    serviceStates: ['NE', 'WY'],
    divisionRegions: ['CENTRAL', 'WESTERN', 'SG CASPER', 'SG GILLTTE', 'SG TORRNTN'],
    progYears: DateHelpers.yearArray(),
  };

  async componentDidMount() {
    await this.onSubmit({
      billClass: this.state.billClasses[0],
      divisionRegion: this.state.divisionRegions[0],
      serviceState: this.state.serviceStates[0],
      progYear: this.state.progYears[0],
    });
  }

  onSubmit = async (values) => {
    const results = await this.search(values);
    this.props.setResults(results);
  };

  search = async (values) => {
    const query = {
      query: `{
          sgCustomer(
            divisionRegion: "${values.divisionRegion}",
            billClass: "${values.billClass}",
            progYear: ${values.progYear},
            serviceState: "${values.serviceState}",
            balloted: ${false},
          ) {
            id
            confirmCode
            divisionRegion
            accountNum
            premiseNumber
            firstName
            lastName
            telephone
            emailAddress
            serviceAddress
            mailingAddress
            mailingCityState
            pricingOpt
            selectMethod
            totalUsage
            janPremUsage
            febPremUsage
            marPremUsage
            aprPremUsage
            mayPremUsage
            junPremUsage
            julPremUsage
            augPremUsage
            sepPremUsage
            octPremUsage
            novPremUsage
            decPremUsage
          }
        }`,
    };
    const res = await axios.post('/graphql/', query);
    if (res.data.errors) {
      return res.data.errors;
    }

    return res.data.data.sgCustomer;
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{
          billClass: this.state.billClasses[0],
          divisionRegion: this.state.divisionRegions[0],
          serviceState: this.state.serviceStates[0],
          progYear: this.state.progYears[0],
        }}
      >
        <Grid
          container
          direction="row"
          justify="space-evenly"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <MuiSelect name="billClass" label="Bill Class" options={this.state.billClasses} variant="outlined" />
          </Grid>
          <Grid item>
            <MuiSelect name="divisionRegion" label="Division Region" options={this.state.divisionRegions} variant="outlined" />
          </Grid>
          <Grid item>
            <MuiSelect name="serviceState" label="Service State" options={this.state.serviceStates} variant="outlined" />
          </Grid>
          <Grid item>
            <MuiSelect name="progYear" label="Prog year" options={this.state.progYears} variant="outlined" />
          </Grid>
        </Grid>
        <div>
          <MuiSubmit buttonText="Search" />
        </div>
      </Form>
    );
  }
}

export default SgCustomerSearch;

SgCustomerSearch.propTypes = {
  setResults: PropTypes.func.isRequired,
};

SgCustomerSearch.defaultProps = {};
