import React from 'react';
import PropTypes from 'prop-types';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Grid from '@material-ui/core/Grid';
import ChoiceSelectionList from './components/ChoiceSelectionList/ChoiceSelectionList';
import ChoiceSelectionForm from './components/ChoiceSelectionForm/ChoiceSelectionForm';

const ChoiceSelectionInfo = ({
  open,
  toggleDialog,
  row,
  updateSelection,
}) => (
  <MuiDialog
    open={open}
    fullScreen
    toggleDialog={toggleDialog}
  >
    { open && (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={3}>
        <ChoiceSelectionList row={row} />
      </Grid>
      <Grid item xs={9}>
        <ChoiceSelectionForm
          row={row}
          toggleDialog={toggleDialog}
          updateSelection={updateSelection}
        />
      </Grid>
    </Grid>
    )}
  </MuiDialog>
);

export default ChoiceSelectionInfo;

ChoiceSelectionInfo.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  updateSelection: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  row: PropTypes.shape({}),
};

ChoiceSelectionInfo.defaultProps = {
  row: {},
};
