import React from 'react';
import PropTypes from 'prop-types';

/**
 * LogoSvgConnect takes a color and renders a the bbb svg
 */

const LogoSvgConnect = ({
  fillOne,
  fillTwo,
  classes,
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 464 509"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path fill={fillOne} d="M195,384 C173.442307,384 152.779697,380.167692 133.656275,373.147182 C114.329733,366.052103 96.5752502,355.700734 81.057673,342.757922 C61.4600634,326.412061 45.4302997,305.93287 34.3076253,282.659595 C23.2123339,259.443616 17,233.447292 17,206 C17,187.554369 19.8057102,169.764047 25.0136433,153.032521 C31.7969236,131.239876 42.655527,111.243459 56.697888,93.9348326 C71.3570906,75.8658874 89.4859013,60.7260546 110.070026,49.5296282 C135.311389,35.799967 164.244805,28 195,28 C219.027266,28 241.942628,32.7606309 262.854294,41.3901027 C283.380707,49.8605945 301.976573,62.0586896 317.798487,77.1409838 C332.404858,91.064557 344.647131,107.446187 353.861738,125.622304 C366.102115,149.766855 373,177.077884 373,206 C373,224.896258 370.055528,243.104798 364.600372,260.191832 C356.999491,283.999859 344.524385,305.630603 328.348457,323.910662 C314.368203,339.709437 297.62362,353.005416 278.872209,363.041097 C253.881575,376.415985 225.326458,384 195,384 Z M195.5,325 C214.702562,325 232.838334,320.432529 248.880175,312.324729 C263.536847,304.917014 276.445586,294.55397 286.82301,282.018977 C294.309109,272.976444 300.477927,262.803642 305.03538,251.794656 C310.812156,237.840272 314,222.542417 314,206.5 C314,193.626676 311.947237,181.2328 308.151185,169.627847 C301.49153,149.268542 289.466397,131.337378 273.746813,117.505381 C263.595067,108.572643 251.902509,101.349508 239.119214,96.2860482 C225.619245,90.938714 210.902809,88 195.5,88 C177.893996,88 161.184764,91.8395386 146.163958,98.7269623 C133.298852,104.625942 121.672321,112.760752 111.781754,122.634004 C101.137447,133.259675 92.5037729,145.898851 86.5007209,159.931541 C80.3853848,174.226706 77,189.968003 77,206.5 C77,223.047437 80.3917115,238.802697 86.5178628,253.108509 C92.5172241,267.118241 101.13901,279.7379 111.765919,290.350186 C122.551032,301.120459 135.401416,309.823146 149.671785,315.812964 C163.769417,321.730277 179.252866,325 195.5,325 Z" id="Combined-Shape" />
    <circle fill={fillTwo} id="Oval-2" cx="195" cy="206" r="118" />
  </svg>
);

export default LogoSvgConnect;

LogoSvgConnect.propTypes = {
  fillOne: PropTypes.string,
  fillTwo: PropTypes.string,
  classes: PropTypes.shape(),
};

LogoSvgConnect.defaultProps = {
  /** color to fill svg outer circle */
  fillOne: '#231f20', // black
  /** color to fill svg inner circle */
  fillTwo: '#277ac3', // blue
  /** class to pass to svg */
  classes: {},
};
