import React, { Component } from 'react';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import SelectField from 'sharedComponents/ReduxForms/SelectField/SelectField';
import { components } from 'sharedComponents/DocuSignRedirect/DocuSignRedirect';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import Form from 'sharedComponents/ReduxForms/Form/Form';
import Submit from 'sharedComponents/ReduxForms/Submit/Submit';
import Input from 'sharedComponents/ReduxForms/Input/Input';
import PropsForm from 'sharedComponents/PropsForm/PropsForm';
import styles from './DocuSignForm.module.scss';

export const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'templateName',
    'templateId',
    'roleName',
    'subQuery',
    'signerOneCallBack',
    'signerTwoCallBack',
    'selectedComponent',
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  errors.props = {};
  Object.keys((values.props || {}))
    .forEach((field) => {
      if (Array.isArray(values.props[field])) {
        errors.props[field] = [];
        values.props[field].forEach((value, index) => {
          const error = {};
          Object.keys(value).forEach((key) => {
            if (
              values.props[field]
              && values.props[field][index]
              && !values.props[field][index][key]
            ) {
              error[key] = 'Required';
              errors.props[field][index] = error;
            }
          });
        });
      }
    });

  return errors;
};

export class DocuSignForm extends Component {
  state = {
    subQueries: [],
    callBacks: [],
  };

  async componentDidMount() {
    const query = { query: 'query { docuSignCallBackMethods, docuSignBindingMethods }' };
    try {
      const { data: { data } } = await axios.post('/graphql', query);

      this.setState({
        subQueries: data.docuSignBindingMethods,
        callBacks: data.docuSignCallBackMethods,
      });
    } catch (err) {
      this.setState({ err });
    }
  }

  docuSignUpsert = async (document) => {
    try {
      const queryString = {
        query: `mutation {
          docuSignUpsert(
            docuSign: ${graphqlObj(document)}
          ) {
            id
            templateId
            roleName
            templateName
            subQuery
            component
            signerOneCallBack
            signerTwoCallBack
            props
          }
        }`,
      };
      const req = await axios.post('/graphql/', queryString);
      return this.props.toggleDialog(req.data.data.docuSignUpsert);
    } catch (error) {
      return this.setState({ error: error.toString() });
    }
  }

  onSubmit = async (values) => {
    const document = JSON.parse(JSON.stringify(values));
    delete document.selectedComponent;
    await this.docuSignUpsert(document);
  }

  render() {
    return (
      <>
        {this.state.err && <h4 style={{ color: 'red' }}>{this.state.err.toString()}</h4>}
        {this.props.open && (
        <MuiDialog
          fullWidth={true}
          open={this.props.open}
          toggleDialog={this.props.toggleDialog}
        >
          {this.state.error && <h4 style={{ color: 'red' }}>{this.state.error}</h4>}
          <Form
            form="DocuSignForm"
            validate={validate}
            onSubmit={this.onSubmit}
            initialValues={Object.assign(this.props.document)}
            classes={styles}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Field
                    component={Input}
                    classes={styles}
                    label="Role Name"
                    type="text"
                    name="roleName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Input}
                    classes={styles}
                    label="Template Name"
                    type="text"
                    name="templateName"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={Input}
                    classes={styles}
                    label="Template Id"
                    type="text"
                    name="templateId"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Field
                  name="subQuery"
                  component={SelectField}
                  options={this.state.subQueries}
                  classes={styles}
                  displayKey="subQuery"
                  selectLabel="subQuery"
                />
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Field
                  name="signerOneCallBack"
                  component={SelectField}
                  options={this.state.callBacks}
                  classes={styles}
                  displayKey="signerOneCallBack"
                  selectLabel="signerOneCallBack"
                />
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Field
                  name="signerTwoCallBack"
                  component={SelectField}
                  options={this.state.callBacks}
                  classes={styles}
                  displayKey="signerTwoCallBack"
                  selectLabel="signerTwoCallBack"
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <PropsForm
                  document={this.props.document}
                  components={components}
                  formState={this.props.formState}
                  changeIndex={this.props.changeIndex}
                  mapValuesToForm={this.props.mapValuesToForm}
                  form="DocuSignForm"
                />
              </Grid>
            </Grid>
            <Submit>
              submit
            </Submit>
          </Form>
        </MuiDialog>
        )}
      </>
    );
  }
}

export default DocuSignForm;

DocuSignForm.propTypes = {
  changeIndex: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    DocuSignForm: PropTypes.shape({
      values: PropTypes.shape({}),
    }),
  }).isRequired,
  toggleDialog: PropTypes.func.isRequired,
  mapValuesToForm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    id: PropTypes.number,
    roleName: PropTypes.string,
    templateId: PropTypes.string,
    templateName: PropTypes.string,
  }).isRequired,
};
