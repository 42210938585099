import React, { Component } from 'react';
import MuiTabs from 'sharedComponents/MuiTabs/MuiTabs';
import NachaReports from './components/NachaReports/NachaReports';
import ViewBatches from './components/ViewBatches/ViewBatches';

export class Nacha extends Component {
  state = {};

  render() {
    return (
      <MuiTabs tabLabels={['nacha', 'batches']}>
        <NachaReports key="0" />
        <ViewBatches key="1" />
      </MuiTabs>
    );
  }
}

export default Nacha;
