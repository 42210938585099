import React, { Component } from 'react';
import { Field } from 'redux-form';
import validateEmail from 'utils/validateEmail';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Input from 'sharedComponents/ReduxForms/Input/Input';
import styles from './FirstAndLastNameValidateEmail.module.scss';

export class FirstAndLastNameValidateEmail extends Component {
  static validate = (values) => {
    const errors = {};
    if (!validateEmail(values.email)) errors.email = 'Enter a valid email';
    if (!values.firstName) errors.firstName = 'required';
    if (!values.lastName) errors.lastName = 'required';

    return errors;
  };

  static initialValues = {};

  render() {
    return (
      <Card>
        <Grid container>
          <Grid container item xs={12} justify="center" alignItems="center">
            <Grid item xs={12}>
              <Field
                name="firstName"
                label="First Name"
                component={Input}
                classes={styles}
              />
              <Field
                name="lastName"
                label="Last Name"
                component={Input}
                classes={styles}
              />
              <Field
                name="email"
                label="email"
                component={Input}
                classes={styles}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default FirstAndLastNameValidateEmail;
