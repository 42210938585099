import React from 'react';
import LogoIconSvg from 'sharedComponents/LogoIconSvg/LogoIconSvg';
import LogoSvgConnect from 'sharedComponents/LogoSvgConnect/LogoSvgConnect';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import styles from './AppLoader.module.scss';

export const AppLoader = ({
  fillOne,
  fillTwo,
  fillOneConnect,
  fillTwoConnect,
  connectEnergy,
  loading,
  message,
}) => {
  if (!loading) return null;
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      {connectEnergy ? (
        <LogoSvgConnect
          classes={styles.fadeOut}
          fillOne={fillOneConnect}
          fillTwo={fillTwoConnect}
        />
      ) : (
        <LogoIconSvg
          classes={styles.fadeOut}
          fillOne={fillOne}
          fillTwo={fillTwo}
        />
      )}
      <h3>{message}</h3>
    </Grid>
  );
};

export default AppLoader;

AppLoader.propTypes = {
  loading: PropTypes.bool,
  fillOne: PropTypes.string,
  fillTwo: PropTypes.string,
  fillOneConnect: PropTypes.string,
  fillTwoConnect: PropTypes.string,
  connectEnergy: PropTypes.bool,
  message: PropTypes.string,
};

AppLoader.defaultProps = {
  fillOne: '#70AE6E',
  fillTwo: '#70AE6E',
  fillOneConnect: '#231f20',
  fillTwoConnect: '#277ac3',
  connectEnergy: process.env.REACT_APP_COMPANY.includes('connectenergy'),
  message: 'Loading',
  loading: true,
};
