import React, { Component } from 'react';
import PropTypes from 'prop-types';
import graphqlObj from 'utils/graphqlObj';
import axios from 'axios';
import Form from 'sharedComponents/ReduxForms/Form/Form';
import Submit from 'sharedComponents/ReduxForms/Submit/Submit';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import styles from './DocuSignRedirect.module.scss';
import PriceAndTermsFrom from './components/PriceAndTermsFrom/PriceAndTermsFrom';
import NewEnrollmentWisconsin from './components/NewEnrollmentWisconsin/NewEnrollmentWisconsin';
import Ach from './components/Ach/Ach';
import NoForm from './components/NoForm/NoForm';
import ValidateEmail from './components/ValidateEmail/ValidateEmail';
import FirstAndLastNameValidateEmail from './components/FirstAndLastNameValidateEmail/FirstAndLastNameValidateEmail';
import ChoicePromoCodeForm from './components/ChoicePromoCodeForm/ChoicePromoCodeForm';

export const components = {
  PriceAndTermsFrom,
  NewEnrollmentWisconsin,
  Ach,
  NoForm,
  ValidateEmail,
  FirstAndLastNameValidateEmail,
  ChoicePromoCodeForm,
};

export const validateForm = (values, template) => {
  let errors = {};
  const requiredFields = ['clientUserId', 'email', 'returnUrl'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (
    components[template.component] &&
    components[template.component].validate
  ) {
    // children components can add errors to form from static validate function
    errors = Object.assign(
      errors,
      components[template.component].validate(values)
    );
  }
  return errors;
};

export class DocuSignRedirect extends Component {
  state = {
    loading: true,
    error: null,
    template: {},
  };

  async componentDidMount() {
    await this.getDocumentTemplate(this.props.templateName);
  }

  getDocumentTemplate = async (templateName) => {
    this.setState({ loading: true });
    try {
      const query = {
        query: `{
          docuSign(
            templateName: "${templateName}",
          ) {
            templateId
            templateName
            subQuery
            component
            props
          }
        }`,
      };
      const data = await axios.post('/graphql/', query);
      const template = data.data.data.docuSign[0];
      if (data.data.data.docuSign[0]) {
        return this.setState({ template, loading: false });
      }
      return this.setState({
        error: `No DocuSign Template with name: ${templateName}`,
      });
    } catch (error) {
      return this.setState({ error });
    }
  };

  createLink = async (signerData) => {
    let res;
    this.setState({ error: null });
    try {
      const docuSignData = signerData;
      if (!signerData.name) {
        if (!signerData.firstName || !signerData.lastName)
          throw new Error('docusign signer name is required').toString();
        docuSignData.name = `${signerData.firstName} ${signerData.lastName}`;
      }

      const query = {
        query: `{
          docuSignClient(
            query: "signing_url",
            subQuery: "${this.state.template.subQuery}",
            signerData: ${graphqlObj(docuSignData)}
          ) {
            url
            error
          }
        }`,
      };
      res = await axios.post('/graphql/', query);
    } catch (error) {
      return this.setState({ error });
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ error: res.data.errors[0].message });
    }

    // return res.data.data.docuSignClient.url;
  };

  onSubmit = async (values) => {
    const docuSignLink = await this.createLink(values);
    // console.log('docusing',docuSignLink);
    // console.log('window.location',window.location);
    // window.locx = window.location;
    // navigate('/choice', { replace: true });
    // if (!this.state.error) navigate('/choice', { replace: true });
    window.location.assign(window.location.href);
  };

  renderTemplateComponent = (template) => {
    if (!template.component) return <div />;
    return React.createElement(components[template.component], {
      ...template.props,
      ...this.props,
      formState: this.props.formState,
    });
  };

  validateValues = (initialValues) => {
    if (this.props.formState.DocuSignRedirectForm) {
      return this.props.formState.DocuSignRedirectForm.values;
    }
    return initialValues;
  };

  render() {
    const {
      clientUserId,
      email,
      name,
      returnUrl,
      buttonText,
      disabled,
      firstName,
      lastName,
    } = this.props;

    // children components can add initialValues to form from static initialValues Object
    let initialValues = {
      clientUserId,
      email,
      name,
      returnUrl,
      templateId: this.state.template.templateId,
      firstName,
      lastName,
    };

    if (
      components[this.state.template.component] &&
      components[this.state.template.component].initialValues
    ) {
      initialValues = {
        ...initialValues,
        // spread selected child initialValues
        ...components[this.state.template.component].initialValues,
      };
    }

    return (
      <>
        {this.state.loading && <AppLoader loading={this.state.loading} />}
        {!this.state.loading && (
          <Form
            form="DocuSignRedirectForm"
            onSubmit={this.onSubmit}
            validate={(value) => validateForm(value, this.state.template)}
            classes={styles}
            initialValues={initialValues}
          >
            {this.renderTemplateComponent(this.state.template)}
            <Submit
              classes={styles}
              disabled={
                disabled ||
                Object.entries(
                  validateForm(
                    this.validateValues(initialValues),
                    this.state.template
                  )
                ).length !== 0
              }
            >
              {buttonText}
            </Submit>
          </Form>
        )}
        {this.state.error && (
          <div className={styles.error}>{this.state.error}</div>
        )}
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
  };
}

export default compose(connect(mapStateToProps))(DocuSignRedirect);

DocuSignRedirect.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  clientUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  formState: PropTypes.shape({
    DocuSignRedirectForm: PropTypes.shape({
      values: PropTypes.shape({}),
    }),
  }),
  email: PropTypes.string.isRequired,
  name: PropTypes.string,
  returnUrl: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  /** button text */
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
};

DocuSignRedirect.defaultProps = {
  formState: {
    DocuSignRedirectForm: {
      values: {},
    },
  },
  disabled: false,
  buttonText: 'Sign Up',
  name: '',
  firstName: '',
  lastName: '',
};
