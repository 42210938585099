/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data } from 'react-data-grid-addons';
import { Button, Tooltip } from '@material-ui/core';
import styles from './DisplayChoiceConfirmationCodes.module.scss';
import RerunConfirmCodes from './components/RerunConfirmCodes/RerunConfirmCodes';

export const setHeight = () => {
  const { body, documentElement } = window.document;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight,
  ) / 1.69 || 600;
};

export const getSubRowDetails = expandedRows => (rowItem) => {
  const expanded = expandedRows[rowItem.id] ? expandedRows[rowItem.id] : false;
  const shouldGroup = (!!(rowItem && rowItem.name));
  return {
    group: shouldGroup,
    expanded,
    field: 'name',
    treeDepth: rowItem.treeDepth || 0,
    siblingIndex: rowItem.siblingIndex,
    numberSiblings: rowItem.numberSiblings,
  };
};

export const updateSubRowDetails = (nestedRows, parentTreeDepth = 0) => nestedRows.map((sr, i) => ({
  ...sr,
  expanded: sr.expanded || false,
  treeDepth: (parentTreeDepth) + 1,
  siblingIndex: i,
  numberSiblings: nestedRows.length,
}));

export const onCellExpand = args => ({ rows, expandedRows }) => {
  const rowKey = args.rowData.id;
  const rowIndex = rows.indexOf(args.rowData);
  const subRows = args.rowData.choiceConfirmationCodes;
  if (expandedRows && !expandedRows[rowKey]) {
    expandedRows[rowKey] = true;
    updateSubRowDetails(subRows, args.rowData.treeDepth);
    rows.splice(rowIndex + 1, 0, ...subRows);
  } else if (expandedRows[rowKey]) {
    expandedRows[rowKey] = false;
    rows.splice(rowIndex + 1, subRows.length);
  }
  return { expandedRows, rows };
};


// eslint-disable-next-line react/prop-types
export const RowRenderer = ({ renderBaseRow, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const color = props.idx % 2 ? 'blue' : 'black';
  return <div style={{ color }}>{renderBaseRow(props)}</div>;
};

export const DisplayChoiceConfirmationCodes = ({ groups }) => {
  const defaultGroupValues = groups.map(item => ({
    ...item,
    code: 'Code',
    divisionRegion: 'Division Region',
    program: 'Program',
    term: 'Term',
    rerun: '',
  }));
  const [state, setState] = useState({ expandedRows: {}, rows: defaultGroupValues });
  const defaultColumnProperties = {
    resizable: true,
  };
  const visibleRows = Data.Selectors.getRows(state);
  useEffect(() => {
    setState({ ...state, rows: defaultGroupValues });
  }, [groups]);

  const groupNameFormatter = value => (
    value.row.choiceConfirmationCodeGroupUrls
      ? (
        <Tooltip title={`Download ${value.value} csv`} placement="right">
          <Button id="groupName">
            <a href={value.row.choiceConfirmationCodeGroupUrls} target="_blank" style={{ color: 'inherit', textDecoration: 'none' }}>
              {value.value}
            </a>
          </Button>
        </Tooltip>
      )
      : (<span>{value.value}</span>)
  );

  const reRunFormatter = value => (
    value.row.choiceConfirmationCodes ? (
      <RerunConfirmCodes group={value.row} />
    ) : null
  );

  const columns = [
    {
      key: 'name',
      name: 'Group Name',
      width: 180,
      resizable: true,
      formatter: groupNameFormatter,
    },
    {
      key: 'code',
      name: '',
      width: 180,
    },
    {
      key: 'divisionRegion',
      name: '',
      width: 180,
    },
    {
      key: 'program',
      name: '',
      width: 180,
    },
    {
      key: 'term',
      name: '',
      width: 180,
    },
    {
      key: 'rerun',
      name: 'Regenerate Report',
      width: 180,
      formatter: reRunFormatter,
    },
  ];

  return (
    <div style={{ marginTop: '50px' }}>
      {groups.length > 0 && (
        <ReactDataGrid
          className={styles.grid}
          rowRenderer={RowRenderer}
          columns={columns.map(c => ({ ...c, ...defaultColumnProperties }))}
          rowGetter={i => visibleRows[i]}
          rowsCount={visibleRows.length}
          getSubRowDetails={getSubRowDetails(state.expandedRows)}
          onCellExpand={args => setState(onCellExpand(args))}
          minHeight={setHeight()}
        />
      )}
    </div>
  );
};

export default DisplayChoiceConfirmationCodes;

DisplayChoiceConfirmationCodes.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
