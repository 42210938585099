import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import RDGridHelpers from 'utils/RDGridHelpers';
import { infoArray } from '../../PathTable';

export const MonthTotalCell = ({
  sumReceiptVolumes,
  sumDeliveryVolume,
  showVolumes,
  formState,
}) => {
  const receiptVolume = sumReceiptVolumes();
  const deliveryVolume = sumDeliveryVolume();
  return (
    <Fragment>
      {showVolumes && (
        <TableBody>
          <TableRow>
            <TableCell align="center" colSpan={1}>
              Month Totals
            </TableCell>
            <TableCell align="center" colSpan={1}>
              {RDGridHelpers.formatNumber(deliveryVolume)}
            </TableCell>
            {infoArray(formState).map((row, i) => (
              <Fragment key={i}>
                {!row.archived && (
                  <TableCell align="center" colSpan={1}>
                    {RDGridHelpers.formatNumber(sumReceiptVolumes(row.groupId))}
                  </TableCell>
                )}
              </Fragment>
            ))}
            <TableCell align="center" colSpan={1}>
              {RDGridHelpers.formatNumber(receiptVolume)}
            </TableCell>
            <TableCell align="center" colSpan={1}>
              {RDGridHelpers.formatNumber(deliveryVolume - receiptVolume)}
            </TableCell>
          </TableRow>
        </TableBody>
      )}
    </Fragment>
  );
};

export default MonthTotalCell;

MonthTotalCell.propTypes = {
  sumReceiptVolumes: PropTypes.func.isRequired,
  sumDeliveryVolume: PropTypes.func.isRequired,
  showVolumes: PropTypes.bool.isRequired,
  formState: PropTypes.shape({
    PathForm: PropTypes.shape({
      fields: PropTypes.shape(),
      values: PropTypes.shape(),
    }),
  }),
};

MonthTotalCell.defaultProps = {
  formState: {
    PathForm: {
      fields: {},
      values: {},
    },
  },
};
