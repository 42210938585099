import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reset } from 'redux-form';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import Form from 'sharedComponents/ReduxForms/Form/Form';
import Submit from 'sharedComponents/ReduxForms/Submit/Submit';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import { components } from 'sharedComponents/DocuSignRedirect/DocuSignRedirect';
import PropsForm from 'sharedComponents/PropsForm/PropsForm';

export const validate = (values) => {
  const errors = {};

  errors.props = {};
  Object.keys((values.props || {}))
    .forEach((field) => {
      if (Array.isArray(values.props[field])) {
        errors.props[field] = [];
        values.props[field].forEach((value, index) => {
          const error = {};
          Object.keys(value).forEach((key) => {
            if (
              values.props[field]
              && values.props[field][index]
              && !values.props[field][index][key]
            ) {
              error[key] = 'Required';
              errors.props[field][index] = error;
            }
          });
        });
      }
    });

  return errors;
};

export class GroupPropsForm extends Component {
  state = {};

  docuSignUpsert = async (document) => {
    try {
      const queryString = {
        query: `mutation {
          docuSignUpsert(
            docuSign: ${graphqlObj(document)}
          ) { id }
        }`,
      };
      const data = await axios.post('/graphql/', queryString);
      return data.data.data.docuSignUpsert;
    } catch (error) {
      return this.setState({ error: error.toString() });
    }
  }

  forLoop = async (props, values, documents) => {
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
    for (let i = 0; i < documents.length; i++) {
      const doc = Object.assign({}, documents[i]);
      doc.component = values.component;
      doc.props = props;
      // eslint-disable-next-line no-await-in-loop
      await this.docuSignUpsert(doc);
    }
  }

  onSubmit = async (values) => {
    const props = JSON.parse(JSON.stringify(values));
    delete props.selectedComponent;
    await this.forLoop(props.props, values, this.props.documents);
    await this.props.loadDocuSign();
    await this.props.dispatch(reset('GroupFrom'));
    await this.props.dispatch(reset('GroupPropsFrom'));
    this.props.toggleGroupFrom(props);
  }

  render() {
    return (
      <>
        <MuiDialog
          fullWidth={true}
          open={this.props.open}
          toggleDialog={this.props.toggleGroupFrom}
        >
          {this.state.error && <h4 style={{ color: 'red' }}>{this.state.error.toString()}</h4>}
          <Form
            form="GroupPropsFrom"
            onSubmit={this.onSubmit}
            validate={validate}
          >
            <PropsForm
              changeIndex={this.props.changeIndex}
              document={this.props.documents[0]}
              components={components}
              formState={this.props.formState}
              mapValuesToForm={this.props.mapValuesToForm}
              form="GroupPropsFrom"
            />
            <Submit>
              submit
            </Submit>
          </Form>
        </MuiDialog>
      </>
    );
  }
}

export default GroupPropsForm;

GroupPropsForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loadDocuSign: PropTypes.func.isRequired,
  changeIndex: PropTypes.func.isRequired,
  mapValuesToForm: PropTypes.func.isRequired,
  formState: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleGroupFrom: PropTypes.func.isRequired,
};
