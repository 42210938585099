import React from 'react';
import styles from './LocationFormNullState.module.scss';

const LocationFormNullState = () => (
  <div className={styles.locationFormNullStateWrapper}>
    No locations found in this bill class with the provided account or premise number
  </div>
);

export default LocationFormNullState;
