import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';

const AccountNoteList = ({notes}) => (
  <List>
    {notes.length > 0 && notes.map(note => (
      <ListItem key={note.id}>
        <ListItemText
          primary={`${note.user.firstName} ${note.user.lastName} ${note.createdAt}`}
          secondary={note.note}
        />
      </ListItem>
    ))}
  </List>
);

export default AccountNoteList;

AccountNoteList.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.shape({})),
};

AccountNoteList.defaultProps = {
  notes: [],
};
