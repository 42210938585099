import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import axios from 'axios';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {Form} from 'react-final-form';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import graphqlObj from 'utils/graphqlObj';
import DetailedReport from './components/DetailedReport/DetailedReport';
import EmailQuote from './components/EmailQuote/EmailQuote';
import ProgramOption from './components/ProgramOption/ProgramOption';
// import GreenPlus from './components/GreenPlus/GreenPlus';
import styles from './SelectPriceForm.module.scss';
import GenerateNewQuote from './components/GenerateNewQuote/GenerateNewQuote';
import ProgramArea from './components/ProgramArea/ProgramArea';
import TopSection from './components/TopSection/TopSection';

const operatorFns = {
  '+': (a, b) => a + b,
  '-': (a, b) => a - b,
};

export class SelectPriceForm extends Component {
  state = {
    openTerms: false,
    openDetails: false,
    openControlNumber: this.props.openControlNumberForm,
    formState: {},
    isCom: false,
    loading: true,
    greenPlus: this.props.quote.greenPlus || false,
    formValues: {},
    guaranteedFixedAdder: 0,
    guaranteedIndexAdder: 0,
    secureFixedAdder: 0,
  };

  async componentDidMount() {
    this.isComAccount(this.props.quote.quoteData.accountList);
    await this.greenPlus();
  }

  greenPlus = async () => {
    try {
      const query = {
        query: `{
          choiceParamsWhere(
            region: "green_plus"
            billClass: "ALL",
            paramTypes: ${graphqlObj([
          'guaranteed_fixed_adder',
          'guaranteed_index_adder',
          'secure_fixed_adder',
        ])}
          ) {
            paramValue
            paramType
          }
        }`,
      };
      const data = await axios.post('/graphql/', query);
      const {errors} = data.data;
      if (errors) {
        throw new Error(errors[0].message);
      }

      const params = data.data.data.choiceParamsWhere.sort((a, b) => {
        if (a.paramType < b.paramType) {
          return -1;
        }
        if (a.paramType > b.paramType) {
          return 1;
        }
        return 0;
      });

      const guaranteedFixedAdder = parseFloat(params[0].paramValue);
      const guaranteedIndexAdder = parseFloat(params[1].paramValue);
      const secureFixedAdder = parseFloat(params[2].paramValue);

      return this.setState({
        guaranteedFixedAdder,
        guaranteedIndexAdder,
        secureFixedAdder,
        loading: false,
      });
    } catch (error) {
      return error;
    }
  };

  isComAccount = (accountList) => {
    let isCom = true;
    accountList.forEach((account) => {
      if (account.billClass === 'RES') {
        isCom = false;
      }
    });
    this.setState({isCom});
  };

  toggleDialog = (key) => {
    this.setState((oldState) => ({
      [key]: !oldState[key],
    }));
  };

  selectedProgram = (values) => {
    switch (values.quoteData.selectedPrice) {
      case '1':
        return {
          price: values.quoteData.gFixed1,
          term: 1,
          program: 'Guaranteed Fixed',
          paramType: 'g_fixed-1',
          choicePriceTypeId: this.props.choicePriceTypes[0].id,
        };
      case '2':
        return {
          price: values.quoteData.gFixed2,
          term: 2,
          program: 'Guaranteed Fixed',
          paramType: 'g_fixed-2',
          choicePriceTypeId: this.props.choicePriceTypes[0].id,
        };
      case '3':
        return {
          price: values.quoteData.baseTpFuelIndex,
          term: 1,
          program: 'Guaranteed Index',
          paramType: 'ind-1',
          choicePriceTypeId: this.props.choicePriceTypes[1].id,
        };
      case '4':
        return {
          price: values.quoteData.baseTpFuelIndex2,
          term: 2,
          program: 'Guaranteed Index',
          paramType: 'ind-2',
          choicePriceTypeId: this.props.choicePriceTypes[1].id,
        };
      case '5':
        return {
          price: values.quoteData.fmb1Total,
          term: 1,
          program: 'Secure Fixed',
          paramType: 'fmb-1',
          choicePriceTypeId: this.props.choicePriceTypes[2].id,
        };
      case '6':
        return {
          price: values.quoteData.fmb2Total,
          term: 2,
          program: 'Secure Fixed',
          paramType: 'fmb-2',
          choicePriceTypeId: this.props.choicePriceTypes[2].id,
        };
      case '7':
        return {
          price: values.quoteData.gFixed3,
          term: 3,
          program: 'Guaranteed Fixed',
          paramType: 'g_fixed-3',
          choicePriceTypeId: this.props.choicePriceTypes[0].id,
        };
      case '8':
        return {
          price: values.quoteData.baseTpFuelIndex3,
          term: 3,
          program: 'Guaranteed Index',
          paramType: 'ind-3',
          choicePriceTypeId: this.props.choicePriceTypes[1].id,
        };
      case '9':
        return {
          price: values.quoteData.fmb3Total,
          term: 3,
          program: 'Secure Fixed',
          paramType: 'fmb-3',
          choicePriceTypeId: this.props.choicePriceTypes[2].id,
        };
      case '10':
        return {
          price: values.quoteData.managedPrice,
          term: 1,
          program: 'Managed Price',
          paramType: 'managed-price',
          choicePriceTypeId: this.props.choicePriceTypes[3].id,
        };
      case '11':
        return {
          price: values.quoteData.managedPrice,
          term: 2,
          program: 'Managed Price',
          paramType: 'managed-price',
          choicePriceTypeId: this.props.choicePriceTypes[3].id,
        };
      case '12':
        return {
          price: values.quoteData.managedPrice,
          term: 3,
          program: 'Managed Price',
          paramType: 'managed-price',
          choicePriceTypeId: this.props.choicePriceTypes[3].id,
        };
      case '13':
        return {
          price: values.quoteData.blended1,
          term: 1,
          program: 'Blended Price',
          paramType: 'blended-1',
          choicePriceTypeId: this.props.choicePriceTypes[4].id,
        };
      case '14':
        return {
          price: values.quoteData.blended2,
          term: 2,
          program: 'Blended Price',
          paramType: 'blended-2',
          choicePriceTypeId: this.props.choicePriceTypes[4].id,
        };
      case '15':
        return {
          price: values.quoteData.blended3,
          term: 3,
          program: 'Blended Price',
          paramType: 'blended-3',
          choicePriceTypeId: this.props.choicePriceTypes[4].id,
        };
      default:
        return {
          price: values.quoteData.gFixed2,
          term: 2,
          program: 'Guaranteed Fixed',
          paramType: 'g_fixed-2',
          choicePriceTypeId: this.props.choicePriceTypes[0].id,
        };
    }
  };

  updateQuote = async (values) => {
    const quote = this.configureQuote(values);
    const queryString = {
      query: `mutation {
        editChoicePriceQuote(quote: ${graphqlObj(quote)}) {
          id
          pubIndex
          premises
          greenPlus
          updatedAt
          quoteData {
            managedPrice
            totalUsages
            choicePriceTypeId
            expirationDate
            acctNums
            selectedPrice
            baseIndex
            baseTpFuelIndex
            baseTpFuelIndex2
            baseTpFuelIndex3
            baseTpIndex
            currentEmail
            fmb1
            fmb1Total
            fmb2
            fmb2Total
            fmb3
            fmb3Total
            fpPriceYr1NoAdders
            fpPriceYr2NoAdders
            gFixed1
            gFixed2
            gFixed3
            blended1
            blended2
            blended3
            paramType
            price
            program
            term
            transportCostTotal
            transportCostUnit
            volume
            mthlyVolume
            accountList {
              mailingAddress
              mailingCityState
              mailingZip
              accountNum
              baseIndex
              baseTpFuelIndex
              baseTpIndex
              billClass
              cogPremium
              curDbYear
              divisionRegion
              firstName
              fmb1
              fmb2
              fmb3
              fpPriceYrNoAdders1
              fpPriceYrNoAdders2
              fuelPercent
              gFixed1
              gFixed2
              gFixed3
              blended1
              blended2
              blended3
              lastName
              premiseNumber
              result
              riskFee
              serviceState
              shellFee
              supplierFee
              transportCostTotal
              transportCostUnit
              vFixed1
              vFixed2
              volume
              status
              complete
              verificationId
              controlId
              errorCode
              formattedAddress
            }
          }
        }
      }`,
    };
    const data = await axios.post('/graphql/', queryString);
    console.log('retval', data)
    return data.data.data.editChoicePriceQuote;
  };

  required = (value) => (value ? undefined : 'Required');

  onSubmit = async (values) => {
    const quote = await this.updateQuote(values);
    this.props.callBack(quote);
  };

  configureQuote = (values) => {
    const selectedQuote = {
      ...values,
      quoteData: Object.assign(values.quoteData, this.selectedProgram(values)),
    };

    delete selectedQuote.accountList;
    const quoteData = selectedQuote.quoteData;
    quoteData.baseIndex = parseFloat(quoteData.baseIndex);
    quoteData.baseTpFuelIndex = parseFloat(quoteData.baseTpFuelIndex);
    quoteData.baseTpFuelIndex2 = parseFloat(quoteData.baseTpFuelIndex2);
    quoteData.baseTpFuelIndex3 = parseFloat(quoteData.baseTpFuelIndex3);
    quoteData.managedPrice = parseFloat(quoteData.managedPrice);
    quoteData.baseTpIndex = parseFloat(quoteData.baseTpIndex);
    quoteData.fmb1 = parseFloat(quoteData.fmb1);
    quoteData.fmb2 = parseFloat(quoteData.fmb2);
    quoteData.fmb3 = parseFloat(quoteData.fmb3);
    quoteData.fpPriceYr1NoAdders = parseFloat(quoteData.fpPriceYr1NoAdders);
    quoteData.fpPriceYr2NoAdders = parseFloat(quoteData.fpPriceYr2NoAdders);
    quoteData.gFixed1 = parseFloat(quoteData.gFixed1);
    quoteData.gFixed2 = parseFloat(quoteData.gFixed2);
    quoteData.gFixed3 = parseFloat(quoteData.gFixed3);
    quoteData.blended1 = parseFloat(quoteData.blended1);
    quoteData.blended2 = parseFloat(quoteData.blended2);
    quoteData.blended3 = parseFloat(quoteData.blended3);
    quoteData.price = parseFloat(quoteData.price);
    quoteData.transportCostTotal = parseFloat(quoteData.transportCostTotal);
    quoteData.transportCostUnit = parseFloat(quoteData.transportCostUnit);
    quoteData.volume = parseFloat(quoteData.volume);
    quoteData.mthlyVolume = quoteData.mthlyVolume.map((v) => parseFloat(v));

    quoteData.fmb1Total =
      quoteData.fmb1Total === null ? 99999 : parseFloat(quoteData.fmb1Total);
    quoteData.fmb2Total =
      quoteData.fmb2Total === null ? 99999 : parseFloat(quoteData.fmb2Total);
    quoteData.fmb3Total =
      quoteData.fmb3Total === null ? 99999 : parseFloat(quoteData.fmb3Total);

    selectedQuote.quoteData.accountList = values.accountList.map(
      (location) => ({
        ...location,
        controlId: location.controlId || null,
        submit: 'X',
        fmb1: parseFloat(location.fmb1, 0),
        fmb2: parseFloat(location.fmb2, 0),
        fmb3: parseFloat(location.fmb3, 0),
      })
    );
    return selectedQuote;
  };

  hideSubmit = (complete, formState) => {
    if (complete) {
      return true;
    }
    if (
      formState.values &&
      Date.parse(this.props.expireDate) >
      Date.parse(this.props.quote.updatedAt.slice(0, 10))
    ) {
      return true;
    }
    return false;
  };

  threeYearOption = () => {
    if (this.props.formType === 'AGR') return true;
    if (this.state.isCom) return true;
    return false;
  };

  setFormState = (formValues) => {
    if (formValues !== this.state.formValues) {
      this.setState({formValues});
    }
  };

  calculateGreenPlus = (state, utils, operator = '+') => {
    const {guaranteedIndexAdder, guaranteedFixedAdder, secureFixedAdder} =
      this.state;

    let fmb1Total = 0;
    let fmb2Total = 0;
    let fmb3Total = 0;

    state.formState.values.accountList.forEach((account, index) => {
      fmb1Total += operatorFns[operator](
        parseFloat(account.fmb1, 0),
        secureFixedAdder
      );
      fmb2Total += operatorFns[operator](
        parseFloat(account.fmb2, 0),
        secureFixedAdder
      );
      fmb3Total += operatorFns[operator](
        parseFloat(account.fmb3, 0),
        secureFixedAdder
      );

      utils.changeValue(state, `accountList[${index}]`, (value) => ({
        ...value,
        fmb1: operatorFns[operator](
          parseFloat(value.fmb1, 0),
          secureFixedAdder
        ).toFixed(3),
        fmb2: operatorFns[operator](
          parseFloat(value.fmb2, 0),
          secureFixedAdder
        ).toFixed(3),
        fmb3: operatorFns[operator](
          parseFloat(value.fmb3, 0),
          secureFixedAdder
        ).toFixed(3),
      }));
    });

    utils.changeValue(state, 'quoteData', (value) => ({
      ...value,
      gFixed1: operatorFns[operator](
        parseFloat(value.gFixed1),
        guaranteedFixedAdder
      ).toFixed(3),
      gFixed2: operatorFns[operator](
        parseFloat(value.gFixed2),
        guaranteedFixedAdder
      ).toFixed(3),
      gFixed3: operatorFns[operator](
        parseFloat(value.gFixed3),
        guaranteedFixedAdder
      ).toFixed(3),
      baseTpFuelIndex: operatorFns[operator](
        parseFloat(value.baseTpFuelIndex),
        guaranteedIndexAdder
      ).toFixed(3),
      baseTpFuelIndex2: operatorFns[operator](
        parseFloat(value.baseTpFuelIndex2),
        guaranteedIndexAdder
      ).toFixed(3),
      baseTpFuelIndex3: operatorFns[operator](
        parseFloat(value.baseTpFuelIndex3),
        guaranteedIndexAdder
      ).toFixed(3),
      fmb1Total: parseFloat(fmb1Total, 0).toFixed(3),
      fmb2Total: parseFloat(fmb2Total, 0).toFixed(3),
      fmb3Total: parseFloat(fmb3Total, 0).toFixed(3),
    }));
  };

  configGreenPlus = (args, state, utils) => {
    if (args[0]) {
      this.calculateGreenPlus(state, utils, '+');
    }

    if (!args[0]) {
      this.calculateGreenPlus(state, utils, '-');
    }

    utils.changeValue(state, 'greenPlus', () => args[0]);
    this.setState((oldState) => ({greenPlus: !oldState.greenPlus}));
  };

  setTotals = (state, utils) => {
    let fmb1Total = 0;
    let fmb2Total = 0;
    let fmb3Total = 0;

    state.formState.values.accountList.forEach((account) => {
      fmb1Total += parseFloat(account.fmb1, 0);
      fmb2Total += parseFloat(account.fmb2, 0);
      fmb3Total += parseFloat(account.fmb3, 0);
    });

    utils.changeValue(state, 'quoteData', (value) => ({
      ...value,
      fmb1Total: parseFloat(fmb1Total, 0).toFixed(3),
      fmb2Total: parseFloat(fmb2Total, 0).toFixed(3),
      fmb3Total: parseFloat(fmb3Total, 0).toFixed(3),
    }));
  };

  showFmb = () =>
    this.props.quote.quoteData.fmb1Total &&
    this.props.quote.quoteData.fmb2Total &&
    this.props.quote.quoteData.fmb2Total < 9000 &&
    this.props.formType !== 'AGR';

  render() {
    return (
      <>
        <Grow
          in={
            !this.state.loading &&
            this.props.quote.id &&
            this.props.choicePriceTypes.length > 0
          }
        >
          <Form
            onSubmit={this.onSubmit}
            mutators={{
              ...arrayMutators,
              toggleGreenPlus: (args, state, utils) => {
                this.configGreenPlus(args, state, utils);
              },
              setTotals: (args, state, utils) => {
                this.setTotals(state, utils);
              },
            }}
            initialValues={Object.assign(
              {accountList: this.props.quote.quoteData.accountList},
              Object.assign(this.props.quote, {
                quoteData: Object.assign(this.props.quote.quoteData, {
                  selectedPrice:
                    this.props.quote.quoteData.selectedPrice || '2',
                }),
              })
            )}
          >
            {({handleSubmit, submitting, form}) => {
              // throws Warning: Cannot update during an existing state transition
              // we need to save the formValues to state to pass to the EmailQuote component
              this.setFormState(form.getState().values);
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <TopSection
                      toggleDialog={this.toggleDialog}
                      openTerms={this.state.openTerms}
                      quote={this.props.quote}
                    />
                    {/* TODO: Find out if we will ever do green plus again or if we should rip it out */}
                    {/*
                        <Grid container item xs={12} spacing={3}>
                          <GreenPlus
                            toggleGreenPlus={form.mutators.toggleGreenPlus}
                            greenPlus={this.state.greenPlus}
                            formType={this.props.formType}
                          />
                        </Grid>
                      */}

                    <SecureFixed
                      showFmb={this.showFmb()}
                      choicePriceType={this.props.choicePriceTypes[2]}
                      greenPlus={this.state.greenPlus}
                      threeYearOption={this.threeYearOption}
                      openDetails={this.state.openDetails}
                      form={form}
                      toggleDialog={this.toggleDialog}
                      quote={this.props.quote}
                    />

                    <GuaranteedFix
                      choicePriceType={this.props.choicePriceTypes[0]}
                      greenPlus={this.state.greenPlus}
                      threeYearOption={this.threeYearOption}
                    />

                    <GuaranteedIndex
                      choicePriceType={this.props.choicePriceTypes[1]}
                      greenPlus={this.state.greenPlus}
                      threeYearOption={this.threeYearOption}
                    />

                    <BlendedPrice
                      choicePriceType={this.props.choicePriceTypes[4]}
                      greenPlus={this.state.greenPlus}
                      threeYearOption={this.threeYearOption}
                      formType={this.props.formType}
                    />

                    <ManagedPrice
                      choicePriceType={this.props.choicePriceTypes[3]}
                      greenPlus={this.state.greenPlus}
                      threeYearOption={this.threeYearOption}
                      formType={this.props.formType}
                    />
                  </Grid>
                  <div>
                    <Submit
                      submitting={submitting}
                      hide={this.hideSubmit(
                        this.props.complete,
                        form.getState()
                      )}
                    >
                      Enroll
                    </Submit>
                  </div>
                </form>
              );
            }}
          </Form>
        </Grow>
        <GenerateNewQuote
          quote={this.state.formValues}
          expireDate={this.props.expireDate}
          complete={this.props.complete}
          callBack={this.props.callBack}
        />
        {this.props.emailQuote && this.state.formValues.id && (
          <EmailQuote
            quote={this.state.formValues}
            updateQuote={this.updateQuote}
            formType={this.props.formType}
          />
        )}
      </>
    );
  }
}

export default SelectPriceForm;

SelectPriceForm.propTypes = {
  expireDate: PropTypes.string.isRequired,
  emailQuote: PropTypes.bool,
  formType: PropTypes.string,
  complete: PropTypes.bool,
  openControlNumberForm: PropTypes.bool,
  callBack: PropTypes.func.isRequired,
  choicePriceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      specificLegal: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  quote: PropTypes.shape({
    updatedAt: PropTypes.string,
    id: PropTypes.number,
    greenPlus: PropTypes.bool,
    choiceQuoteNotes: PropTypes.arrayOf(PropTypes.shape({})),
    premises: PropTypes.arrayOf(PropTypes.number),
    quoteData: PropTypes.shape({
      expirationDate: PropTypes.string,
      paramType: PropTypes.string,
      selectedPrice: PropTypes.string,
      accountList: PropTypes.arrayOf(PropTypes.shape({})),
      fmb1Total: PropTypes.number,
      fmb2Total: PropTypes.number,
      fmb3Total: PropTypes.number,
      gFixed2: PropTypes.number,
      gFixed1: PropTypes.number,
      gFixed3: PropTypes.number,
      blended2: PropTypes.number,
      blended1: PropTypes.number,
      blended3: PropTypes.number,
      baseTpFuelIndex: PropTypes.number,
      baseTpFuelIndex2: PropTypes.number,
      baseTpFuelIndex3: PropTypes.number,
    }),
  }),
};

SelectPriceForm.defaultProps = {
  emailQuote: true,
  formType: 'ResCom',
  complete: false,
  openControlNumberForm: false,
  quote: {
    greenPlus: false,
    choiceQuoteNotes: [],
    quoteData: {
      accountList: [],
    },
  },
};

const GuaranteedFix = ({
  choicePriceType,
  greenPlus,
  threeYearOption,
}) => (
  <Grid container item xs={12} spacing={3}>
    <ProgramArea choicePriceType={choicePriceType} greenPlus={greenPlus}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={styles.containerRow}
      >
        <Grid item xs={12} md={4}>
          <ProgramOption
            inputFieldName="quoteData.gFixed1"
            radioFieldValue="1"
            label="One Year Price"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ProgramOption
            inputFieldName="quoteData.gFixed2"
            radioFieldValue="2"
            label="Two Year Price"
          />
        </Grid>
        {threeYearOption() && (
          <Grid item xs={12} md={4}>
            <ProgramOption
              inputFieldName="quoteData.gFixed3"
              radioFieldValue="7"
              label="Three Year Price"
            />
          </Grid>
        )}
      </Grid>
    </ProgramArea>
  </Grid>
);

const GuaranteedIndex = ({
  choicePriceType,
  greenPlus,
  threeYearOption,
}) => (
  <Grid container item xs={12} spacing={3}>
    <ProgramArea choicePriceType={choicePriceType} greenPlus={greenPlus}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={styles.containerRow}
      >
        <Grid item xs={12} md={4}>
          <ProgramOption
            inputFieldName="quoteData.baseTpFuelIndex"
            radioFieldValue="3"
            label="One Year Price"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ProgramOption
            inputFieldName="quoteData.baseTpFuelIndex2"
            radioFieldValue="4"
            label="Two Year Price"
          />
        </Grid>
        {threeYearOption() && (
          <Grid item xs={12} md={4}>
            <ProgramOption
              inputFieldName="quoteData.baseTpFuelIndex3"
              radioFieldValue="8"
              label="Three Year Price"
            />
          </Grid>
        )}
      </Grid>
    </ProgramArea>
  </Grid>
);

const SecureFixed = ({
  choicePriceType,
  greenPlus,
  threeYearOption,
  openDetails,
  form,
  toggleDialog,
  quote,
  showFmb,
}) => {
  if (!showFmb) return null;

  return (
    <Grid container item xs={12} spacing={3}>
      <ProgramArea choicePriceType={choicePriceType} greenPlus={greenPlus}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={styles.containerRow}
        >
          <Grid item xs={12} md={4}>
            <ProgramOption
              inputFieldName="quoteData.fmb1Total"
              disabled
              radioFieldValue="5"
              label="One Year Price"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProgramOption
              inputFieldName="quoteData.fmb2Total"
              disabled
              radioFieldValue="6"
              label="Two Year Price"
            />
          </Grid>
          {threeYearOption() && (
            <Grid item xs={12} md={4}>
              <ProgramOption
                inputFieldName="quoteData.fmb3Total"
                disabled
                radioFieldValue="9"
                label="Three Year Price"
              />
            </Grid>
          )}
        </Grid>
        <Grid item className={styles.secureFixedDetails}>
          <div className={styles.secureFixDescription}>
            The Secure Fixed Price reflect the subtotal for all selected
            accounts.
          </div>
          <DetailedReport
            open={openDetails}
            toggleDialog={() => toggleDialog('openDetails')}
            setTotal={form.mutators.setTotals}
            formState={{quoteForm: {values: form.getState().values}}}
            accountList={quote.quoteData.accountList}
          />
        </Grid>
      </ProgramArea>
    </Grid>
  );
};

const ManagedPrice = ({
  choicePriceType,
  greenPlus,
  threeYearOption,
  formType,
}) => {
  if (formType !== 'AGR') return null;

  return (
    <Grid container item xs={12} spacing={3}>
      <ProgramArea choicePriceType={choicePriceType} greenPlus={greenPlus}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={styles.containerRow}
        >
          <Grid item xs={12} md={4}>
            <ProgramOption
              inputFieldName="quoteData.managedPrice"
              radioFieldValue="10"
              label="One Year Price"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ProgramOption
              inputFieldName="quoteData.managedPrice"
              radioFieldValue="11"
              label="Two Year Price"
            />
          </Grid>
          {threeYearOption() && (
            <Grid item xs={12} md={4}>
              <ProgramOption
                inputFieldName="quoteData.managedPrice"
                radioFieldValue="12"
                label="Three Year Price"
              />
            </Grid>
          )}
        </Grid>
      </ProgramArea>
    </Grid>
  );
  };

  const BlendedPrice = ({
    choicePriceType,
    greenPlus,
    threeYearOption,
    formType,
  }) => {
    if (formType !== 'AGR') return null;

    return (
      <Grid container item xs={12} spacing={3}>
        <ProgramArea choicePriceType={choicePriceType} greenPlus={greenPlus}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={styles.containerRow}
          >
            <Grid item xs={12} md={4}>
              <ProgramOption
                inputFieldName="quoteData.blended1"
                radioFieldValue="13"
                label="One Year Price"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProgramOption
                inputFieldName="quoteData.blended2"
                radioFieldValue="14"
                label="Two Year Price"
              />
            </Grid>
            {threeYearOption() && (
              <Grid item xs={12} md={4}>
                <ProgramOption
                  inputFieldName="quoteData.blended3"
                  radioFieldValue="15"
                  label="Three Year Price"
                />
              </Grid>
            )}
          </Grid>
        </ProgramArea>
      </Grid>
    );  
};
