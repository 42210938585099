import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import InputFieldArray from 'sharedComponents/ReduxForms/InputFieldArray/InputFieldArray';
import { FieldArray, stopAsyncValidation } from 'redux-form';
import _debounce from 'lodash.debounce';
import styles from './DthFieldCell.module.scss';

export class DthFieldCell extends Component {
  // eslint-disable-next-line react/sort-comp
  sortPaths = () => {
    if (
      this.props.formState.PathForm.values
      && this.props.formState.PathForm.values[this.props.row.name]
    ) {
      return this.props.formState.PathForm.values[this.props.row.name]
        .sort((a, b) => a.groupId - b.groupId);
    }
    return [];
  };

  updatePathDth = async (parts, formState) => {
    const path = formState.PathForm.values[parts[0]][parts[1]];
    await this.props.editPathBatch([Object.assign(path, { dth: parseInt(path.dth, 10) })]);
  };

  setError = async (parts, formState, errorMsg = 'check values') => {
    const { dispatch } = this.props;
    const form = formState.PathForm || { asyncErrors: {} };
    const asyncErrors = form.asyncErrors || {};
    const dayErrors = asyncErrors[parts[0]] || [];
    dayErrors[parts[1]] = { [parts[2]]: errorMsg };
    const errors = Object.assign(asyncErrors, { [parts[0]]: dayErrors });
    await dispatch(stopAsyncValidation('PathForm', errors)); /* pass in async error directly! */
  };

  checkIfValid = async () => {
    const { formState } = this.props;
    const parts = formState.PathForm.active.replace(/\]/g, '').replace(/\[/g, ' ').split(' ');
    const cellValue = formState.PathForm.values[parts[0]][parts[1]][parts[2]];

    if (!cellValue) {
      await this.setError(parts, formState, 'this input requires a number value');
      return;
    }

    try {
      await this.updatePathDth(parts, formState);
    } catch (error) {
      await this.setError(parts, formState, error.toString());
    }
  }

  debounceValidate = _debounce(this.checkIfValid, 100);

  render() {
    return (
      <>
        {this.props.showVolumes && this.sortPaths().map((r, i) => (
          <Fragment key={i}>
            {!r.archived && (
              <TableCell align="center" colSpan={1}>
                <FieldArray
                  disabled={this.props.disabled}
                  min="0"
                  onChange={this.debounceValidate}
                  classes={styles}
                  component={InputFieldArray}
                  smallInput={true}
                  type="number"
                  name={this.props.row.name}
                  objKey="dth"
                  label="dth"
                  index={i}
                />
              </TableCell>
            )}
          </Fragment>
        ))}
      </>
    );
  }
}

export default DthFieldCell;

DthFieldCell.propTypes = {
  disabled: PropTypes.bool.isRequired,
  formState: PropTypes.shape({
    PathForm: PropTypes.shape({
      active: PropTypes.string,
      values: PropTypes.shape(),
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  editPathBatch: PropTypes.func.isRequired,
  showVolumes: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

DthFieldCell.defaultProps = {
  formState: {
    PathForm: {
      values: {},
    },
  },
};
