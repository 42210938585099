import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import notifyBannerAction from 'actions/NotifyBannerAction';
import {
  NetworkLocked,
  LockOpen,
  Lock,
} from '@material-ui/icons';
import {Tooltip, Avatar} from '@material-ui/core';
import styles from './LockPathsByPipeline.module.scss';
import {userId} from 'auth';

export class LockPathsByPipeline extends Component {
  state = {
    userLock: null,
    lock: false,
  }

  async componentDidUpdate(prevProps) {
    if (
      !this.props.disabled
      && this.props.location.pipelineId
      && prevProps.location.pipelineId !== this.props.location.pipelineId
    ) {
      let lock = await this.checkForLock();

      if (lock.userId) {
        return this.lockPathsByUserId(lock);
      }

      lock = await this.createContentLock();
      return this.lockPathsByUserId(lock);
    }
    return null;
  }

  lockPathsByUserId = (userLock = {}) => {
    if (userLock.user.firstName) {
      this.setState({userLock: userLock.user});
    }
    return this.props.lockPaths(userLock.userId);
  }

  dispatchBanner = user => this.props.dispatch(notifyBannerAction({
    message: `Paths Already Locked by ${user.firstName} ${user.lastName}`,
    showBanner: true,
    error: true,
  }))

  checkForLock = async () => {
    const query = {
      query: `{ contentLock(
        resourceType: "PathsPipeline"
        resourceId: ${this.props.location.pipelineId}
      ){
        userId
        user {
          id
          firstName
          lastName
        }
      }
    }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      const contentLock = res.data.data.contentLock || {};
      return contentLock;
    } catch (error) {
      return this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
  };

  createContentLock = async () => {
    const query = {
      query: `mutation {
        createContentLock (
          resourceType: "PathsPipeline",
          resourceId: ${this.props.location.pipelineId},
        ) {
          userId
          user {
            id
            firstName
            lastName
          }
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      const createContentLock = res.data.data.createContentLock || {};
      this.setState({lock: false});
      return createContentLock;
    } catch (error) {
      return this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
  }

  deleteContentLocks = async () => {
    const query = {
      query: `mutation {
        deleteContentLocks (
          resourceType: "PathsPipeline",
          resourceId: ${this.props.location.pipelineId},
          userId: ${userId},
        ) {
          userId
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      const {deleteContentLocks} = res.data.data;
      this.setState({lock: true});
      return deleteContentLocks;
    } catch (error) {
      return this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
  }

  lockOrDelete = async () => {
    const alreadyLocked = await this.checkForLock();

    let userLock;
    if (!alreadyLocked.user) {
      userLock = await this.createContentLock();
      return this.lockPathsByUserId(userLock);
    }

    if (alreadyLocked.user && alreadyLocked.user.id === userId) {
      userLock = await this.deleteContentLocks();
      return userLock;
    }

    this.dispatchBanner(alreadyLocked.user);
    return this.lockPathsByUserId(alreadyLocked);
  };

  render() {
    if (!this.props.location.pipelineId || this.props.disabled) return null;

    return (
      <>
        {this.props.locked && (
          <Tooltip title={`
            Last Locked By: ${this.state.userLock.firstName} ${this.state.userLock.lastName}
            click to check if unlocked
          `}
          >
            <Avatar
              className={styles.onClick}
              onClick={this.lockOrDelete}
            >
              <NetworkLocked />
            </Avatar>
          </Tooltip>
        )}
        {!this.props.locked && (
          <Tooltip title={this.state.lock ? 'lock' : 'unlock'}>
            {this.state.lock ? (
              <Avatar
                className={styles.onClick}
                onClick={this.lockOrDelete}
              >
                <LockOpen />
              </Avatar>
            ) : (
              <Avatar
                className={styles.onClick}
                onClick={this.lockOrDelete}
              >
                <Lock />
              </Avatar>
            )}
          </Tooltip>
        )}
      </>
    );
  }
}

export default LockPathsByPipeline;

LockPathsByPipeline.propTypes = {
  dispatch: PropTypes.func.isRequired,
  locked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  lockPaths: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pipelineId: PropTypes.number,
  }),
};

LockPathsByPipeline.defaultProps = {
  location: {
    pipelineId: null,
  },
};
