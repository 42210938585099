import React from 'react';
import PropTypes from 'prop-types';
import MuiTablePagination from 'sharedComponents/MuiTablePagination/MuiTablePagination';
import axios from 'axios';
import jsonToCsv from 'utils/jsonToCsv';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import styles from './PromoCodes.module.scss';

export const promoCodeChoiceSelection = async (id) => {
  let res;
  try {
    const queryString = {
      query: `query {
          choicePromoCodeChoiceSelections(id: "${id}") {
            id,
            verificationId,
            name,
            createdAt,
            quoteId,
            choicePriceTypeId,
            quotedPrice,
            controlNumber,
            confirmNumber,
            posted,
            sentToBhe,
            emailAddress,
            emailDate,
            serviceState,
            divisionRegion,
            billClass,
            accountNum,
            progYear,
            term,
            transportCostUnit,
            transportCostTotal,
            margin,
            bheConfirmCode,
            totalPrice,
            servicePointId,
            documentUrl,
            delegationAgreementUrl,
            choicePromoCodeId,
            promoCodeExported,
            choicePriceType { name },
          }
        }`,
    };

    res = await axios.post('/graphql/', queryString);
  } catch (error) {
    return error;
  }

  if (res.data.errors && res.data.errors.length > 0) {
    return res.data.errors[0].message;
  }
  const selections = res.data.data.choicePromoCodeChoiceSelections;
  if (selections.length > 0) {
    const csv = jsonToCsv(selections);
    const csvURL = window.URL.createObjectURL(csv);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `promocode-${id}-choiceselections.csv`);
    tempLink.click();
    return tempLink.remove();
  }
  // eslint-disable-next-line no-alert
  return window.alert('No Choice Selections made with that promocode');
};

const PromoCodes = ({
  promoCodes,
  toggleDialog,
}) => {
  const columns = [{
    key: 'promoCode',
    label: 'promo code',
    minWidth: 25,
    headerFormat: () => (
      <Tooltip title="Click To Create new Promo Code" aria-label="add">
        <Button className="HeaderBtn" onClick={() => toggleDialog({ active: true })}> Promo Code </Button>
      </Tooltip>
    ),
    format: (value, row) => (
      <Button className="PromoCodeBtn" onClick={() => toggleDialog(row)}>
        {value || ''}
      </Button>
    ),
  },
  { key: 'description', label: 'description', minWidth: 100 },
  { key: 'discount', label: 'discount', minWidth: 25 },
  {
    key: 'active',
    label: 'active',
    minWidth: 25,
    format: value => (
      <Switch checked={value} />
    ),
  },
  { key: 'signUpLimit', label: 'sign up limit', minWidth: 25 },
  {
    key: 'id',
    label: 'id',
    minWidth: 25,
    format: value => (
      <Button className="DownloadSelections" onClick={() => promoCodeChoiceSelection(value)}>
        {value || ''}
      </Button>
    ),
  },
  ];

  return (
    <>
      {promoCodes.length > 0 && (
        <Card className={styles.paper}>
          <MuiTablePagination
            columns={columns}
            rows={promoCodes}
          />
        </Card>
      )}
    </>
  );
};

export default PromoCodes;

PromoCodes.propTypes = {
  promoCodes: PropTypes.arrayOf(PropTypes.shape()),
  toggleDialog: PropTypes.func.isRequired,
};

PromoCodes.defaultProps = {
  promoCodes: [],
};
