import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Choice from "components/Nav/components/MuiToolbar/components/AppMenu/components/GeneralMenu/components/Choice/Choice";
import PropTypes from "prop-types";
import { Component } from "react";
import { withRouter } from "react-router-dom";
import Pathing from "./Pathing";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = {
  nested: { paddingLeft: 15 },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

export class AppMenu extends Component {
  state = { left: false };

  toggleDrawer = () => {
    this.setState((oldState) => ({
      left: !oldState.left,
    }));
  };

  reactChangeRoute = (route = "") => {
    if (route === "Welcome") this.props.history.push("/");
    else this.props.history.push(`/${route}`);
    this.setState({ left: false });
  };

  renderNav = () => {
    return (
      <>
        <ListItem
          button
          onClick={() => {
            this.reactChangeRoute("nacha");
          }}
        >
          <ListItemText inset primary="Nacha" />
        </ListItem>
        <Pathing reactChangeRoute={this.reactChangeRoute} />
        <Choice
          reactChangeRoute={this.reactChangeRoute}
          role={this.props.role}
        />
      </>
    );
  };

  render() {
    return (
      <Grid container item direction="row" xs={6}>
        <Grid item xs={6}>
          <IconButton
            className={this.props.classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={this.toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Drawer open={this.state.left} onClose={this.toggleDrawer}>
            {this.renderNav()}
          </Drawer>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(AppMenu));

AppMenu.propTypes = {
  appInfo: PropTypes.shape({}).isRequired,
  handleOpen: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.shape({
    menuButton: PropTypes.string,
    button: PropTypes.string,
  }).isRequired,
};
