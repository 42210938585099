import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

/**
 * Input for redux forms input field
 *
 * console shows onSubmit values
 */

export const SelectField = ({
  classes,
  options,
  displayKey,
  disabled,
  onChangeCallBack,
  width,
  margin,
  field: {
    input,
    name,
    label,
    meta: { error, touched },
  },
}) => {
  const [state, setState] = useState({ labelWidth: 0 });
  const inputLabelRef = useRef(null);
  const hasError = error && touched;

  const labelColor = () => {
    let style = {};
    if (hasError) {
      style = { color: 'red' };
    }
    return style;
  };

  /* istanbul ignore next */
  useEffect(() => {
    const findNode = findDOMNode(inputLabelRef.current); // eslint-disable-line
    setState({
      labelWidth: findNode ? findNode.offsetWidth : 0,
    });
  }, []);

  return (
    <FormControl
      variant="outlined"
      className={classes.selectField}
      style={{ width, margin }}
    >
      <InputLabel
        style={labelColor()}
        htmlFor="select-component-outlined"
        ref={inputLabelRef}
      >
        {label}
      </InputLabel>
      <Select
        id="select"
        {...input}
        onChange={e => input.onChange(onChangeCallBack(e.target.value))}
        input={(
          <OutlinedInput
            disabled={disabled}
            labelWidth={state.labelWidth || 0}
            name={name}
            error={hasError}
          />
        )}
      >
        {options.map((item, i) => (
          <MenuItem key={i} value={item}>
            <option value={item}>
              {item instanceof Object ? item[displayKey] : item}
            </option>
          </MenuItem>
        ))}
      </Select>
      {error && (<FormHelperText id="component-error-text">{error}</FormHelperText>)}
    </FormControl>
  );
};

export default SelectField;

SelectField.propTypes = {
  /** options for select */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({}),
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  /** field props for input this is passed from FinalForms Field Component */
  field: PropTypes.shape({
    /** name of input */
    name: PropTypes.string,
    /** label of input */
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func,
      /** placeholder of input */
      placeholder: PropTypes.string,
      /** type of the input ex ( text, number, date) */
      type: PropTypes.string,

      /** Value of input */
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({}),
      ]),
    }),
    /** metadata regarding input state */
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  }),
  /** width for your input */
  width: PropTypes.string,
  /** margin for your input */
  margin: PropTypes.string,
  /**
   *  valueCallBack return a new value to dropdown
   * as to be a pure function with out any async actions
   */
  onChangeCallBack: PropTypes.func,
  /** displayKey if array of objects */
  displayKey: PropTypes.string,
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    selectField: PropTypes.string,
  }),
  /** disabled input */
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  field: {
    input: {
      placeholder: '',
      type: 'text',
      value: undefined,
      name: '',
      label: '',
    },
  },
  width: '100%',
  margin: '10px 0px 10px 0px',
  onChangeCallBack: item => item,
  displayKey: '',
  classes: {},
  disabled: false,
};
