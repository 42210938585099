/* eslint-disable no-restricted-globals */
/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import {
  Form,
  MuiInput,
  MuiSubmit,
  MuiSelect,
} from 'rff-wrapper';
import arrayMutators from 'final-form-arrays';
import graphqlCall from 'utils/graphqlCall';
import graphqlObj from 'utils/graphqlObj';
import { FORM_ERROR } from 'final-form';
import ChoiceConfirmCodeArrayField from './components/ChoiceConfirmCodeArrayField/ChoiceConfirmCodeArrayField';

export const formValidators = (values) => {
  const errors = {
    divisionRegion: values.divisionRegion ? undefined : 'Required',
    name: values.name ? undefined : 'Required',
    choiceConfirmationCodes: [],
  };
  const codeErrors = [];
  const codesArr = values.choiceConfirmationCodes.map(item => item.code);

  codesArr.some((item, i) => {
    if (codesArr[i] === '') {
      errors.choiceConfirmationCodes[i] = { code: 'Required' };
      return undefined;
    }
    if (isNaN(parseInt(codesArr[i], 10))) {
      errors.choiceConfirmationCodes[i] = { code: 'Invalid Input. Must be a number.' };
      return undefined;
    }
    if (
      codesArr[i] && values.divisionRegion === 'NEBRASKA'
      || codesArr[i] && values.divisionRegion === 'CENTRAL'
      || codesArr[i] && values.divisionRegion === 'WESTERN'
    ) {
      if (parseInt(codesArr[i], 10) < 58000 || parseInt(codesArr[i], 10) > 60000) {
        errors.choiceConfirmationCodes[i] = { code: 'NEBRASKA Code Must Be In Range Of 58000 - 60000' };
      }
    }
    if (
      codesArr[i]
      && values.divisionRegion === 'CASPER'
      || values.divisionRegion === 'GILLETTE'
      || values.divisionRegion === 'TORRINGTON'
    ) {
      if (parseInt(codesArr[i], 10) < 88000 || parseInt(codesArr[i], 10) > 90000) {
        errors.choiceConfirmationCodes[i] = { code: 'WYOMING Code Must Be In Range Of 88000 - 90000' };
      }
    }
    if (codesArr.indexOf(item) !== i) {
      errors.choiceConfirmationCodes[i] = { code: `No Duplicate Codes Allowed: ${item}` };
    }
    return codesArr.indexOf(item) !== i;
  });
  codeErrors.push(errors);
  return errors;
};

export const CreateConfirmationCodeGroup = ({ createCallback }) => {
  const [state, setState] = useState({ open: false });
  const toggleDialog = () => setState({ open: !state.open });

  const onSubmit = async (values) => {
    const newValue = {
      ...values,
      choiceConfirmationCodes: values.choiceConfirmationCodes.map(item => ({
        ...item, divisionRegion: values.divisionRegion,
      })),
    };

    const queryString = {
      query: `mutation {
          createChoiceConfirmationCodeGroup(group: ${graphqlObj(newValue)}) {
            id
            name
            divisionRegion
            choiceConfirmationCodes {
              id
              code
              divisionRegion
              term
              program
            }
          }
        }`,
    };
    try {
      const { createChoiceConfirmationCodeGroup } = await graphqlCall(queryString);
      createCallback(createChoiceConfirmationCodeGroup);
      toggleDialog();
      return createChoiceConfirmationCodeGroup;
    } catch (err) {
      return { [FORM_ERROR]: err.toString() };
    }
  };

  return (
    <MuiDialog
      open={state.open}
      toggleDialog={toggleDialog}
      buttonText="create confirmation code group"
    >
      <Form
        onSubmit={onSubmit}
        validate={values => formValidators(values)}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          name: '',
          choiceConfirmationCodes: [
            { term: '1', program: 'index', code: '' },
            { term: '2', program: 'index', code: '' },
            { term: '3', program: 'index', code: '' },
            { term: '1', program: 'fmb', code: '' },
            { term: '2', program: 'fmb', code: '' },
            { term: '3', program: 'fmb', code: '' },
            { term: '1', program: 'fixed', code: '' },
            { term: '2', program: 'fixed', code: '' },
            { term: '3', program: 'fixed', code: '' },
          ],
        }}
        render={formProps => (
          <form onSubmit={formProps.handleSubmit}>
            <MuiInput name="name" label="Group Name" fullWidth size="small" />
            <MuiSelect
              name="divisionRegion"
              label="Select Division Region"
              options={['CASPER', 'GILLETTE', 'TORRINGTON', 'WESTERN', 'CENTRAL', 'NEBRASKA']}
              size="small"
              fullWidth
            />
            <ChoiceConfirmCodeArrayField />
            <MuiSubmit />
            {formProps.submitError && <div style={{ color: 'red' }}>{formProps.submitError}</div>}
          </form>
        )}
      />
    </MuiDialog>
  );
};
export default CreateConfirmationCodeGroup;

CreateConfirmationCodeGroup.propTypes = {
  createCallback: PropTypes.func.isRequired,
};
