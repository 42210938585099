import React from 'react';
import PropTypes from 'prop-types';
import Refresh from '@material-ui/icons/Refresh';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './RefreshSummary.module.scss';

export const RefreshSummary = ({ summaryViewCallBack }) => (
  <Tooltip title="refresh summary" aria-label="add">
    <Avatar
      className={styles.onClick}
      onClick={summaryViewCallBack}
    >
      <Refresh />
    </Avatar>
  </Tooltip>
);

export default RefreshSummary;

RefreshSummary.propTypes = {
  summaryViewCallBack: PropTypes.func.isRequired,
};

RefreshSummary.defaultProps = {};
