import React, { Component } from 'react';
import SelectField from 'sharedComponents/ReduxForms/SelectField/SelectField';
import Input from 'sharedComponents/ReduxForms/Input/Input';
import { components } from 'sharedComponents/DocuSignRedirect/DocuSignRedirect';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import ArrayProp from './components/ArrayProp/ArrayProp';
import styles from './PropsForm.module.scss';

/*
  takes defaultProps from DocuSignRedirect exported components
  to make a form from the selected component defaultProps to CRUD
  DocuSignRedirect.components props forms values
*/
export class PropsForm extends Component {
  constructor(props) {
    super(props);
    const options = [];
    Object.keys(components).forEach((key) => {
      options.push({
        component: key,
        ...components[key].defaultProps,
      });
    });
    this.state = { options };
  }

  async componentDidMount() {
    if (this.props.document.component) {
      const selectedComponent = this.state.options
        .filter(option => option.component === this.props.document.component)[0];

      const groupProps = Object.assign(selectedComponent, this.props.document.props);

      await this.props
        .mapValuesToForm(this.props.document.props, this.props.form, groupProps);
    }
  }

  async componentDidUpdate(prevProps) {
    const newForm = this.props.formState[this.props.form] || {};
    const oldForm = prevProps.formState[this.props.form] || {};
    const newValues = newForm.values || {};
    const oldValues = oldForm.values || {};

    if (
      oldValues.selectedComponent !== newValues.selectedComponent
      && newValues.selectedComponent
      && newValues.selectedComponent.component
      && oldValues.component !== newValues.selectedComponent.component
    ) {
      await this.props.mapValuesToForm(newValues.selectedComponent, this.props.form);
    }
  }

  setProps = () => {
    const { values } = this.props.formState[this.props.form];
    const { selectedComponent, props } = values;

    if (values.component !== selectedComponent.component) {
      return selectedComponent;
    }
    if (Object.entries(this.props.document.props).length !== 0) {
      return props || this.props.document.props;
    }
    return Object.entries(props || {}).length !== 0 ? props : selectedComponent;
  }

  renderProps = () => {
    const props = this.setProps();
    return Object.entries(props)
      .map((prop, index) => {
        const type = typeof prop[1];
        if (prop[0] !== 'component' && (type === 'string' || type === 'number')) {
          return (
            <Field
              key={`${prop[0]}-${index}`}
              component={Input}
              classes={styles}
              label={prop[0]}
              type={type === 'string' ? 'text' : 'number'}
              name={`props.${prop[0]}`}
            />
          );
        }
        if (Array.isArray(prop[1])) {
          return (
            <ArrayProp
              document={this.props.document}
              key={`props.${prop[0]}-${index}`}
              arrayPropkey={prop[0]}
              value={prop[1].filter(item => item !== null)}
              index={index}
              changeIndex={this.props.changeIndex}
              form={this.props.form}
            />
          );
        }
        return <div key={index} />;
      });
  }

  render() {
    return (
      <Grid container item xs={12}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Field
            name="selectedComponent"
            component={SelectField}
            options={this.state.options}
            displayKey="component"
            selectLabel="selectedComponent"
            classes={styles}
          />
        </Grid>

        {this.props.formState[this.props.form]
          && this.props.formState[this.props.form].values
          && this.props.formState[this.props.form].values.selectedComponent
          && this.renderProps()}
      </Grid>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
  };
}

export default connect(mapStateToProps)(PropsForm);

PropsForm.propTypes = {
  changeIndex: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
  document: PropTypes.shape({
    props: PropTypes.shape({}).isRequired,
    component: PropTypes.string,
  }).isRequired,
  mapValuesToForm: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    GroupPropsFrom: PropTypes.shape({
      values: PropTypes.shape({
        component: PropTypes.string,
        selectedComponent: PropTypes.shape({}),
      }),
    }),
  }),
};

PropsForm.defaultProps = {
  formState: {
    GroupPropsFrom: {
      values: {
        component: '',
      },
    },
  },
};
