import React from 'react';
import { MuiInput, RFFFieldArray } from 'rff-wrapper';
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core/';

export const ChoiceConfirmCodeArrayField = () => (
  <Grid container>
    <RFFFieldArray
      fieldArrayName="choiceConfirmationCodes"
      render={fieldArrayProps => fieldArrayProps.fields.map((name, idx) => (
        <Grid item sm={4}>
          <Card style={{ margin: '2px' }}>
            <CardContent>
              <Typography variant="h6">
                {`${fieldArrayProps.fields.value[idx].program}: ${fieldArrayProps.fields.value[idx].term} Year`}
              </Typography>
              <MuiInput
                name={`${name}.code`}
                label="Code"
                size="small"
                fullWidth
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    />
  </Grid>
);
export default ChoiceConfirmCodeArrayField;

ChoiceConfirmCodeArrayField.propTypes = {};
