import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';


export class SgCustomerVolumeDialog extends Component {
  render() {
    const {
      open,
      toggleDialog,
      sgCustomer,
    } = this.props;

    return (
      <MuiDialog
        open={open}
        toggleDialog={toggleDialog}
      >
        {open && (
        <List>
          <ListItem>
            <ListItemText
              primary="January"
              secondary={sgCustomer.janPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="February"
              secondary={sgCustomer.febPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="March"
              secondary={sgCustomer.marPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="April"
              secondary={sgCustomer.aprPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="May"
              secondary={sgCustomer.mayPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="June"
              secondary={sgCustomer.junPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="July"
              secondary={sgCustomer.julPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="August"
              secondary={sgCustomer.augPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="September"
              secondary={sgCustomer.sepPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="October"
              secondary={sgCustomer.octPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="November"
              secondary={sgCustomer.novPremUsage / 10}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="December"
              secondary={sgCustomer.decPremUsage / 10}
            />
          </ListItem>
        </List>
        )}
      </MuiDialog>
    );
  }
}

export default SgCustomerVolumeDialog;

SgCustomerVolumeDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  sgCustomer: PropTypes.shape(''),
};

SgCustomerVolumeDialog.defaultProps = '';
