import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import InputFieldArray from 'sharedComponents/FinalForm/InputFieldArray/InputFieldArray';
import ChoicePromoCodeInput from 'sharedComponents/ChoicePromoCodeInput/ChoicePromoCodeInput';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import validateEmail from 'utils/validateEmail';
import graphqlObj from 'utils/graphqlObj';
import styles from './ControlNumberTable.module.scss';

export const validate = (values) => {
  const errors = {
    accountList: [],
  };

  errors.currentEmail = validateEmail(values.currentEmail) ? undefined : 'Enter a valid email';

  if (values.accountList) {
    values.accountList.forEach((location, index) => {
      if (!location.controlId) {
        errors.accountList[index] = { controlId: 'Required' };
      } else if (!location.controlId.match(/^\d+$/)) {
        errors.accountList[index] = { controlId: 'No spaces only numbers' };
      } else {
        errors.accountList[index] = { controlId: undefined };
      }
    });
  }

  return errors;
};

export class ControlNumberTable extends Component {
  state = {
    error: null,
  };

  onSubmit = async (values) => {
    let res;
    this.setState({ error: null });
    try {
      const quote = {
        ...{
          ...this.props.quote,
          quoteData: {
            ...this.props.quote.quoteData,
            currentEmail: values.currentEmail,
            accountList: values.accountList.map(x => x),
          },
        },
      };

      delete quote.SelectedPrice;

      const query = {
        query: `mutation {
        createChoiceSelectionFromQuote(
          quote: ${graphqlObj(quote)},
          promoCode: "${values.promoCode ? values.promoCode : ''}"
        ) {
          id
          pubIndex
          premises
          updatedAt
          choicePromoCodeId
          greenPlus
          quoteData {
            managedPrice
            expirationDate
            acctNums
            selectedPrice
            baseIndex
            baseTpFuelIndex
            baseTpFuelIndex2
            baseTpFuelIndex3
            baseTpIndex
            currentEmail
            fmb1
            fmb1Total
            fmb2
            fmb2Total
            fmb3
            fmb3Total
            fpPriceYr1NoAdders
            fpPriceYr2NoAdders
            gFixed1
            gFixed2
            gFixed3
            paramType
            price
            program
            term
            transportCostTotal
            transportCostUnit
            volume
            mthlyVolume
            totalUsages
            accountList {
              accountNum
              baseIndex
              baseTpFuelIndex
              baseTpIndex
              billClass
              cogPremium
              curDbYear
              divisionRegion
              firstName
              fmb1
              fmb2
              fmb3
              fpPriceYrNoAdders1
              fpPriceYrNoAdders2
              fuelPercent
              gFixed1
              gFixed2
              gFixed3
              lastName
              premiseNumber
              result
              riskFee
              serviceState
              shellFee
              supplierFee
              transportCostTotal
              transportCostUnit
              vFixed1
              vFixed2
              volume
              status
              complete
              verificationId
              controlId
              errorCode
              formattedAddress
            }
          }
        }
      }`,
      };
      res = await axios.post('/graphql/', query);
    } catch (error) {
      return this.setState({ error });
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ error: res.data.errors[0].message });
    }
    return this.props.setQuote(res.data.data.createChoiceSelectionFromQuote);
  };

  renderPrice = (paramType = '', location) => {
    if (paramType) {
      if (paramType.includes('fmb-1')) {
        return location.fmb1;
      }
      if (paramType.includes('fmb-2')) {
        return location.fmb2;
      }
      if (paramType.includes('fmb-3')) {
        return location.fmb3;
      }
    }

    return this.props.quote.quoteData.price;
  }

  render() {
    const { quote } = this.props;
    return (
      <div className={styles.container}>
        <Form
          onSubmit={this.onSubmit}
          mutators={{ ...arrayMutators }}
          validate={validate}
          initialValues={{
            currentEmail: this.props.quote.quoteData.currentEmail,
            accountList: [...quote.quoteData.accountList],
          }}
        >
          {({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow className={`${styles.tableRow}`}>
                      <TableCell className={`${styles.tableCell}`} padding="none">Service Point ID</TableCell>
                      <TableCell className={`${styles.tableCell}`} padding="none">Program</TableCell>
                      <TableCell className={`${styles.tableCell}`} padding="none">Price</TableCell>
                      <TableCell className={`${styles.tableCell}`} padding="none">Term</TableCell>
                      <TableCell className={`${styles.tableCell}`} padding="none">Control Number</TableCell>
                      <TableCell className={`${styles.tableCell}`} padding="none">complete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="accountList">
                      {({ fields }) => fields.map((name, i) => (
                        <TableRow className={`${styles.tableRow}`} key={i}>
                          <TableCell className={`${styles.tableCell}`} padding="none">{fields.value[i].premiseNumber}</TableCell>
                          <TableCell className={`${styles.tableCell}`} padding="none">{quote.quoteData.program}</TableCell>
                          <TableCell className={`${styles.tableCell}`} padding="none">
                            {this.renderPrice(quote.quoteData.paramType, fields.value[i])}
                          </TableCell>
                          <TableCell className={`${styles.tableCell}`} padding="none">
                            {`${quote.quoteData.term} year`}
                          </TableCell>
                          <TableCell className={`${styles.tableCell}`} padding="none">
                            <InputFieldArray
                              name={name}
                              disabled={fields.value[i].complete}
                              objKey="controlId"
                              label="Control number"
                            />
                          </TableCell>
                          {fields.value[i].complete && !fields.value[i].errorCode ? (
                            <TableCell className={`${styles.tableCell} ${styles.bheComplete}`} padding="none">
                              <CheckCircleOutline />
                              <h5>{fields.value[i].status}</h5>
                            </TableCell>
                          ) : null}
                          {fields.value[i].complete && fields.value[i].errorCode ? (
                            <TableCell className={`${styles.tableCell} ${styles.bheError}`} padding="none">
                              <ErrorOutline />
                              <h5>{fields.value[i].status}</h5>
                            </TableCell>
                          ) : null}
                          {!fields.value[i].complete && fields.value[i].errorCode ? (
                            <TableCell className={`${styles.tableCell} ${styles.bheError}`} padding="none">
                              <ErrorOutline />
                              <h5>{fields.value[i].status}</h5>
                            </TableCell>
                          ) : null}
                        </TableRow>
                      ))}
                    </FieldArray>
                  </TableBody>
                </Table>
              </Paper>
              <Grid
                container
                className={styles.emailContainer}
                direction="row"
                justify="center"
                alignContent="center"
                alignItems="center"
              >
                <Field
                  name="currentEmail"
                  label="Current Email"
                  type="email"
                  validate={this.validateEmail}
                  disabled={this.props.complete}
                >
                  {field => (<Input field={field} />)}
                </Field>
              </Grid>
              <Grid
                container
                className={styles.emailContainer}
                direction="row"
                justify="center"
                alignContent="center"
                alignItems="center"
              >
                <ChoicePromoCodeInput
                  quote={this.props.quote}
                  reactFinalFormInput
                />
                {this.state.error && (<div className={styles.error}>{this.state.error}</div>)}
              </Grid>
              {this.props.complete && (
                <h2 className={styles.contactBhe}>
                  If you have questions about the status of your
                  account call black hills energy at&nbsp;
                  <a href="tel:+8888905554">888-890-5554</a>
                </h2>
              )}
              <div>
                <Submit submitting={submitting}>
                  Sign up
                </Submit>
              </div>
            </form>
          )}
        </Form>
      </div>
    );
  }
}

export default ControlNumberTable;

ControlNumberTable.propTypes = {
  complete: PropTypes.bool,
  quote: PropTypes.shape({
    quoteData: PropTypes.shape({
      program: PropTypes.string,
      paramType: PropTypes.string,
      term: PropTypes.string,
      accountList: PropTypes.arrayOf(PropTypes.shape({})),
      currentEmail: PropTypes.string,
      price: PropTypes.number,
    }),
  }),
  setQuote: PropTypes.func.isRequired,
};

ControlNumberTable.defaultProps = {
  complete: false,
  quote: {
    quoteData: {
      accountList: [],
    },
  },
};
