import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Grid from '@material-ui/core/Grid';
import DocuSignRedirectChoice from 'sharedComponents/DocuSignRedirectChoice/DocuSignRedirectChoice';

export default function OnlineDelegationAgreement({
  complete,
  quote,
}) {
  const [open, setOpen] = useState(false);

  const toggleDialog = () => {
    if (open) {
      return setOpen(false);
    }
    return setOpen(true);
  };
  const d = new Date();

  return (
    <Grid container direction="column" alignContent="center" alignItems="center">
      <Grid item>
        <h1>
          {`${d.getFullYear()}/${d.getFullYear() + 1} Pre Choice Gas Selection`}
        </h1>
      </Grid>
      <Grid item container direction="column" alignContent="center" alignItems="center">
        <h3> Clicking the button below will open up a pop up to verify your email address</h3>
      </Grid>
      <Grid item container direction="column" alignContent="center" alignItems="center">
        <h3>
          After verifying an email Click Sign DELEGATION AGREEMENT to be redirected
          to DocuSign to complete the online delegation agreement
        </h3>
      </Grid>
      <Grid item>
        <MuiDialog
          open={open}
          toggleDialog={toggleDialog}
          titleText="Verify email address"
          buttonText="Sign Delegation Agreement"
        >
          <DocuSignRedirectChoice quote={quote} disabled={complete} />
        </MuiDialog>
      </Grid>
    </Grid>
  );
}

OnlineDelegationAgreement.propTypes = {
  complete: PropTypes.bool,
  quote: PropTypes.shape({}),
};

OnlineDelegationAgreement.defaultProps = {
  complete: false,
  quote: {},
};
