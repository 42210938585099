import React, { Component } from 'react';
import PropTypes from 'prop-types';
import graphqlCall from 'utils/graphqlCall';
import graphqlObj from 'utils/graphqlObj';
import { Button } from '@material-ui/core';

export class RerunConfirmCodes extends Component {
  state = {
    isGenerating: false,
  }

  handleJobRerun = async () => {
    const { group } = this.props;
    const groupRerun = {
      id: group.id,
      name: group.name,
      divisionRegion: group.divisionRegion,
      choiceConfirmationCodes: group.choiceConfirmationCodes,
    };
    const queryString = {
      query: `mutation {
          createChoiceConfirmationCodeGroup(group: ${graphqlObj(groupRerun)}) {
            id
            name
            divisionRegion
            choiceConfirmationCodes {
              id
              code
              divisionRegion
              term
              program
            }
          }
        }`,
    };
    try {
      const { createChoiceConfirmationCodeGroup } = await graphqlCall(queryString);
      this.setState({ isGenerating: true });
      return createChoiceConfirmationCodeGroup;
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <Button
        onClick={this.handleJobRerun}
        size="small"
        disabled={this.state.isGenerating}
      >
        { this.state.isGenerating ? 'Generating Report' : 'Rerun Job' }
      </Button>
    );
  }
}

export default RerunConfirmCodes;

RerunConfirmCodes.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    divisionRegion: PropTypes.string.isRequired,
    choiceConfirmationCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};
