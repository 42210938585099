import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import SelectField from 'sharedComponents/ReduxForms/SelectField/SelectField';

export class StateSelectField extends Component {
  states = ['AK - Alaska',
    'AL - Alabama',
    'AR - Arkansas',
    'AS - American Samoa',
    'AZ - Arizona',
    'CA - California',
    'CO - Colorado',
    'CT - Connecticut',
    'DC - District of Columbia',
    'DE - Delaware',
    'FL - Florida',
    'GA - Georgia',
    'GU - Guam',
    'HI - Hawaii',
    'IA - Iowa',
    'ID - Idaho',
    'IL - Illinois',
    'IN - Indiana',
    'KS - Kansas',
    'KY - Kentucky',
    'LA - Louisiana',
    'MA - Massachusetts',
    'MD - Maryland',
    'ME - Maine',
    'MI - Michigan',
    'MN - Minnesota',
    'MO - Missouri',
    'MS - Mississippi',
    'MT - Montana',
    'NC - North Carolina',
    'ND - North Dakota',
    'NE - Nebraska',
    'NH - New Hampshire',
    'NJ - New Jersey',
    'NM - New Mexico',
    'NV - Nevada',
    'NY - New York',
    'OH - Ohio',
    'OK - Oklahoma',
    'OR - Oregon',
    'PA - Pennsylvania',
    'PR - Puerto Rico',
    'RI - Rhode Island',
    'SC - South Carolina',
    'SD - South Dakota',
    'TN - Tennessee',
    'TX - Texas',
    'UT - Utah',
    'VA - Virginia',
    'VI - Virgin Islands',
    'VT - Vermont',
    'WA - Washington',
    'WI - Wisconsin',
    'WV - West Virginia',
    'WY - Wyoming']

  render() {
    const {
      onChangeCallBack,
      displayKey,
      selectLabel,
      input,
      meta,
      classes,
      disabled,
      name,
    } = this.props;

    return (
      <Field
        {...input}
        {...meta}
        name={name}
        component={SelectField}
        options={this.states}
        onChangeCallBack={onChangeCallBack}
        displayKey={displayKey}
        selectLabel={selectLabel}
        classes={classes}
        disabled={disabled}
      />
    );
  }
}


export default StateSelectField;

StateSelectField.propTypes = {
  /** valueCallBack return a new value to dropdow has to be a
   * pure function with out any async actions */
  onChangeCallBack: PropTypes.func,
  name: PropTypes.string,
  /** displayKey if array of objects */
  displayKey: PropTypes.string,
  /** selectLabel label for your input */
  selectLabel: PropTypes.string,
  /** input for your input ;) */
  input: PropTypes.shape({}),
  /** metadata regarding input state */
  meta: PropTypes.shape({
    touched: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    error: PropTypes.string,
  }),
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  /** disabled input */
  disabled: PropTypes.bool,
};

StateSelectField.defaultProps = {
  name: 'state',
  onChangeCallBack: item => item,
  displayKey: '',
  input: {},
  meta: {
    touched: 'false',
    error: '',
    message: '',
  },
  selectLabel: '',
  classes: {},
  disabled: false,
};
