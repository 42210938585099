const host = process.env.REACT_APP_COMPANY.includes('connectenergy') ? 'connectenergy' : 'woodriverenergy';

const woodriver = {
  host,
  companyName: 'WoodRiver Energy LLC',
  phoneNumber: '18885109315',
  displayNumber: '1.888.510.9315',
  salesEmail: 'sales@woodriverenergy.com',
  bbbUrl: 'https://www.bbb.org/us/co/denver/profile/natural-gas-companies/woodriver-energy-llc-0885-90229834',
  facebookUrl: 'https://www.facebook.com/WoodRiverEnergy/',
  linkedinUrl: 'https://www.linkedin.com/company/woodriver-energy-llc/',
  googleUrl: 'https://www.google.com/search?q=WoodRiver+Energy&oq=WoodRiver+Energy&aqs=chrome.0.69i59j69i60l2j69i59j0l2.2711j0j4&sourceid=chrome&ie=UTF-8',
  accountsPayableEmail: 'ap@woodriverenergy.com',
  accountsReceivableEmail: 'AR@woodriverenergy.com',
};
const connect = {
  host,
  companyName: 'Connect Energy',
  phoneNumber: '18668202411',
  displayNumber: '1.866.820.2411',
  salesEmail: 'info@connectenergy.ca',
  accountsPayableEmail: 'info@connectenergy.ca',
  googleUrl: 'https://www.google.com/search?rlz=1C5CHFA_enUS825US825&ei=a5jfXK-VIM68sAXu-5PICg&q=connectenergy.ca&oq=connectenergy.ca&gs_l=psy-ab.3..0i5i30.11124.14939..15639...0.0..0.72.337.5......0....1..gws-wiz.......0i71j35i304i39j0i8i7i30j0i13i30.QWn5VVZKxvs',
};
const initialState = host === 'connectenergy' ? connect : woodriver;

export default function appInfo(state = initialState) {
  return state;
}
