import React from 'react';
import PropTypes from 'prop-types';
import MuiTabs from 'sharedComponents/MuiTabs/MuiTabs';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import ChoiceSelectPrice from 'sharedComponents/ChoiceSelectPrice/ChoiceSelectPrice';
import SearchForQuote from './components/SearchForQuote/SearchForQuote';
import SetUpSelection from './components/SetUpSelection/SetUpSelection';

export const NewSelection = ({
  open,
  toggleDialog,
  setQuote,
  toggleAndDisplay,
  quote,
}) => (
  <MuiDialog
    open={open}
    toggleDialog={toggleDialog}
    fullScreen
  >
    <SearchForQuote setQuote={setQuote} />
    {quote.id && (
    <MuiTabs tabLabels={['RES COM', 'AGR']}>
      <ChoiceSelectPrice
        key="0"
        quote={quote}
        complete={false}
        callBack={setQuote}
      />
      <ChoiceSelectPrice
        key="1"
        formType="AGR"
        quote={quote}
        complete={false}
        callBack={setQuote}
      />
    </MuiTabs>

    )}
    <SetUpSelection
      quote={quote}
      toggleAndDisplay={toggleAndDisplay}
    />
  </MuiDialog>
);

export default NewSelection;

NewSelection.propTypes = {
  toggleAndDisplay: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  setQuote: PropTypes.func.isRequired,
  quote: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
};

NewSelection.defaultProps = {};
