import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';
import SmallInput from 'sharedComponents/FinalForm/SmallInput/SmallInput';

/**
 * Requires FieldArray parent component from react final form
*/

export const InputFieldArray = ({
  smallInput,
  objKey,
  type,
  disabled,
  label,
  validate,
  name,
  classes,
  placeholder,
  multiline,
}) => (
  <>
    {smallInput && (
    <Field
      name={`${name}[${objKey}]`}
      disabled={disabled}
      type={type}
      placeholder={placeholder}
      validate={validate}
      label={label}
    >
      {field => <SmallInput field={field} />}
    </Field>
    )}
    {!smallInput && (
      <Field
        name={`${name}[${objKey}]`}
        placeholder={placeholder}
        type={type}
        validate={validate}
      >
        {field => (
          <Input
            disabled={disabled}
            label={label}
            field={field}
            classes={classes}
            multiline={multiline}
          />
        )}
      </Field>
    )}
  </>
);

export default InputFieldArray;

InputFieldArray.propTypes = {
  /** validate function from RFF field */
  validate: PropTypes.func,
  /** name from FieldArray fields.map function returns name[index] */
  name: PropTypes.string.isRequired,
  /** placeholder of input */
  placeholder: PropTypes.string,
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  smallInput: PropTypes.bool,
  /** objKey that you want to update within the object */
  objKey: PropTypes.string.isRequired,
  /** fields of the position of the checkbox */
  label: PropTypes.string,
  /** Boolean that defines whether or not you want to disable the checkbox */
  disabled: PropTypes.bool,
  /** type for input default is primary */
  type: PropTypes.string,
  /** MUI value to render TextArea */
  multiline: PropTypes.bool,
};

InputFieldArray.defaultProps = {
  validate: undefined,
  placeholder: '',
  classes: {},
  smallInput: false,
  label: '',
  disabled: false,
  type: 'text',
};
