import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import VolumeCell from './components/VolumeCell/VolumeCell';
import styles from './VolumeTable.module.scss';

const VolumeTable = ({volumes, expand}) => (
  <Grow in={expand}>
    <div
      className={styles.wrapper}
      style={expand ? {
        height: '100%',
        transition: 'height 2ms 2ms, opacity 600ms 0ms',
      } : {
        height: 0,
        transition: 'height 2ms 400ms, opacity 400ms 0ms',
      }}
    >
      <div>
        <h5 className={styles.accountInfo}>
          {`Account Number: ${volumes.accountNum}`}
        </h5>
        <h5 className={styles.accountInfo}>
          {`Account Name: ${volumes.firstName} ${volumes.lastName}`}
        </h5>
        <h5 className={styles.accountInfo}>
          {`Address: ${volumes.serviceAddress} ${volumes.serviceCity} ${volumes.serviceState} ${volumes.serviceZip}`}
        </h5>
      </div>
      <div>
        <h4>Aggregate Monthly Volume and Total Volume Per Therm</h4>
      </div>
      <div className={styles.usageWrapper}>
        <VolumeCell month="Jan" usage={volumes.janPremUsage / 10} />
        <VolumeCell month="Feb" usage={volumes.febPremUsage / 10} />
        <VolumeCell month="March" usage={volumes.marPremUsage / 10} />
        <VolumeCell month="April" usage={volumes.aprPremUsage / 10} />
        <VolumeCell month="May" usage={volumes.mayPremUsage / 10} />
        <VolumeCell month="June" usage={volumes.junPremUsage / 10} />
        <VolumeCell month="July" usage={volumes.julPremUsage / 10} />
        <VolumeCell month="Aug" usage={volumes.augPremUsage / 10} />
        <VolumeCell month="Sep" usage={volumes.sepPremUsage / 10} />
        <VolumeCell month="Oct" usage={volumes.octPremUsage / 10} />
        <VolumeCell month="Nov" usage={volumes.novPremUsage / 10} />
        <VolumeCell month="Dec" usage={volumes.decPremUsage / 10} />
        <VolumeCell month="Total" usage={volumes.totalUsage} />
      </div>
    </div>
  </Grow>
);

export default VolumeTable;

VolumeTable.propTypes = {
  expand: PropTypes.bool,
  volumes: PropTypes.shape({
    serviceAddress: PropTypes.string,
    serviceCity: PropTypes.string,
    serviceState: PropTypes.string,
    serviceZip: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    accountNum: PropTypes.number,
    janPremUsage: PropTypes.number,
    febPremUsage: PropTypes.number,
    marPremUsage: PropTypes.number,
    aprPremUsage: PropTypes.number,
    mayPremUsage: PropTypes.number,
    junPremUsage: PropTypes.number,
    julPremUsage: PropTypes.number,
    augPremUsage: PropTypes.number,
    sepPremUsage: PropTypes.number,
    octPremUsage: PropTypes.number,
    novPremUsage: PropTypes.number,
    decPremUsage: PropTypes.number,
    totalUsage: PropTypes.number,
  }),
};

VolumeTable.defaultProps = {
  volumes: {},
  expand: false,
};
