import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import styles from './NeedAControlNumber.module.scss';

const d = new Date();

export const NeedAControlNumber = () => (
  <Grid container direction="column" alignContent="center" alignItems="center">
    <Grid item>
      <h1>
        {`Choice Gas Selection ${d.getFullYear()}/${d.getFullYear() + 1}`}
      </h1>
    </Grid>
    <Grid item container direction="column" alignContent="center" alignItems="center">
      <h4 className={styles.subText}> Need A Control Number?</h4>
      <Button
        href="https://www.blackhillsenergy.com/app-choicegas/lookup"
        target="_blank"
        variant="outlined"
        size="medium"
        color="secondary"
        component="a"
      >
        Click Here
      </Button>
    </Grid>
    <Grid item>
      <h5>
        After entering your control # and confirm your email address
        click then enrollment button below
      </h5>
    </Grid>
  </Grid>
);

export default NeedAControlNumber;
