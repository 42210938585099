import React, { Component } from 'react';
import { Field } from 'redux-form';
import validateEmail from 'utils/validateEmail';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Input from 'sharedComponents/ReduxForms/Input/Input';
import styles from './ValidateEmail.module.scss';

export class ValidateEmail extends Component {
  static validate = (values) => {
    const errors = {};
    if (!validateEmail(values.email)) errors.email = 'Enter a valid email';
    return errors;
  };

  static initialValues = {};

  render() {
    return (
      <Card>
        <Grid container>
          <Grid container item xs={12} justify="center" alignItems="center">
            <Grid item xs={12}>
              <Field
                name="email"
                label="email"
                component={Input}
                classes={styles}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default ValidateEmail;
