import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Input from 'sharedComponents/ReduxForms/Input/Input';
import StateSelectField from 'sharedComponents/ReduxForms/StateSelectField/StateSelectField';
import Switch from 'sharedComponents/ReduxForms/Switch/Switch';
import validateZipCode from 'utils/validateZipCode';

import styles from './Ach.module.scss';

export class Ach extends Component {
  static validate = (values) => {
    const errors = {};
    const requiredFields = [
      'achBillingAddress',
      'achBillingAddressCity',
      'achBillingAddressState',
      'achNameOnAccount',
      'achBankName',
      'achAccountNumber',
      'achRoutingNumber',
      'achBankCity',
      'achBankState',
      'achAccountNumberConfirmation',
    ];

    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });

    const zipCodeError = validateZipCode(values.achBillingAddressZip);
    if (zipCodeError) {
      errors.achBillingAddressZip = zipCodeError;
    }

    if (values.achRoutingNumber && values.achRoutingNumber.length < 9) {
      errors.achRoutingNumber = 'Routing numbers are 9 digits long';
    }

    if (values.achAccountNumberConfirmation !== values.achAccountNumber) {
      errors.achAccountNumberConfirmation = 'must match ach account number';
    }

    return errors;
  };

  isExpanded = (array) => {
    if (
      this.props.formState.DocuSignRedirectForm
      && this.props.formState.DocuSignRedirectForm.values
    ) {
      if (!this.props.formState.DocuSignRedirectForm.syncErrors) return true;
      return array.every(key => this.props.formState.DocuSignRedirectForm.values[key]);
    }
    return false;
  }

  accountType = () => {
    if (
      this.props.formState.DocuSignRedirectForm
      && this.props.formState.DocuSignRedirectForm.values
    ) {
      return this.props.formState.DocuSignRedirectForm.values.checkingAccount ? 'checking account' : 'savings account';
    }
    return undefined;
  }

  static initialValues = {
    checkingAccount: true,
  };

  render() {
    return (
      <Grid container>
        <ExpansionPanel expanded={true} className={styles.panel}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Bank Billing Info</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid container item xs={12} justify="center" alignItems="center">
                <Field
                  name="achBillingAddress"
                  label="Billing Address"
                  component={Input}
                  classes={styles}
                />
              </Grid>
              <Grid container item xs={12} justify="center" alignItems="center">
                <Grid item xs={4}>
                  <Field
                    name="achBillingAddressCity"
                    label="Billing City"
                    component={Input}
                    classes={styles}
                  />
                </Grid>
                <Grid item xs={4}>
                  <StateSelectField
                    name="achBillingAddressState"
                    selectLabel="Billing State"
                    classes={styles}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name="achBillingAddressZip"
                    label="Billing zip"
                    component={Input}
                    classes={styles}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          className={styles.panel}
          expanded={this.isExpanded(['achBillingAddressZip', 'achBillingAddressState', 'achBillingAddressCity', 'achBillingAddress'])}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Bank Account Info</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={6}>
                <Field
                  name="achRoutingNumber"
                  label="Ach Routing Number"
                  component={Input}
                  classes={styles}
                />
              </Grid>
              <Grid container item xs={6}>
                <Field
                  component={Switch}
                  name="checkingAccount"
                  label={this.accountType()}
                  showChecked={false}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="achAccountNumber"
                  label="Ach Account Number"
                  component={Input}
                  classes={styles}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="achAccountNumberConfirmation"
                  label="Ach Account Number Confirmation"
                  component={Input}
                  classes={styles}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="achNameOnAccount"
                  label="Name on Account"
                  component={Input}
                  classes={styles}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          className={styles.panel}
          expanded={this.isExpanded(['achRoutingNumber', 'achAccountNumberConfirmation', 'achAccountNumber', 'achNameOnAccount'])}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Bank Info</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={12}>
                <Field
                  name="achBankName"
                  label="Bank Name"
                  component={Input}
                  classes={styles}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="achBankCity"
                  label="Bank City"
                  component={Input}
                  classes={styles}
                />
              </Grid>
              <Grid item xs={6}>
                <StateSelectField
                  name="achBankState"
                  selectLabel="Bank State"
                  classes={styles}
                />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    );
  }
}

export default Ach;

Ach.propTypes = {
  formState: PropTypes.shape({
    DocuSignRedirectForm: PropTypes.shape({
      values: PropTypes.shape({
        checkingAccount: PropTypes.bool,
      }),
      accountType: PropTypes.bool,
      syncErrors: PropTypes.shape({}),
    }),
  }),
};

Ach.defaultProps = {
  formState: {
    DocuSignRedirectForm: {
      values: {},
      accountType: true,
    },
  },
};
