/* eslint-disable radix */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import validateEmail from 'utils/validateEmail';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import ReactDropZone from 'sharedComponents/FinalForm/ReactDropZone/ReactDropZone';
import arrayMutators from 'final-form-arrays';
import CheckBox from 'sharedComponents/FinalForm/CheckBox/CheckBox';
import { Form, Field } from 'react-final-form';
import styles from './ChoiceSelectionForm.module.scss';

export class ChoiceSelectionForm extends React.Component {
  state = { error: '' };

  onSubmit = async (values) => {
    const controlNumber = values.selection.controlNumber
      ? parseInt(values.selection.controlNumber, 0) : null;

    const confirmNumber = values.selection.confirmNumber
      ? parseInt(values.selection.confirmNumber, 0) : null;

    const selection = {
      ...values.selection,
      controlNumber,
      confirmNumber,
    };
    const delegationAgreement = values.delegationAgreement.length
      ? values.delegationAgreement[0] : '';

    const queryString = {
      query: `mutation {
          updateChoiceSelection(
            choiceSelection: ${graphqlObj(selection)}
            delegationAgreement: "${delegationAgreement}"
            ) {
              id,
              verificationId,
              name,
              createdAt,
              quoteId,
              choicePriceTypeId,
              quotedPrice,
              controlNumber,
              confirmNumber,
              posted,
              sentToBhe,
              emailAddress,
              emailDate,
              serviceState,
              divisionRegion,
              billClass,
              accountNum,
              progYear,
              term,
              transportCostUnit,
              transportCostTotal,
              margin,
              bheConfirmCode,
              totalPrice,
              servicePointId,
              documentUrl,
              delegationAgreementUrl,
              promoCodeExported,
              choicePromoCodeId,
              choicePriceType { name },
           }
        }`,
    };

    let res = null;

    try {
      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      // TODO: airbrake and support email message
      return this.setState({ error: JSON.stringify(error) });
    }
    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ error: JSON.stringify(res.data.errors) });
    }
    this.setState({ error: '' });
    this.props.updateSelection(res.data.data.updateChoiceSelection);
    return this.props.toggleDialog();
  };

  required = value => (value ? undefined : 'Required');

  validateEmail = value => (validateEmail(value) ? undefined : 'Enter a valid email');

  oneDelegationAgreement = (form) => {
    const { delegationAgreement } = form.getState().values;
    const docLength = delegationAgreement.length;
    if (docLength === 0) return false;
    if (docLength > 1) {
      this.setState({ error: 'only one document is allowed to be uploaded' });
      return true;
    }
    return false;
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        mutators={{ ...arrayMutators }}
        initialValues={{
          selection: { ...this.props.row },
          delegationAgreement: [],
        }}
      >
        {({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="selection.verificationId"
              label="Verification Id"
            >
              {field => <Input field={field} />}
            </Field>
            <Field
              name="selection.controlNumber"
              label="Control Number"
              type="number"
            >
              {field => <Input field={field} width="50%" />}
            </Field>
            <Field
              name="selection.confirmNumber"
              label="Confirm Number"
            >
              {field => <Input field={field} width="50%" />}
            </Field>
            <Field
              name="selection.name"
              label="name"
            >
              {field => <Input field={field} width="50%" />}
            </Field>
            <Field
              name="selection.emailAddress"
              label="email address"
              type="email"
              validate={this.validateEmail}
            >
              {field => <Input field={field} width="50%" />}
            </Field>
            <Field
              name="selection.sentToBhe"
              label="Sent To BHE"
              type="checkbox"
            >
              {field => <CheckBox field={field} />}
            </Field>
            <Field
              name="selection.promoCodeExported"
              label="Promo Code Exported"
              type="checkbox"
            >
              {field => <CheckBox field={field} />}
            </Field>
            <Field
              name="selection.posted"
              label="posted"
              type="checkbox"
            >
              {field => <CheckBox field={field} />}
            </Field>
            <ReactDropZone name="delegationAgreement" acceptImageTypes={['application/pdf']} />
            <Submit
              submitting={submitting}
              disabled={this.oneDelegationAgreement(form)}
            >
              Update
            </Submit>
            {this.state.error && <span className={styles.error}>{this.state.error}</span>}
          </form>
        )}
      </Form>
    );
  }
}


export default ChoiceSelectionForm;

ChoiceSelectionForm.propTypes = {
  row: PropTypes.shape({}),
  updateSelection: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

ChoiceSelectionForm.defaultProps = {
  row: {},
};
