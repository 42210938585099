import React, { Component } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "react-final-form-arrays";
import InputFieldArray from "sharedComponents/FinalForm/InputFieldArray/InputFieldArray";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export class DetailTable extends Component {
  state = {};

  render() {
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Premise Number</TableCell>
              <TableCell>Bill Class</TableCell>
              <TableCell align="right">One Year Price</TableCell>
              <TableCell align="right">Two Year Price</TableCell>
              <TableCell align="right">Volume</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name="accountList">
              {({ fields }) =>
                fields.map((name, i) => (
                  <TableRow key={i}>
                    <TableCell>{fields.value[i].premiseNumber}</TableCell>
                    <TableCell>{fields.value[i].billClass}</TableCell>
                    <TableCell align="right">
                      <InputFieldArray
                        name={name}
                        type="number"
                        objKey="fmb1"
                      />
                      <span>{parseFloat(fields.value[i].fmb1).toFixed(3)}</span>
                    </TableCell>
                    <TableCell align="right">
                      <InputFieldArray
                        name={name}
                        type="number"
                        objKey="fmb2"
                      />
                      <span>{parseFloat(fields.value[i].fmb2).toFixed(3)}</span>
                    </TableCell>
                    <TableCell align="right">
                      {fields.value[i].volume}
                    </TableCell>
                  </TableRow>
                ))
              }
            </FieldArray>
            <TableRow>
              <TableCell colSpan={2}> Totals </TableCell>
              <TableCell align="right">
                {this.props.formState.quoteForm.values.quoteData.fmb1Total}
              </TableCell>
              <TableCell align="right">
                {this.props.formState.quoteForm.values.quoteData.fmb2Total}
              </TableCell>
              <TableCell align="right">
                {this.props.formState.quoteForm.values.accountList.reduce(
                  (prev, cur) => prev + parseFloat(cur.volume),
                  0,
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

export default DetailTable;

DetailTable.propTypes = {
  formState: PropTypes.shape({
    quoteForm: PropTypes.shape({
      values: PropTypes.shape({
        quoteData: PropTypes.shape({
          fmb1Total: PropTypes.number,
          fmb2Total: PropTypes.number,
        }),
        accountList: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    }),
  }),
};

DetailTable.defaultProps = {
  formState: {
    quoteForm: {
      values: {
        accountList: [],
      },
    },
  },
};
