import {userId} from 'auth';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {Field, Form} from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';

export class CreateNewNote extends Component {
  state = {}

  onSubmit = async (values) => {
    const query = {
      query: `mutation {
        upsertChoiceAccountNote (
          note: {
            note: "${values.note}"
            userId: ${userId}
            accountNum: ${this.props.accountNum}
          }
        ) {
          id
          accountNum
          note
          createdAt
          user {
           firstName
           lastName
          }
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      return this.props.callback(res.data.data.upsertChoiceAccountNote);
    } catch (error) {
      return error;
    }
  }

  render() {
    return (
      <Form onSubmit={this.onSubmit}>
        {({handleSubmit, submitting, form}) => (
          <form
            onSubmit={async (event) => {
              await handleSubmit(event);
              form.reset();
            }}
          >
            <Field
              name="note"
              label="note"
              type="text"
              validate={this.required}
            >
              {field => <Input field={field} multiline />}
            </Field>
            <Submit submitting={submitting}>
              Create Note
            </Submit>
          </form>
        )}
      </Form>
    );
  }
}

export default CreateNewNote;

CreateNewNote.propTypes = {
  accountNum: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
};

CreateNewNote.defaultProps = {};
