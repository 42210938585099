import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import InputFieldArray from 'sharedComponents/FinalForm/InputFieldArray/InputFieldArray';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import notifyBannerAction from 'actions/NotifyBannerAction';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

export class ReviewPaths extends Component {
  createPaths = async (paths = []) => {
    try {
      const queryString = {
        query: `mutation {
          createPaths(paths: ${graphqlObj(paths)}) {
            id
            gasDay
            receiptLocationId
            deliveryLocationId
            pipelineId
            groupId
            transportId
            fuelPercent
            companyId
            dth
            archived
          }
        }`,
      };

      const res = await axios.post('/graphql/', queryString);
      return res.data.data.createPaths;
    } catch (error) {
      this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
    return null;
  }

  copyPaths = values => (
    values.paths.map((path, i) => {
      const newPath = { ...path };
      delete newPath.deliveryLocation;
      delete newPath.receiptLocation;
      return Object.assign(newPath, {
        dth: parseInt(newPath.dth, 10),
        deliveryLocationId: values.paths[i].deliveryLocation.id,
      });
    })
  );

  onSubmit = async (values = {}) => {
    const paths = this.copyPaths(values);
    await this.createPaths(paths);
    this.props.pathViewCallBack();
    this.props.toggleDialog();
  };

  render() {
    const {
      open,
      toggleDialog,
      paths,
    } = this.props;

    return (
      <MuiDialog
        open={open}
        toggleDialog={toggleDialog}
      >
        {open && (
        <Form
          onSubmit={this.onSubmit}
          initialValues={{ paths }}
          mutators={{ ...arrayMutators }}
        >
          {({ handleSubmit, submitting, form }) => (
            <form onSubmit={handleSubmit}>
              <Table className="" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Date</TableCell>
                    <TableCell align="center">dth</TableCell>
                    <TableCell align="center">receiptLocation</TableCell>
                    <TableCell align="center">deliveryLocation</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="paths">
                    {({ fields }) => fields.map((name, i) => (
                      <TableRow key={i}>
                        <TableCell align="center">{fields.value[i].gasDay}</TableCell>
                        <TableCell align="center">
                          <InputFieldArray
                            name={name}
                            smallInput={true}
                            type="number"
                            objKey="dth"
                            label="dth"
                          />
                        </TableCell>
                        <TableCell align="center">{fields.value[i].receiptLocation.name}</TableCell>
                        <TableCell align="center">{fields.value[i].deliveryLocation.name}</TableCell>
                      </TableRow>
                    ))}
                  </FieldArray>
                </TableBody>
              </Table>

              <div>
                <Submit submitting={submitting}>
                  create paths
                </Submit>
              </div>
            </form>
          )}
        </Form>
        )}
      </MuiDialog>
    );
  }
}

export default ReviewPaths;

ReviewPaths.propTypes = {
  dispatch: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  paths: PropTypes.arrayOf(PropTypes.shape({})),
  pathViewCallBack: PropTypes.func.isRequired,
};

ReviewPaths.defaultProps = {
  paths: [],
};
