import React from 'react';
import PropTypes from 'prop-types';

/**
 * Requires FieldArray parent component from react final form
*/


export const SmallInput = ({
  field: {
    input,
    meta: { error, touched },
  },
}) => (
  <div>
    <input {...input} />
    {error && touched && <span>{error}</span>}
  </div>
);

export default SmallInput;

SmallInput.propTypes = {
  field: PropTypes.shape({
    input: PropTypes.shape({
      /** placeholder of input */
      placeholder: PropTypes.string,
      /** name of input */
      name: PropTypes.string,
      /** type of the input ex ( text, number, date) */
      type: PropTypes.string,
      /** label of input */
      label: PropTypes.string,
      /** Value of input */
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    /** metadata regarding input state */
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  }).isRequired,
};

SmallInput.defaultProps = {};
