import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import {Component} from 'react';
import SwipeableViews from 'react-swipeable-views';
import TabContainer from 'sharedComponents/TabContainer/TabContainer';
import ChoiceQuotes from './components/ChoiceQuotes/ChoiceQuotes';
import LocationForm from './components/LocationForm/LocationForm';

class ChoiceTabs extends Component {
  state = {
    tabIndex: 0,
    disabledTabs: false,
    submitted: false,
  };

  componentDidMount() {
    const {resComSearchResults, agrSearchResults} = this.props;

    if (resComSearchResults.length === 0 && agrSearchResults.length > 0) {
      this.setState({tabIndex: 1});
    }
  }

  handleChangeIndex = (event, tabIndex) => {
    this.setState({tabIndex});
  };

  renderQuote = () => {
    this.setState({disabledTabs: true, submitted: true});
  };

  render() {
    const {
      resComSearchResults,
      agrSearchResults,
      formState,
    } = this.props;

    return (
      <div ref={this.props.scrollRef}>
        <AppBar position="static">
          <Tabs
            value={this.state.tabIndex}
            onChange={this.handleChangeIndex}
            scrollable="true"
            scrollButtons="auto"
            color="primary"
          // className={styles.tabBar}
          >
            <Tab
              label="Residential/Commercial"
              disabled={this.state.disabledTabs}
            />
            <Tab label="Agricultural" disabled={this.state.disabledTabs} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={this.state.tabIndex}
          onChangeIndex={this.handleChangeIndex}
        >
          <TabContainer>
            {this.state.tabIndex === 0 && (
              <LocationForm
                quote={this.props.quote}
                preloadQuoteId={this.props.preloadQuoteId}
                submitted={this.state.submitted}
                formName="ResCom"
                setQuote={this.props.setQuote}
                searchResults={resComSearchResults}
              />
            )}
            {this.props.quote.id && this.state.tabIndex === 0 && (
              <ChoiceQuotes
                formType="ResCom"
                resetForm={this.props.resetForm}
                choicePriceTypes={this.props.choicePriceTypes}
                complete={this.props.complete}
                quote={this.props.quote}
                formState={formState}
                setQuote={this.props.setQuote}
                openControlNumberForm={this.props.openControlNumberForm}
                enrolledCompleteDialog={this.props.enrolledCompleteDialog}
                toggleEnrolledCompleteDialog={
                  this.props.toggleEnrolledCompleteDialog
                }
              />
            )}
          </TabContainer>
          <TabContainer>
            {/* <h2>Choice for agriculture has closed.</h2> */}
            {this.state.tabIndex === 1 && (
              <LocationForm
                quote={this.props.quote}
                preloadQuoteId={this.props.preloadQuoteId}
                submitted={this.state.submitted}
                formName="AGR"
                setQuote={this.props.setQuote}
                searchResults={agrSearchResults}
              />
            )}

            {this.props.quote.id && this.state.tabIndex === 1 && (
              <ChoiceQuotes
                formType="AGR"
                resetForm={this.props.resetForm}
                choicePriceTypes={this.props.choicePriceTypes}
                complete={this.props.complete}
                quote={this.props.quote}
                formState={formState}
                setQuote={this.props.setQuote}
                openControlNumberForm={this.props.openControlNumberForm}
                enrolledCompleteDialog={this.props.enrolledCompleteDialog}
                toggleEnrolledCompleteDialog={
                  this.props.toggleEnrolledCompleteDialog
                }
              />
            )}
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

export default ChoiceTabs;

ChoiceTabs.propTypes = {
  resetForm: PropTypes.func.isRequired,
  preloadQuoteId: PropTypes.string.isRequired,
  choicePriceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enrolledCompleteDialog: PropTypes.bool,
  toggleEnrolledCompleteDialog: PropTypes.func.isRequired,
  setQuote: PropTypes.func.isRequired,
  openControlNumberForm: PropTypes.bool,
  complete: PropTypes.bool,
  agrSearchResults: PropTypes.arrayOf(PropTypes.shape({})),
  resComSearchResults: PropTypes.arrayOf(PropTypes.shape({})),
  formState: PropTypes.shape({
    Agr: PropTypes.shape({}),
    ResCom: PropTypes.shape({}),
  }).isRequired,
  quote: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  scrollRef: PropTypes.shape({}).isRequired,
};

ChoiceTabs.defaultProps = {
  enrolledCompleteDialog: false,
  openControlNumberForm: false,
  complete: false,
  agrSearchResults: [],
  resComSearchResults: [],
  quote: {},
};
