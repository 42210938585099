import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import { Form, Field } from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';

export const onSubmit = async (values, quoteId, setNoteCallBack, toggleDialog) => {
  const queryString = {
    query: `mutation {
      createChoiceQuoteNote(
        note: "${values.note}"
        choicePriceQuoteId: ${quoteId}
      ) {
        id
        choicePriceQuoteId
        userId
        note
        createdAt
        user {
          firstName
          lastName
        }
      }
    }`,
  };
  const req = await axios.post('/graphql/', queryString);
  toggleDialog('openCreateNote');
  setNoteCallBack(req.data.data.createChoiceQuoteNote);
};

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['note'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export const CreateNote = ({
  toggleDialog,
  open,
  quoteId,
  setNoteCallBack,
}) => (
  <MuiDialog
    open={open}
    toggleDialog={() => toggleDialog('openCreateNote')}
    titleText="New Note"
    buttonText="New Note"
  >
    <Form
      onSubmit={values => onSubmit(values, quoteId, setNoteCallBack, toggleDialog)}
      validate={validate}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="note"
              label="note"
            >
              {field => (<Input field={field} multiline />)}
            </Field>
          </div>
          <div>
            <Submit submitting={submitting}>
              Add Note
            </Submit>
          </div>
        </form>
      )}
    </Form>
  </MuiDialog>
);

export default CreateNote;

CreateNote.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  setNoteCallBack: PropTypes.func.isRequired,
  open: PropTypes.bool,
  quoteId: PropTypes.number.isRequired,
};

CreateNote.defaultProps = {
  open: false,
};
