import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import PropTypes from 'prop-types';
import {Component} from 'react';
import ChoiceSelectPrice from 'sharedComponents/ChoiceSelectPrice/ChoiceSelectPrice';
import ChoiceQuoteNotes from './components/ChoiceQuoteNotes/ChoiceQuoteNotes';
import Enroll from './components/Enroll/Enroll';

export class ChoiceQuotes extends Component {
  state = {
    openControlNumber: this.props.openControlNumberForm,
    formState: {},
  };

  async componentDidMount() {
    await this.expireDate();
  }

  expireDate = async () => {
    const query = {
      query: `{
        choiceParam(
          region: "ALL",
          billClass: "ALL",
          paramType: "expire_date"
        ) {
          effectiveDate
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
      // TODO: airbrake and support email message
      return err;
    }
    const {choiceParam} = res.data.data;
    return this.setState({
      expireDate: choiceParam.effectiveDate,
    });
  };

  toggleDialog = () =>
    this.setState((oldState) => ({
      openControlNumber: !oldState.openControlNumber,
    }));

  isComplete = () => {
    // a little crazy in here but checks props and dates to
    // see if ControlNumbersDialog should show or not
    if (this.props.complete && this.props.quote.quoteData.paramType)
      return true;
    if (
      this.state.openControlNumber &&
      this.props.quote.quoteData.paramType &&
      this.state.formState.quoteForm
    )
      return true;
    return false;
  };

  setNoteCallBack = (note) => {
    const quote = {...this.props.quote};
    if (quote.choiceQuoteNotes) quote.choiceQuoteNotes.push(note);
    else quote.choiceQuoteNotes = [note];
    this.props.setQuote(quote);
  };

  callBack = (quote) => {
    this.setState({
      openControlNumber: true,
      formState: {quoteForm: {values: quote}},
    });
    this.props.setQuote(quote);
  };

  render() {
    return (
      <>
        <Grid container direction="column" justify="center" alignItems="center">
          <ChoiceQuoteNotes
            notes={this.props.quote.choiceQuoteNotes}
            setNoteCallBack={this.setNoteCallBack}
            quoteId={this.props.quote.id}
          />
          <ChoiceSelectPrice
            formType={this.props.formType}
            quote={this.props.quote}
            complete={this.props.complete}
            callBack={this.callBack}
          />
        </Grid>
        {this.isComplete() && (
          <Enroll
            resetForm={this.props.resetForm}
            complete={this.props.complete}
            open={this.state.openControlNumber}
            toggleDialog={this.toggleDialog}
            quote={this.props.quote}
            formState={this.state.formState}
            setQuote={this.props.setQuote}
            enrolledCompleteDialog={this.props.enrolledCompleteDialog}
            toggleEnrolledCompleteDialog={
              this.props.toggleEnrolledCompleteDialog
            }
          />
        )}
      </>
    );
  }
}

export default ChoiceQuotes;

ChoiceQuotes.propTypes = {
  formType: PropTypes.string.isRequired,
  resetForm: PropTypes.func.isRequired,
  enrolledCompleteDialog: PropTypes.bool,
  toggleEnrolledCompleteDialog: PropTypes.func.isRequired,
  complete: PropTypes.bool,
  openControlNumberForm: PropTypes.bool,
  setQuote: PropTypes.func.isRequired,

  quote: PropTypes.shape({
    id: PropTypes.number,
    choiceQuoteNotes: PropTypes.arrayOf(PropTypes.shape({})),
    premises: PropTypes.arrayOf(PropTypes.number),
    quoteData: PropTypes.shape({
      expirationDate: PropTypes.string,
      paramType: PropTypes.string,
      selectedPrice: PropTypes.string,
      accountList: PropTypes.arrayOf(PropTypes.shape({})),
      fmb1Total: PropTypes.number,
      fmb2Total: PropTypes.number,
      fmb3Total: PropTypes.number,
      gFixed2: PropTypes.number,
      gFixed1: PropTypes.number,
      gFixed3: PropTypes.number,
      baseTpFuelIndex: PropTypes.number,
    }),
  }),
  formState: PropTypes.shape({
    quoteForm: PropTypes.shape({
      values: PropTypes.shape({
        accountList: PropTypes.arrayOf(PropTypes.shape({})),
        quoteData: PropTypes.shape({
          expirationDate: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
};

ChoiceQuotes.defaultProps = {
  enrolledCompleteDialog: false,
  complete: false,
  openControlNumberForm: false,
  quote: {
    choiceQuoteNotes: [],
    quoteData: {
      accountList: [],
    },
  },
};
