import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';

export const CheckBox = ({
  field,
  color,
  disabled,
}) => (
  <FormControlLabel
    control={(
      <MUICheckbox
        id={field.input.name}
        checked={field.input.value}
        color={color}
        disabled={disabled}
        {...field.input}
      />
  )}
    label={field.label}
  />
);
export default CheckBox;

CheckBox.propTypes = {
  /** fields of the position of the checkbox */
  field: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
  }).isRequired,
  /** string representing label wanted for checkbox */
  color: PropTypes.string,
  /** Boolean that defines whether or not you want to disable the checkbox */
  disabled: PropTypes.bool,
};

CheckBox.defaultProps = {
  color: 'primary',
  disabled: false,
};
