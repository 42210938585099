import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';

/**
 * Form wrapper for redux forms need to have at least one child element
 * Requires FieldArray parent component from redux-forms
 * mui checkbox requires "value" prop to be a string
 */

export const handleOnChange = (e, fields, index) => {
  if (!fields.get(index)) return fields.push(e.target.value);
  return fields.remove(index);
};

export const assignIndex = (fields, val) => {
  let index;
  fields.forEach((name, i, f) => {
    if (JSON.parse(f.get(i)).id === val.id) index = i;
  });
  return index;
};

// Requires FieldArray parent component from redux-forms
export const FieldArrayCheckbox = ({
  label,
  fields,
  val, // mui checkbox requires "value" prop to be a string
  callback,
  checked,
  color,
  disabled,
}) => {
  const checkboxValue = typeof val === 'object' ? JSON.stringify(val) : val;
  const index = assignIndex(fields, val);

  return (
    <FormControlLabel
      control={(
        <MUICheckbox
          disabled={disabled}
          checked={checked || typeof index === 'number'}
          onChange={(e) => {
            if (callback) callback(fields);
            else handleOnChange(e, fields, index);
          }}
          value={checkboxValue}
          type="checkbox"
          color={color}
        />
      )}
      label={label}
    />
  );
};

export default FieldArrayCheckbox;

FieldArrayCheckbox.propTypes = {
  /** fields of the position of the checkbox */
  fields: PropTypes.shape({}).isRequired,
  /** string representing label wanted for checkbox */
  label: PropTypes.string,
  /** val is the checkbox value to be sent across the wire */
  val: PropTypes.shape({}),
  /** callback to override the default callback will pass fields through to act on */
  callback: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  /** checked is if you need to manage checked value outside if component */
  checked: PropTypes.bool,
  /** color for checkbox default is primary */
  color: PropTypes.string,
  /** Boolean that defines whether or not you want to disable the checkbox */
  disabled: PropTypes.bool,
};

FieldArrayCheckbox.defaultProps = {
  label: '',
  val: {},
  callback: false,
  checked: false,
  color: 'primary',
  disabled: false,
};
