import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';

/**
 * Radio for React Final Form needs Field with type='radio"
*/

export const Radio = ({
  field: {
    label,
    input,
  },
  color,
  disabled,
}) => (
  <FormControlLabel
    id="MuiRadio"
    label={label}
    disabled={disabled}
    onChange={() => input.onChange(input.value)}
    control={(
      <MuiRadio
        checked={input.checked}
        color={color}
      />
    )}
  />
);

export default Radio;

Radio.propTypes = {
  field: PropTypes.shape({
    /** RFF type Radio */
    input: PropTypes.shape(),
    /** RFF type Radio */
    checked: PropTypes.bool,
    /** label for your Radio */
    label: PropTypes.string,
  }).isRequired,
  /** string: to set radio color default to primary MUI "primary","secondary","default" */
  color: PropTypes.string,
  /** boolean: default disabled */
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  disabled: false,
  color: 'primary',
};
