import Adder from "components/Adder/Adder";
import ChoiceCallLog from "components/ChoiceCallLog/ChoiceCallLog";
import ChoiceConfirmationCodes from "components/ChoiceConfirmationCodes/ChoiceConfirmationCodes";
import ChoiceConfirmationCodesSearch from "components/ChoiceConfirmationCodesSearch/ChoiceConfirmationCodesSearch";
import ChoiceDelegationAgreement from "components/ChoiceDelegationAgreement/ChoiceDelegationAgreement";
import ChoiceList from "components/ChoiceList/ChoiceList";
import ChoiceParams from "components/ChoiceParams/ChoiceParams";
import ChoicePriceTypes from "components/ChoicePriceTypes/ChoicePriceTypes";
import ChoicePromoCodes from "components/ChoicePromoCodes/ChoicePromoCodes";
import ChoiceSelection from "components/ChoiceSelection/ChoiceSelection";
import ChoiceSelectionsEmail from "components/ChoiceSelectionsEmail/ChoiceSelectionsEmail";
import DocuSignPortal from "components/DocuSignPortal/DocuSignPortal";
import Footer from "components/Footer/Footer";
import Nacha from "components/Nacha/Nacha";
import Nav from "components/Nav/Nav";
import NotifyBanner from "components/NotifyBanner/NotifyBanner";
import Pathing from "components/Pathing/Pathing";
import SiteThemeProvider from "components/SiteThemeProvider/SiteThemeProvider";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ChoiceSignup from "sharedComponents/ChoiceSignup/ChoiceSignup";
import { ShynetWrapper } from "shynet_wrapper";

const Application = () => (
  <>
    <Nav />
    <NotifyBanner />
    <Switch>
      <Route path="/adders" component={Adder} />
      <Route path="/choice" component={ChoiceSignup} exact />
      <Route path="/choice-call-log" component={ChoiceCallLog} exact />
      <Route
        path="/choice-confirmation-codes"
        component={ChoiceConfirmationCodes}
      />
      <Route
        path="/choice-confirmation-codes-search"
        component={ChoiceConfirmationCodesSearch}
        exact
      />
      <Route
        path="/choice-delegation-agreement"
        component={ChoiceDelegationAgreement}
        exact
      />
      <Route path="/choice-embedded" component={ChoiceSignup} exact />
      <Route path="/choice-list" component={ChoiceList} exact />
      <Route path="/choice-params" component={ChoiceParams} exact />
      <Route path="/choice-price-types" component={ChoicePriceTypes} exact />
      <Route path="/choice-promo-codes" component={ChoicePromoCodes} exact />
      <Route path="/choice-selection" component={ChoiceSelection} exact />
      <Route
        path="/choice-selections-email"
        component={ChoiceSelectionsEmail}
        exact
      />
      <Route path="/docu-sign-portal" component={DocuSignPortal} exact />
      <Route path="/nacha" component={Nacha} exact />
      <Route path="/pathing" component={Pathing} exact />
    </Switch>
    <Footer />
  </>
);

const App = ({ store }) => (
  <Provider store={store}>
    <SiteThemeProvider>
      <BrowserRouter>
        <ShynetWrapper />
        <Application />
      </BrowserRouter>
    </SiteThemeProvider>
  </Provider>
);

export default App;
