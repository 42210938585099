import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import PromoCodes from './components/PromoCodes/PromoCodes';
import ChoicePromoCodeForm from './components/ChoicePromoCodeForm/ChoicePromoCodeForm';

export const choicePromoCodes = async () => {
  let res;
  try {
    const queryString = {
      query: `query {
          choicePromoCodes{
            id
            description
            promoCode
            discount
            active
            signUpLimit
          }
        }`,
    };

    res = await axios.post('/graphql/', queryString);
  } catch (error) {
    return error;
  }

  if (res.data.errors && res.data.errors.length > 0) {
    return res.data.errors[0].message;
  }
  return res.data.data.choicePromoCodes;
};

export const ChoicePromoCodes = ({
  promoCodes,
  open,
}) => {
  const [state, setState] = useState({ open, promoCodes, selectedRow: {} });

  useEffect(() => {
    (async () => {
      const awaitPromoCodes = await choicePromoCodes();
      return setState(() => ({ promoCodes: awaitPromoCodes }));
    })();
  }, [promoCodes]);

  const toggleDialog = (selectedRow = {}) => {
    let updateCodes = state.promoCodes;
    if (state.open && selectedRow.id) {
      updateCodes = [selectedRow, ...state.promoCodes.filter(promo => promo.id !== selectedRow.id)];
    }
    setState({
      ...state,
      open: !state.open,
      selectedRow,
      promoCodes: updateCodes,
    });
  };

  return (
    <>
      <ChoicePromoCodeForm
        open={state.open}
        toggleDialog={toggleDialog}
        selectedRow={state.selectedRow}
      />
      <PromoCodes promoCodes={state.promoCodes} toggleDialog={toggleDialog} />
    </>
  );
};


export default ChoicePromoCodes;

// use props to set initial state for to make test easier to write
ChoicePromoCodes.propTypes = {
  promoCodes: PropTypes.arrayOf(PropTypes.shape()),
  open: PropTypes.bool,
};

ChoicePromoCodes.defaultProps = {
  promoCodes: [],
  open: false,
};
