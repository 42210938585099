import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Clear from '@material-ui/icons/Clear';
import Grid from '@material-ui/core/Grid';
import UpdatePathVolumes from './components/UpdatePathVolumes/UpdatePathVolumes';
import styles from './LocationVolumeHeaders.module.scss';
import { infoArray } from '../../../../PathTable';

const LocationVolumeHeaders = ({
  startDate,
  sumReceiptVolumes,
  sumDeliveryVolume,
  showVolumes,
  toggleArchived,
  dispatch,
  editPathBatch,
  summaryViewCallBack,
  formState,
  disabled,
}) => (
  <Fragment>
    {showVolumes &&
      infoArray(formState).map((row, i) => (
        <Fragment key={i}>
          {!row.archived && (
            <TableCell align="center" colSpan={1}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid>
                  <UpdatePathVolumes
                    disabled={disabled}
                    locationName={row.deliveryLocation.name}
                    groupId={row.groupId}
                    index={i}
                    sumDeliveryVolume={sumDeliveryVolume}
                    sumReceiptVolumes={sumReceiptVolumes}
                    startDate={startDate}
                    formState={formState}
                    dispatch={dispatch}
                    editPathBatch={editPathBatch}
                    summaryViewCallBack={summaryViewCallBack}
                  />
                </Grid>
                <Grid>
                  {sumReceiptVolumes(row.groupId) === 0 && !disabled && (
                    <Clear
                      className={styles.pointer}
                      onClick={() => toggleArchived(row)}
                    />
                  )}
                </Grid>
              </Grid>
            </TableCell>
          )}
        </Fragment>
      ))}
  </Fragment>
);

export default LocationVolumeHeaders;

LocationVolumeHeaders.propTypes = {
  disabled: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  sumReceiptVolumes: PropTypes.func.isRequired,
  sumDeliveryVolume: PropTypes.func.isRequired,
  showVolumes: PropTypes.bool.isRequired,
  toggleArchived: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  editPathBatch: PropTypes.func.isRequired,
  summaryViewCallBack: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    PathForm: PropTypes.shape({
      fields: PropTypes.shape(),
      values: PropTypes.shape(),
    }),
  }),
};

LocationVolumeHeaders.defaultProps = {
  formState: {
    PathForm: {
      fields: {},
      values: {},
    },
  },
};
