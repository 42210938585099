import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DateHelpers from 'utils/DateHelpers';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RDGridHelpers from 'utils/RDGridHelpers';
import LocationSummary from './components/LocationSummary/LocationSummary';
import PipelineBreakDown from './components/PipelineBreakDown/PipelineBreakDown';
import SearchResults from './components/SearchResults/SearchResults';
import styles from './SummaryView.module.scss';

export class SummaryView extends Component {
  state = {
    rows: [],
    searchResults: [],
    expanded: {},
    loading: true,
    open: false,
  };

  async componentDidMount() {
    await this.setTableRows();
  }

  async componentDidUpdate(oldProps) {
    if (oldProps.summaryToggle !== this.props.summaryToggle) {
      await this.setTableRows();
    }
  }

  setTableRows = async () => {
    const rows = await this.dateRangeSummaryByPipeline();
    this.setState({ rows, searchResults: rows, expanded: {} });
  };

  sortByName = array => array.sort((a, b) => {
    if (a.name < b.name) { return -1; }
    if (a.name > b.name) { return 1; }
    return 0;
  });

  filterInterstate = (paths) => {
    if (this.props.interstate !== 'all') {
      return paths.filter(path => path.interstate === this.props.interstate);
    }
    return paths;
  };

  dateRangeSummaryByPipeline = async () => {
    this.setState({ loading: true });
    const startDate = DateHelpers.formatDate(this.props.startDate);
    const endDate = DateHelpers.formatDate(this.props.endDate);
    const query = {
      query: `{
        pathingReports (
          startDate: "${startDate}"
          endDate: "${endDate}"
          companyId: ${this.props.companyId}
        ) {
          id
          name
          volume
          interstate
        }
      }`,
    };
    let res;
    try {
      res = await axios.post('/graphql/', query);
      this.setState({ loading: false });
      const nameSort = this.sortByName(res.data.data.pathingReports);
      return this.filterInterstate(nameSort);
    } catch (err) {
      return this.setState({ error: err.toString() });
    }
  };

  expandTable = (index = 0) => {
    this.setState(oldState => ({
      expanded: Object.assign(oldState.expanded, { [index]: !oldState.expanded[index] }),
    }));
  };

  togglePipelineBreakDown = (pipelineId, pipelineName, pipelineVolume, locationId = undefined) => {
    this.setState(prevState => ({
      open: !prevState.open,
      pipelineId,
      pipelineName,
      pipelineVolume,
      locationId,
    }));
  };

  setSearchResult = (searchResults) => {
    this.setState({ searchResults, expanded: {} });
  };

  render() {
    return (
      <>
        {this.state.error && (
          <span style={{ color: 'red' }}>
            {this.state.error}
          </span>
        )}
        <PipelineBreakDown
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          toggleDialog={this.togglePipelineBreakDown}
          open={this.state.open}
          pipelineId={this.state.pipelineId}
          companyId={this.props.companyId}
          pipelineName={this.state.pipelineName}
          pipelineVolume={this.state.pipelineVolume}
          locationId={this.state.locationId}
        />
        {this.state.loading ? (
          <AppLoader loading={this.state.loading} />
        ) : (
          <List
            className={styles.container}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItem>
              <SearchResults
                searchArray={this.state.rows}
                setSearchResult={this.setSearchResult}
              />
            </ListItem>
            {this.state.searchResults.map((row, i) => (
              <div key={i}>
                <ListItem>
                  <ListItemText
                    className={styles.onClick}
                    onClick={() => this.togglePipelineBreakDown(row.id, row.name, row.volume)}
                    primary={row.name}
                    secondary={RDGridHelpers.formatNumber(row.volume)}
                  />
                  {this.state.expanded[i]
                    ? (
                      <ExpandLess
                        className={styles.onClick}
                        onClick={() => this.expandTable(i)}
                      />
                    )
                    : (
                      <ExpandMore
                        className={styles.onClick}
                        onClick={() => this.expandTable(i)}
                      />
                    )
                  }
                </ListItem>
                <Collapse in={this.state.expanded[i]} timeout="auto" unmountOnExit>
                  { this.state.expanded[i] && (
                  <LocationSummary
                    row={row}
                    selectLocation={this.props.selectLocation}
                    selectedLocation={this.props.selectedLocation}
                    sortByName={this.sortByName}
                    startDate={DateHelpers.formatDate(this.props.startDate)}
                    endDate={DateHelpers.formatDate(this.props.endDate)}
                    companyId={this.props.companyId}
                    toggleDialog={this.togglePipelineBreakDown}
                  />
                  )}
                </Collapse>
              </div>
            ))}
          </List>
        )}
      </>
    );
  }
}

export default SummaryView;

SummaryView.propTypes = {
  summaryToggle: PropTypes.bool.isRequired,
  interstate: PropTypes.string.isRequired,
  companyId: PropTypes.number.isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
