import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

/**
 * Input for redux forms input field
 *
 * console shows onSubmit values
 */

export const SelectField = ({
  input,
  meta: { error },
  classes,
  options,
  displayKey,
  selectLabel,
  disabled,
  onChangeCallBack,
}) => {
  const [state, setState] = useState({
    labelWidth: 0,
  });
  const inputLabelRef = useRef(null);

  useEffect(() => {
    const findNode = findDOMNode(inputLabelRef.current); // eslint-disable-line
    setState({
      labelWidth: findNode ? findNode.offsetWidth : 0,
    });
  }, []);

  return (
    <FormControl variant="outlined" className={classes.selectField} disabled={disabled}>
      <InputLabel ref={inputLabelRef} style={error && { color: 'red' }} htmlFor="select-component-outlined">
        {selectLabel}
      </InputLabel>
      <Select
        disabled={disabled}
        id="select"
        {...input}
        onChange={e => input.onChange(onChangeCallBack(e.target.value))}
        input={(
          <OutlinedInput
            labelWidth={state.labelWidth || 0}
            name={selectLabel}
            id="select-component-outlined"
          />
        )}
      >
        {options.map((item, i) => (
          <MenuItem key={i} value={item}>
            <option value={item}>
              {item instanceof Object ? item[displayKey] : item}
            </option>
          </MenuItem>
        ))}
      </Select>
      {error && (<FormHelperText id="component-error-text">{error}</FormHelperText>)}
    </FormControl>
  );
};

export default SelectField;

SelectField.propTypes = {
  /** options for select */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  /**
   *  valueCallBack return a new value to dropdown
   * as to be a pure function with out any async actions
   */
  onChangeCallBack: PropTypes.func,
  /** displayKey if array of objects */
  displayKey: PropTypes.string,
  /** selectLabel label for your input */
  selectLabel: PropTypes.string,
  /** input for your input ;) */
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
  /** metadata regarding input state */
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    input: PropTypes.string,
    selectField: PropTypes.string,
  }),
  /** disabled input */
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  onChangeCallBack: item => item,
  displayKey: '',
  input: {},
  meta: {
    touched: 'false',
    error: '',
    message: '',
  },
  selectLabel: '',
  classes: {},
  disabled: false,
};
