import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Form from 'sharedComponents/ReduxForms/Form/Form';
import Submit from 'sharedComponents/ReduxForms/Submit/Submit';
import FieldArrayCheckbox from 'sharedComponents/ReduxForms/FieldArrayCheckbox/FieldArrayCheckbox';
import { FieldArray } from 'redux-form';

export const validate = (form) => {
  if (form && form.values) {
    if (form.values.documents.length > 0) return false;
  }
  return true;
};

const DocuSignTable = ({
  toggleGroupFrom,
  docuSign,
  result,
  toggleDialog,
  deleteDocument,
  formState,
}) => (
  <Form
    form="GroupFrom"
    onSubmit={toggleGroupFrom}
    initialValues={{ documents: [] }}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">Group</TableCell>
          <TableCell align="center">Template Name</TableCell>
          <TableCell align="center">Template Id</TableCell>
          <TableCell align="center">Role Name</TableCell>
          <TableCell align="center">Delete</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {docuSign.length > 0 && result.map((row, i) => (
          <TableRow key={row.templateId}>
            <TableCell align="center">
              <FieldArray key={i} component={FieldArrayCheckbox} name="documents" val={row} />
            </TableCell>
            <TableCell align="center">
              <Button onClick={() => toggleDialog(row)}>
                {row.templateName}
              </Button>
            </TableCell>
            <TableCell align="center">{row.templateId}</TableCell>
            <TableCell align="center">{row.roleName}</TableCell>
            <TableCell align="center">
              <Tooltip title="delete document">
                <IconButton onClick={() => deleteDocument(row.id)}>
                  <Clear />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Submit disabled={validate(formState.GroupFrom)}>
      Edit Group
    </Submit>
  </Form>
);


export default DocuSignTable;

DocuSignTable.propTypes = {
  toggleGroupFrom: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    GroupFrom: PropTypes.shape(),
  }).isRequired,
  toggleDialog: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  docuSign: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  result: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
