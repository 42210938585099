import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDataGrid from 'react-data-grid';
import { Data, Toolbar } from 'react-data-grid-addons';
import Button from '@material-ui/core/Button';

// eslint-disable-next-line react/prop-types
export const RowRenderer = ({ renderBaseRow, ...props }) => {
  // eslint-disable-next-line react/prop-types
  const color = props.idx % 2 ? 'black' : 'blue';
  return <div style={{ color }}>{renderBaseRow(props)}</div>;
};

export const setHeight = () => {
  const { body, documentElement } = window.document;
  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    documentElement.clientHeight,
    documentElement.scrollHeight,
    documentElement.offsetHeight,
  ) / 1.69 || 600;
};

export class SearchResultsTable extends Component {
  state = {
    filters: {},
    columns: [
      {
        key: 'confirmCode', name: 'Confirm Code', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'divisionRegion', name: 'Division Region', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'accountNum',
        name: 'Account Num',
        minWidth: 25,
        filterable: true,
        resizable: true,
        formatter: value => (
          <Button
            id="AccountNumBtn"
            onClick={() => this.props.toggleDialog(value.value)}
            style={{ color: 'inherit' }}
          >
            {value.value}
          </Button>
        ),
      },
      {
        key: 'premiseNumber', name: 'Premise Number', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'firstName', name: 'First Name', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'lastName', name: 'Last Name', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'telephone', name: 'Telephone', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'emailAddress', name: 'Email Address', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'serviceAddress', name: 'Service Address', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'mailingAddress', name: 'Mailing Address', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'mailingCityState', name: 'Mailing City State', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'pricingOpt', name: 'Pricing Opt', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'selectMethod', name: 'Select Method', minWidth: 25, filterable: true, resizable: true,
      },
      {
        key: 'totalUsage',
        name: 'Total Usage',
        minWidth: 25,
        filterable: true,
        resizable: true,
        formatter: value => (
          <Button
            id="TotalUsageBtn"
            onClick={() => this.props.setSgCustomer(value.row)}
            style={{ color: 'inherit' }}
          >
            {value.value}
          </Button>
        ),
      },
    ],
  };

  handleFilterChange = (filter) => {
    const { filters } = this.state;
    const newFilters = Object.assign({}, filters);
    if (filter.filterTerm) {
      newFilters[filter.column.key] = filter;
    } else {
      delete newFilters[filter.column.key];
    }
    this.setState({ filters: newFilters });
    return newFilters;
  };

  render() {
    const { searchResults } = this.props;
    const { filters, columns } = this.state;
    let visibleRows = [];
    if (searchResults.length > 0) {
      visibleRows = Data.Selectors.getRows({ rows: searchResults, filters });
    }

    return (
      <ReactDataGrid
        columns={columns}
        rowGetter={i => visibleRows[i]}
        rowsCount={visibleRows.length}
        minHeight={setHeight()}
        rowRenderer={RowRenderer}
        toolbar={(<Toolbar enableFilter={true} />)}
        onAddFilter={filter => this.handleFilterChange(filter)}
        onClearFilters={() => this.setState({ filters: {} })}
      />
    );
  }
}

export default SearchResultsTable;

SearchResultsTable.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape),
  setSgCustomer: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
};

SearchResultsTable.defaultProps = {
  searchResults: [],
};
