import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const EnrolledCompleteDialog = ({
  toggleDialog,
  open,
  quote,
}) => (
  <Dialog
    open={open}
    onClose={toggleDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {`${quote.quoteData.accountList.reduce((sum = 0, { errorCode, complete }) => (!errorCode && complete ? sum + 1 : sum + 0), 0)} out of ${quote.quoteData.accountList.length} successful sign ups`}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        This quote has been completed. For more information
        review the complete status by pressing &quot;GOT IT&quot;
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleDialog} color="primary" autoFocus>
        GOT IT
      </Button>
    </DialogActions>
  </Dialog>
);

export default EnrolledCompleteDialog;

EnrolledCompleteDialog.propTypes = {
  open: PropTypes.bool,
  toggleDialog: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    quoteData: PropTypes.shape({
      accountList: PropTypes.arrayOf(PropTypes.shape({

      })),
    }),
  }).isRequired,
};

EnrolledCompleteDialog.defaultProps = {
  open: false,
};
