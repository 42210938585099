/* eslint-disable react/style-prop-object */
/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * LogoIconSvg takes a color and render woodriver energys logo svg
 */

const LogoIconSvg = ({
  fillOne,
  fillTwo,
  classes,
}) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 464 509"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <g>
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="210.9411" y1="508.8806" x2="210.9411" y2="-9.094947e-13">
        <stop offset="0" />
        <stop offset="0.2366" />
        <stop offset="0.732"/>
        <stop offset="1" />
      </linearGradient>
      <path
        className="st0"
        fill={fillTwo}
        d="M325.9,137.9h-62.5c-21.6,129-9.2,250,12.2,352.3c-25.4-43.3-56.6-202.1-56.8-326.5c-0.3-9-0.2-17.5,0.3-25.8
		C223.3,61.2,256.9,16.3,275.8,0c-56.6,22.1-80.8,74.8-88,137.9c-11.2,97.7,18.4,220.4,31.6,292.9c0,0-48.1-58.2-65-285.2l-3.9-7.7
		c-45.4,1.1-81.8,38.2-81.8,83.8V425c0,46.3,37.6,83.9,83.9,83.9h173.1c10.8-22.8,18.6-46.1,23.1-69.8
		C362,368.7,342.9,233.2,325.9,137.9z"
      />

      <linearGradient id="SVGID_00000120555806128781445540000006715063480032809862_" gradientUnits="userSpaceOnUse" x1="395.177" y1="508.0572" x2="395.177" y2="137.9468">
        <stop offset="0" />
        <stop offset="0.2366" />
        <stop offset="0.732" />
        <stop offset="1" />
      </linearGradient>
      <path
        fill={fillOne}
        d="M359.4,137.9H347
		c47.1,109.8,33,362.5,33,362.5l-9,7.6c40.8-5.7,72.2-40.7,72.2-83.1V221.8C443.3,175.5,405.8,137.9,359.4,137.9z"
      />
    </g>
  </svg>

);

export default LogoIconSvg;

LogoIconSvg.propTypes = {
  fillOne: PropTypes.string,
  fillTwo: PropTypes.string,
  classes: PropTypes.string,
};

LogoIconSvg.defaultProps = {
  // /** color to fill svg */
  fillOne: '#fff',
  // /** color to fill svg */
  fillTwo: '#fff',
  // /** class to pass to svg */
  classes: '',
};
