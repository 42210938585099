import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import CardActions from '@material-ui/core/CardActions';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import { DOCUSIGN_URL } from 'constants/AppConstants';

import styles from './ReAuthenticateAdmin.module.scss';

export class ReAuthenticateAdmin extends Component {
  state = {
    docuSignAuthorizedMessage: '',
    loading: true,
  };

  componentDidMount = async () => {
    await this.docuSignLogin();
  };

  docuSignLogin = async () => {
    try {
      this.setState({ loading: true });
      const qs = queryString.parse(this.props.location.search).code || '';
      const query = {
        query: `{
          docuSignClient(
            query: "login",
            token: "${qs}"
          ) {
            message
          }
        }`,
      };
      const data = await axios.post('/graphql/', query);
      this.setState({
        docuSignAuthorizedMessage: data.data.data.docuSignClient.message,
        loading: false,
        error: '',
      });
      this.props.history.push({ search: '' });
    } catch (error) {
      this.setState({
        error: error.toString(),
        loading: false,
      });
    }
  };

  authorizing = () => {
    if (this.state.loading) return <AppLoader loading={this.state.loading} />;
    if (this.state.docuSignAuthorizedMessage.includes('Authorized'))
      return <ThumbUp />;
    return <ThumbDown />;
  };

  render() {
    return (
      <Card>
        <CardHeader
          avatar={this.authorizing()}
          title="Auth Status"
          subheader={this.state.error || this.state.docuSignAuthorizedMessage}
        />
        <CardContent>
          Clicking below will redirect to docusigns login portal to login as a
          admin and give permissions to the api to create envelopes and signing
          urls. This will re authenticate on when the component mounts and may
          require you to sign into docusign when loading tab route but will not
          set an invalid refresh token if credentials are incorrect.
        </CardContent>
        <CardActions>
          <a className={styles.aTag} href={DOCUSIGN_URL}>
            <Button size="small" variant="outlined">
              Log DocuSign Admin in
            </Button>
          </a>
        </CardActions>
      </Card>
    );
  }
}

export default ReAuthenticateAdmin;

ReAuthenticateAdmin.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
