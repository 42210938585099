import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import DateHelpers from 'utils/DateHelpers';
import DupPaths from './components/DupPaths/DupPaths';
import IsOpen from './components/IsOpen/IsOpen';
import NormalizeVolumes from './components/NormalizeVolumes/NormalizeVolumes';
import RefreshSummary from './components/RefreshSummary/RefreshSummary';
import SearchMonth from './components/SearchMonth/SearchMonth';

export const SummaryOptions = ({
  startDate,
  searchDate,
  summaryViewCallBack,
  disablePaths,
  disabled,
  dispatch,
  company,
  interstate,
  companies,
  interstates,
}) => (
  <Grid
    container
    direction="row"
    justify="flex-start"
    alignItems="center"
  >
    <Grid>
      <SearchMonth
        startDate={startDate}
        searchDate={searchDate}
        company={company}
        interstate={interstate}
        companies={companies}
        interstates={interstates}
      />
    </Grid>
    <Grid>
      <DupPaths
        startDate={startDate}
        dispatch={dispatch}
      />
    </Grid>
    <Grid>
      <RefreshSummary summaryViewCallBack={summaryViewCallBack} />
    </Grid>
    <Grid>
      <IsOpen
        disablePaths={disablePaths}
        startDate={startDate}
        disabled={disabled}
        dispatch={dispatch}
      />
    </Grid>
    <Grid>
      <NormalizeVolumes
        begDate={startDate}
        dispatch={dispatch}
      />
    </Grid>
    <Grid item xs={12}>
      {DateHelpers.formatYearMonth(startDate)}
    </Grid>
  </Grid>
);

export default SummaryOptions;

SummaryOptions.propTypes = {
  company: PropTypes.shape({}).isRequired,
  interstate: PropTypes.shape({}).isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  interstates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disablePaths: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  searchDate: PropTypes.func.isRequired,
  summaryViewCallBack: PropTypes.func.isRequired,
};

SummaryOptions.defaultProps = {};
