import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import styles from './Submit.module.scss';

export const Submit = ({
  classes,
  hide,
  children,
  variant,
  width,
  margin,
  color,
  disabled,
  submitting,
}) => (
  <>
    {submitting && (
      <AppLoader
        loading={submitting}
      />
    )}
    <Button
      type="submit"
      fullWidth
      variant={variant}
      color={color}
      disabled={disabled}
      className={submitting || hide ? styles.hideSubmit : `${classes.submit} ${styles.default}`}
      style={{ width, margin }}
    >
      {children || 'Submit'}
    </Button>
  </>
);


export default Submit;

Submit.propTypes = {
  children: PropTypes.node,
  /** React Final Form prop */
  submitting: PropTypes.bool,
  /** classes with submit will apply to the button */
  classes: PropTypes.shape({
    submit: PropTypes.string,
  }),
  /** MUI variant prop changes what the input looks like */
  variant: PropTypes.string,
  /** hide button from dom */
  hide: PropTypes.bool,
  /** disabled input from being submitted */
  disabled: PropTypes.bool,
  /** color MUI prop ["default","inherit","primary","secondary"] */
  color: PropTypes.string,
  /** width for input */
  width: PropTypes.string,
  /** margin for input */
  margin: PropTypes.string,
};

Submit.defaultProps = {
  width: '100%',
  margin: '10px 0px 10px 0px',
  color: 'primary',
  children: null,
  classes: {},
  submitting: false,
  variant: 'outlined',
  hide: false,
  disabled: false,
};
