import axios from 'axios';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import ToggleViews from 'sharedComponents/ToggleViews/ToggleViews';
import DateHelpers from 'utils/DateHelpers';
import CenterOptions from './components/CenterOptions/CenterOptions';
import PathParts from './components/PathParts/PathParts';
import Paths from './components/Paths/Paths';
import SummaryOptions from './components/SummaryOptions/SummaryOptions';
import SummaryView from './components/SummaryView/SummaryView';
import {userId} from 'auth';

const companies = [{
  name: 'WoodRiver Energy',
  id: 766,
}, {
  name: 'Connect Energy',
  id: 767,
}];

const interstates = [{
  search: 'true',
  value: true,
}, {
  search: 'false',
  value: false,
}, {
  search: 'all',
  value: 'all',
}];

export class Pathing extends Component {
  state = {
    selectedLocation: '',
    location: {},
    summaryToggle: false,
    pathsToggle: false,
    startDate: DateHelpers.beginningOfTheMonth(),
    endDate: DateHelpers.endOfTheMonth(),
    disabled: true,
    locked: false,
    companies,
    interstates,
    company: companies[0],
    interstate: interstates[2],
  }

  async componentWillUnmount() {
    if (this.state.location.pipelineId) {
      await this.deleteContentLocksByUserId();
    }
  }

  searchDate = (startDate, endDate, company, interstate) => {
    this.setState({
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      company,
      interstate,
    });
    this.selectLocation();
    this.summaryViewCallBack();
    this.pathViewCallBack();
  }

  summaryViewCallBack = () => {
    this.setState(oldState => ({...oldState, summaryToggle: !oldState.summaryToggle}));
  }

  pathViewCallBack = () => {
    this.setState(oldState => ({...oldState, pathsToggle: !oldState.pathsToggle}));
  }

  disablePaths = (disabled = true) => this.setState({disabled});

  lockPaths = (uid = null) => {
    if (uid && uid !== userId) {
      return this.setState({locked: true});
    }

    return this.setState({locked: false});
  };

  selectLocation = async (location = {}, selectedLocation = {}) => {
    if (location.pipelineId && location.pipelineId !== this.state.location.pipelineId) {
      await this.deleteContentLocksByUserId();
    }
    this.setState({
      locked: false,
      selectedLocation,
      location,
    });
  }

  deleteContentLocksByUserId = async () => {
    const query = {
      query: `mutation {
        deleteContentLocks (
          resourceType: "PathsPipeline",
          userId: ${userId},
        ) {
          userId,
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      const {deleteContentLocks} = res.data.data;
      return deleteContentLocks;
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <ToggleViews
        leftView={true}
        titleLeft={(
          <SummaryOptions
            startDate={this.state.startDate}
            searchDate={this.searchDate}
            summaryViewCallBack={this.summaryViewCallBack}
            disablePaths={this.disablePaths}
            disabled={this.state.disabled}
            dispatch={this.props.dispatch}
            company={this.state.company}
            interstate={this.state.interstate}
            companies={this.state.companies}
            interstates={this.state.interstates}
          />
        )}
        leftComponent={(
          <SummaryView
            selectLocation={this.selectLocation}
            selectedLocation={this.state.selectedLocation}
            summaryToggle={this.state.summaryToggle}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            companyId={this.state.company.id}
            interstate={this.state.interstate.value}
          />
        )}
        titleCenter={(
          <CenterOptions
            location={this.state.location}
            lockPaths={this.lockPaths}
            locked={this.state.locked}
            disabled={this.state.disabled}
            dispatch={this.props.dispatch}
            pathViewCallBack={this.pathViewCallBack}
          />
        )}
        centerComponent={(
          <>
            {this.state.location.pipelineId
              && this.state.location.id
              && (
                <Paths
                  disabled={this.state.locked || this.state.disabled}
                  formState={this.props.formState}
                  dispatch={this.props.dispatch}
                  row={this.state.location}
                  summaryViewCallBack={this.summaryViewCallBack}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  toggle={this.state.pathsToggle}
                  lockPaths={this.lockPaths}
                  companyId={this.state.company.id}
                />
              )
            }
          </>
        )}
        rightComponent={(
          <>
            {this.state.location.pipelineId
              && this.state.location.id
              && (
                <PathParts
                  formState={this.props.formState}
                  dispatch={this.props.dispatch}
                  startDate={this.state.startDate}
                  pipelineName={this.state.location.pipelineName}
                  pipelineId={this.state.location.pipelineId}
                  receiptLocationId={this.state.location.id}
                  receiptLocation={this.state.location}
                  pathViewCallBack={this.pathViewCallBack}
                />
              )
            }
          </>
        )}
      />
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
    dispatch: state.dispatch,
  };
}

export default compose(
  connect(mapStateToProps),
)(Pathing);

Pathing.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.shape({}).isRequired,
};
