import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'sharedComponents/ReduxForms/Input/Input';
import InputSmall from 'sharedComponents/ReduxForms/InputSmall/InputSmall';

/**
 * Input for redux forms input field
 *
 * Requires FieldArray parent component from redux-forms
 *
 *  console shows onSubmit values
 */

//  TODO look in to parse for numbers and dates
// https://redux-form.com/7.4.2/docs/api/field.md/

export const InputFieldArray = ({
  smallInput,
  index,
  objKey,
  fields,
  type,
  disabled,
  label,
  meta: { error },
  ...custom
}) => (
  <>
    <Field
      {...custom}
      name={`${fields.name}[${index}][${objKey}]`}
      type={type}
      error={error}
      component={smallInput ? InputSmall : Input}
      label={label || objKey}
      disabled={disabled}
    />
  </>
);

export default InputFieldArray;

InputFieldArray.propTypes = {
  smallInput: PropTypes.bool,
  /** objKey that you want to update within the object */
  objKey: PropTypes.string.isRequired,
  /** index of your values in the array */
  index: PropTypes.number.isRequired,
  /** fields of the position of the checkbox */
  fields: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  /** string representing label wanted for checkbox */
  label: PropTypes.string,
  /** val is the checkbox value to be sent across the wire */
  val: PropTypes.shape({}),
  /** label width for your input */
  labelWidth: PropTypes.number,
  /** custom props for your input */
  custom: PropTypes.shape({}),
  /** color for input default is primary */
  color: PropTypes.string,
  /** Boolean that defines whether or not you want to disable the checkbox */
  disabled: PropTypes.bool,
  /** type for input default is primary */
  type: PropTypes.string,
  /** metadata regarding input state */
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

InputFieldArray.defaultProps = {
  smallInput: false,
  label: '',
  val: {},
  custom: {},
  meta: {
    touched: 'false',
    error: '',
    message: '',
  },
  color: 'primary',
  disabled: false,
  labelWidth: 50,
  type: 'text',
};
