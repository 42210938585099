import axios from 'axios';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import RegionTabs from './components/RegionTabs/RegionTabs';

export class ChoiceParams extends Component {
  state = {choiceParams: [], regions: [], loading: true};

  async componentDidMount() {
    await this.loadParams();
  }

  sortParams = (choiceParams) => {
    const allRegions = choiceParams.map(param => param.region);
    const regions = allRegions.filter((region, i) => allRegions.indexOf(region) === i);
    this.setState({choiceParams, regions, loading: false});
  }

  loadParams = async () => {
    const queryString = {
      query: `{
        choiceParams{
          id
          region
          paramType
          paramValue
          effectiveDate
          billClass
          formula
          updatedAt
        }
      }`,
    };
    const req = await axios.post('/graphql/', queryString);
    this.sortParams(req.data.data.choiceParams);
  }

  render() {
    return (
      <>
        {this.state.loading && <AppLoader loading={this.state.loading} />}
        {this.state.choiceParams.length > 1 && (
          <RegionTabs
            sortParams={this.sortParams}
            formState={this.props.formState}
            regions={this.state.regions}
            choiceParams={this.state.choiceParams}
          />
        )}
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
  };
}

export default compose(
  connect(mapStateToProps),
)(ChoiceParams);

ChoiceParams.propTypes = {
  formState: PropTypes.shape({}).isRequired,
};
