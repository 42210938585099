import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DocuSignRedirect from 'sharedComponents/DocuSignRedirect/DocuSignRedirect';

const DocuSignRedirectChoice = ({ quote, disabled }) => {
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    let docuSignTitle = `${quote.quoteData.accountList[0].serviceState} Choice Delegation Agreement`;
    if (quote.quoteData.accountList[0].billClass === 'AGR' && quote.quoteData.accountList[0].serviceState === 'NE') {
      docuSignTitle = `${quote.quoteData.accountList[0].serviceState} ${quote.quoteData.accountList[0].billClass} Choice Delegation Agreement`;
    }
    setTemplateName(docuSignTitle);
  }, [quote]);

  return (
    <>
      {templateName && (
      <DocuSignRedirect
        disabled={disabled}
        buttonText="sign delegation agreement"
        email={quote.quoteData.currentEmail}
        returnUrl={`${window.location.origin}/choice-delegation-agreement?quote=${quote.pubIndex}`}
        clientUserId={quote.pubIndex}
        templateName={templateName}
        firstName={quote.quoteData.accountList[0].firstName}
        lastName={quote.quoteData.accountList[0].lastName}
        quote={quote}
      />
      )}
    </>
  );
};

export default DocuSignRedirectChoice;

DocuSignRedirectChoice.propTypes = {
  quote: PropTypes.shape({
    id: PropTypes.number,
    pubIndex: PropTypes.string,
    quoteData: PropTypes.shape({
      currentEmail: PropTypes.string,
      accountList: PropTypes.arrayOf(PropTypes.shape({
        billClass: PropTypes.string,
        serviceState: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      })),
    }),
  }).isRequired,
  disabled: PropTypes.bool,
};

DocuSignRedirectChoice.defaultProps = {
  disabled: false,
};
