import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import graphqlCall from 'utils/graphqlCall';
import graphqlObj from 'utils/graphqlObj';
import { FORM_ERROR } from 'final-form';
import { Form, MuiInput, MuiSubmit } from 'rff-wrapper';

export class CreateGroup extends Component {
  state = {
    open: false,
  }

  toggleDialog = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  }

  onSubmit = async (values) => {
    const { createCallback } = this.props;
    const queryString = {
      query: `mutation {
          createGroup(group: ${graphqlObj(values)}) {
            name
          }
        }`,
    };
    try {
      const { createGroup } = await graphqlCall(queryString);
      createCallback(createGroup);
      return this.setState({ open: false });
    } catch (err) {
      return { [FORM_ERROR]: err.toString() };
    }
  }

  render() {
    const { open } = this.state;

    return (
      <div>
        <MuiDialog
          open={open}
          toggleDialog={() => this.toggleDialog()}
          titleText="Create New Group"
          buttonText="New Group"
          buttonVariant="outlined"
          buttonSize="small"
        >
          <Form
            onSubmit={this.onSubmit}
            initialValues={{ name: '' }}
            render={formProps => (
              <form onSubmit={formProps.handleSubmit}>
                <MuiInput name="name" label="Group Name" fullWidth size="small" />
                <MuiSubmit buttonText="Create Group" />
                {formProps.submitError && <div style={{ color: 'red' }}>{formProps.submitError}</div>}
              </form>
            )}
          />
        </MuiDialog>
      </div>
    );
  }
}

export default CreateGroup;

CreateGroup.propTypes = {
  createCallback: PropTypes.func.isRequired,
};
