import React, { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import SigningComplete from './components/SigningComplete/SigningComplete';
import SignUp from './components/SignUp/SignUp';

export class ChoiceDelegationAgreement extends Component {
  state = {
    preloadQuoteId: queryString.parse(this.props.location.search).quote || '',
    event: queryString.parse(this.props.location.search).event || '',
    quote: {},
    loading: true,
    error: '',
  };

  componentDidMount() {
    const { preloadQuoteId } = this.state;
    if (preloadQuoteId) this.loadQuote(preloadQuoteId);
  }

  loadQuote = async (id) => {
    this.setState({
      error: '',
      loading: true,
    });
    if (id) {
      const query = {
        query: `{
          choicePriceQuote(pubIndex: "${id}") {
            id
            pubIndex
            greenPlus
            updatedAt
            quoteData {
              totalUsages
              currentEmail
              accountList {
                firstName
                lastName
                billClass
                serviceState
              }
            }
          }
        }`,
      };

      let res = null;
      try {
        res = await axios.post('/graphql/', query);
        if (res.data.errors) throw new Error(res.data.errors[0].message).toString();
        const quote = res.data.data.choicePriceQuote;
        this.setState({ quote, loading: false });
      } catch (error) {
        this.setState({ error: error.toString(), loading: false });
      }
    }
  }

  renderEvent = (event) => {
    switch (event) {
      case 'signing_complete':
        return (
          <SigningComplete data-testid="SigningComplete" quote={this.state.quote} />
        );
      case 'sign_up':
        return (
          <SignUp
            data-testid="SignUp"
            quote={this.state.quote}
            title="Fill out the information below to sign your Choice Gas Delegation Agreement! "
          />
        );
      default:
        return (
          <SignUp
            data-testid="SignUpError"
            quote={this.state.quote}
            title="There was an error signing the document please click below to resign"
          />
        );
    }
  }

  render() {
    return (
      <>
        {this.state.error && (<p style={{ color: 'red' }}>{this.state.error}</p>)}
        <AppLoader loading={this.state.loading} />
        {!this.state.loading && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          {this.renderEvent(this.state.event)}
        </Grid>
        )}
      </>
    );
  }
}

export default ChoiceDelegationAgreement;

ChoiceDelegationAgreement.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

ChoiceDelegationAgreement.defaultProps = {};
