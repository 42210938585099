import React, { Component } from 'react';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import notifyBannerAction from 'actions/NotifyBannerAction';

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['name', 'pipelineId'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export class CreateLocation extends Component {
  onSubmit = async (values = {}) => {
    try {
      const location = await this.upsertLocation(values);

      this.props.dispatch(notifyBannerAction({
        message: `location: ${location.name} made on pipeline ${location.pipeline.name}`,
        showBanner: true,
        error: false,
      }));
    } catch (error) {
      this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
  };

  upsertLocation = async (location) => {
    let res;

    try {
      const queryString = {
        query: `mutation {
          upsertLocation(location: ${graphqlObj(location)}) {
           id
           createdAt
           updatedAt
           name
           pipelineId
           poolId
           defaultLocation
           pipeline {
             name
           }
          }
        }`,
      };
      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return error;
    }

    if (res.data.errors && res.data.errors.length > 0) {
      throw new Error(res.data.errors[0].message);
    }

    return res.data.data.upsertLocation;
  }

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        validate={validate}
        initialValues={{ pipelineId: this.props.pipelineId }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                name="name"
                label="name"
              >
                {field => (<Input field={field} />)}
              </Field>
            </div>
            <div>
              <Submit submitting={submitting}>
                Create Location
              </Submit>
            </div>
          </form>
        )}
      </Form>
    );
  }
}

export default CreateLocation;

CreateLocation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pipelineId: PropTypes.number,
};

CreateLocation.defaultProps = {
  pipelineId: null,
};
