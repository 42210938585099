import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import DocuSignRedirectChoice from 'sharedComponents/DocuSignRedirectChoice/DocuSignRedirectChoice';
import Typography from '@material-ui/core/Typography';

const SignUp = ({ quote, title }) => (
  <Card style={{ maxWidth: '1600px' }}>
    <CardMedia
      style={{ height: '250px' }}
      image="https://woodriverenergy.com/wp-content/uploads/2021/12/Delegation-image.jpg"
      title="pay less"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        {title}
      </Typography>
      <Typography color="textSecondary" component="p">
        <DocuSignRedirectChoice quote={quote} />
      </Typography>
      <Typography color="textSecondary" component="p">
        If you have any questions or concerns, please contact us at 877-790-4990 or choice@woodriverenergy.com.
      </Typography>
    </CardContent>

    <CardActions>
      <a href="tel:18777904990">
        <Button size="small" color="primary">
          Call Us
        </Button>
      </a>
      <a href="mailto:choice@woodriverenergy.com">
        <Button size="small" color="primary">
          Email Us
        </Button>
      </a>
    </CardActions>
  </Card>
);

export default SignUp;

SignUp.propTypes = {
  quote: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
};

SignUp.defaultProps = {};
