import { user } from "auth";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

let shynetLoading = false;
let shynetResolve: any = null;
const shynetLoadPromise = new Promise((res) => (shynetResolve = res));

export const ShynetWrapper = () => {
  const location = useLocation();

  useEffect(() => {
    // Because I hate the network tab spam.
    if (window.location.host.includes("localhost")) {
      return;
    }

    (async () => {
      if (!shynetLoading) {
        shynetLoading = true;
        const element = document.createElement("script");
        document.body.appendChild(
          element
        ).src = `https://shynet.dokku.woodriverenergy.com/ingress/edbfef2a-e8fa-402f-96a8-b0fd924013b8/${encodeURIComponent(
          user.email ?? "unknown@woodriverenergy.com"
        )}/script.js`;
        element.onload = () => {
          shynetResolve();
        };
      }

      await shynetLoadPromise;

      Shynet.newPageLoad();
    })();
  }, [location.key]);
  return null;
};
