import {IconButton} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import Call from '@material-ui/icons/Call';
import Email from '@material-ui/icons/Email';
import Home from '@material-ui/icons/Home';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import LogoSvgConnect from 'sharedComponents/LogoSvgConnect/LogoSvgConnect';
import WoodRiverEnergySvg from 'sharedComponents/WoodRiverEnergySvg/WoodRiverEnergySvg';
import AppMenu from './AppMenu';
import styles from './MuiToolbar.module.scss';

export class MuiToolbar extends Component {
  render() {
    return (
      <Toolbar>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item container xs={4} justify="flex-start">
            <AppMenu
              handleOpen={this.props.handleOpen}
              appInfo={this.props.appInfo}
            />
          </Grid>

          <Grid item container xs={4} justify="center" alignItems="center">
            <Hidden smDown>
              <a href="/">
                {this.props.appInfo.host.includes('connectenergy') ? (
                  <LogoSvgConnect />
                ) : (
                  <WoodRiverEnergySvg />
                )}
              </a>
            </Hidden>

            <Hidden mdUp>
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                justify="center"
              >
                <a href="tel:+18885109315">
                  <Call className={styles.icon} />
                </a>
              </Grid>
              <Grid
                item
                container
                xs={6}
                alignItems="center"
                justify="center"
              >
                <a href="mailto:sales@woodriverenergy.com">
                  <Email className={styles.icon} />
                </a>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>

      </Toolbar>
    );
  }
}

export default withRouter(MuiToolbar);

MuiToolbar.propTypes = {
  appInfo: PropTypes.shape({
    host: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  handleOpen: PropTypes.func.isRequired,
};
