/* eslint-disable */

class Themes {
  static pickTheme(userTheme = false, company = process.env.REACT_APP_COMPANY) {
    if (company.includes('connectenergy')) {
      return Themes.connectEnergy();
    }
    return userTheme ? Themes.darkWoodRiver() : Themes.woodRiver();
  }

  static woodRiver() {
    return {
      palette: {
        type: 'light',
        primary: {
          main: '#70AE6E',
          light: '#88BC86',
          dark: '#3E855E',
          contrastText: '#FEFEFF',
          backgroundColor: '#70AE6E',
        },
        secondary: {
          light: '#74B3CE',
          main: '#007799',
          dark: '#06547A',
          contrastText: '#172A3A',
          backgroundColor: '#007799',
        },
        error: {
          light: '#ff0000',
          main: '#ff0000',
          dark: '#ff0000',
          contrastText: '#FEFEFF',
          backgroundColor: '#ff0000',
        },
        background: {
          paper: '#FFFFFF',
          default: '#FFFFFF',
        },
      },
      typography: {
        useNextVariants: true,
        fontSize: 10,
        htmlFontSize: 10,
        fontFamily: ['Lato', 'sans-serif'],
      },
    };
  }

  static connectEnergy() {
    return {
      palette: {
        primary: {
          light: '#66a9f6',
          main: '#277ac3',
          dark: '#004f92',
          contrastText: '#ffffff',
        },
        secondary: {
          light: '#4b4547',
          main: '#231e20',
          dark: '#000000',
          contrastText: '#ffffff',
        },
        error: {
          light: '#e57373',
          main: '#f44336',
          dark: '#d32f2f',
          contrastText: '#fff',
        },
        background: {
          paper: '#fefeff',
          default: '#fafafa',
        },
      },
      typography: {
        useNextVariants: true,
        fontSize: 10,
        htmlFontSize: 10,
        fontFamily: ['Lato', 'sans-serif'],
      },
    };
  }

  static darkWoodRiver() {
    return {
      palette: {
        type: 'dark',
        primary: {
          main: '#212121',
        },
        secondary: {
          main: '#88BC86',
        },
      },
      typography: {
        useNextVariants: true,
        fontSize: 10,
        htmlFontSize: 10,
        fontFamily: ['Lato', 'sans-serif'],
      },
    };
  }
}

export default Themes;
