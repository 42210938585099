import React, { Component } from 'react';
import PropTypes from 'prop-types';
import notifyBannerAction from 'actions/NotifyBannerAction';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Form from 'sharedComponents/ReduxForms/Form/Form';
import Submit from 'sharedComponents/ReduxForms/Submit/Submit';
import { change } from 'redux-form';
import graphqlObj from 'utils/graphqlObj';
import DateHelpers from 'utils/DateHelpers';
import axios from 'axios';
import HeaderCell from './components/HeaderCell/HeaderCell';
import BodyCells from './components/BodyCells/BodyCells';
import MonthTotalCell from './components/MonthTotalCell/MonthTotalCell';
import styles from './PathTable.module.scss';

export const infoArray = (formState = {}) => {
  if (formState.PathForm && formState.PathForm.values) {
    const key = Object.keys(formState.PathForm.values)[0];
    const fieldArray = formState.PathForm.values[key] || [];
    return fieldArray.sort((a, b) => a.groupId - b.groupId);
  }
  return [];
};

export const focusNextDay = (lastDayOfMonth, name) => {
  const firstIndexOfDay = name.lastIndexOf('-') + 1;
  const lastIndexOfDay = name.indexOf('[');
  const nextDay =
    parseInt(name.substring(firstIndexOfDay, lastIndexOfDay), 10) + 1;

  let stringDate = nextDay < 10 ? `0${nextDay}` : nextDay;
  let newFocus;
  if (nextDay <= lastDayOfMonth) {
    stringDate = nextDay < 10 ? `0${nextDay}` : nextDay;
    newFocus = `${
      name.substring(0, firstIndexOfDay) +
      stringDate +
      name.substring(lastIndexOfDay)
    }`;
  } else {
    newFocus = `${name.substring(0, firstIndexOfDay)}01${name.substring(
      lastIndexOfDay
    )}`;
  }
  return { newFocus, nextDay };
};

export const focusNextIndex = (lastDayOfMonth, rows, nextDay, newFocus) => {
  if (nextDay > lastDayOfMonth) {
    const firstIndexOfIndex = newFocus.indexOf('[') + 1;
    const lastIndexOfIndex = newFocus.indexOf(']');
    let newIndex =
      parseInt(newFocus.substring(firstIndexOfIndex, lastIndexOfIndex), 10) + 1;
    if (newIndex > rows) newIndex = 0;
    return `${
      newFocus.substring(0, firstIndexOfIndex) +
      newIndex +
      newFocus.substring(lastIndexOfIndex)
    }`;
  }
  return newFocus;
};

export const addEventListener = (lastDayOfMonth, rows) => {
  function logKey(e) {
    const { name } = e.target;
    if (e.key === 's' && name.includes('[dth]')) {
      const focus = focusNextDay(lastDayOfMonth, name);
      const newFocus = focusNextIndex(
        lastDayOfMonth,
        rows,
        focus.nextDay,
        focus.newFocus
      );
      const querySelector = document.querySelector(`input[name="${newFocus}"]`);
      if (querySelector) querySelector.focus();
    }
  }

  document.addEventListener('keypress', logKey);
};

export class PathTable extends Component {
  state = {
    showVolumes: false,
  };

  componentDidMount() {
    if (
      this.props.rows[0] &&
      this.props.rows[0].receipts &&
      this.props.rows[0].receipts.length
    ) {
      const rows = this.props.rows[0].receipts.length - 1;
      addEventListener(DateHelpers.daysInMonth(this.props.startDate), rows);
    }
  }

  componentWillUnmount() {
    document.onkeydown = null;
  }

  toggleVolumes = () => {
    this.setState((prevState) => ({
      showVolumes: !prevState.showVolumes,
    }));
  };

  archivePaths = async (values, groupId, archived, dth) => {
    const pathPromises = Object.keys(values).map(async (key) => {
      const newValues = values[key].map((value) => {
        if (value.groupId === groupId) {
          return {
            ...value,
            ...{
              archived: !archived,
              dth: parseInt(dth, 10),
            },
          };
        }
        return value;
      });
      await this.props.dispatch(change('PathForm', key, newValues));
      return newValues;
    });
    const resolvedPaths = await Promise.all(pathPromises);
    return resolvedPaths.flat().filter((path) => path.archived && path);
  };

  toggleArchived = async (row) => {
    const { values } = this.props.formState.PathForm;
    const paths = await this.archivePaths(
      values,
      row.groupId,
      row.archived,
      row.dth
    );
    await this.editPathBatch(paths);
  };

  editPathBatch = async (paths) => {
    try {
      const queryString = {
        query: `mutation {
          editPathBatch (
            paths: ${graphqlObj(paths)}
          ) {
            id
            gasDay
            receiptLocationId
            deliveryLocationId
            pipelineId
            groupId
            transportId
            fuelPercent
            companyId
            dth
            archived
          }
        }`,
      };
      const data = await axios.post('/graphql/', queryString);
      const { errors } = data.data;
      if (errors) {
        if (errors[1]) {
          const userLock = JSON.parse(errors[1].message);
          this.props.lockPaths(userLock.id);
        }
        throw new Error(errors[0].message);
      }
      return data.data.data.editPathBatch;
    } catch (error) {
      return this.props.dispatch(
        notifyBannerAction({
          message: error.toString(),
          showBanner: true,
          error: true,
        })
      );
    }
  };

  initialValues = () => {
    const value = {};
    this.props.rows.forEach((element) => {
      value[element.name] = element.receipts || [];
    });
    return value;
  };

  sumDeliveryVolume = () =>
    this.props.rows.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.volume, 10),
      0
    );

  sumReceiptVolumes = (groupId = null) => {
    let value = 0;
    const formValues = this.props.formState.PathForm
      ? this.props.formState.PathForm.values
      : {};

    Object.values(formValues).forEach((paths) => {
      value += paths.reduce((accumulator, currentValue) => {
        const shouldGroup = groupId ? currentValue.groupId === groupId : true;
        if (!currentValue.archived && shouldGroup) {
          return accumulator + parseInt(currentValue.dth, 10);
        }
        return accumulator;
      }, 0);
    });

    return value;
  };

  render() {
    return (
      <Paper className={styles.root}>
        <Form
          form="PathForm"
          onSubmit={() => {}}
          initialValues={this.initialValues()}
          classes={styles}
        >
          <Table className={styles.table} size="small">
            <HeaderCell
              disabled={this.props.disabled}
              showVolumes={this.state.showVolumes}
              toggleVolumes={this.toggleVolumes}
              toggleArchived={this.toggleArchived}
              rows={this.props.rows}
              sumReceiptVolumes={this.sumReceiptVolumes}
              formState={this.props.formState}
              dispatch={this.props.dispatch}
              sumDeliveryVolume={this.sumDeliveryVolume}
              startDate={this.props.startDate}
              editPathBatch={this.editPathBatch}
              summaryViewCallBack={this.props.summaryViewCallBack}
            />
            <BodyCells
              locationId={this.props.locationId}
              disabled={this.props.disabled}
              rows={this.props.rows}
              showVolumes={this.state.showVolumes}
              editPathBatch={this.editPathBatch}
              sumDeliveryVolume={this.sumDeliveryVolume}
              sumReceiptVolumes={this.sumReceiptVolumes}
              formState={this.props.formState}
              dispatch={this.props.dispatch}
            />
            <MonthTotalCell
              showVolumes={this.state.showVolumes}
              sumReceiptVolumes={this.sumReceiptVolumes}
              formState={this.props.formState}
              rows={this.props.rows}
              sumDeliveryVolume={this.sumDeliveryVolume}
            />
          </Table>
          {/* need submit so redux forms works  */}
          <Submit hide />
        </Form>
      </Paper>
    );
  }
}

export default PathTable;

PathTable.propTypes = {
  locationId: PropTypes.number.isRequired,
  lockPaths: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  summaryViewCallBack: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.shape({
    PathForm: PropTypes.shape({
      fields: PropTypes.shape(),
      values: PropTypes.shape(),
    }),
  }),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      receipts: PropTypes.arrayOf(PropTypes.shape()),
    }).isRequired
  ).isRequired,
};

PathTable.defaultProps = {
  formState: {
    PathForm: {
      fields: {},
      values: {},
    },
  },
};
