/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RDGridHelpers from 'utils/RDGridHelpers';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import NormalizeVolumeForm from './components/NormalizeVolumeForm/NormalizeVolumeForm';
import DeliveryPaths from './components/DeliveryPaths/DeliveryPaths';

export class NormalizeVolume extends Component {
  state = {
    open: false,
  }

  toggleDialog = async () => {
    this.setState(oldState => ({
      open: !oldState.open,
    }));
  };

  formatVolume = RDGridHelpers.formatNumber(this.props.volume)

  render() {
    return (
      <MuiDialog
        open={this.state.open}
        toggleDialog={this.toggleDialog}
        fullScreen
        titleText={`Volume: ${this.formatVolume}`}
        buttonText={this.formatVolume}
      >
        {this.state.open && (
          <>
            <DeliveryPaths
              gasDay={this.props.gasDay}
              locationId={this.props.locationId}
            />
            <NormalizeVolumeForm
              gasDay={this.props.gasDay}
              locationId={this.props.locationId}
            />
          </>
        )}

      </MuiDialog>
    );
  }
}

export default NormalizeVolume;

NormalizeVolume.propTypes = {
  volume: PropTypes.number.isRequired,
  gasDay: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
};

NormalizeVolume.defaultProps = {};
