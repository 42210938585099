// takes json array and returns it in a csv in base64 format
const base64Download = (base64, fileName) => {
  const csvURL = window.URL.createObjectURL(base64);
  const tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName);
  tempLink.click();
  tempLink.remove();
};

export default base64Download;
