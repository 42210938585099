import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import FileCopy from '@material-ui/icons/FileCopy';
import {
  Tooltip,
  Avatar,
} from '@material-ui/core';
import notifyBannerAction from 'actions/NotifyBannerAction';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import { Form, Field } from 'react-final-form';
import DateHelpers from 'utils/DateHelpers';
import styles from './DupPaths.module.scss';

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['selectedDate', 'toDate'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
};

export class DupPaths extends Component {
  state = {
    open: false,
  }

  toggleDialog = () => {
    this.setState(prevState => ({
      open: !prevState.open,
      message: '',
    }));
  }

  duplicatePaths = async (selectedDate, toDate) => {
    const query = {
      query: `mutation {
        duplicatePaths (
          selectedDate: "${selectedDate}",
          toDate: "${toDate}"
        )
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      if (res.data.errors) {
        return this.props.dispatch(notifyBannerAction({
          message: res.data.errors[0].message,
          showBanner: true,
          error: true,
        }));
      }

      const message = res.data.data.duplicatePaths;
      return this.setState({ message });
    } catch (error) {
      this.props.dispatch(notifyBannerAction({
        message: error.toString(),
        showBanner: true,
        error: true,
      }));
    }
    return null;
  };

  onSubmit = async (values) => {
    const selectedDate = new Date(`${values.selectedDate}-1`);
    const toDate = new Date(`${values.toDate}-1`);
    await this.duplicatePaths(selectedDate, toDate);
  }

  initialValues = () => {
    const date = DateHelpers.addMonthsToDate(new Date(this.props.startDate), 0);
    return {
      selectedDate: DateHelpers.formatYearMonth(new Date(this.props.startDate)),
      toDate: DateHelpers.formatYearMonth(date),
    };
  }

  submitText = (formState = {}) => {
    if (formState.values) {
      return `copy paths from ${formState.values.selectedDate} to ${formState.values.toDate}`;
    }
    return 'Select toDate';
  }

  render() {
    return (
      <>
        <MuiDialog
          open={this.state.open}
          toggleDialog={this.toggleDialog}
          titleText="copy all paths in the summary view to new a new month"
        >
          <Form
            onSubmit={this.onSubmit}
            validate={validate}
            initialValues={this.initialValues()}
          >
            {({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field
                    name="toDate"
                    type="month"
                    label="copy to month"
                  >
                    {field => (<Input field={field} />)}
                  </Field>
                </div>
                <div>
                  <Submit submitting={submitting}>
                    {this.submitText(form.getState())}
                  </Submit>
                </div>
              </form>
            )}
          </Form>
          {this.state.message}
        </MuiDialog>
        <Tooltip title="copy paths in view to new month" aria-label="add">
          <Avatar
            className={styles.onClick}
            onClick={this.toggleDialog}
          >
            <FileCopy />
          </Avatar>
        </Tooltip>
      </>
    );
  }
}

export default DupPaths;

DupPaths.propTypes = {
  dispatch: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
};

DupPaths.defaultProps = {
};
