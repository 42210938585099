import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Timeline from '@material-ui/icons/Timeline';

const Pathing = ({ reactChangeRoute }) => (
  <List component="nav">
    <ListItem button onClick={() => { reactChangeRoute('pathing'); }}>
      <ListItemIcon>
        <Timeline />
      </ListItemIcon>
      <ListItemText inset primary="Pathing" />
    </ListItem>
  </List>
);

export default Pathing;

Pathing.propTypes = {
  reactChangeRoute: PropTypes.func.isRequired,
};
