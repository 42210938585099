import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import graphqlObj from 'utils/graphqlObj';
import Fuse from 'fuse.js';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import InputFieldArray from 'sharedComponents/FinalForm/InputFieldArray/InputFieldArray';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';

export class RegionTable extends Component {
  state = {
    result: [],
  };

  componentDidMount() {
    this.search();
  }

  onSubmit = async (values) => {
    const difference = values.choiceParams.filter(x => (
      !this.state.result.includes(x)
    ));

    if (difference.length > 0) {
      const queryString = {
        query: `mutation {
          editChoiceParams(params: ${graphqlObj(difference)}) {
            id
            region
            paramType
            paramValue
            effectiveDate
            billClass
            formula
            updatedAt
           }
        }`,
      };
      const req = await axios.post('/graphql/', queryString);
      this.props.sortParams(req.data.data.editChoiceParams);
    }
  };

  search = (search) => {
    const options = {
      shouldSort: true,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ['paramType', 'billClass'],
    };
    const fuse = new Fuse(this.props.params, options);
    const result = search ? fuse.search(search) : this.props.params;
    this.setState({ result });
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <TextField
          onChange={e => this.search(e.target.value)}
          label="Search by param type or bill class"
          margin="normal"
          variant="outlined"
          style={{ width: '50%' }}
        />
        <Form
          onSubmit={this.onSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{ choiceParams: this.state.result }}
        >
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">param type</TableCell>
                    <TableCell align="center">bill class</TableCell>
                    <TableCell align="center">Param Value</TableCell>
                    <TableCell align="center">Effective Date</TableCell>
                    <TableCell align="center">Updated At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="choiceParams">
                    {({ fields }) => fields.map((name, i) => (
                      <TableRow key={i}>
                        <TableCell align="center">
                          {fields.value[i].paramType}
                        </TableCell>
                        <TableCell align="center">
                          {fields.value[i].billClass}
                        </TableCell>
                        <TableCell align="center">
                          <InputFieldArray
                            name={name}
                            objKey="paramValue"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <InputFieldArray
                            name={name}
                            type="date"
                            objKey="effectiveDate"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {new Date(fields.value[i].updatedAt).toDateString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </FieldArray>
                </TableBody>
              </Table>
              <Submit submitting={submitting}>
                Update
              </Submit>
            </form>
          )}
        </Form>
      </Grid>
    );
  }
}

export default RegionTable;

RegionTable.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape({
    billClass: PropTypes.string,
    paramType: PropTypes.string,
    region: PropTypes.string,
    effectiveDate: PropTypes.string,
    paramValue: PropTypes.string,
  })).isRequired,
  sortParams: PropTypes.func.isRequired,
};
