import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabContainer from './components/TabContainer/TabContainer';

/**
 * MuiTabs
 * pass key down to children to hide and show on correct tab
 */

class MuiTabs extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
    if (this.props.handleClick) this.props.handleClick(this.props.tabLabels[value]);
  };

  renderChildren = (child, i = 0) => (
    <TabContainer key={i}>
      { child.key === this.state.value.toString() ? child : <div /> }
    </TabContainer>
  );

  render() {
    const {
      xs,
      md,
      lg,
      tabLabels,
      children,
      color,
      centered,
      rightContent,
      leftContent,
      icons,
      justify,
      position,
      variant,
      tabsWidth,
    } = this.props;

    return (
      <Grid container item xs={Number(xs)} md={Number(md)} lg={Number(lg)}>
        <AppBar position={position} color={color}>
          <Grid container direction="row" justify={justify} wrap="nowrap">
            {leftContent}
            <Grid item justify="center" alignItems="center">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                centered={centered}
                variant={variant}
                style={{ width: tabsWidth }}
                TabIndicatorProps={{ style: { backgroundColor: '#FFF' } }}
              >
                {tabLabels.map((label, i) => <Tab key={i} label={label} icon={icons[i]} />)}
              </Tabs>
            </Grid>
            {rightContent}
          </Grid>
        </AppBar>
        {children.length > 0 && children.map((child, i) => this.renderChildren(child, i))}
        {!children.length && this.renderChildren(children)}
      </Grid>
    );
  }
}

export default MuiTabs;

MuiTabs.propTypes = {
  /** array of string labels for tabs */
  tabLabels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  /** children add key prop to child to match on selected tab */
  children: PropTypes.node.isRequired,
  /** small screen size grid space */
  xs: PropTypes.string,
  /** medium screen size grid space */
  md: PropTypes.string,
  /** large screen size grid space */
  lg: PropTypes.string,
  /** The color of the component.
   * It supports those theme colors that make sense for this component.
   */
  color: PropTypes.string,
  justify: PropTypes.string,
  position: PropTypes.string,
  variant: PropTypes.string,
  tabsWidth: PropTypes.string,
  handleClick: PropTypes.func,
  centered: PropTypes.bool,
  rightContent: PropTypes.node,
  leftContent: PropTypes.node,
  icons: PropTypes.arrayOf(PropTypes.node),
};

MuiTabs.defaultProps = {
  xs: '12',
  md: '12',
  lg: '12',
  color: 'primary',
  handleClick: undefined,
  centered: false,
  rightContent: null,
  leftContent: null,
  justify: undefined,
  icons: [],
  position: 'static',
  variant: undefined,
  tabsWidth: '100%',
};
