

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Clear from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DialogTitle from '@material-ui/core/DialogTitle';
import SlideUp from './components/SlideUp/SlideUp';
import styles from './MuiDialog.module.scss';

/**
 * MuiDialog is a pop up component
 */
const MuiDialog = forwardRef(({
  open,
  toggleDialog,
  fullWidth,
  width,
  children,
  titleText,
  buttonText,
  buttonVariant,
  fullScreen,
  disableEscapeKeyDown,
  disableBackdropClick,
  disableExitButton,
  buttonSize,
}, ref) => (
  <>
    {buttonText && (
      <Button
        color="primary"
        size={buttonSize}
        onClick={toggleDialog}
        data-testid="MuiDialogToggleButton"
        variant={buttonVariant}
      >
        {buttonText}
      </Button>
    )}
    {open && (
      <Dialog
        ref={ref}
        open={open}
        TransitionComponent={SlideUp}
        keepMounted
        onClose={toggleDialog}
        classes={styles}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth="xl"
        disableEscapeKeyDown={disableEscapeKeyDown}
        disableBackdropClick={disableBackdropClick}
      >
        <DialogActions
          style={{ minWidth: '800px', width }}
          className={styles.dialogActions}
        >
          <DialogTitle id="alert-dialog-title">
            {titleText}
          </DialogTitle>
          {!disableExitButton && (
            <Tooltip title="Close Dialog">
              <IconButton
                id="muiCloseDialog"
                aria-label="Close Dialog"
                onClick={toggleDialog}
                size="small"
              >
                <Clear />
              </IconButton>
            </Tooltip>
          )}
        </DialogActions>
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    )}
  </>
));

export default MuiDialog;

MuiDialog.propTypes = {
  /** text for btn if here btn will show  */
  buttonText: PropTypes.string,
  /** text for title  */
  titleText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  /** children to be passed down  */
  children: PropTypes.node.isRequired,
  /** function to controls open close state of dialog  */
  toggleDialog: PropTypes.func.isRequired,
  /** is it open  */
  open: PropTypes.bool.isRequired,
  /** take up all of the screen  */
  fullWidth: PropTypes.bool,
  /** width  */
  width: PropTypes.string,
  /** fullScreen MUI pro */
  fullScreen: PropTypes.bool,
  /** as titled */
  disableEscapeKeyDown: PropTypes.bool,
  /** as titled */
  disableBackdropClick: PropTypes.bool,
  /** used for hiding the X button to close the modal */
  disableExitButton: PropTypes.bool,
  /** Variant of the button  */
  buttonVariant: PropTypes.string,
  /** Size of the button ['small', 'medium', 'large'] */
  buttonSize: PropTypes.string,
};

MuiDialog.defaultProps = {
  buttonVariant: 'standard',
  buttonSize: 'medium',
  buttonText: '',
  titleText: '',
  width: '100%',
  fullWidth: false,
  fullScreen: false,
  disableEscapeKeyDown: false,
  disableBackdropClick: false,
  disableExitButton: false,
};
