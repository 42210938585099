import React from 'react';
import PropTypes from 'prop-types';
import styles from './VolumeCell.module.scss';

const VolumeCell = ({ month, usage }) => (
  <div className={styles.fullCell}>
    <div className={styles.monthCell}>{month}</div>
    <div className={styles.volumeCell}>{usage}</div>
  </div>
);

export default VolumeCell;

VolumeCell.propTypes = {
  month: PropTypes.string.isRequired,
  usage: PropTypes.number.isRequired,
};
