import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import {
  ArrowRight,
  ArrowLeft,
} from '@material-ui/icons';
import RDGridHelpers from 'utils/RDGridHelpers';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';

import styles from './LocationSummary.module.scss';

export class LocationSummary extends Component {
  state = {
    rows: [],
    loading: true,
    error: '',
  };

  async componentDidMount() {
    await this.dateRangeSummaryByPipeline();
  }

  dateRangeSummaryByPipeline = async () => {
    this.setState({ loading: true });
    const query = {
      query: `{
        pathingReports(
          id: ${this.props.row.id},
          startDate: "${this.props.startDate}",
          endDate: "${this.props.endDate}"
          companyId: ${this.props.companyId}
        ) {
          id,
          name,
          volume,
          pipelineName,
          pipelineId
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
      const rows = this.props.sortByName(res.data.data.pathingReports);
      return this.setState({
        loading: false,
        rows,
        error: '',
      });
    } catch (err) {
      return this.setState({
        loading: false,
        error: err.toString(),
      });
    }
  };

  viewPaths = async (i, row) => {
    await this.props.selectLocation(row, `${row.name}-${i}`);
  }

  getLocationSummary = row => this.props.toggleDialog(row.pipelineId, row.name, row.volume, row.id)

  render() {
    return (
      <>
        {this.state.error && (
          <span style={{ color: 'red' }}>
            {this.state.error}
          </span>
        )}
        {this.state.loading ? (
          <AppLoader loading={this.state.loading} />
        ) : (
          <List component="div" disablePadding className={styles.list}>
            {this.state.rows.map((row, i) => (
              <ListItem
                selected={this.props.selectedLocation === `${row.name}-${i}`}
                key={`${row.name}-${i}`}
              >
                <ListItemText
                  className={styles.onClick}
                  onClick={() => this.getLocationSummary(row)}
                  primary={row.name}
                  secondary={RDGridHelpers.formatNumber(row.volume)}
                />
                {this.props.selectedLocation === `${row.name}-${i}`
                  ? (<ArrowRight id="ArrowRight" />)
                  : (
                    <IconButton
                      id="ArrowLeft"
                      onClick={() => this.viewPaths(i, row)}
                    >
                      <ArrowLeft />
                    </IconButton>
                  )
                }
              </ListItem>
            ))}
          </List>
        )}
      </>
    );
  }
}

export default LocationSummary;

LocationSummary.propTypes = {
  companyId: PropTypes.number.isRequired,
  sortByName: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectedLocation: PropTypes.string.isRequired,
};
