export const LOG_IN_REQUEST = 'LOG_IN_REQUEST';
export const EMAIL_SIGN_IN_START = 'EMAIL_SIGN_IN_START';
export const EMAIL_SIGN_IN_COMPLETE = 'EMAIL_SIGN_IN_COMPLETE';

export const GET_USER_DATA_REQUEST = 'GET_USER_DATA_REQUEST';
export const GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS';
export const GET_USER_DATA_FAILURE = 'GET_USER_DATA_FAILURE';

export const CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST';
export const CUSTOMERS_SUCCESS = 'CUSTOMERS';
export const CUSTOMERS_FAILURE = 'CUSTOMERS_FAILURE';

export const NOTIFY_BANNER_REQUEST = 'NOTIFY_BANNER_REQUEST';
export const NOTIFY_BANNER_SUCCESS = 'NOTIFY_BANNER_SUCCESS';

export const USER_SELECTED_CUSTOMER_REQUEST = 'USER_SELECTED_CUSTOMER_REQUEST';
export const USER_SELECTED_CUSTOMER_SUCCESS = 'USER_SELECTED_CUSTOMER_SUCCESS';
export const USER_SELECTED_CUSTOMER_FAILURE = 'USER_SELECTED_CUSTOMER_FAILURE';

export const SET_THEME_REQUEST = 'SET_THEME_REQUEST';
export const SET_THEME_SUCCESS = 'SET_THEME_SUCCESS';
