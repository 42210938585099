import axios from 'axios';

export const graphqlCall = async (queryString) => {
  console.log('GraphQL call:', queryString);
  let res;
  try {
    res = await axios.post('/graphql/', queryString);
  } catch (error) {
    throw new Error(error);
  }
  if (res.data?.errors?.length > 0) {
    throw new Error(res.data.errors[0].message);
  }
  return res.data.data;
};

export default graphqlCall;
