import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Input from 'sharedComponents/ReduxForms/Input/Input';

export const ReduxFormInput = ({
  name,
  label,
}) => (
  <Field
    name={name}
    label={label}
    component={Input}
  />
);

export default ReduxFormInput;

ReduxFormInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

ReduxFormInput.defaultProps = {
  name: 'promoCode',
  label: 'Promo Code',
};
