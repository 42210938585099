import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import setThemeAction from 'actions/SetThemeAction';
import PropTypes from 'prop-types';
import {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AppLoader} from 'sharedComponents/AppLoader/AppLoader';
import Themes from '../../theme';

export class SiteThemeProvider extends Component {
  componentDidMount = () => {
    this.setApplicationTheme();
  }

  setApplicationTheme = () => {
    const defaultTheme = createMuiTheme(Themes.pickTheme());
    return this.props.setThemeAction(defaultTheme);
  }

  render() {
    if (this.props.theme.isLoading) return <AppLoader />;
    return (
      <MuiThemeProvider theme={this.props.theme.theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => ({
  theme: state.theme,
});

/* istanbul ignore next */
const mapDispatchToProps = dispatch => (
  bindActionCreators({setThemeAction}, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(SiteThemeProvider);

SiteThemeProvider.propTypes = {
  setThemeAction: PropTypes.func.isRequired,
  theme: PropTypes.shape({isLoading: PropTypes.bool, theme: PropTypes.shape({})}).isRequired,
  children: PropTypes.node.isRequired,
};
