/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import graphqlObj from 'utils/graphqlObj';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import NormalizeVolumeTable from './components/NormalizeVolumeTable/NormalizeVolumeTable';

export class NormalizeVolumeForm extends Component {
  state = {
    dealDetailVolumeNormalizeMarketTrue: [],
    dealDetailVolumeNormalizeMarketFalse: [],
    message: '',
  }

  async componentDidMount() {
    await this.dealsNormalizeVolumeSummary();
  }

  displayNormalizedVolume = volumes => volumes.map(volume => ({
    ...volume,
    actualVolume: volume.actualVolume || volume.contractVolume,
  }))

  dealsNormalizeVolumeSummary = async () => {
    this.setState({ message: null });
    const query = {
      query: `{
      dealsNormalizeVolumeSummary(
          begDate: "${this.props.gasDay}",
          endDate:  "${this.props.gasDay}",
          locationId: ${this.props.locationId}
      ) {
          customerId
          customerName
          dealId
          pipelineName
          dealDetailVolumeNormalizesId
          dealDetailVolumeId
          tradeDate
          actualVolume
          contractVolume
          gasDay
          locationId
          market
          indexType
      }
    }`,
    };

    let res = null;

    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
    // TODO: airbrake and support email message
      return this.setState({ message: 'Sorry something went wrong!' });
    }
    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ message: res.data.errors[0].message });
    }

    const volumes = this.displayNormalizedVolume(res.data.data.dealsNormalizeVolumeSummary);

    return this.setState({
      dealDetailVolumeNormalizeMarketTrue: volumes.filter(x => x.market),
      dealDetailVolumeNormalizeMarketFalse: volumes.filter(x => !x.market),
    });
  };

  normalizeVolumes = (state, utils) => {
    const volumes = state.formState.values.dealDetailVolumeNormalize.map(item => ({
      ...item,
      actualVolume: item.actualVolume ? item.actualVolume : item.contractVolume,
    }));
    utils.changeValue(state, 'dealDetailVolumeNormalize', () => volumes);
  };

  onSubmit = async (values) => {
    const ddvn = [
      ...values.dealDetailVolumeNormalizeMarketTrue,
      ...values.dealDetailVolumeNormalizeMarketFalse,
    ].map(volume => ({
      id: volume.dealDetailVolumeNormalizesId,
      actualVolume: parseFloat(volume.actualVolume || 0),
      dealDetailVolumeId: volume.dealDetailVolumeId,
    }));
    const query = {
      query: `mutation {
        updateDealDetailsVolumeNormalizes(
          dealDetailsVolumeNormalizes: ${graphqlObj(ddvn)},
        )
      }`,
    };

    let res = null;

    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
      // TODO: airbrake and support email message
      return this.setState({ message: 'Sorry something went wrong!' });
    }
    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ message: res.data.errors[0].message });
    }
    return this.setState({ message: res.data.data.updateDealDetailsVolumeNormalizes });
  };

  showSubmit = () => (
    this.state.dealDetailVolumeNormalizeMarketTrue.length
    || this.state.dealDetailVolumeNormalizeMarketFalse.length
  );

  render() {
    return (
      <Form
        onSubmit={this.onSubmit}
        mutators={{
          ...arrayMutators,
          normalizeVolumes: (args, state, utils) => {
            this.normalizeVolumes(state, utils);
          },
          displayTotal: ([fieldName], state) => {
            const sum = (total, v) => parseInt(total, 10) + parseInt(v.actualVolume, 10) || 0;
            return state.formState.values[fieldName].reduce(sum, 0);
          },
        }}
        initialValues={{
          dealDetailVolumeNormalizeMarketTrue: this.state.dealDetailVolumeNormalizeMarketTrue,
          dealDetailVolumeNormalizeMarketFalse: this.state.dealDetailVolumeNormalizeMarketFalse,
        }}
      >
        {({ handleSubmit, submitting, form }) => (
          <form onSubmit={handleSubmit}>
            <>
              {this.state.dealDetailVolumeNormalizeMarketTrue.length ? (
                <NormalizeVolumeTable
                  fieldName="dealDetailVolumeNormalizeMarketTrue"
                  volume={form.mutators.displayTotal('dealDetailVolumeNormalizeMarketTrue')}
                />
              ) : <p>No Market True volumes</p>}
              {this.state.dealDetailVolumeNormalizeMarketFalse.length ? (
                <NormalizeVolumeTable
                  fieldName="dealDetailVolumeNormalizeMarketFalse"
                  volume={form.mutators.displayTotal('dealDetailVolumeNormalizeMarketFalse')}
                />
              ) : <p>No Market False volumes</p>}
            </>
            {this.state.message && (
            <h3>{this.state.message}</h3>
            )}
            {this.showSubmit() ? (
              <Submit submitting={submitting}>
                Update
              </Submit>
            ) : null}
          </form>
        )}
      </Form>
    );
  }
}

export default NormalizeVolumeForm;

NormalizeVolumeForm.propTypes = {
  gasDay: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
};

NormalizeVolumeForm.defaultProps = {};
