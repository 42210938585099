import React, { Component } from 'react';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import jsonToCsv from 'utils/jsonToCsv';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import arrayMutators from 'final-form-arrays';
import ReactDropZone from 'sharedComponents/FinalForm/ReactDropZone/ReactDropZone';
import { Form } from 'react-final-form';
import styles from './BheResponse.module.scss';

export class BheResponse extends Component {
  state = {
    error: '',
  };

  onSubmit = async (values) => {
    const query = {
      query: `mutation {
        updateChoiceSelectionsFromBheCsv(
          csv: "${values.csv[0]}"
        )
      }`,
    };

    let res = null;

    try {
      res = await axios.post('/graphql/', query);
      if (res.data.errors && res.data.errors.length > 0) {
        return this.setState({ error: JSON.stringify(res.data.errors), message: '' });
      } if (res.data.data) {
        return this.setState({ error: '', message: res.data.data.updateChoiceSelectionsFromBheCsv });
      }
    } catch (err) {
      // TODO: airbrake and support email message
      return this.setState({ error: err, message: '' });
    }
    return null;
  }

  documentRequired = (form) => {
    const { csv } = form.getState().values;
    return !(csv.length === 1);
  }

  csvExampleDownload = () => {
    const csv = jsonToCsv([{
      person_id: '',
      account_id: 'required',
      service_point_id: 'required',
      control_no: 'required',
      first_name: '',
      last_name: '',
      service_address: '',
      service_city: '',
      service_state: '',
      bill_class: '',
      supplier_detail: '',
    }]);

    const csvURL = window.URL.createObjectURL(csv);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'bhe_example_csv.csv');
    tempLink.click();
    tempLink.remove();
  }

  render() {
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Card className={styles.card}>
          <CardHeader
            avatar={(
              <Avatar>
                !
              </Avatar>
            )}
            action={(
              <IconButton
                aria-label="settings"
                onClick={this.csvExampleDownload}
              >
                <CloudDownload />
              </IconButton>
              )}
            title="Choice Response Email"
            subheader="click icon to download test csv"
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              drag and drop csv or select it to update choice selections
            </Typography>

            <Form
              onSubmit={this.onSubmit}
              mutators={{ ...arrayMutators }}
              initialValues={{
                csv: [],
                billClass: 'AGR',
              }}
            >
              {({ handleSubmit, submitting, form }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <ReactDropZone name="csv" acceptImageTypes={['text/csv']} />
                  </div>
                  <div>
                    <p>{this.documentRequired(form) ? 'csv from bhe is required' : ''}</p>
                    <Submit submitting={submitting} disabled={this.documentRequired(form)}>
                      Update Selections
                    </Submit>
                    {this.state.error && (
                    <span className={styles.error}>
                      {this.state.error}
                    </span>
                    )}
                    {this.state.message && (
                    <span>
                      {this.state.message}
                    </span>
                    )}
                  </div>
                </form>
              )}
            </Form>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

export default BheResponse;

BheResponse.propTypes = {};

BheResponse.defaultProps = {
  quote: {},
};
