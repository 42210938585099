import React from 'react';
import PropTypes from 'prop-types';
import Refresh from '@material-ui/icons/Refresh';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './RefreshPathView.module.scss';

export const RefreshPathView = ({ pathViewCallBack }) => (
  <Tooltip title="refresh paths">
    <Avatar
      className={styles.onClick}
      onClick={pathViewCallBack}
    >
      <Refresh />
    </Avatar>
  </Tooltip>
);

export default RefreshPathView;

RefreshPathView.propTypes = {
  pathViewCallBack: PropTypes.func.isRequired,
};

RefreshPathView.defaultProps = {};
