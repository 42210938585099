import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import {Field} from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';
import Radio from 'sharedComponents/FinalForm/Radio/Radio';

export const required = value => (value ? undefined : 'Required');

export const disabledInput = (disabled = false) => {
  if (disabled) return true;
  return false;
};

export const ProgramOption = ({
  inputFieldName,
  radioFieldName,
  radioFieldValue,
  disabled,
  setValue,
  label,
}) => (
  <Grid
    item
    xs={12}
    container
    justify="center"
    alignItems="center"
    direction="row"
  >
    <Grid item xs={2}>
      <Field
        name={radioFieldName}
        type="radio"
        value={radioFieldValue}
      >
        {field => <Radio field={field} />}
      </Field>
    </Grid>
    <Grid item xs={10}>
      <Field
        name={inputFieldName}
        label={label}
        type="number"
        validate={required}
      >
        {field => (
          <Input
            width="80%"
            field={field}
            disabled={disabledInput(disabled)}
            setValue={setValue}
          />
        )}
      </Field>
    </Grid>
  </Grid>
);

export default ProgramOption;

ProgramOption.propTypes = {
  label: PropTypes.string.isRequired,
  inputFieldName: PropTypes.string.isRequired,
  radioFieldName: PropTypes.string,
  radioFieldValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  setValue: PropTypes.string,
};

ProgramOption.defaultProps = {
  setValue: undefined,
  radioFieldName: 'quoteData.selectedPrice',
  disabled: false,
};
