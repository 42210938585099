import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUICheckbox from '@material-ui/core/Checkbox';

export const handleOnChange = (value, fields, index) => {
  if (!index && typeof index !== 'number') return fields.push(value);
  return fields.remove(index);
};

export const getIndex = (fieldValue, value) => {
  const parseFieldValue = typeof fieldValue === 'object' ? JSON.stringify(fieldValue) : fieldValue;
  const parseCheckBoxValue = typeof value === 'object' ? JSON.stringify(value) : value;
  return parseFieldValue === parseCheckBoxValue;
};

export const assignIndex = (fields, value) => {
  const index = fields.value.findIndex(fieldValue => getIndex(fieldValue, value));
  if (index >= 0) return index;
  return false;
};

export const CheckBoxArray = ({
  fields,
  value,
  label,
  color,
  disabled,
  callback,
  checked,
}) => {
  const index = assignIndex(fields, value);
  return (
    <FormControlLabel
      control={(
        <MUICheckbox
          id="MUICheckbox"
          value={value}
          onChange={() => {
            if (callback) callback(fields);
            else handleOnChange(value, fields, index);
          }}
          checked={checked || typeof index === 'number'}
          color={color}
          disabled={disabled}
        />
      )}
      label={label}
    />
  );
};
export default CheckBoxArray;

CheckBoxArray.propTypes = {
  /** fields of the position of the checkbox */
  fields: PropTypes.shape({}).isRequired,
  /** value of checkbox */
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  /** string representing label wanted for checkbox */
  label: PropTypes.string,
  /** string: to set radio color default to primary MUI "primary","secondary","default" */
  color: PropTypes.string,
  /** Boolean that defines whether or not you want to disable the checkbox */
  disabled: PropTypes.bool,
  /** callback to override the default callback will pass fields through to act on */
  callback: PropTypes.func,
  /** checked is if you need to manage checked value outside of component */
  checked: PropTypes.bool,
};

CheckBoxArray.defaultProps = {
  label: '',
  color: 'primary',
  disabled: false,
  checked: undefined,
  callback: undefined,
};
