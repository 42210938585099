import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Input from 'sharedComponents/FinalForm/Input/Input';
import DateHelpers from 'utils/DateHelpers';
import { Form, Field } from 'react-final-form';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';

export class SearchNacha extends Component {
  state = {
    error: null,
    loading: true,
  };

  async componentDidMount() {
    await this.loadNachaReport(
      DateHelpers.formatDate(DateHelpers.removeDaysFromDate(1)),
      DateHelpers.formatDate(new Date()),
    );
  }

  loadNachaReport = async (startDate = null, endDate = null) => {
    let res;
    const queryArgs = startDate && endDate
      ? `(startDate: "${startDate}", endDate: "${endDate}" )`
      : '';

    try {
      const queryString = {
        query: `{
        nachaReport${queryArgs} {
          customerName
          customerId
          invoiceDate
          invoiceNumber
          pmtDue
          invTotal
          balDue
          achAccount
          achRouting
          invoiceStatusId
        }
      }`,
      };

      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return this.setState({ error: error.toString() });
    }
    if (res.data.errors && res.data.errors.length > 0) {
      return this.setState({ error: res.data.errors[0].message });
    }

    const { nachaReport } = res.data.data;
    this.props.setNachaReport(nachaReport);
    return this.setState({ loading: false });
  };

  handleDateSubmit = async ({ startDate, endDate }) => {
    await this.loadNachaReport(startDate, endDate);
  }

  required = value => (value ? undefined : 'Required');

  render() {
    return (
      <Form
        onSubmit={this.handleDateSubmit}
        initialValues={{
          startDate: DateHelpers.formatDate(DateHelpers.removeDaysFromDate(1)),
          endDate: DateHelpers.formatDate(new Date()),
        }}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <Field
                name="startDate"
                label="startDate"
                type="date"
                validate={this.required}
              >
                {field => <Input field={field} />}
              </Field>
            </div>
            <div>
              <Field
                name="endDate"
                label="End Date"
                type="date"
                validate={this.required}
              >
                {field => <Input field={field} />}
              </Field>
            </div>
            <Submit submitting={submitting || this.state.loading}>
              Submit
            </Submit>
            {this.state.error && <span>{this.state.error.toString()}</span>}
          </form>
        )}
      </Form>
    );
  }
}

export default SearchNacha;

SearchNacha.propTypes = {
  setNachaReport: PropTypes.func.isRequired,
};
