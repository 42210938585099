import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const SigningComplete = ({ quote }) => (
  <Card style={{ maxWidth: '1600px' }}>
    <CardMedia
      style={{ height: '250px' }}
      image="https://woodriver.s3.us-west-1.amazonaws.com/assets/faq.png"
      title="pay less"
    />
    <CardContent>
      <Typography gutterBottom variant="h5" component="h2">
        Thank you for choosing WoodRiverEnergy as your natural gas provider
      </Typography>
      <Typography color="textSecondary" component="p">
        {`
          The Document is currently processing with Black Hills Energy. you will
          receive an email at ${quote.quoteData.currentEmail} with a link to the
          signed document once everything is finalized.
        `}
      </Typography>
      <Typography color="textSecondary" component="p">
        if you have any questions or concerns call us at 1-888-510-9315
        Or email us at sales@woodriverenergy.com.
      </Typography>
    </CardContent>

    <CardActions>
      <a href="tel:18885109315">
        <Button size="small" color="primary">
          Call Us
        </Button>
      </a>
      <a href="mailto:sales@woodriverenergy.com">
        <Button size="small" color="primary">
          Email Us
        </Button>
      </a>
    </CardActions>
  </Card>
);

export default SigningComplete;

SigningComplete.propTypes = {
  quote: PropTypes.shape({
    quoteData: PropTypes.shape({
      currentEmail: PropTypes.string,
    }),
  }).isRequired,
};

SigningComplete.defaultProps = {};
