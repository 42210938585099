import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';

export const TopSection = ({
  toggleDialog,
  openTerms,
  quote,
}) => (
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={12} md={4}>
      <TermsAndConditions
        open={openTerms}
        toggleDialog={() => toggleDialog('openTerms')}
      />
    </Grid>
    <Grid item xs={12} md={4}>
      {`Quote Id: ${quote.id}`}
    </Grid>
  </Grid>
);

export default TopSection;

TopSection.propTypes = {
  openTerms: PropTypes.bool,
  toggleDialog: PropTypes.func.isRequired,

  quote: PropTypes.shape({
    id: PropTypes.number,
  }),
};

TopSection.defaultProps = {
  openTerms: false,
  quote: {},
};
