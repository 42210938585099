import React from 'react';
import PropTypes from 'prop-types';
import graphqlCall from 'utils/graphqlCall';
import Cancel from '@material-ui/icons/Cancel';
import { IconButton, Tooltip } from '@material-ui/core';

export const DestroyAdder = ({ adderId, callback }) => {
  const deleteAdder = async () => {
    const queryString = {
      query: `mutation {
        destroyAdder(id: ${adderId})
        {id}
      }`,
    };
    try {
      const { destroyAdder } = await graphqlCall(queryString);
      return callback(destroyAdder.id);
    } catch (err) {
      return err.toString();
    }
  };

  return (
    <Tooltip title="Delete Adder" placement="top">
      <IconButton onClick={deleteAdder}>
        <Cancel />
      </IconButton>
    </Tooltip>
  );
};

export default DestroyAdder;

DestroyAdder.propTypes = {
  adderId: PropTypes.number.isRequired,
  callback: PropTypes.func.isRequired,
};
