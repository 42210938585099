import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

export const MuiTablePagination = ({
  columns,
  rows,
  rowsPerPageOptions,
  form,
}) => {
  const [state, setState] = useState({
    rowsPerPage: 10,
    page: 0,
  });

  const styles = makeStyles({
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
  })();

  const handleChangeRowsPerPage = event => setState({
    page: 0,
    rowsPerPage: +event.target.value,
  });

  const handleChangePage = (event, page) => setState({ ...state, page });

  return (
    <Paper className={styles.root}>
      <TableContainer className={styles.container}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.headerFormat ? column.headerFormat(column, i, form) : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(
              state.page * state.rowsPerPage,
              (state.page * state.rowsPerPage) + state.rowsPerPage,
            ).map((row, rowIndex) => (
              <TableRow hover tabIndex={-1} key={rowIndex}>
                {columns.map((column, columnIndex) => {
                  const value = row[column.key];
                  return (
                    <TableCell key={column.key} align={column.align}>
                      {column.format ? column.format(
                        value,
                        row,
                        columnIndex,
                        ((state.rowsPerPage * state.page) + rowIndex),
                        form,
                      ) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rows.length}
        rowsPerPage={state.rowsPerPage}
        page={state.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default MuiTablePagination;

MuiTablePagination.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    minWidth: PropTypes.number,
    align: PropTypes.string,
    format: PropTypes.func,
    headerFormat: PropTypes.func,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape()),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  // react final form added to props
  form: PropTypes.shape({}),
};

MuiTablePagination.defaultProps = {
  rows: [],
  rowsPerPageOptions: [10, 25, 100],
  form: {},
};
