import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

/**
 * Input for redux forms input field
*/

export const Input = ({
  classes,
  helperText,
  setValue,
  tempValue,
  endAdornment,
  width,
  margin,
  disabled,
  multiline,
  field: {
    input,
    label,
    meta: { error, touched },
  },
}) => {
  const [state, setState] = useState({ labelWidth: 0 });
  const inputLabelRef = useRef(null);
  const hasError = error && touched;

  useEffect(() => {
    const findNode = findDOMNode(inputLabelRef.current); // eslint-disable-line
    setState({
      labelWidth: findNode ? findNode.offsetWidth : 0,
    });
  }, []);

  const pickValue = (permanent, inputValue, temp) => {
    if (permanent) return permanent;
    if (inputValue) return inputValue;
    if (temp && !inputValue) return temp;
    return '';
  };

  const labelColor = () => {
    let style = {};
    if (hasError) {
      style = { color: 'red' };
    }
    return style;
  };

  return (
    <FormControl
      variant="outlined"
      className={classes ? classes.input : ''}
      style={{ width, margin }}
    >
      {input.type !== 'date' ? (
        <InputLabel
          style={labelColor()}
          htmlFor="component-outlined"
          ref={inputLabelRef}
        >
          {label}
        </InputLabel>
      ) : <small id="DateLabel" style={labelColor()}>{label}</small>}
      <OutlinedInput
        data-testid="FFInput"
        {...input}
        multiline={multiline}
        value={pickValue(setValue, input.value, tempValue)}
        error={hasError}
        labelWidth={state.labelWidth || 0}
        disabled={disabled}
        endAdornment={endAdornment}
      />
      {helperText && (<FormHelperText id="component-helper-text">{helperText}</FormHelperText>)}
      {hasError && (<FormHelperText id="component-error-text">{error}</FormHelperText>)}
    </FormControl>
  );
};

export default Input;

Input.propTypes = {
  /** field props for input this is passed from FinalForms Field Component */
  field: PropTypes.shape({
    /** label of input */
    label: PropTypes.string,
    input: PropTypes.shape({
      /** placeholder of input */
      placeholder: PropTypes.string,
      /** name of input */
      name: PropTypes.string,
      /** type of the input ex ( text, number, date) */
      type: PropTypes.string,
      /** Value of input */
      value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
    /** metadata regarding input state */
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
  }),
  /** disabled input from being edited */
  disabled: PropTypes.bool,
  /** classes pass down with input class to override default */
  classes: PropTypes.shape({
    input: PropTypes.string,
  }),
  width: PropTypes.string,
  /** margin for your input */
  margin: PropTypes.string,
  /** helper text for your input */
  helperText: PropTypes.string,
  /** add a component to show at the end of the input */
  endAdornment: PropTypes.node,
  /** set the value of the input out side of the form */
  setValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  /** MUI value to render TextArea */
  multiline: PropTypes.bool,

  tempValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
};

Input.defaultProps = {
  field: {
    input: {
      placeholder: '',
      type: 'text',
      value: undefined,
      name: '',
      label: '',
    },
  },
  classes: {},
  width: '100%',
  margin: '10px 0px 10px 0px',
  helperText: '',
  multiline: false,
  endAdornment: undefined,
  tempValue: undefined,
  setValue: undefined,
  disabled: false,
};
