import IconButton from '@material-ui/core/IconButton';
import Email from '@material-ui/icons/Email';
import axios from 'axios';
import PropTypes from 'prop-types';
import {useState} from 'react';
import {Field, Form} from 'react-final-form';
import Input from 'sharedComponents/FinalForm/Input/Input';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import validateEmail from 'utils/validateEmail';

export const onSubmit = async (values, toggleDialog) => {
  const query = {
    query: ` {
      sendDelegationAgreement (
          pubIndex: "${values.pubIndex}",
          email: "${values.email}"
        )
      }`,
  };

  let res;
  try {
    res = await axios.post('/graphql/', query);
    if (res.data.errors) {
      return res.data.errors;
    }
    return toggleDialog();
  } catch (error) {
    return error;
  }
};

export const SendDelegationAgreement = ({
  quote,
}) => {
  const [state, setState] = useState({open: false});
  const toggleDialog = () => setState({open: !state.open});

  return (
    <>
      <IconButton onClick={toggleDialog}>
        <Email fontSize="small" />
      </IconButton>
      <MuiDialog
        open={state.open}
        toggleDialog={toggleDialog}
        titleText="Email Delegation Agreement"
      >
        <Form
          onSubmit={(values => onSubmit(values, toggleDialog))}
          initialValues={{
            email: quote.quoteData.currentEmail,
            pubIndex: quote.pubIndex,
          }}
        >
          {({handleSubmit, submitting}) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                label="email"
                validate={value => (validateEmail(value) ? undefined : 'Enter a valid email')}
              >
                {field => (
                  <Input
                    width="95%"
                    field={field}
                  />
                )}
              </Field>
              <div>
                <Submit submitting={submitting}>
                  Send Email
                </Submit>
              </div>
            </form>
          )}
        </Form>
      </MuiDialog>
    </>
  );
};
export default SendDelegationAgreement;

SendDelegationAgreement.propTypes = {
  quote: PropTypes.shape({
    pubIndex: PropTypes.string,
    quoteData: PropTypes.shape({
      currentEmail: PropTypes.string,
    }),
  }),
};

SendDelegationAgreement.defaultProps = {
  quote: {},
};
