import axios from 'axios';
import PropTypes from 'prop-types';
import {Component} from 'react';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import MuiDialog from 'sharedComponents/MuiDialog/MuiDialog';
import AccountNoteList from './components/AccountNoteList/AccountNoteList';
import CreateNewNote from './components/CreateNewNote/CreateNewNote';

export class ChoiceAccountNotes extends Component {
  state = {
    loading: true,
    choiceAccountNotes: [],
  };

  async componentDidUpdate(prevProps) {
    if (prevProps.accountNum !== this.props.accountNum) {
      await this.choiceAccountNotesCall(this.props.accountNum);
    }
  }

  choiceAccountNotesCall = async (accountNum) => {
    this.setState({loading: true});
    const query = {
      query: `{
        choiceAccountNotes(accountNum: ${parseInt(accountNum, 10)}) {
           id
           accountNum
           note
           createdAt
           user {
            firstName
            lastName
           }
          }
        }`,
    };
    const res = await axios.post('/graphql/', query);
    if (res.data.errors) {
      return res.data.errors;
    }

    return this.setState({
      loading: false,
      choiceAccountNotes: res.data.data.choiceAccountNotes,
    });
  };

  addNewNoteToArray = (newNote) => {
    const {choiceAccountNotes} = this.state;
    choiceAccountNotes.unshift(newNote);
    this.setState({choiceAccountNotes});
  }

  render() {
    const {
      open,
      toggleDialog,
      accountNum,
    } = this.props;

    return (
      <MuiDialog
        open={open}
        toggleDialog={() => toggleDialog(accountNum)}
        titleText={this.props.accountNum}
      >
        {open && (
          <>
            {this.state.loading && <AppLoader loading={this.state.loading} />}
            <CreateNewNote
              accountNum={accountNum}
              callback={this.addNewNoteToArray}
            />
            {!this.state.loading && (<AccountNoteList notes={this.state.choiceAccountNotes} />)}
          </>
        )}
      </MuiDialog>
    );
  }
}

export default ChoiceAccountNotes;

ChoiceAccountNotes.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  accountNum: PropTypes.number,
};

ChoiceAccountNotes.defaultProps = {
  accountNum: undefined,
};
