import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import SelectField from 'sharedComponents/ReduxForms/SelectField/SelectField';
import styles from './PriceAndTermsFrom.module.scss';

export class PriceAndTermsFrom extends Component {
  static validate = (values) => {
    const errors = {};
    const requiredFields = [
      'terms',
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Required';
      }
    });
    return errors;
  };

  render() {
    const { terms } = this.props;
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Field
            name="terms"
            component={SelectField}
            options={terms}
            classes={styles}
            displayKey="description"
            selectLabel="terms"
          />
        </Grid>
      </Grid>
    );
  }
}


export default PriceAndTermsFrom;

PriceAndTermsFrom.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.shape({
    term: PropTypes.string,
    price: PropTypes.string,
  })),
};

PriceAndTermsFrom.defaultProps = {
  terms: [{
    term: 'N/A',
    price: 'N/A',
    description: 'unavailable at this time',
  }],
};
