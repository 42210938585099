import React, { Component } from 'react';
import { FieldArray } from 'redux-form';
import InputFieldArray from 'sharedComponents/ReduxForms/InputFieldArray/InputFieldArray';
import PlusOne from '@material-ui/icons/PlusOne';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import styles from './ArrayProp.module.scss';

export class ArrayProp extends Component {
  state = {};

  setFreshValue = (value) => {
    const dupValue = JSON.parse(JSON.stringify(value));
    Object.keys(dupValue).forEach((index) => { dupValue[index] = ''; });
    return dupValue;
  }

  addIndex = async (value, key) => {
    const freshValue = this.setFreshValue(value[0]);

    const newProps = {
      [key]: JSON.parse(JSON.stringify(value)),
    };

    newProps[key].push(freshValue);

    await this.props.changeIndex(newProps, this.props.form);
  }

  removeIndex = async (index, key, value) => {
    const removeIndex = value.filter((v, i) => i !== index && v);
    const newProps = { [key]: removeIndex };
    await this.props.changeIndex(newProps, this.props.form);
  }

  arrayProp = (value, key) => value.map((input, index) => (
    <Card key={`${key}-${index}`} className={styles.propCard}>
      <CardContent>
        {value.length > 1 && (
          <Grid
            container
            direction="row"
          >
            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              alignItems="center"
            >
              <CardActions>
                <Avatar
                  onClick={() => this.removeIndex(index, key, value)}
                  className={styles.onClick}
                >
                  <DeleteForever />
                </Avatar>
              </CardActions>
            </Grid>
          </Grid>
        )}
        {Object.keys(input).map((item, i) => (
          <Grid
            key={`${key}-${index}-${i}`}
            container
            direction="row"
            justify="center"
            alignItems="center"

          >
            <Grid item xs>
              <FieldArray
                component={InputFieldArray}
                name={`props.${key}`}
                objKey={item}
                label={item}
                tempValue={input[item]}
                index={index}
              />
            </Grid>
          </Grid>
        ))}
      </CardContent>
    </Card>
  ))

  render() {
    const { arrayPropkey, index, value } = this.props;
    return (
      <Grid
        container
        direction="row"
        key={`${arrayPropkey} ${index}`}
      >
        <Grid
          container
          direction="row"
          item
          xs={12}
        >
          <Avatar
            onClick={() => this.addIndex(value, arrayPropkey)}
            className={styles.onClick}
          >
            <PlusOne />
          </Avatar>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          styles={styles.propCard}
          item
        >
          {this.arrayProp(value, arrayPropkey, index)}
        </Grid>
      </Grid>
    );
  }
}
export default ArrayProp;

ArrayProp.propTypes = {
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
  ])).isRequired,
  arrayPropkey: PropTypes.string.isRequired,
  form: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  changeIndex: PropTypes.func.isRequired,
};

ArrayProp.defaultProps = {
};
