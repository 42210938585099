import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import validateEmail from 'utils/validateEmail';
import Grid from '@material-ui/core/Grid';
import Input from 'sharedComponents/ReduxForms/Input/Input';
import ChoicePromoCodeInput from 'sharedComponents/ChoicePromoCodeInput/ChoicePromoCodeInput';

export class ChoicePromoCodeForm extends Component {
  static validate = (values) => {
    const errors = {};
    // if (!validateEmail(values.email)) errors.email = 'Enter a valid email';
    if (!values.firstName) errors.firstName = 'required';
    if (!values.lastName) errors.lastName = 'required';
    return errors;
  };

  state = {}

  static initialValues = {};

  render() {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <ChoicePromoCodeInput
            name="promoCode"
            label="Promo Code"
            reactFinalFormInput={false}
            quote={this.props.quote}
          />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Field
            name="firstName"
            label="First Name"
            component={Input}
          />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Field
            name="lastName"
            label="Last Name"
            component={Input}
          />
        </Grid>
        <Grid item xs zeroMinWidth>
          <Field
            name="email"
            label="email"
            component={Input}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ChoicePromoCodeForm;

ChoicePromoCodeForm.propTypes = {
  quote: PropTypes.shape({}).isRequired,
};

ChoicePromoCodeForm.defaultProps = {};
