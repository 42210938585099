import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetailTable from './components/DetailTable/DetailTable';

export const DetailedReport = ({
  toggleDialog,
  open,
  accountList,
  formState,
  setTotal,
}) => (
  <>
    <Button color="primary" onClick={toggleDialog}>
      Secure Fixed Detailed Report
    </Button>
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>
        Secure Fixed Detailed Pricing Report
      </DialogTitle>
      <DialogContent>
        <DetailTable
          setTotal={setTotal}
          accountList={accountList}
          formState={formState}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id="CloseButton"
          onClick={() => {
            setTotal();
            toggleDialog();
          }}
          color="primary"
          autoFocus
        >
          Return to page
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

export default DetailedReport;

DetailedReport.propTypes = {
  setTotal: PropTypes.func.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  open: PropTypes.bool,
  accountList: PropTypes.arrayOf(PropTypes.shape({})),
  formState: PropTypes.shape({
    quoteForm: PropTypes.shape({
      values: PropTypes.shape({}),
    }),
  }).isRequired,
};

DetailedReport.defaultProps = {
  open: false,
  accountList: [],
};
