import {
  USER_SELECTED_CUSTOMER_REQUEST,
  USER_SELECTED_CUSTOMER_SUCCESS,
  USER_SELECTED_CUSTOMER_FAILURE,
} from '../constants/ActionTypes';

const initialState = { loading: true };

export const userSelectedCustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SELECTED_CUSTOMER_REQUEST:
      return { ...state, loading: true };
    case USER_SELECTED_CUSTOMER_SUCCESS:
      return { ...state, loading: false, ...action.data };
    case USER_SELECTED_CUSTOMER_FAILURE:
      return { ...state, loading: false, ...action.data };
    default:
      return state;
  }
};

export default userSelectedCustomerReducer;
