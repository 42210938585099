import React, { Component } from 'react';
import MuiTabs from 'sharedComponents/MuiTabs/MuiTabs';
import BheEmail from './components/BheEmail/BheEmail';
import BheResponse from './components/BheResponse/BheResponse';

export class ChoiceSelectionEmail extends Component {
  render() {
    return (
      <MuiTabs tabLabels={['BHE Email', 'Bhe Response']}>
        <BheEmail key="0" />
        <BheResponse key="1" />
      </MuiTabs>
    );
  }
}

export default ChoiceSelectionEmail;
