import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';
import Input from 'sharedComponents/FinalForm/Input/Input';
import arrayMutators from 'final-form-arrays';
import ReactDropZone from 'sharedComponents/FinalForm/ReactDropZone/ReactDropZone';
import ChoicePromoCodeInput from 'sharedComponents/ChoicePromoCodeInput/ChoicePromoCodeInput';
import validateEmail from 'utils/validateEmail';
import { Form, Field } from 'react-final-form';
import styles from './SetUpSelection.module.scss';

export class SetUpSelection extends Component {
  state = {
    error: null,
  };

  onSubmit = async (values) => {
    const query = {
      query: `mutation {
        createChoiceSelectionWithDocument(
          quoteId: "${values.quoteId}",
          email: "${values.email}",
          userName: "${values.userName}",
          documents: "${values.documents}",
          promoCode: "${values.promoCode ? values.promoCode : ''}"
        ) {
          id
          name
          createdAt
          quoteId
          choicePriceTypeId
          quotedPrice
          controlNumber
          confirmNumber
          posted
          emailAddress
          emailDate
          serviceState
          divisionRegion
          billClass
          accountNum
          progYear
          term
          transportCostUnit
          transportCostTotal
          margin
          bheConfirmCode
          totalPrice
          servicePointId
          documentUrl
          delegationAgreementUrl
          choicePriceType { name }
        }
      }`,
    };

    let res = null;

    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
      // TODO: airbrake and support email message
      this.setState({ error: 'Sorry something went wrong!' });
      return;
    }
    if (res.data.errors && res.data.errors.length > 0) {
      this.setState({ error: res.data.errors[0].message });
    } else if (res.data.data) {
      this.props.toggleAndDisplay(res.data.data.createChoiceSelectionWithDocument);
    }
  }

  required = value => (value ? undefined : 'Required');

  validateEmail = value => (validateEmail(value) ? undefined : 'Enter a valid email');

  documentRequired = (form) => {
    const { documents } = form.getState().values;
    return !(documents.length === 1);
  }

  render() {
    return (
      <>
        {this.props.quote.id && this.props.quote.quoteData.price && (
          <Form
            onSubmit={this.onSubmit}
            mutators={{ ...arrayMutators }}
            initialValues={{
              email: this.props.quote.quoteData.currentEmail,
              userName: `${this.props.quote.quoteData.accountList[0].firstName} ${this.props.quote.quoteData.accountList[0].lastName}`,
              quoteId: this.props.quote.id,
              documents: [],
              promoCode: '',
            }}
          >
            {({ handleSubmit, submitting, form }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <Field
                    name="userName"
                    label="Signers name"
                    type="text"
                    validate={this.required}
                  >
                    {field => <Input field={field} width="50%" />}
                  </Field>
                  <Field
                    name="email"
                    label="Signers email"
                    type="email"
                    validate={this.validateEmail}
                  >
                    {field => <Input field={field} width="50%" />}
                  </Field>
                </div>
                <div>
                  <ReactDropZone name="documents" acceptImageTypes={['application/pdf']} />
                </div>
                <ChoicePromoCodeInput
                  quote={this.props.quote}
                  reactFinalFormInput
                />
                {this.state.error && (<div className={styles.error}>{this.state.error}</div>)}
                <div>
                  <p>{this.documentRequired(form) ? 'One Delegation Agreement is required to creat selection' : '' }</p>
                  <Submit submitting={submitting} disabled={this.documentRequired(form)}>
                    Create Selection
                  </Submit>
                </div>
              </form>
            )}
          </Form>
        )}
      </>
    );
  }
}

export default SetUpSelection;

SetUpSelection.propTypes = {
  toggleAndDisplay: PropTypes.func.isRequired,
  quote: PropTypes.shape(),
};

SetUpSelection.defaultProps = {
  quote: {},
};
