import {
  CUSTOMERS_REQUEST,
  CUSTOMERS_SUCCESS,
  CUSTOMERS_FAILURE,
} from '../constants/ActionTypes';

const initialState = { loading: true };

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case CUSTOMERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    case CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.data,
      };
    default:
      return state;
  }
}
