import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
} from "@material-ui/core";
import axios from "axios";
import PropTypes from "prop-types";
import queryString from "query-string";
import { Component, createRef } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import graphqlObj from "utils/graphqlObj";
import styles from "./ChoiceSignup.module.scss";
import ChoiceForm from "./components/ChoiceForm/ChoiceForm";
import ChoiceTabs from "./components/ChoiceTabs/ChoiceTabs";

export class ChoiceSignup extends Component {
  constructor(props) {
    super(props);
    this.myRef = createRef();
    this.state = {
      preloadQuoteId: queryString.parse(window.location.search)?.quote || "",
      searchResults: [],
      choicePriceTypes: [],
      quote: {
        quoteData: {
          accountList: [{}],
        },
      },
      openControlNumberForm: false,
      enrolledCompleteDialog: false,
      complete: false,
      error: null,
      agr: this.props.agr,
    };
  }

  componentDidMount() {
    this.loadChoicePriceTypes();
    const { preloadQuoteId } = this.state;
    const param = queryString.parse(window.location.search)?.billClass;
    if (preloadQuoteId) this.loadQuote(preloadQuoteId);
    if (param === "AGR") {
      this.setState({ agr: true });
    }
  }

  whereBillClasses = () => {
    if (this.state.agr) {
      return `billClasses: ${graphqlObj(["AGR"])}`;
    }
    return `billClasses: ${graphqlObj(["COM", "RES", "IND"])}`;
  };

  executeScroll = (block) => {
    // TODO: HOTfix to get quotes to load in
    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: "smooth", block });
    }, 1000);
  };

  setSearchResults = (newResults) => {
    if ((newResults && newResults.length === 0) || !newResults) {
      return this.setState({
        error: "No locations found",
      });
    }

    this.setState((oldState) => ({
      searchResults: [...newResults, ...oldState.searchResults].filter(
        (elem, index, self) =>
          self.findIndex(
            (item) => item.premiseNumber === elem.premiseNumber
          ) === index
      ),
      error: null,
    }));
    this.executeScroll("nearest");
    return this.state.searchResults;
  };

  clearQuote = () => {
    this.setState({
      quote: {
        quoteData: {
          accountList: [{}],
        },
      },
      openControlNumberForm: false,
      enrolledCompleteDialog: false,
      complete: false,
      error: null,
    });
  };

  resetForm = () => {
    this.props.dispatch(reset("ChoiceForm"));
    this.clearQuote();
    this.setState({ searchResults: [] });
  };

  loadLocations = async (searchValue, searchKey = "accountNums") => {
    const billClasses = this.whereBillClasses();
    const query = {
      query: `{
        sgCustomer(
          ${searchKey}: ${
        searchKey === "accountNum" ? searchValue : `"${searchValue}"`
      }
          ${billClasses}
          loggedIn: ${true}
          balloted: ${false}
        ) {
          id
          billClass
          serviceAddress
          serviceCity
          serviceState
          serviceZip
          premiseNumber
          accountNum
          firstName
          lastName
          progYear
          totalUsage
        janPremUsage
          febPremUsage
          marPremUsage
          aprPremUsage
          mayPremUsage
          junPremUsage
          julPremUsage
          augPremUsage
          sepPremUsage
          octPremUsage
          novPremUsage
          decPremUsage
          emailAddress
        }
      }`,
    };

    let res;
    try {
      res = await axios.post("/graphql/", query);
    } catch (err) {
      // TODO: airbrake and support email message
      this.setState({ error: err.toString() });
      return;
    }
    this.setSearchResults(res.data.data.sgCustomer);
  };

  loadChoicePriceTypes = async () => {
    const query = {
      query: `{
        choicePriceTypes( nameArray: ["Guaranteed Fixed", "Guaranteed Index", "Secure Fixed"] ) {
          id
          name
          specificLegal
        }
      }`,
    };

    let res;
    try {
      res = await axios.post("/graphql/", query);
    } catch (err) {
      // TODO: airbrake and support email message
      this.setState({ error: err.toString() });
      return;
    }

    const { choicePriceTypes } = res.data.data;
    this.setState({ choicePriceTypes });
  };

  loadQuote = async (id = null, searchKey = "pubIndex") => {
    this.setState({ error: null });

    if (id) {
      const query = {
        query: `{
          choicePriceQuote(${searchKey}: ${
          searchKey === "id" ? id : `"${id}"`
        }) {
            id
            pubIndex
            premises
            greenPlus
            updatedAt
            choiceQuoteNotes {
              note
              createdAt
              user {
                firstName
                lastName
              }
            }
            quoteData {
              totalUsages
              choicePriceTypeId
              expirationDate
              acctNums
              selectedPrice
              baseIndex
              baseTpFuelIndex
              baseTpFuelIndex2
              baseTpFuelIndex3
              baseTpIndex
              currentEmail
              fmb1
              fmb1Total
              fmb2
              fmb2Total
              fmb3
              fmb3Total
              fpPriceYr1NoAdders
              fpPriceYr2NoAdders
              gFixed1
              gFixed2
              gFixed3
              blended1
              blended2
              blended3
              paramType
              price
              program
              term
              transportCostTotal
              transportCostUnit
              volume
              mthlyVolume
              managedPrice
              accountList {
                mailingAddress
                mailingCityState
                mailingZip
                accountNum
                baseIndex
                baseTpFuelIndex
                baseTpIndex
                billClass
                cogPremium
                curDbYear
                divisionRegion
                firstName
                fmb1
                fmb2
                fmb3
                fpPriceYrNoAdders1
                fpPriceYrNoAdders2
                fuelPercent
                gFixed1
                gFixed2
                gFixed3
                blended1
                blended2
                blended3
                lastName
                premiseNumber
                result
                riskFee
                serviceState
                shellFee
                supplierFee
                transportCostTotal
                transportCostUnit
                vFixed1
                vFixed2
                volume
                status
                complete
                verificationId
                controlId
                errorCode
                formattedAddress
              }
            }
          }
        }`,
      };

      let res = null;

      try {
        res = await axios.post("/graphql/", query);
      } catch (err) {
        // TODO: airbrake and support email message
        this.setState({ error: err.toString() });
        return;
      }
      if (res.data.errors && res.data.errors.length > 0) {
        this.setState({ error: res.data.errors[0].message });
      } else if (
        res.data.data.choicePriceQuote &&
        res.data.data.choicePriceQuote.id
      ) {
        this.setQuote(res.data.data.choicePriceQuote);
        await this.loadLocations(
          res.data.data.choicePriceQuote.premises.join(),
          "premiseNums"
        );
      }
    }
  };

  setQuote = (quote) => {
    if (quote) {
      this.setState({ quote });
      this.state.agr = quote.quoteData.accountList[0].billClass == "AGR";
      const isDone = quote.quoteData.accountList.every(
        (account) => account.complete
      );
      if (isDone && quote.quoteData.paramType) {
        this.setState({
          complete: true,
          openControlNumberForm: true,
          enrolledCompleteDialog: true,
        });
      }
      if (!isDone) {
        if (quote.quoteData.paramType && !this.state.openControlNumberForm) {
          this.setState({ openControlNumberForm: true });
        }
      }
      this.executeScroll("start");
    }
  };

  toggleDialog = () => {
    this.setState((oldState) => ({
      enrolledCompleteDialog: !oldState.enrolledCompleteDialog,
    }));
  };

  clearError = () => {
    this.setState({ error: null });
  };

  handleSwitchChange = () => {
    this.setState((prevState) => ({ agr: !prevState.agr }));
    this.resetForm();
  };

  render() {
    return (
      <div>
        <Card className={styles.card}>
          <Grid container direction="row" justify="flex-end">
            <section>
              <Grid container direction="row">
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.agr}
                      onChange={this.handleSwitchChange}
                      color="primary"
                    />
                  }
                  label={this.state.agr ? "AGR" : "RES/COM"}
                />
                <Button
                  color="primary"
                  onClick={this.resetForm}
                  variant="contained"
                  size="small"
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{ marginLeft: "10px" }}
                >
                  <a href="tel:1877-790-4990" className={styles.phoneLink}>
                    1 877-790-4990
                  </a>
                </Button>
              </Grid>
            </section>
          </Grid>
          <CardContent>
            <>
              <ChoiceForm
                whereBillClasses={this.whereBillClasses}
                quote={this.state.quote}
                clearQuote={this.clearQuote}
                loadQuote={this.loadQuote}
                loadLocations={this.loadLocations}
                setSearchResults={this.setSearchResults}
                formState={this.props.formState}
                clearError={this.clearError}
              />
              {this.state.error && (
                <span className={styles.error}>{this.state.error}</span>
              )}
            </>
          </CardContent>
          {this.state.searchResults.length > 0 &&
            this.state.choicePriceTypes.length > 0 && (
              <CardContent>
                <ChoiceTabs
                  scrollRef={this.myRef}
                  resetForm={this.resetForm}
                  preloadQuoteId={this.state.preloadQuoteId}
                  choicePriceTypes={this.state.choicePriceTypes}
                  complete={this.state.complete}
                  openControlNumberForm={this.state.openControlNumberForm}
                  quote={this.state.quote}
                  setQuote={this.setQuote}
                  formState={this.props.formState}
                  resComSearchResults={this.state.searchResults.filter(
                    (results) => results.billClass !== "AGR"
                  )}
                  agrSearchResults={this.state.searchResults.filter(
                    (results) => results.billClass === "AGR"
                  )}
                  enrolledCompleteDialog={this.state.enrolledCompleteDialog}
                  toggleEnrolledCompleteDialog={this.toggleDialog}
                />
              </CardContent>
            )}
        </Card>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
  };
}

export default connect(mapStateToProps)(ChoiceSignup);

ChoiceSignup.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formState: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  agr: PropTypes.bool,
};

ChoiceSignup.defaultProps = {
  agr: false,
};
