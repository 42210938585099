import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Whatshot from '@material-ui/icons/Whatshot';
import PropTypes from 'prop-types';

const Choice = ({reactChangeRoute}) => {
  return (
    <List component="nav">
      <ListItem button onClick={() => {reactChangeRoute('choice');}}>
        <ListItemIcon>
          <Whatshot />
        </ListItemIcon>
        <ListItemText inset primary="Choice Program" />
      </ListItem>
    </List>
  );
};

export default Choice;

Choice.propTypes = {
  reactChangeRoute: PropTypes.func.isRequired,
};
