import {
  SET_THEME_REQUEST,
  SET_THEME_SUCCESS,
} from '../constants/ActionTypes';

const initialState = { isLoading: true, theme: {} };

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_THEME_SUCCESS:
      return {
        ...state,
        theme: action.theme,
        isLoading: false,
      };
    default:
      return state;
  }
}
