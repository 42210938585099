import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FieldArray } from 'react-final-form-arrays';
import Thumbs from './components/Thumbs/Thumbs';
import DisplayErrorMessage from './components/DisplayErrorMessage/DisplayErrorMessage';
import styles from './ReactDropZone.module.scss';

export const removeFilesFromForm = (oldFiles, fields) => {
  // eslint-disable-next-line no-unused-vars
  oldFiles.forEach((_value, _index) => {
    fields.remove(0); // empty array
  });
};

export const addFilesToFrom = async (fileArray, fields) => {
  const promises = [];
  fileArray.forEach((file) => {
    const reader = new FileReader(); // eslint-disable-line
    return new Promise((resolve) => {
      reader.onload = (event) => {
        fields.push(event.target.result);
        promises.push(event.target.result);
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  });
  await Promise.all(promises);
};

export const ReactDropZone = ({ loading, name, acceptImageTypes }) => {
  const [state, setState] = useState({
    files: [],
    errorMessage: '',
    fields: null,
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxSize: 2000000,
    accept: acceptImageTypes.join(', '),
    // eslint-disable-next-line
    onDrop: async (acceptedFiles, rejectedFiles) => {
      const oldFiles = state.files;
      removeFilesFromForm(oldFiles, state.fields);


      if (rejectedFiles.length > 0) {
        return setState({ ...state, errorMessage: 'Image too large. Please select an image up to 2MB.' });
      }

      if (rejectedFiles.length === 0) {
        setState({ ...state, errorMessage: '' });
      }

      const fileArray = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }));

      setState({
        ...state,
        oldFiles,
        files: fileArray,
      });

      await addFilesToFrom(fileArray, state.fields);
    },
  });

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '70px 80px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const activeStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#2CB2D0',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isDragActive,
    isDragReject,
    activeStyle,
    baseStyle,
    isDragAccept,
    rejectStyle,
    acceptStyle,
  ]);

  const setFieldsToState = (fields) => {
    if (state.fields) return;
    setState({ ...state, fields });
  };

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    state.files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [state.files]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <FieldArray name={name}>
      { ({ fields }) => {
        setFieldsToState(fields);
        return (
          <section className="container">
            <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <Typography variant="subtitle1" className={styles.text}>
                Drag &#39;n&#39; drop some files here, or click to select files
              </Typography>
            </div>
            <Thumbs files={state.files} />
            <DisplayErrorMessage errorMessage={state.errorMessage} />
          </section>
        );
      }}
    </FieldArray>
  );
};

export default ReactDropZone;

ReactDropZone.propTypes = {
  loading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  acceptImageTypes: PropTypes.arrayOf(PropTypes.string),
};

ReactDropZone.defaultProps = {
  loading: false,
  acceptImageTypes: ['image/jpeg', 'image/png', 'application/pdf'],
};
