import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Clear from '@material-ui/icons/Clear';
import Avatar from '@material-ui/core/Avatar';
import styles from './Left.module.scss';

export const Left = ({
  leftView,
  toggleView,
  leftComponent,
  title,
}) => (
  <Grid
    container
    item
    xs={12}
    md={3}
    style={{
      display: leftView ? 'block' : 'none',
    }}
  >
    <Card className={styles.card}>
      <CardHeader
        title={title}
        avatar={(
          <Avatar
            className={styles.onClick}
            onClick={() => toggleView('leftView')}
          >
            <Clear />
          </Avatar>
        )}
      />
      <CardContent>
        {leftComponent}
      </CardContent>
    </Card>
  </Grid>
);

export default Left;

Left.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  leftView: PropTypes.bool,
  toggleView: PropTypes.func.isRequired,
  leftComponent: PropTypes.node,
};

Left.defaultProps = {
  title: '',
  leftComponent: null,
  leftView: false,
};
