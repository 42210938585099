import React, { Component } from 'react';
import axios from 'axios';
import graphqlObj from 'utils/graphqlObj';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FileCopy from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RDGridHelpers from 'utils/RDGridHelpers';
import MuiTablePagination from 'sharedComponents/MuiTablePagination/MuiTablePagination';

export class ViewBatches extends Component {
  state = {
    nachaBatches: [],
    columns: [
      {
        key: 'batchStatus',
        label: 'Status',
        format: (value, row) => (
          <Select
            value={value}
            onChange={e => this.handleChange(row, e)}
            disabled={value === 'batch rejected'}
          >
            <MenuItem value="batch created">batch created</MenuItem>
            <MenuItem value="batch rejected">batch rejected</MenuItem>
          </Select>
        ),
      },
      { key: 'description', label: 'description' },
      { key: 'invoiceCount', label: 'invoice Count' },
      {
        key: 'totalDebit',
        label: 'total Debit',
        format: value => RDGridHelpers.formatNumber(value),
      },
      { key: 'createdAt', label: 'created at' },
      {
        key: 'nachaFileUrl',
        label: 'nacha file',
        format: (value, row) => (
          <IconButton aria-label="delete" onClick={() => this.nachaBatch(row.id, 'nachaFileUrl')}>
            <FileCopy />
          </IconButton>
        ),
      },
      {
        key: 'denaliFileUrl',
        label: 'BC file',
        format: (value, row) => (
          <IconButton aria-label="delete" onClick={() => this.nachaBatch(row.id, 'denaliFileUrl')}>
            <FileCopy />
          </IconButton>
        ),
      },
      {
        key: 'customerCsvUrl',
        label: 'customer csv',
        format: (value, row) => (
          <IconButton aria-label="delete" onClick={() => this.nachaBatch(row.id, 'customerCsvUrl')}>
            <FileCopy />
          </IconButton>
        ),
      },
    ],
  };

  async componentDidMount() {
    await this.nachaBatches();
  }

  setNachaBatch = (upsertNachaBatch, nachaBatch) => this.setState(oldState => ({
    nachaBatches: oldState.nachaBatches.map((batch) => {
      if (batch.id === nachaBatch.id) {
        return upsertNachaBatch;
      }
      return batch;
    }),
  }));

  handleChange = async (row, e) => {
    const nachaBatch = { ...row, ...{ batchStatus: e.target.value } };
    await this.upsertNachaBatch(nachaBatch);
  }

  upsertNachaBatch = async (nachaBatch) => {
    let res;
    try {
      const queryString = {
        query: `mutation {
        upsertNachaBatch(
          nachaBatch: ${graphqlObj(nachaBatch)},
        ) {
          id
          createdAt
          description
          invoiceCount
          effectiveDate
          batchStatus
          totalDebit
        }
      }`,
      };
      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return error;
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return new Error(res.data.errors[0].message);
    }

    return this.setNachaBatch(res.data.data.upsertNachaBatch, nachaBatch);
  }

  nachaBatches = async () => {
    let res;
    try {
      const queryString = {
        query: `{
        nachaBatches {
         id
         createdAt
         description
         invoiceCount
         effectiveDate
         batchStatus
         totalDebit
        } 
      }`,
      };
      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return error;
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return new Error(res.data.errors[0].message);
    }
    const { nachaBatches } = res.data.data;
    return this.setState({ nachaBatches });
  }

  nachaBatch = async (id, file = 'nachaFileUrl') => {
    let res;
    try {
      const queryString = {
        query: `{
        nachaBatch(id: ${id}) {
         id
         createdAt
         description
         effectiveDate
         batchStatus
         totalDebit
         nachaFileUrl
         denaliFileUrl
         customerCsvUrl
        } 
      }`,
      };
      res = await axios.post('/graphql/', queryString);
    } catch (error) {
      return error;
    }

    if (res.data.errors && res.data.errors.length > 0) {
      return new Error(res.data.errors[0].message);
    }

    const { nachaBatch } = res.data.data;
    return this.download(nachaBatch[file]);
  }

  download = (file) => {
    const a = document.createElement('a');
    a.href = file;
    a.click();
  }

  render() {
    return (
      <Paper>
        <MuiTablePagination
          columns={this.state.columns}
          rows={this.state.nachaBatches}
        />
      </Paper>
    );
  }
}

export default ViewBatches;

ViewBatches.propTypes = {};

ViewBatches.defaultProps = {};
